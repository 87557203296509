import React, { useContext, useState, useMemo, useRef } from 'react';
import BigNumber from 'bignumber.js';
import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import { RoleBreakdownParameters } from 'generated-types';

export type SaveDraftContextValue = {
  snapToNearestXValue: BigNumber;
  setSnapToNearestXValue: (snapToNearest: BigNumber) => void;
  globalUtilization: BigNumber;
  setGlobalUtilization: (globalUtilization: BigNumber) => void;
  roleDataParameters: RoleBreakdownParameters[];
  setRoleDataParameters: (roles: RoleBreakdownParameters[]) => void;
  hasBusinessCostChanges: boolean;
  setHasBusinessCostChanges: (hasBusinessCostChanges: boolean) => void;
  hasRoleCostChanges: boolean;
  setHasRoleCostChanges: (hasRoleChanges: boolean) => void;
  hasSnapToNearestChanges: boolean;
  setHasSnapToNearestChanges: (snapToNearestChanges: boolean) => void;
  resetRoleFormRef: React.MutableRefObject<() => void>;
  billingRateValue: BigNumber;
  setBillingRateValue: (value: BigNumber) => void;
};

const SaveDraftContext = React.createContext<SaveDraftContextValue | undefined>(
  undefined
);
export const useSaveDraftContext = (): SaveDraftContextValue => {
  const val = useContext(SaveDraftContext);

  if (!val) {
    throw new Error(
      'useSaveDraftContext must be called within a SaveDraftContextProvider.'
    );
  }

  return val;
};

const emptyFn = () => {
  return;
};

export const SaveDraftContextProvider: React.FC = props => {
  const { children } = props;

  const [roleDataParameters, setRoleDataParameters] = useState(
    [] as RoleBreakdownParameters[]
  );
  const [snapToNearestXValue, setSnapToNearestXValue] = useState<BigNumber>(
    new BigNumber(0)
  );
  const [globalUtilization, setGlobalUtilization] = useState<BigNumber>(
    RoleBillingRateCalculator.DEFAULT_UTILIZATION.multipliedBy(100)
  );

  const [hasBusinessCostChanges, setHasBusinessCostChanges] = useState(false);
  const [hasRoleCostChanges, setHasRoleCostChanges] = useState(false);
  const [hasSnapToNearestChanges, setHasSnapToNearestChanges] = useState(false);
  const [billingRateValue, setBillingRateValue] = useState(new BigNumber(0));
  const formResetRef = useRef(emptyFn);

  const saveDraftContextValue = useMemo<SaveDraftContextValue>(
    () => ({
      snapToNearestXValue,
      setSnapToNearestXValue,
      globalUtilization,
      setGlobalUtilization,
      roleDataParameters: roleDataParameters,
      setRoleDataParameters: setRoleDataParameters,
      hasSnapToNearestChanges,
      setHasSnapToNearestChanges,
      hasBusinessCostChanges: hasBusinessCostChanges,
      setHasBusinessCostChanges: setHasBusinessCostChanges,
      hasRoleCostChanges: hasRoleCostChanges,
      setHasRoleCostChanges: setHasRoleCostChanges,
      resetRoleFormRef: formResetRef,
      billingRateValue,
      setBillingRateValue
    }),
    [
      billingRateValue,
      globalUtilization,
      hasBusinessCostChanges,
      hasRoleCostChanges,
      hasSnapToNearestChanges,
      roleDataParameters,
      snapToNearestXValue
    ]
  );
  return (
    <SaveDraftContext.Provider value={saveDraftContextValue}>
      {children}
    </SaveDraftContext.Provider>
  );
};
