import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import {
  Date,
  DateRange,
  HistoryExpansionDetailsSummary
} from 'common/components';
import { useHasCpoEditPermission } from 'common/hooks';
import { CostOptimizationResult } from 'generated-types';
import { RateCardButtonRow } from 'rateCard';
import { UpcomingPanelActions } from './UpcomingPanelActions';
import {
  useExpansionPanelSummaryStyles,
  useRateCardButtonRowStyles,
  useStyles
} from './useStyle';

const expansionPanelTransitionProps = { unmountOnExit: true };

const useDateSytles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(1.5)
  },
  date: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary
  }
}));

type UpcomingPanelContentProps = {
  costOptimizationResult: CostOptimizationResult;
  getNextRateCard: (id: string) => CostOptimizationResult | null;
};

export const UpcomingPanelContent: React.FC<UpcomingPanelContentProps> = ({
  costOptimizationResult,
  getNextRateCard
}: UpcomingPanelContentProps) => {
  const classes = useStyles();
  const dateClasses = useDateSytles();
  const expansionPanelSummaryClasses = useExpansionPanelSummaryStyles();
  const rateCardButtonRowClasses = useRateCardButtonRowStyles();

  const [expanded, setExpanded] = React.useState<boolean>(false);
  const expansionPanelChangeHandler = useCallback(
    (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpanded(isExpanded);
    },
    [setExpanded]
  );

  const hasCpoEditPermission = useHasCpoEditPermission();
  return (
    <>
      <Paper elevation={4} square className={classes.paper}>
        <Accordion
          onChange={expansionPanelChangeHandler}
          TransitionProps={expansionPanelTransitionProps}
          className={classes.expansionPanel}>
          <AccordionSummary classes={expansionPanelSummaryClasses}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              className={classes.gridContainer}>
              <Grid item>
                <IconButton
                  className={classes.expandIcon}
                  aria-label={`${costOptimizationResult.fileName} - ${costOptimizationResult.effectiveDate} / ${costOptimizationResult.endDate}`}>
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Grid>
              <Grid item className={classes.fileNameContainer}>
                <Tooltip title={costOptimizationResult.fileName ?? ''}>
                  <Typography noWrap className={classes.fileNameActive}>
                    {costOptimizationResult.fileName}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={2} className={classes.dateContainer}>
                <Typography className={classes.dateTitle}>
                  <FormattedMessage id="upcoming.effectiveDate" />
                </Typography>
                <DateRange
                  startDate={costOptimizationResult.effectiveDate ?? ''}
                  endDate={costOptimizationResult.endDate ?? ''}
                  classes={dateClasses}
                />
              </Grid>
              <Grid item xs={2} className={classes.dateContainer}>
                <Typography className={classes.dateTitle}>
                  <FormattedMessage id="upcoming.uploaded" />
                </Typography>
                {costOptimizationResult.lastModified ? (
                  <Date
                    className={classes.date}
                    date={costOptimizationResult.lastModified}
                  />
                ) : (
                  <>&mdash;</>
                )}
              </Grid>
              <Grid item xs={2} className={classes.commited}>
                <Typography className={classes.commitedBy}>
                  <FormattedMessage id="upcoming.committedBy" />
                </Typography>
                <Typography className={classes.uploaded}>
                  {costOptimizationResult.uploadedBy?.displayText}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <RateCardButtonRow
            costOptimizationResultId={costOptimizationResult.id}
            className={rateCardButtonRowClasses.buttonRow}
          />
          <HistoryExpansionDetailsSummary id={costOptimizationResult.id} />
          {hasCpoEditPermission && (
            <UpcomingPanelActions
              costOptimizationResult={costOptimizationResult}
              getNextRateCard={getNextRateCard}
            />
          )}
        </Accordion>
      </Paper>
    </>
  );
};
