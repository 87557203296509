import React from 'react';

import { PlanStatus } from 'generated-types';
import { ProgressButton } from 'common/components';
import { CommittingDialog, UpdateActivePlanDialog } from 'rateCard';
import { useSaveAndCommit } from 'plans/plan/hooks';
import { useDialogState, useRedirect } from 'common/hooks';
import { useHasBlockingErrors } from 'plans/hooks';
import { usePlanContext } from './context';
import { SetPlanWarningDialog } from './components/SetPlanWarningDialog';

const voidFn = () => undefined;

const PlanSetRatesButton: React.FC = () => {
  const { plan } = usePlanContext();

  const {
    open: setPlanWarningOpen,
    openDialog: openSetPlanWarningDialog,
    closeDialog: closeSetPlanWarningDialog
  } = useDialogState();

  const {
    open: committingOpen,
    openDialog: openCommittingDialog,
    closeDialog: closeComittingDialog
  } = useDialogState();

  const {
    open: updateOpen,
    openDialog: openUpdateDialog,
    closeDialog: closeUpdateDialog
  } = useDialogState();

  const redirectToList = useRedirect('/plans');

  const {
    onSaveClick,
    loading,
    progressPercentage,
    commitFailure,
    commitSuccess
  } = useSaveAndCommit(plan, {
    beforeSave: closeSetPlanWarningDialog,
    beforeCommit: openCommittingDialog
  });

  const hasBlockingErrors = useHasBlockingErrors(
    plan?.overlappingPlansWarnings,
    plan?.status
  );

  return (
    <>
      <ProgressButton
        color="primary"
        variant="contained"
        labelId="planSetRatesAction.label"
        disabled={Boolean(!plan || hasBlockingErrors)}
        loading={loading}
        onClick={
          plan?.status !== PlanStatus.Draft
            ? openUpdateDialog
            : openSetPlanWarningDialog
        }
      />
      {plan?.status !== PlanStatus.Draft ? (
        <UpdateActivePlanDialog
          open={updateOpen}
          closeDialog={closeUpdateDialog}
          onSaveClick={onSaveClick}
        />
      ) : (
        <SetPlanWarningDialog
          closeDialog={closeSetPlanWarningDialog}
          onSetPlanClick={onSaveClick}
          open={setPlanWarningOpen}
        />
      )}
      <CommittingDialog
        open={committingOpen}
        committing={loading}
        closeDialog={closeComittingDialog}
        commitFailure={commitFailure}
        commitSuccess={commitSuccess}
        handleFailure={voidFn}
        handleSuccess={redirectToList}
        progressPercentage={progressPercentage}
      />
    </>
  );
};

export default PlanSetRatesButton;
