import React from 'react';
import { CellProps } from 'react-table';
import { TooltipMoney } from 'common/components';
import { BillingByRoleData } from 'common/types';
import { useRoleTableFormContext } from 'plans/plan/context';

export const RevenueCell: React.FC<CellProps<BillingByRoleData>> = ({
  value,
  row
}: CellProps<BillingByRoleData>) => {
  const { getValues } = useRoleTableFormContext();

  return (
    <TooltipMoney
      currencySymbol={value?.currency?.symbol ?? ''}
      value={getValues?.()[row.original.id].revenue}
      placement="right"
    />
  );
};
