import React, { useMemo } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { OverviewLegend } from 'overview/hooks';
import { Legend } from './Legend';

const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(4),
    justifyContent: 'flex-end',
    flexWrap: 'wrap'
  },
  legendText: {}
}));

type LegendsProps = {
  className?: string;
  legends: OverviewLegend[];
};

export const Legends: React.FC<LegendsProps> = ({
  legends,
  className
}: LegendsProps) => {
  const classes = useStyle();
  const chartLegendClasses = useMemo(
    () => ({ legendText: classes.legendText }),
    [classes.legendText]
  );

  return (
    <div className={classnames(classes.container, className)}>
      {legends.map(legend => {
        return (
          <Legend
            classes={chartLegendClasses}
            key={legend.label}
            label={legend.label}
            color={legend.color}
          />
        );
      })}
    </div>
  );
};
