import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { useIsBreakpointDown } from 'common/hooks';
import { Label, MoneyValue, NumberValue } from 'common/components';
import { Money } from 'generated-types';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    ...theme.typography.body1,
    padding: theme.spacing(1.5, 2, 0, 2)
  },
  dialogContent: {
    ...theme.typography.body1,
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: '0 0 20px 0'
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  table: {
    width: '100%'
  },
  exchangeData: {
    width: '35%'
  },
  currencyData: {
    width: '31%'
  },
  numeric: {
    textAlign: 'right'
  },
  divider: {
    marginTop: theme.spacing(2),
    height: 2,
    width: '100%'
  },
  summary: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export interface ExportRateDialogProps {
  open: boolean;
  closeDialog: () => void;
  currencies: (Money | undefined | null)[] | null | undefined;
  baseCurrency?: string;
  totalCost?: Money | null;
}

export const ExchangeRateDialog: React.FC<ExportRateDialogProps> = ({
  currencies,
  open,
  closeDialog,
  baseCurrency,
  totalCost
}: ExportRateDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Label variant="h6" labelId="exchangeRateDialog.title" />
        <Label
          variant="body1"
          className={classes.info}
          labelId="exchangeRateDialog.baseCurrency">
          {baseCurrency}
        </Label>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classnames(classes.rowTitle, classes.numeric)}>
                <FormattedMessage id="exchangeRateDialog.totalLoaded" />
              </th>
              <th className={classnames(classes.rowTitle, classes.numeric)}>
                <FormattedMessage id="exchangeRateDialog.exchangeRate" />
              </th>
              <th className={classnames(classes.rowTitle, classes.numeric)}>
                <FormattedMessage id="exchangeRateDialog.totalBase" />
                {baseCurrency}
              </th>
            </tr>
          </thead>
          <tbody>
            {currencies?.map(c => (
              <tr key={c?.uri ?? ''}>
                <td
                  className={classnames(classes.currencyData, classes.numeric)}>
                  <MoneyValue
                    value={c?.amount?.toNumber() ?? 0}
                    currencySymbol={c?.currency?.symbol}
                  />
                </td>
                <td
                  className={classnames(classes.exchangeData, classes.numeric)}>
                  <NumberValue value={c?.toBaseCurrency?.exchangeRate ?? 0} />
                </td>
                <td className={classes.numeric}>
                  <MoneyValue
                    value={c?.toBaseCurrency?.value?.amount?.toNumber() ?? 0}
                    currencySymbol={c?.toBaseCurrency?.value?.currency?.symbol}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Divider className={classes.divider} />
        <div className={classes.summary}>
          <div className={classnames(classes.rowTitle, classes.numeric)}>
            <FormattedMessage id="exchangeRateDialog.totalBase" />
            {baseCurrency}
          </div>
          <MoneyValue
            value={totalCost?.amount?.toNumber() ?? 0}
            currencySymbol={totalCost?.currency?.symbol}
          />
        </div>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            <FormattedMessage id="exchangeRateDialog.close" />
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ExchangeRateDialog;
