import React, { useContext } from 'react';
import { FieldValues } from 'react-hook-form';
import { Maybe } from 'generated-types';
import { ColumnOnChange } from 'common/types';

export type RoleTableFormContextValue = {
  getValues: () => FieldValues;
  onUtilizationChangeHandler: ColumnOnChange;
  onBillingRateChangeHandler: ColumnOnChange;
  onMarginChangeHandler: ColumnOnChange;
  onGlobalUtilizationChangeHandler: (value: string) => void;
  onGlobalBillingRateChangeHandler: (value: string) => void;
  onGlobalMarginChangeHandler: (value: string) => void;
  snapToNearestX: (value: string) => void;
  nearestXValue: string;
  setValues: (values: Record<string, any>) => void;
};

const RoleTableFormContext = React.createContext<
  Maybe<RoleTableFormContextValue>
>(null);

const useRoleTableFormContext = (): RoleTableFormContextValue => {
  const val = useContext(RoleTableFormContext);
  if (!val) {
    throw new Error(
      'useRoleTableFormContext must be called within a RoleTableFormContext .'
    );
  }

  return val;
};

const RoleTableFormContextProvider = RoleTableFormContext.Provider;
const RoleTableFormContextConsumer = RoleTableFormContext.Consumer;

export {
  RoleTableFormContext,
  useRoleTableFormContext,
  RoleTableFormContextProvider,
  RoleTableFormContextConsumer
};
