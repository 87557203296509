import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Role } from 'generated-types';
import { RoleFileName } from './RoleFileName';
import { RoleName } from './RoleName';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    display: 'flex',
    padding: theme.spacing(1, 3),
    textAlign: 'center'
  },
  text: {
    display: 'flex',
    padding: theme.spacing(1.5, 1)
  }
}));

type RoleSummaryInfoProps = {
  costOptimizationResultId: string;
  roleData: Maybe<Role>;
  fileName: Maybe<string>;
  onNavigateBack: () => void;
  editing?: boolean;
};
export const RoleSummaryInfo: React.FC<RoleSummaryInfoProps> = ({
  roleData,
  fileName,
  onNavigateBack
}: RoleSummaryInfoProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton onClick={onNavigateBack}>
        <ArrowBackIcon fontSize="small" />
      </IconButton>
      <div className={classes.text}>
        <RoleFileName fileName={fileName ?? ''} />/
        <RoleName displayText={roleData?.displayText ?? ''} />
      </div>
    </div>
  );
};
