import React, { useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { Label } from 'common/components';
import { ReactComponent as CommitSuccessIcon } from './CommitSuccess.svg';
import { ReactComponent as CommitFailureIcon } from './CommitFailed.svg';
import { CommittingAnimationIcon } from './CommittingIcon';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: theme.spacing(2, 4, 0, 4)
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2, 4, 4, 4)
  },
  message: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800]
  },
  commitProgress: {
    alignSelf: 'center',
    margin: theme.spacing(1, 0),
    width: '100%',
    height: theme.spacing(0.75)
  },
  icon: {
    alignSelf: 'center',
    padding: theme.spacing(2)
  }
}));

export interface CommittingDialogProps {
  open: boolean;
  committing: boolean;
  commitSuccess: boolean;
  commitFailure: boolean;
  progressPercentage?: BigNumber | null;
  closeDialog: () => void;
  handleSuccess: () => void;
  handleFailure: () => void;
}

export const CommittingDialog: React.FC<CommittingDialogProps> = ({
  open,
  committing,
  commitSuccess,
  commitFailure,
  progressPercentage,
  closeDialog,
  handleSuccess,
  handleFailure
}: CommittingDialogProps) => {
  const classes = useStyles();
  useEffect(() => {
    if (commitSuccess || commitFailure) {
      const t = setTimeout(() => {
        closeDialog();
        if (commitSuccess) {
          handleSuccess();
        } else {
          handleFailure();
        }
      }, 2000);

      return () => {
        clearTimeout(t);
      };
    }
  }, [
    closeDialog,
    commitFailure,
    commitSuccess,
    committing,
    handleFailure,
    handleSuccess
  ]);

  const messageId = useMemo(() => {
    if (committing) {
      return progressPercentage
        ? 'committingDialog.message.progress'
        : 'committingDialog.message.prepare';
    }
    if (commitSuccess) {
      return 'committingDialog.message.success';
    }
    if (commitFailure) {
      return 'committingDialog.message.failure';
    }
  }, [commitFailure, commitSuccess, committing, progressPercentage]);

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      maxWidth="xs"
      disableBackdropClick={committing}
      disableEscapeKeyDown={committing}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Label variant="h6" labelId="committingDialog.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {commitSuccess && <CommitSuccessIcon className={classes.icon} />}
        {commitFailure && <CommitFailureIcon className={classes.icon} />}
        {committing && <CommittingAnimationIcon className={classes.icon} />}
        <Label
          className={classes.message}
          variant="subtitle2"
          labelId={messageId}
        />
        {committing && (
          <LinearProgress
            variant={progressPercentage ? 'determinate' : 'indeterminate'}
            className={classes.commitProgress}
            value={progressPercentage?.toNumber() ?? 0}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
