import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { IntlProvider } from 'react-intl';
import { useMeContext } from 'App/Me/useMeContext';
import { Me } from 'generated-types';
import { UseIntlProvider } from './UseIntlHookProvider';
import useLocaleMessages from './useLocaleMessages';

export const getLocale = (me: Me): string | undefined | null =>
  me.locale?.language?.languageCode;

const getCustomTerms = (me: Me): any => me.locale?.customTerms ?? [];

const MessageProvider: React.FC = ({ children }) => {
  const me = useMeContext();
  const customTermData = getCustomTerms(me);
  const locale = getLocale(me);
  const messages = useLocaleMessages(locale as string, customTermData);

  if (!messages) {
    return <LinearProgress variant="query" />;
  }
  return (
    <IntlProvider locale={locale ? locale : 'en-US'} messages={messages}>
      <UseIntlProvider>{children}</UseIntlProvider>
    </IntlProvider>
  );
};

export default MessageProvider;
