import { ErrorHandler } from '@apollo/client/link/error';

type LogFunction = (arg: string) => void;

export const errorLoggingMiddleware = (logger: LogFunction): ErrorHandler => ({
  graphQLErrors
}): void => {
  if (graphQLErrors?.length) {
    graphQLErrors.map(({ message, locations, path }) =>
      logger(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
};
