import React, { useCallback } from 'react';
import { TablePagination } from '@material-ui/core';
import { PageInfo } from 'generated-types';
import { usePaginationLabelFn } from 'common/hooks';

type RoleSummaryTablePaginationProps = {
  setCursor: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageInfo: PageInfo;
  page: number;
  rowsPerPage: number;
};

export const RoleSummaryTablePagination: React.FC<RoleSummaryTablePaginationProps> = ({
  setCursor,
  setPage,
  pageInfo,
  page,
  rowsPerPage
}: RoleSummaryTablePaginationProps) => {
  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      const canGoToNextPage = page < newPage && pageInfo.hasNextPage;
      if (canGoToNextPage) {
        setCursor(pageInfo?.nextPage ?? '');
        setPage(newPage);
      }
      if (page > newPage) {
        setCursor(pageInfo?.previousPage ?? '');
        setPage(newPage);
      }
    },
    [page, pageInfo, setCursor, setPage]
  );
  return (
    <TablePagination
      rowsPerPageOptions={[rowsPerPage]}
      component="div"
      labelDisplayedRows={usePaginationLabelFn()}
      count={pageInfo.count ?? 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
    />
  );
};

export default RoleSummaryTablePagination;
