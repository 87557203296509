import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  makeStyles
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Skeleton } from '@material-ui/lab';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { useMeContext } from 'App/Me';
import { Label } from 'common/components';
import { Maybe, User } from 'generated-types';
import { usePlanWarningContext } from 'plans/plan/context';

type UserLinkProps = { user: Maybe<User> };

export const UserLink: React.FC<UserLinkProps> = ({ user }: UserLinkProps) => {
  const { links } = useMeContext();
  const href = useMemo(() => {
    const baseUrl = links?.uiRootUrl;
    return `${baseUrl}administration/users/details/${user?.slug}/billing-cost`;
  }, [links, user]);

  if (!user) {
    return <></>;
  }
  return (
    <Link href={href} target="_blank">
      <Label variant="body2">{user.displayText}</Label>
    </Link>
  );
};

const useExpansionPanelStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      marginTop: 0,
      marginBottom: theme.spacing(2)
    }
  },
  expanded: {}
}));

const useExpansionPanelSummaryStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.warning.light,
    minHeight: theme.spacing(5),
    '&$expanded': {
      minHeight: theme.spacing(5)
    },
    padding: theme.spacing(0, 2, 0, 4)
  },
  content: {
    '&$expanded': {
      margin: theme.spacing(0.5, 0)
    }
  },
  expanded: {}
}));

const useExpansionPanelDetailsStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.warning.light,
    padding: theme.spacing(0, 2, 2, 4)
  }
}));

const useStyles = makeStyles(theme => ({
  skeleton: {
    width: '100%'
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const UsersDirectCostWarning: React.FC = () => {
  const classes = useStyles();
  const expansionPanelClasses = useExpansionPanelStyles();
  const expansionPanelSummaryClasses = useExpansionPanelSummaryStyles();
  const expansionPanelDetailsClasses = useExpansionPanelDetailsStyles();
  const { plan, loading } = usePlanWarningContext();

  const titleValue = useMemo(
    () => ({
      count: (
        <span className={classes.bold}>
          &nbsp;{plan?.warningsCount?.userDirectCostWarningCount?.toString()}
          &nbsp;
        </span>
      )
    }),
    [classes.bold, plan]
  );

  if (loading || !plan) {
    return <Skeleton className={classes.skeleton} height={60} />;
  }
  return (
    <Accordion classes={expansionPanelClasses}>
      <AccordionSummary
        classes={expansionPanelSummaryClasses}
        expandIcon={<ArrowDropDownIcon />}>
        <Label variant="body2">
          <FormattedPlural
            one={
              <FormattedMessage
                id="userDirectCostWarning.title.one"
                values={titleValue}
              />
            }
            other={
              <FormattedMessage
                id="userDirectCostWarning.title.other"
                values={titleValue}
              />
            }
            value={
              plan?.warningsCount?.userDirectCostWarningCount?.toNumber() ?? 0
            }
          />
        </Label>
      </AccordionSummary>
      <AccordionDetails classes={expansionPanelDetailsClasses}>
        <div>
          {plan.userDirectCostWarnings?.map(user => (
            <UserLink key={user?.id as string} user={user} />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
