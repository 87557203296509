import { useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import { DateTime } from 'luxon';

export const useChartStyles = ({
  startDate,
  endDate
}: {
  startDate: DateTime;
  endDate: DateTime;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Record<string, any> => {
  const theme = useTheme();
  const {
    actualBarWidth,
    budgetBarWidth,
    estimatedBarWidth,
    chartDomainPadding
  } = useMemo(() => {
    const months = Math.ceil(
      endDate.diff(startDate, ['month']).toObject().months ?? 0
    );
    if (months >= 15) {
      return {
        budgetBarWidth: 8,
        actualBarWidth: 6,
        estimatedBarWidth: 4,
        chartDomainPadding: 20
      };
    }
    if (months <= 6) {
      return {
        budgetBarWidth: 31,
        actualBarWidth: 25,
        estimatedBarWidth: 20,
        chartDomainPadding: 35
      };
    }

    if (months <= 9) {
      return {
        budgetBarWidth: 22,
        actualBarWidth: 18,
        estimatedBarWidth: 14,
        chartDomainPadding: 20
      };
    }

    return {
      budgetBarWidth: 11,
      actualBarWidth: 8,
      estimatedBarWidth: 5,
      chartDomainPadding: 20
    };
  }, [endDate, startDate]);

  return {
    tooltip: {
      pointerLength: 2,
      pointerWidth: 3,
      flyoutWidth: 23,
      flyoutHeight: 11,
      cornerRadius: 1,
      flyoutStyle: {
        stroke: 'transparent',
        strokeWidth: 2,
        fill: theme.palette.grey[700]
      },
      labelStyle: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.spacing(0.3),
        fontFamily: theme.typography.fontFamily,
        fill: 'white'
      }
    },
    chart: {
      height: 100,
      domainPadding: { x: chartDomainPadding },
      padding: {
        top: theme.spacing(1),
        bottom: theme.spacing(1.5),
        left: theme.spacing(2),
        right: theme.spacing(1)
      }
    },
    bar: {
      label: {
        dy: 4,
        style: {
          fill: theme.palette.grey[900],
          fontSize: theme.spacing(0.3),
          fontFamily: theme.typography.fontFamily,
          fontWeight: theme.typography.fontWeightMedium
        }
      },
      budget: {
        barWidth: budgetBarWidth,
        style: {
          data: {
            fill: '#CBF9E8',
            cursor: 'pointer'
          }
        }
      },
      actual: {
        barWidth: actualBarWidth,
        style: {
          data: {
            fill: '#00CD8C'
          }
        }
      },
      estimated: {
        barWidth: estimatedBarWidth,
        style: {
          data: {
            fill: '#C3B9EC'
          }
        }
      }
    },
    axis: {
      labelStyles: {
        paddingTop: 5,
        angle: 0,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.spacing(0.375),
        fontFamily: theme.typography.fontFamily,
        verticalAnchor: 'middle',
        textAnchor: 'middle',
        fill: theme.palette.grey[700]
      },
      x: {
        style: {
          axis: {
            stroke: 'transparent'
          },
          grid: {
            stroke: 'transparent'
          },
          ticks: { stroke: 'transparent' }
        }
      },
      y: {
        style: {
          ticks: { stroke: 'transparent' },
          grid: {
            stroke: theme.palette.grey[300],
            strokeDasharray: 0
          }
        },
        axisLabel: {
          fontWeight: theme.typography.fontWeightRegular,
          fontSize: theme.spacing(0.375),
          verticalAnchor: 'middle',
          fontFamily: theme.typography.fontFamily,
          fill: theme.palette.text.secondary
        }
      }
    }
  };
};
