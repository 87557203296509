import React, { useState, ChangeEvent } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Tabs,
  Tab,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { useIntl, useMessages } from 'App/MessageProvider';
import { Button, ProgressButton } from 'common/components';
import { useAddPlanForm, useCreatePlan, useOverlappingPlans } from './hooks';
import { PlanWarnings } from './components';
import { CopyPlan } from './CopyPlan';
import { AddPlan } from './AddPlan';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  cancelBtn: {},
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    margin: theme.spacing(1, 0),
    justifyContent: 'space-between'
  },
  leftField: {
    width: '95%'
  },
  rightField: {
    width: '100%'
  },
  actions: {
    padding: theme.spacing(1, 2)
  },
  tabTitle: {
    ...theme.typography.subtitle2
  }
}));

interface AddPlanDialogProps {
  open: boolean;
  closeDialog: () => void;
}
export enum TabValue {
  addPlan = 0,
  copyPlan = 1
}
export const AddPlanDialog: React.FC<AddPlanDialogProps> = ({
  open,
  closeDialog
}: AddPlanDialogProps) => {
  const classes = useStyles();
  const intl = useIntl();
  const [tabValue, setTabValue] = useState<TabValue>(TabValue.addPlan);
  const {
    register,
    errors,
    getValues,
    setValue,
    onStartDateChange,
    triggerValidation,
    onEndDateChange,
    handleSubmit
  } = useAddPlanForm(tabValue);

  const {
    onCreatePlan,
    creatingPlan,
    createPlanFailure,
    createPlanErrors,
    createPlanWarnings,
    ignoreWarning
  } = useCreatePlan(closeDialog);
  const messages = useMessages([
    'addPlanDialog.name',
    'addPlanDialog.nameRequired',
    'addPlanDialog.startDate',
    'addPlanDialog.startDateRequired',
    'addPlanDialog.endDate',
    'addPlanDialog.endDateRequired',
    'addPlanDialog.startMonth',
    'addPlanDialog.startMonthRequired',
    'addPlanDialog.endMonth',
    'addPlanDialog.endMonthRequired',
    'addPlanDialog.utilization',
    'addPlanDialog.utilizationRequired',
    'addPlanDialog.margin',
    'addPlanDialog.marginRequired',
    'addPlanDialog.failureMsg',
    'addPlanDialog.utilizationNonNegative',
    'addPlanDialog.marginNonNegative',
    'addPlanDialog.marginOver100'
  ]);

  const onTabClick = (e: ChangeEvent<unknown>, value: number): void => {
    setTabValue(value);
  };
  const { startDate, endDate } = getValues();
  const { data: overlappingPlans } = useOverlappingPlans(startDate, endDate);

  const hasOverlappingPlans = Boolean(
    overlappingPlans && overlappingPlans?.smartBudgets.edges.length > 0
  );
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      disableBackdropClick={creatingPlan}
      disableEscapeKeyDown={creatingPlan}
      role="dialog"
      aria-label={intl.formatMessage({
        id: 'addPlanDialog.title'
      })}>
      <DialogTitle className={classes.titleContainer} disableTypography>
        <Tabs value={tabValue} indicatorColor="primary" onChange={onTabClick}>
          <Tab
            label={
              <Typography
                variant="h1"
                component="h1"
                className={classes.tabTitle}>
                <FormattedMessage id="addPlanDialog.title" />
              </Typography>
            }
          />
          <Tab
            label={
              <Typography
                variant="h1"
                component="h1"
                className={classes.tabTitle}>
                <FormattedMessage id="addPlanDialog.copyTab" />
              </Typography>
            }
          />
        </Tabs>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {createPlanFailure && (
          <Alert severity="error">{messages['addPlanDialog.failureMsg']}</Alert>
        )}
        {tabValue === 0 ? (
          <AddPlan
            register={register}
            errors={errors}
            onEndDateChange={onEndDateChange}
            onStartDateChange={onStartDateChange}
            messages={messages}
            hasOverlappingPlans={hasOverlappingPlans}
            startDate={startDate}
            endDate={endDate}
            overlappingPlans={overlappingPlans}
            createPlanErrors={createPlanErrors}
          />
        ) : (
          <CopyPlan
            register={register}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            onEndDateChange={onEndDateChange}
            onStartDateChange={onStartDateChange}
            messages={messages}
            triggerValidation={triggerValidation}
            hasOverlappingPlans={hasOverlappingPlans}
            overlappingPlans={overlappingPlans}
            createPlanErrors={createPlanErrors}
          />
        )}
        {Boolean(createPlanWarnings?.length) && (
          <Grid item xs={12}>
            <PlanWarnings warnings={createPlanWarnings} />
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={closeDialog}
          disabled={creatingPlan}
          color="default"
          labelId="addPlanDialog.cancel"
          data-qe-id="CancelAddSmartBudget"
        />
        <ProgressButton
          variant="text"
          color="primary"
          labelId={
            ignoreWarning ? 'addPlanDialog.continue' : 'addPlanDialog.addPlan'
          }
          onClick={handleSubmit(onCreatePlan) as () => void}
          loading={creatingPlan}
          data-qe-id="AddSmartBudget"
        />
      </DialogActions>
    </Dialog>
  );
};
