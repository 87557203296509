import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ErrorOutlined from '@material-ui/icons/ErrorOutlineSharp';
import { FormattedMessage } from 'react-intl';

const toTitle = (error: Error, componentStack: string): string =>
  `${error.toString()}\n\nThis is located at:${componentStack}`;

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    maxHeight: '100vh',
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.error.dark,
    boxSizing: 'border-box',
    cursor: 'help'
  },
  svg: {
    fill: 'currentColor',
    flex: '1 1 auto'
  }
}));

type ErrorBoundaryFallbackComponentProps = {
  componentStack: string;
  error: Error;
};

export const ErrorBoundaryFallbackComponent: React.FC<ErrorBoundaryFallbackComponentProps> = ({
  componentStack,
  error
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root} title={toTitle(error, componentStack)}>
      <ErrorOutlined />
      <Typography variant="h6">
        <FormattedMessage id="errorOccurred" />
        <span id="errorBoundary">{` ${error}`}</span>
      </Typography>
    </div>
  );
};

export default ErrorBoundaryFallbackComponent;
