import { useMemo } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { OverlappingPlansWarning, PlanStatus } from 'generated-types';

export const useHasBlockingErrors = (
  overlappingPlans?: Maybe<OverlappingPlansWarning>[] | null,
  planStatus?: PlanStatus | null
): boolean => {
  return useMemo(() => {
    const plans = overlappingPlans?.filter(overlappingPlan => {
      return overlappingPlan?.planStatus !== PlanStatus.Draft;
    });
    return planStatus
      ? Boolean(planStatus === PlanStatus.Draft && plans && plans.length > 0)
      : Boolean(plans && plans?.length > 0);
  }, [overlappingPlans, planStatus]);
};
