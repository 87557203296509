/**
 * This model represents the table model for CPOv2, where our primary modification
 * vectors are via changes to the Business Cost, Utilization and Billing Rate.
 * The outcomes of these changes are adjustments to the margin and revenue.
 */

import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import BigNumber from 'bignumber.js';
import RateCardModel, {
  RateCardColumn,
  RateCardModelState
} from './RateCardModel';

const rbrc = new RoleBillingRateCalculator();

const marginCalculationFunction = (
  _: RateCardColumn,
  state: RateCardModelState
): BigNumber => {
  return rbrc.calculateMargin(
    state['revenue'],
    state['loadedCost'],
    state['totalHours']
  );
};

const revenueCalculationFunction = (
  changedColumn: RateCardColumn,
  state: RateCardModelState
): BigNumber => {
  return rbrc.calculateRevenue(
    state['billingRate'],
    state['utilization'],
    state['totalHours']
  );
};

const loadedCostCalculationFunction = (
  changedColumn: RateCardColumn,
  state: RateCardModelState
) => {
  return state['standardRate']
    .plus(state['nonBillableOverhead'])
    .plus(state['businessOverhead']);
};

const utilizationBillingRateModel = new RateCardModel();

// Columns are specified in the order they should be updated on a change
// to a dependant column.
const columns = [
  new RateCardColumn('standardRate'),
  new RateCardColumn('nonBillableOverhead'),
  new RateCardColumn('businessOverhead'),
  new RateCardColumn(
    'loadedCost',
    ['standardRate', 'nonBillableOverhead', 'businessOverhead'],
    loadedCostCalculationFunction
  ),
  new RateCardColumn('totalHours'),
  new RateCardColumn('billingRate'),
  new RateCardColumn('utilization'),
  new RateCardColumn(
    'revenue',
    ['loadedCost', 'utilization', 'billingRate'],
    revenueCalculationFunction
  ),
  new RateCardColumn('margin', ['revenue'], marginCalculationFunction)
];

columns.map(
  utilizationBillingRateModel.registerColumn.bind(utilizationBillingRateModel)
);

export default utilizationBillingRateModel;
