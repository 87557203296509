import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { useMeNumberFormats } from 'App/NumberFormatProvider';

type NumberValueProps = {
  value: number;
  className?: string;
} & NumberFormatProps;

const NumberValue: React.FC<NumberValueProps> = memo(props => {
  const { numberFormat } = useMeNumberFormats();
  return <NumberFormat {...props} {...numberFormat} displayType="text" />;
});

NumberValue.displayName = 'NumberValue';
NumberValue.propTypes = {
  value: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default NumberValue;
