import { ApolloLink } from '@apollo/client';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { withScalars } from 'apollo-link-scalars';
import introspectionResult from 'graphql_schema.json';
import bigNumber from './BigNumber';

const typesMap = { ...bigNumber };

const schema = buildClientSchema(
  (introspectionResult as unknown) as IntrospectionQuery
);

export const withCustomScalar = (): ApolloLink =>
  withScalars({ schema, typesMap });
