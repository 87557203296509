import React, { useCallback } from 'react';
import { makeStyles, ButtonGroup, Button } from '@material-ui/core';
import { Maybe } from 'generated-types';
import { Label } from 'common/components';

const options = [1, 5, 10];
type NearestXBillingRateButtonProps = {
  currencySymbol?: Maybe<string>;
  setNearestValue: (value: string) => void;
  disabled?: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  buttonGroup: {
    alignSelf: 'flex-end'
  },
  label: {
    ...theme.typography.caption,
    color: theme.palette.grey[700]
  }
}));

export const SnapToNearestXBillingRateButton: React.FC<NearestXBillingRateButtonProps> = ({
  currencySymbol,
  setNearestValue,
  disabled = false
}: NearestXBillingRateButtonProps) => {
  const classes = useStyles();
  const handleChange = useCallback(
    (event: React.MouseEvent) => {
      const value = event.currentTarget?.getAttribute('data-value');
      if (value !== undefined && value !== null) {
        setNearestValue(value);
      }
    },
    [setNearestValue]
  );

  return (
    <div className={classes.root}>
      <Label className={classes.label} labelId="nearestXDropdown.title" />
      <ButtonGroup className={classes.buttonGroup} color="primary">
        {options.map(option => (
          <Button
            key={option}
            onClick={handleChange}
            data-value={option}
            disabled={disabled}
            size="small">
            {currencySymbol}&nbsp;{option}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};
