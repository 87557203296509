import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center'
  }
});

const PlanTitleBarActions: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default PlanTitleBarActions;
