import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Button, Label, DateRange } from 'common/components';
import { useIsBreakpointDown } from 'common/hooks';
import { useActiveCostOptimizationResult } from 'active/hooks';
import { useUpcomingCostOptimizationResult } from 'futurePage/hooks';
import { validateRateCardOverlap } from './hooks/validateRateCardOverlap';
import { useEffectiveDate } from './useEffectiveDate';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: theme.spacing(0, 0, 2.5, 0)
  },
  error: {
    textAlign: 'left',
    color: theme.palette.error.main
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  },
  cancelBtn: {
    color: theme.palette.grey[600]
  },
  dateDivider: {
    marginRight: theme.spacing(3)
  },
  errorContainer: {
    paddingTop: theme.spacing(2)
  },
  dateLabel: {
    display: 'inline'
  },
  errorRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 2.5, 0)
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1)
  }
}));

const useDateStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.error.main
  },
  date: {
    ...theme.typography.body1
  }
}));

const startDateLabel = <FormattedMessage id="effectiveDateDialog.startDate" />;
const endDateLabel = <FormattedMessage id="effectiveDateDialog.endDate" />;

export interface EffectiveDateDialogProps {
  open: boolean;
  closeDialog: () => void;
  commitRates: (startDate: DateTime, endDate: DateTime) => void;
}

export const EffectiveDateDialog: React.FC<EffectiveDateDialogProps> = ({
  open,
  closeDialog,
  commitRates
}: EffectiveDateDialogProps) => {
  const classes = useStyles();
  const dateStyles = useDateStyles();
  const {
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    setRates
  } = useEffectiveDate(closeDialog, commitRates);

  const activeRateCard = useActiveCostOptimizationResult();
  const futureRateCards = useUpcomingCostOptimizationResult();

  const overlappingFutureRateCard = futureRateCards.costOptimizationResults
    ?.map(result => {
      return validateRateCardOverlap(endDate, startDate, result);
    })
    .filter(r => r !== null);

  const overlappingActiveRateCard = validateRateCardOverlap(
    endDate,
    startDate,
    activeRateCard.costOptimizationResult
  );

  return (
    <Dialog
      id="effective date dialog"
      aria-labelledby="title"
      classes={useDialogStyles()}
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}>
      <DialogTitle id="title" className={classes.dialogTitle} disableTypography>
        <Label variant="h6" labelId="effectiveDateDialog.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {Boolean(startDate && endDate) ? (
          <>
            <Label
              variant="body1"
              className={classes.info}
              labelId="effectiveDateDialog.info.line1"
            />
            <Label
              variant="body1"
              className={classnames(classes.info, classes.bold)}
              labelId="effectiveDateDialog.info.line2"
            />
          </>
        ) : (
          <Label
            variant="body1"
            className={classes.info}
            labelId="effectiveDateDialog.info.selectMsg"
          />
        )}
        <DatePicker
          id="startDate"
          className={classes.dateDivider}
          autoOk
          inputVariant="filled"
          label={startDateLabel}
          disablePast
          value={startDate}
          onChange={onStartDateChange}
        />
        <DatePicker
          id="endDate"
          autoOk
          disabled={!Boolean(startDate)}
          inputVariant="filled"
          label={endDateLabel}
          disablePast
          value={endDate}
          onChange={onEndDateChange}
        />
        <div className={classes.errorContainer}>
          {overlappingActiveRateCard && (
            <div className={classes.errorRow}>
              <Label
                variant="body1"
                className={classes.error}
                labelId="effectiveDateDialog.info.activeOverlapError"
              />
              &nbsp;
              <DateRange
                classes={dateStyles}
                startDate={overlappingActiveRateCard.effectiveDate ?? ''}
                endDate={overlappingActiveRateCard.endDate ?? ' '}
              />
            </div>
          )}
          {overlappingFutureRateCard?.map(r => {
            if (r) {
              return (
                <div key={r.id} className={classes.errorRow}>
                  <Label
                    variant="body1"
                    className={classes.error}
                    labelId="effectiveDateDialog.info.futureOverlapError"
                  />
                  &nbsp;
                  <DateRange
                    classes={dateStyles}
                    startDate={r.effectiveDate ?? ''}
                    endDate={r.endDate ?? ' '}
                  />
                </div>
              );
            }
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelBtn}
          onClick={closeDialog}
          color="default"
          labelId="effectiveDateDialog.cancel"
        />
        <Button
          onClick={setRates}
          color="primary"
          disabled={
            !Boolean(startDate && endDate) ||
            Boolean(overlappingActiveRateCard) ||
            (overlappingFutureRateCard && overlappingFutureRateCard.length > 0)
          }
          labelId="effectiveDateDialog.setRates"
        />
      </DialogActions>
    </Dialog>
  );
};
