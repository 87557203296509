import React from 'react';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { CellProps } from 'react-table';
import { BillingByRoleData } from 'common/types';

export const CurrentUtilizationCell: React.FC<CellProps<BillingByRoleData>> = ({
  value
}: CellProps<BillingByRoleData>) => {
  return (
    <>
      {value ? (
        <>
          {value.toFixed(2)}
          &nbsp;%
        </>
      ) : (
        <Tooltip
          title={
            <FormattedMessage id="planPage.billableResourcesCard.currentUtilizationField.noValueTooltipTitle" />
          }>
          <div>&mdash;</div>
        </Tooltip>
      )}
    </>
  );
};
