import React, { useMemo, memo } from 'react';
import { TextAlignProperty } from 'csstype';
import { makeStyles } from '@material-ui/core/styles';
import { ColumnOnChange, FormData } from 'common/types';
import {
  NumberEditField,
  NumberEditFieldProps
} from 'common/components/NumberEditField';
import { useDirection } from 'App/MuiThemeProvider/useDirection';

type TableFormNumberEditFieldProps = {
  id: string;
  attribute: keyof FormData;
  onChanged?: ColumnOnChange;
} & NumberEditFieldProps;

const useInputClasses = makeStyles(() => ({
  input: {
    textAlign: ({
      direction
    }: {
      direction: string;
      classes: Record<string, string> | undefined;
    }): TextAlignProperty => (direction === 'rtl' ? 'start' : 'right')
  }
}));

export const TableFormNumberEditField: React.FC<TableFormNumberEditFieldProps> = memo(
  ({
    id,
    attribute,
    value,
    onChanged,
    classes,
    ...rest
  }: TableFormNumberEditFieldProps) => {
    const direction = useDirection();
    const inputClasses = useInputClasses({ direction, classes });
    const onBlur = useMemo(() => onChanged?.(attribute, id), [
      attribute,
      id,
      onChanged
    ]);
    return (
      <NumberEditField
        classes={inputClasses}
        attribute={attribute}
        name={`${id}.${attribute}`}
        value={value}
        onBlur={onBlur}
        {...rest}
      />
    );
  }
);

TableFormNumberEditField.displayName = 'TableFormNumberEditField';
