import LuxonUtils from '@date-io/luxon';
import { useMeDateTimeFormats } from 'App/DateTimeFormatProvider';

export const useLuxonUtil = (): unknown => {
  const { dateFormat: cpoDateFormat } = useMeDateTimeFormats();
  class CustomLuxonUtils extends LuxonUtils {
    constructor(options?: { locale?: string }) {
      super(options);
      if (cpoDateFormat) {
        this.dateFormat = cpoDateFormat;
      }
    }
  }

  return CustomLuxonUtils;
};
