import React, { useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Label } from 'common/components';
import { useIsBreakpointDown } from 'common/hooks';
import { CostOptimizationResult } from 'generated-types';
import { DeleteConfirmationContent } from './DeleteConfirmationContent';

const useStyles = makeStyles(theme => ({
  cancel: {
    color: theme.palette.grey[600]
  },
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: '0 0 20px 0'
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1.5)
  }
}));
type DeleteConfirmationDialogProps = {
  open: boolean;
  closeDialog: () => void;
  onDeleteClick: () => void;
  nextCostOptimizationResult?: CostOptimizationResult | null;
};

export const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  closeDialog,
  onDeleteClick,
  nextCostOptimizationResult
}: DeleteConfirmationDialogProps) => {
  const classes = useStyles();
  const nextEffectiveDate = useMemo(
    () => nextCostOptimizationResult?.effectiveDate,
    [nextCostOptimizationResult]
  );
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}
      classes={useDialogStyles()}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Label variant="h6" labelId="upcoming.deleteConfirmation.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DeleteConfirmationContent upcomingEffectiveDate={nextEffectiveDate} />
      </DialogContent>
      <DialogActions className={classes.dialogContent}>
        <Button
          onClick={closeDialog}
          className={classes.cancel}
          color="default"
          labelId="upcoming.deleteConfirmation.cancel"
        />
        <Button
          color="primary"
          onClick={onDeleteClick}
          labelId="upcoming.deleteConfirmation.delete"
        />
      </DialogActions>
    </Dialog>
  );
};
