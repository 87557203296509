/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Theme
} from '@material-ui/core';
import { Direction } from '@material-ui/core/styles';
import { useDirection } from 'App/MuiThemeProvider/useDirection';
const useStyles = makeStyles<Theme, StickyHeaderProps>(theme => ({
  root: {
    top: ({ level = 0 }): number => level * theme.spacing(6),
    left: ({ direction }) => (direction !== 'ltr' ? 'unset' : 0),
    color: theme.palette.text.primary,
    minHeight: theme.spacing(6),
    borderBottom: ({ bottomBorder }): string =>
      bottomBorder ? `1px solid ${theme.palette.divider}` : 'none',
    zIndex: ({ level = 0 }): number => theme.zIndex.appBar - level
  },
  paddingRight: { paddingRight: theme.spacing(2) },
  toolbar: {
    minHeight: theme.spacing(6)
  },
  title: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    flexGrow: ({ titleFullWidth }): number => (titleFullWidth ? 1 : 0),
    fontSize: theme.typography.h6.fontSize,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body1.fontSize,
      paddingLeft: 0,
      paddingRight: 16
    }
  }
}));

export type StickyHeaderProps = {
  title?: string;
  titleFullWidth?: boolean;
  bottomBorder?: boolean;
  level?: number;
  className?: string;
  direction?: Direction;
};

export const StickyHeader: React.FC<StickyHeaderProps> = props => {
  const { title, className, children } = props;
  const direction = useDirection();
  const classes = useStyles({
    level: props.level,
    direction: direction,
    titleFullWidth: props.titleFullWidth
  });

  return (
    <AppBar
      className={classNames(classes.root, className)}
      component="div"
      color="inherit"
      position="sticky"
      elevation={0}>
      <Toolbar className={classes.toolbar} disableGutters>
        {title && (
          <Typography className={classes.title} component="h1" variant="h1">
            {title}
          </Typography>
        )}
        {children}
      </Toolbar>
    </AppBar>
  );
};

StickyHeader.defaultProps = {
  level: 0,
  bottomBorder: true,
  titleFullWidth: true
};

export default StickyHeader;
