import React from 'react';

const RoleTableColumns: React.FC = () => {
  return (
    <colgroup>
      <col width="11%" />
      <col width="8%" />
      <col width="9%" />
      <col width="8%" />
      <col span={2} width="9%" />
      <col width="10%" />
      <col span={4} width="9%" />
    </colgroup>
  );
};

export default RoleTableColumns;
