import React from 'react';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  makeStyles
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  TableHeaderRow,
  TableHeaderCell,
  TableHeader
} from '@replicon/react-data-table';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  firstCell: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderWidth: theme.spacing(0, 0.125, 0.125, 0)
  },
  row: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderWidth: theme.spacing(0.125)
  }
}));

type PlanListTableSkeletonProps = {
  headers: { text: string; align: boolean; button?: boolean; class?: string }[];
  className: string;
};

export const PlanListTableSkeleton: React.FC<PlanListTableSkeletonProps> = ({
  headers,
  className
}: PlanListTableSkeletonProps) => {
  const classes = useStyles();
  const numberColumnDirection = 'right';
  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          {headers.map(h => {
            return (
              <TableHeaderCell
                key={h.text}
                className={className}
                align={h.align ? numberColumnDirection : 'left'}>
                <FormattedMessage id={h.text} />
              </TableHeaderCell>
            );
          })}
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {[...Array(7)].map((__: number, rowIndex: number) => (
          <TableRow key={`Row_${rowIndex}`} className={classes.row}>
            <TableCell align="left" scope="row" className={classes.firstCell}>
              <Skeleton width="30%" />
            </TableCell>
            {[...Array(11)].map((___: number, cellIndex: number) => (
              <TableCell key={`Cell_${rowIndex}_${cellIndex}`} align="right">
                <Skeleton width="70%" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PlanListTableSkeleton;
