import { useState, useCallback } from 'react';

export default (): {
  anchorEl: Element | undefined;
  onMenuOpen: (anchor: Element) => void;
  onMenuClose: () => void;
  onMenuClick: (event: React.MouseEvent) => void;
} => {
  const [anchorEl, setAnchorE1] = useState<Element>();

  return {
    anchorEl,
    onMenuOpen: useCallback<(anchor: Element) => void>(anchor => {
      setAnchorE1(anchor);
    }, []),
    onMenuClose: useCallback<() => void>(() => {
      setAnchorE1(undefined);
    }, []),
    onMenuClick: useCallback<(event: React.MouseEvent) => void>(event => {
      setAnchorE1(
        event && event.target ? (event.target as Element) : undefined
      );
    }, [])
  };
};
