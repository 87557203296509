import { DateObject } from 'luxon';
import {
  useCostOptimizationResultCurrencyTotalsQuery,
  CostOptimizationResult
} from 'generated-types';

export const useCostOptimizationResultCurrencyTotals = (
  id: string,
  today: DateObject
): {
  loading: boolean;
  costOptimizationResult?: CostOptimizationResult | null;
} => {
  const { loading, data } = useCostOptimizationResultCurrencyTotalsQuery({
    variables: {
      id: id ?? '',
      today: {
        year: today.year,
        month: today.month,
        day: today.day
      }
    }
  });

  return {
    loading,
    costOptimizationResult: data?.costOptimizationResult
  };
};
