import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Button, Label, ProgressButton } from 'common/components';
import { useIsBreakpointDown } from 'common/hooks';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: '0 0 20px 0'
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1.5)
  }
}));
type SetPlanWarningDialogProps = {
  open: boolean;
  closeDialog: () => void;
  onSetPlanClick: () => void;
};

export const SetPlanWarningDialog: React.FC<SetPlanWarningDialogProps> = ({
  open,
  closeDialog,
  onSetPlanClick
}: SetPlanWarningDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}
      classes={useDialogStyles()}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Label variant="h6" labelId="setPlanWarningDialog.setPlan" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Label
          variant="body1"
          className={classes.info}
          labelId={'setPlanWarningDialog.info1'}
        />
        <Label
          variant="body1"
          className={classes.info}
          labelId={'setPlanWarningDialog.info2'}
        />
      </DialogContent>
      <DialogActions className={classes.dialogContent}>
        <Button onClick={closeDialog} labelId="setPlanWarningDialog.cancel" />
        <ProgressButton
          autoFocus
          variant="text"
          color="primary"
          loading={false}
          onClick={onSetPlanClick}>
          <FormattedMessage id="setPlanWarningDialog.setPlan" />
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};
