import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import { Maybe, Money, Currency, SmartBudgetResult } from 'generated-types';
import { rateToTotal } from 'util/money';
import { BillingByRoleData } from 'common/types';
import { calculateGlobalMargin } from 'rateCard/helpers';

export type PlanTotals = {
  userCount?: Maybe<BigNumber>;
  totalHours?: Maybe<BigNumber>;
  totalCost?: Maybe<Money>;
  standardRate?: Maybe<Money>;
  nonBillableOverhead?: Maybe<Money>;
  nonBillableOverheadDirectCost?: Maybe<Money>;
  businessOverhead?: Maybe<Money>;
  businessOverheadDirectCost?: Maybe<Money>;
  loadedCost?: Maybe<Money>;
  utilization?: Maybe<BigNumber>;
  billingRate?: Maybe<Money>;
  margin?: Maybe<BigNumber>;
  revenue?: Maybe<Money>;
  profit?: Maybe<Money>;
  currentUtilization?: Maybe<BigNumber>;
  currentBillingRate?: Maybe<Money>;
};
const billingRateCalculator = new RoleBillingRateCalculator();

const calculateAggregates = (
  roleData?: BillingByRoleData[],
  totalBillableHours?: BigNumber,
  currency?: Currency,
  totalCurrentUtilization?: Maybe<BigNumber>
): {
  totalCost: Money;
  totalUsers: BigNumber;
  loadedCostRate: Money;
  currentBillingRate?: Money;
} => {
  const agg = (roleData ?? []).reduce(
    (aggs, r) => {
      return {
        cost: aggs.cost.plus(r.totalCost.amount ?? 0),
        users: aggs.users.plus(r.userCount ?? 0),
        loadedCost: aggs.loadedCost.plus(
          (r.loadedCost.amount ?? new BigNumber(0)).times(r.totalHours)
        ),
        currentRevenue: aggs.currentRevenue.plus(
          billingRateCalculator.calculateRevenue(
            r.currentBillingRate?.amount ?? new BigNumber(0),
            r.currentUtilization ?? new BigNumber(0),
            r.totalHours
          )
        )
      };
    },
    {
      cost: new BigNumber(0),
      users: new BigNumber(0),
      loadedCost: new BigNumber(0),
      currentRevenue: new BigNumber(0)
    }
  );

  return {
    loadedCostRate: {
      amount:
        totalBillableHours && !totalBillableHours.isZero()
          ? agg.loadedCost.dividedBy(totalBillableHours)
          : new BigNumber(0),
      currency
    },
    totalCost: {
      amount: agg.cost,
      currency
    },
    totalUsers: agg.users,
    currentBillingRate: !agg.currentRevenue.isZero()
      ? {
          amount: billingRateCalculator.calculateGlobalBillingRate(
            agg.currentRevenue,
            totalCurrentUtilization ?? new BigNumber(0),
            totalBillableHours ?? new BigNumber(0)
          ),
          currency
        }
      : undefined
  };
};

export const usePlanTotals = ({
  totalRevenue,
  result,
  roleData,
  utilization
}: {
  totalRevenue: BigNumber;
  utilization: BigNumber;
  result?: Maybe<SmartBudgetResult>;
  roleData?: BillingByRoleData[];
}): Maybe<PlanTotals> | undefined => {
  const totalCostBreakdown = useMemo(() => {
    if (result) {
      const globalRevenue = () => {
        if (totalRevenue.isGreaterThan(0)) {
          return totalRevenue;
        }
        return result.totalRevenue?.amount ?? new BigNumber(0);
      };
      const aggs = calculateAggregates(
        roleData,
        result.totalBillableHours ?? undefined,
        result.currency ?? undefined,
        result.totalCurrentUtilization
      );
      const businessOverhead =
        roleData?.[0]?.businessOverhead ?? result?.businessOverheadRate;
      const margin = calculateGlobalMargin(
        globalRevenue(),
        aggs.loadedCostRate.amount ?? new BigNumber(0),
        result.totalBillableHours ?? new BigNumber(0)
      );
      const profit = globalRevenue().minus(
        (aggs.loadedCostRate.amount ?? new BigNumber(0)).multipliedBy(
          result.totalBillableHours ?? new BigNumber(0)
        )
      );
      return {
        totalCost: aggs.totalCost,
        standardRate: result?.standardRate,
        nonBillableOverhead: result?.nonBillableOverheadRate,
        nonBillableOverheadDirectCost: result?.nonBillableOverheadRate
          ? rateToTotal(
              result.nonBillableOverheadRate,
              result.totalBillableHours ?? new BigNumber(0)
            )
          : undefined,
        businessOverhead: businessOverhead,
        businessOverheadDirectCost: businessOverhead
          ? rateToTotal(
              businessOverhead,
              result.totalBillableHours ?? new BigNumber(0)
            )
          : undefined,
        loadedCost: aggs.loadedCostRate,
        billingRate: {
          amount: billingRateCalculator.calculateGlobalBillingRate(
            globalRevenue(),
            utilization.div(100),
            result.totalBillableHours ?? new BigNumber(0)
          ),
          currency: result.currency
        },
        utilization: utilization,
        currentUtilization: result?.totalCurrentUtilization ?? null,
        margin: margin,
        totalHours: result?.totalBillableHours,
        revenue: result?.totalRevenue,
        userCount: aggs.totalUsers,
        profit: { amount: profit, currency: result.currency },
        currentBillingRate: aggs.currentBillingRate
      };
    }
  }, [result, roleData, totalRevenue, utilization]);

  return totalCostBreakdown;
};
