import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps
} from '@material-ui/core';

export interface ButtonProps extends MuiButtonProps {
  labelId?: string;
}

const Button: React.FC<ButtonProps> = ({
  labelId: messageId,
  children,
  ...rest
}: ButtonProps) => {
  return (
    <MuiButton {...rest}>
      {messageId !== undefined && <FormattedMessage id={messageId} />}
      {children}
    </MuiButton>
  );
};

export default Button;
