import React from 'react';
import { CellProps } from 'react-table';
import { BillingByRoleData } from 'common/types';
import {
  usePlanEditContext,
  useRoleTableFormContext
} from 'plans/plan/context';
import { TableFormNumberEditField } from 'rateCard';
import { useLockedColumnContext } from 'common/context';
import { useMessage } from 'App/MessageProvider';
import { NumberValue } from 'common/components';
import { useIsCalculatingAttribute } from 'plans/plan/hooks';

export const MarginCell: React.FC<CellProps<BillingByRoleData>> = ({
  row,
  column: { id }
}: CellProps<BillingByRoleData>) => {
  const { getValues, onMarginChangeHandler } = useRoleTableFormContext();
  const { lockedColumn } = useLockedColumnContext();
  const label = useMessage('planPage.billableResourcesCard.marginFieldLabel', {
    roleName: row.original.roleName
  });
  const { canEdit } = usePlanEditContext();
  const isCalculateForColumn = useIsCalculatingAttribute('margin');

  if (!canEdit || isCalculateForColumn) {
    return (
      <NumberValue
        value={Number(getValues?.()[row.original.id].margin)}
        suffix=" %"
        fixedDecimalScale
      />
    );
  }

  return (
    <TableFormNumberEditField
      id={row.original.id}
      attribute="margin"
      ariaLabel={label}
      fullWidth
      value={getValues?.()[row.original.id].margin}
      onChanged={onMarginChangeHandler}
      suffix="%"
      disabled={lockedColumn === id}
    />
  );
};
