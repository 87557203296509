import React from 'react';
import Label, { LabelProps } from '../Label/Label';
import DateBase from './DateBase';

export interface DateProps extends LabelProps {
  date?: string | null;
  format?: string;
  className?: string;
}

export const Date: React.FC<DateProps> = ({
  date,
  format,
  className,
  ...rest
}: DateProps) => {
  return (
    <Label className={className} variant="body1" {...rest}>
      <DateBase date={date} format={format} />
    </Label>
  );
};
