import { useState, useCallback } from 'react';
import {
  useDeleteSmartBudgetMutation,
  PlanStatus,
  Maybe
} from 'generated-types';
import { useDiscardCostOptimizationResult } from 'common/hooks';
import { useSaveDraft } from './useSaveDraft';

type DeletePlanResults = {
  removing: boolean;
  removePlan: () => void;
};

export const useRemovePlan = (
  onSuccess: () => void,
  planId: string,
  planStatus?: Maybe<PlanStatus>
): DeletePlanResults => {
  const [deleting, setDeleting] = useState(false);
  const [deleteSmartBudgetMutation] = useDeleteSmartBudgetMutation();
  const {
    discardCostOptimizationResult,
    discarding
  } = useDiscardCostOptimizationResult(planId as string, onSuccess);
  const { resetChanges } = useSaveDraft();

  const deletePlan = useCallback(async () => {
    setDeleting(true);
    const { data } = await deleteSmartBudgetMutation({
      variables: {
        input: {
          smartBudgetId: planId
        }
      }
    });

    if (data?.deleteSmartBudget) {
      setDeleting(false);
      onSuccess();
    }
  }, [deleteSmartBudgetMutation, onSuccess, planId]);

  const removePlan = useCallback(() => {
    resetChanges();
    return planStatus !== PlanStatus.Draft
      ? deletePlan()
      : discardCostOptimizationResult();
  }, [deletePlan, discardCostOptimizationResult, planStatus, resetChanges]);

  const removing = planStatus !== PlanStatus.Draft ? deleting : discarding;

  return { removing, removePlan };
};
