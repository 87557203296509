import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { Skeleton } from '@material-ui/lab';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import classnames from 'classnames';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Button } from 'common/components';
import { useDialogState } from 'common/hooks';
import { OpenInDrawerIcon } from 'common/icons';
import { usePlanContext } from 'plans/plan/context';
import { useHasBlockingErrors } from 'plans/hooks';
import { PlanWarningDrawer } from '../PlanWarningDrawer/PlanWarningDrawer';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
    background: theme.palette.warning.light,
    marginLeft: theme.spacing(1),
    color: theme.palette.warning.dark,
    textTransform: 'none',
    padding: theme.spacing(0.5, 2),
    '&:hover': {
      background: theme.palette.warning.light
    }
  },
  rootError: {
    background: 'rgb(253, 236, 234)',
    color: theme.palette.error.dark,
    '&:hover': {
      background: 'rgb(253, 236, 234)'
    }
  },
  warningIcon: {
    color: theme.palette.warning.main
  },
  errorIcon: {
    color: theme.palette.error.main
  },
  drawerOpenIcon: {
    color: theme.palette.warning.dark
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  },
  label: {},
  startIcon: {},
  endIcon: {}
}));

export type WarningTitleTileProps = {
  classes?: ClassNameMap;
  disableRipple?: boolean;
};
export const WarningTitleTile: React.FC<WarningTitleTileProps> = ({
  classes,
  disableRipple = false
}) => {
  const cls = useStyles({ classes });
  const { plan, loading } = usePlanContext();

  const hasBlockingErrors = useHasBlockingErrors(
    plan?.overlappingPlansWarnings,
    plan?.status
  );

  const { startIcon, endIcon } = useMemo(
    () => ({
      startIcon: hasBlockingErrors ? (
        <ErrorOutlinedIcon className={cls.errorIcon} />
      ) : (
        <ReportProblemOutlinedIcon className={cls.warningIcon} />
      ),
      endIcon: <OpenInDrawerIcon className={cls.drawerOpenIcon} />
    }),
    [cls.drawerOpenIcon, cls.errorIcon, cls.warningIcon, hasBlockingErrors]
  );

  const totalWarningsCount =
    (plan?.warningsCount?.totalWarningsCount?.toNumber() ?? 0) +
    (plan?.operationOverhead?.length ? 0 : 1);

  const countValue = useMemo(
    () => ({
      count: <span className={cls.bold}>&nbsp;{totalWarningsCount}&nbsp;</span>
    }),
    [cls.bold, totalWarningsCount]
  );

  const {
    open,
    openDialog: openDrawer,
    closeDialog: closeDrawer
  } = useDialogState();

  const buttonStyle = useMemo(
    () => ({
      root: classnames(cls.root, {
        [cls.rootError]: hasBlockingErrors
      }),
      label: cls.label,
      startIcon: cls.startIcon,
      endIcon: cls.endIcon
    }),
    [
      cls.endIcon,
      cls.label,
      cls.root,
      cls.rootError,
      cls.startIcon,
      hasBlockingErrors
    ]
  );

  if (loading || !plan) {
    return <Skeleton width={200} />;
  }

  return Boolean(totalWarningsCount) ? (
    <>
      <Button
        classes={buttonStyle}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={openDrawer}
        variant="text"
        disableRipple={disableRipple}>
        <FormattedPlural
          one={<FormattedMessage id="planPage.warning.text.one" />}
          other={
            <FormattedMessage
              id="planPage.warning.text.other"
              values={countValue}
            />
          }
          value={totalWarningsCount}
        />
      </Button>
      {open && (
        <PlanWarningDrawer
          planId={plan.id}
          open={open}
          closeDrawer={closeDrawer}
        />
      )}
    </>
  ) : (
    <></>
  );
};
