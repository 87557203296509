import React, { useCallback } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Maybe } from 'generated-types';

const options = [1, 5, 10];
type NearestXBillingRateSelect = {
  currencySymbol?: Maybe<string>;
  nearestValue: string | undefined;
  setNearestValue: (value: string) => void;
  disabled?: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(25),
    marginRight: theme.spacing(5),
    textAlign: 'right'
  },
  option: {
    width: '100%',
    textAlign: 'right'
  },
  select: {
    marginTop: theme.spacing(2)
  }
}));

export const SnapToNearestXBillingRateSelect: React.FC<NearestXBillingRateSelect> = ({
  currencySymbol,
  nearestValue,
  setNearestValue,
  disabled = false
}: NearestXBillingRateSelect) => {
  const classes = useStyles();
  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) =>
      setNearestValue(event.target.value as string),
    [setNearestValue]
  );

  return (
    <FormControl className={classes.root} variant="standard">
      <InputLabel shrink>
        <FormattedMessage id="nearestXDropdown.title" />
      </InputLabel>
      <Select
        className={classes.select}
        variant="standard"
        displayEmpty
        onChange={handleChange}
        value={nearestValue}
        disabled={disabled}>
        <MenuItem value="">
          <span className={classes.option}>
            <FormattedMessage id="nearestXDropdown.noneOption" />
          </span>
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <span className={classes.option}>
              {currencySymbol ?? ''}&nbsp;{option.toFixed(2)}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
