import { useState, useEffect, useCallback, MutableRefObject } from 'react';
import BigNumber from 'bignumber.js';
import { SmartBudgetResult, Maybe } from 'generated-types';
import { useSaveDraftContext } from 'plans/plan/context';
import { useFeatureFlag } from 'common/hooks';

export type UpdateBillingRatesRef = MutableRefObject<
  ((value: BigNumber) => void) | undefined
>;

export const useSnapToNearestX = ({
  plan,
  onSnapBillingRates
}: {
  plan: Maybe<SmartBudgetResult> | undefined;
  onSnapBillingRates: (nearest: BigNumber) => void;
}): {
  nearestXValue: string;
  snapToNearestX: (value: string) => void;
} => {
  const [nearestXValue, setNearestXValue] = useState<string>('');
  useEffect(() => {
    if (plan?.nearestX && !plan.nearestX.isZero()) {
      setNearestXValue(plan?.nearestX?.toString());
    }
  }, [plan]);
  const {
    setHasSnapToNearestChanges,
    setSnapToNearestXValue
  } = useSaveDraftContext();
  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const snapToNearestX = useCallback(
    (value: string) => {
      if (nearestXValue !== value || cpoNewUx) {
        setNearestXValue(value);
        setHasSnapToNearestChanges(
          !plan?.nearestX?.isEqualTo(new BigNumber(value ?? 0))
        );
        setSnapToNearestXValue(new BigNumber(value ?? 0));
        onSnapBillingRates(new BigNumber(value));
      }
    },
    [
      cpoNewUx,
      nearestXValue,
      onSnapBillingRates,
      plan,
      setHasSnapToNearestChanges,
      setSnapToNearestXValue
    ]
  );
  return { nearestXValue, snapToNearestX };
};
