import { useState, useCallback } from 'react';

export default (): {
  open: boolean;
  openDialog: () => void;
  closeDialog: () => void;
} => {
  const [open, setDialog] = useState<boolean>(false);

  const openDialog = useCallback<() => void>(() => setDialog(true), [
    setDialog
  ]);

  const closeDialog = useCallback<() => void>(() => setDialog(false), [
    setDialog
  ]);

  return {
    open,
    openDialog,
    closeDialog
  };
};
