import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Label } from 'common/components';
import { RoleCostError } from '../../types';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export type RoleCostErrorProps = {
  roleCostError: RoleCostError;
};

const MAX_ITEMS_TO_SHOW = 10;

export const RoleError: React.FC<RoleCostErrorProps> = ({ roleCostError }) => {
  const classes = useStyles();
  const roles = useMemo(() => roleCostError.roles.slice(0, MAX_ITEMS_TO_SHOW), [
    roleCostError.roles
  ]);
  const values = useMemo(
    () => ({
      count: Number(roleCostError.roleCount - MAX_ITEMS_TO_SHOW).toString()
    }),
    [roleCostError.roleCount]
  );
  return (
    <>
      {roles.map((role: { displayText: string; uri: string }) => (
        <Label key={role.uri} className={classes.bold}>
          {role.displayText}
        </Label>
      ))}
      {roleCostError.roleCount > MAX_ITEMS_TO_SHOW && (
        <Label labelId="addPlanDialog.errors.more" values={values} />
      )}
    </>
  );
};
