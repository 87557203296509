import { useMemo } from 'react';
import { Me, NumberFormat, Maybe } from 'generated-types';
import { useMeContext } from 'App/Me';
import {
  NumberFormatProviderData,
  ThousandGroupStyle,
  NumberValueSettings
} from './NumberFormatProvider';

const convertGroupSizes = (
  groupSizes: Maybe<number>[] = []
): ThousandGroupStyle => {
  return groupSizes?.includes(2)
    ? 'lakh'
    : groupSizes?.includes(4)
    ? 'wan'
    : 'thousand';
};

const convertNumberSettings = (
  numberSettings: NumberFormat
): NumberValueSettings => {
  return {
    thousandSeparator: numberSettings?.groupSeparator ?? ',',
    decimalSeparator: numberSettings?.decimalSeparator ?? '.',
    thousandsGroupStyle: convertGroupSizes(numberSettings?.groupSizes ?? [3]),
    decimalScale: numberSettings?.decimalDigits ?? 2
  };
};

const getNumberFormatFromMe = (me: Me): NumberValueSettings => {
  const { locale } = me;

  return convertNumberSettings(locale?.numberSettings?.number ?? {});
};

const getCurrencyFormatFromMe = (me: Me): NumberValueSettings => {
  const { locale } = me;

  return convertNumberSettings(locale?.numberSettings?.currency ?? {});
};

export const useNumberFormat = (): NumberFormatProviderData => {
  const me = useMeContext();
  return useMemo(
    () => ({
      currencyFormat: getCurrencyFormatFromMe(me),
      numberFormat: getNumberFormatFromMe(me)
    }),
    [me]
  );
};
