import React, { memo } from 'react';
import { makeStyles, Card } from '@material-ui/core';
import { SmartBudgetResult, Maybe } from 'generated-types';
import { useIntl } from 'App/MessageProvider';
import { PLAN_CARD_DETAIL_HEIGHT } from '../cards/constants';
import { DataCardHeader } from '../DataCard';
import {
  BusinessCostCardSkeleton,
  BusinessCostCardContent
} from './components';

type BusinessCostCardProps = {
  plan?: Maybe<SmartBudgetResult>;
  loading?: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginLeft: 12,
    marginBottom: 12,
    position: 'relative',
    minHeight: PLAN_CARD_DETAIL_HEIGHT,
    height: '100%'
  }
}));

const BusinessCostsCard: React.FC<BusinessCostCardProps> = props => {
  const { loading, plan } = props;
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Card className={classes.root}>
      <DataCardHeader
        title={intl.formatMessage({
          id: 'planPage.businessCostCard.title'
        })}
        titleTypographyProps={{ variant: 'h6' }}
      />
      {loading && <BusinessCostCardSkeleton />}
      {plan && <BusinessCostCardContent plan={plan} />}
    </Card>
  );
};

export default memo(BusinessCostsCard);
