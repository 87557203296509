import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow
} from '@replicon/react-data-table';
import {
  ButtonBase,
  TableSortLabel,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { DateRangeBase, MoneyValue, NumberValue } from 'common/components';
import { SortDirection, PlanListSortableFields } from 'generated-types';
import { useSearchCriteriaContext } from 'common/context';
import PlanStatus from '../PlanStatus';
import { usePlans } from './hooks/usePlans';
import PlanListTableSkeleton from './PlansListTableSkeleton';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - 98px)',
    overflowX: 'auto'
  },
  fileName: {
    ...theme.typography.body2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary
  },
  percentage: {
    paddingLeft: 1,
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '80%'
    }
  },
  cell: {
    width: 100
  },
  sortHeader: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[900]
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[700]
  },
  planNameCell: {
    minWidth: 150,
    width: 150,
    maxWidth: '17em'
  },
  planNameButton: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'left',
    height: theme.spacing(6),
    paddingLeft: 16
  },
  planName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  headCount: {
    width: 115,
    overflowX: 'hidden',
    [theme.breakpoints.only('xl')]: {
      maxWidth: 115
    },
    color: theme.palette.text.secondary
  },
  status: {
    maxWidth: 145,
    width: 120
  }
}));

export type PlansListTableProps = {
  periodSortDirection: SortDirection;
  loadingTableSetting: boolean;
  togglePeriodSort: () => void;
  navigateToPlanPage: (e: any) => void;
};

const getSortDirection = (direction: SortDirection): 'asc' | 'desc' =>
  direction === SortDirection.Asc ? 'asc' : 'desc';

export const PlansListTable: React.FC<PlansListTableProps> = ({
  periodSortDirection,
  loadingTableSetting,
  togglePeriodSort,
  navigateToPlanPage
}: PlansListTableProps) => {
  const classes = useStyles();
  const { searchCriteria } = useSearchCriteriaContext();

  const { loading, costOptimizationResults } = usePlans({
    count: 50,
    sort: {
      field: PlanListSortableFields.Period,
      direction: periodSortDirection as SortDirection
    },
    skip: loadingTableSetting,
    searchText: searchCriteria.keywords?.length
      ? searchCriteria.keywords.join(' ')
      : undefined
  });

  const headers = useMemo(
    () => [
      {
        text: 'planListPage.name',
        align: false,
        button: true,
        class: classes.planName
      },
      { text: 'planListPage.period', align: false, sortable: true },
      { text: 'planListPage.status', align: false, class: classes.status },
      {
        text: 'planListPage.totalHeadcount',
        align: true,
        class: classes.headCount
      },
      { text: 'planListPage.hours', align: true },
      { text: 'planListPage.totalCost', align: true },
      { text: 'planListPage.loadedLaborRate', align: true },
      { text: 'planListPage.utilization', align: true },
      { text: 'planListPage.billingRate', align: true },
      { text: 'planListPage.margin', align: true },
      { text: 'planListPage.revenue', align: true },
      { text: 'planListPage.profit', align: true }
    ],
    [classes.headCount, classes.planName, classes.status]
  );
  const numberColumnDirection = 'right';

  const noPad = { padding: 0 };
  return (
    <>
      {loading || loadingTableSetting ? (
        <PlanListTableSkeleton headers={headers} className={classes.cell} />
      ) : (
        <div className={classes.container}>
          <Table>
            <TableHeader>
              <TableHeaderRow>
                {headers.map(h => {
                  return (
                    <TableHeaderCell
                      key={h.text}
                      className={classnames(h.class, {
                        [classes.cell]: !h?.class,
                        [classes.sortHeader]: h.sortable,
                        [classes.header]: !h.sortable
                      })}
                      align={h.align ? numberColumnDirection : 'left'}
                      sortDirection={
                        !h.sortable
                          ? false
                          : getSortDirection(periodSortDirection)
                      }>
                      {h.sortable ? (
                        <TableSortLabel
                          active={Boolean(periodSortDirection)}
                          direction={getSortDirection(periodSortDirection)}
                          onClick={togglePeriodSort}>
                          <FormattedMessage id={h.text} />
                        </TableSortLabel>
                      ) : (
                        <FormattedMessage id={h.text} />
                      )}
                    </TableHeaderCell>
                  );
                })}
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {costOptimizationResults?.edges?.map(r => {
                return (
                  <TableRow key={r.node.id}>
                    <TableCell className={classes.planNameCell} style={noPad}>
                      <Tooltip title={r.node.name ?? ''}>
                        <ButtonBase
                          onClick={() => navigateToPlanPage(r.node.id)}
                          className={classes.planNameButton}>
                          <span className={classes.planName}>
                            {r.node.name}
                          </span>
                        </ButtonBase>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <DateRangeBase
                        startDate={r.node.effectiveDate ?? ''}
                        endDate={r.node.endDate ?? ''}
                      />
                    </TableCell>
                    <TableCell className={classes.status}>
                      <PlanStatus status={r.node.status ?? undefined} />
                    </TableCell>
                    <TableCell align={numberColumnDirection}>
                      <NumberValue value={r.node.usersCount ?? 0} />
                    </TableCell>
                    <TableCell
                      align={numberColumnDirection}
                      className={classes.cell}>
                      <NumberValue
                        value={
                          r.node.totalBillableHours
                            ?.plus(r.node.totalNonBillableHours ?? 0)
                            .toNumber() ?? 0
                        }
                      />
                    </TableCell>
                    <TableCell
                      align={numberColumnDirection}
                      className={classes.cell}>
                      <MoneyValue
                        currencySymbol={
                          r.node.totalCost?.currency?.symbol ?? ''
                        }
                        value={r.node.totalCost?.amount?.toNumber() ?? 0}
                      />
                    </TableCell>
                    <TableCell
                      align={numberColumnDirection}
                      className={classes.cell}>
                      <MoneyValue
                        currencySymbol={
                          r.node.laborRate?.currency?.symbol ?? ''
                        }
                        value={r.node.laborRate?.amount?.toNumber() ?? 0}
                      />
                    </TableCell>
                    <TableCell
                      align={numberColumnDirection}
                      className={classes.cell}>
                      <>
                        {r.node.utilization?.toFixed(2)}
                        &nbsp;%
                      </>
                    </TableCell>
                    <TableCell
                      align={numberColumnDirection}
                      className={classes.cell}>
                      <MoneyValue
                        currencySymbol={
                          r.node.billingRate?.currency?.symbol ?? ''
                        }
                        value={r.node.billingRate?.amount?.toNumber() ?? 0}
                      />
                    </TableCell>
                    <TableCell
                      align={numberColumnDirection}
                      className={classes.cell}>
                      <>{r.node.margin?.toFixed(2)}&nbsp;%</>
                    </TableCell>
                    <TableCell
                      align={numberColumnDirection}
                      className={classes.cell}>
                      <MoneyValue
                        currencySymbol={
                          r.node.totalRevenue?.currency?.symbol ?? ''
                        }
                        value={r.node.totalRevenue?.amount?.toNumber() ?? 0}
                      />
                    </TableCell>
                    <TableCell
                      align={numberColumnDirection}
                      className={classes.cell}>
                      <MoneyValue
                        currencySymbol={
                          r.node.totalRevenue?.currency?.symbol ?? ''
                        }
                        value={
                          r.node.totalRevenue?.amount
                            ?.minus(r.node.totalCost?.amount ?? 0)
                            .toNumber() ?? 0
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  );
};
