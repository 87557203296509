import React from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import BigNumber from 'bignumber.js';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core';
import { TotalCostBreakdown } from 'common/types';
import { RoleDataTableHeader, RoleDataTableTotals } from 'rateCard/components';

type RoleSummaryTableGlobalHeaderProps = {
  totalCostBreakdown: Maybe<TotalCostBreakdown>;
  cellClasses?: ClassNameMap;
  margin?: BigNumber;
  utilization?: BigNumber;
};

const useStyles = makeStyles(() => ({
  tableCell: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fontWeight: 'normal !important' as any
  }
}));

export const RoleSummaryTableGlobalHeader: React.FC<RoleSummaryTableGlobalHeaderProps> = ({
  totalCostBreakdown,
  cellClasses,
  utilization,
  margin
}: RoleSummaryTableGlobalHeaderProps) => {
  const totalClasses = useStyles();

  return (
    <>
      <RoleDataTableHeader cellClasses={cellClasses} />
      <RoleDataTableTotals
        cellClasses={cellClasses}
        totalCostBreakdown={totalCostBreakdown}
        utilization={utilization}
        margin={margin}
        totalRevenue={
          totalCostBreakdown?.totalRevenue?.amount ?? new BigNumber(0)
        }
        classes={totalClasses}
      />
    </>
  );
};

export default RoleSummaryTableGlobalHeader;
