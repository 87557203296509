import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { PlanStatus } from 'generated-types';
import PlanStatusChip from 'plans/PlanStatus';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1, 0, 2),
    display: 'flex',
    flexDirection: 'row'
  }
}));

type PlanPageStatusBarProps = {
  status: PlanStatus;
  loading: boolean;
};

const PlanPageStatusBar: React.FC<PlanPageStatusBarProps> = ({
  status,
  loading,
  children
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!loading && (
        <>
          <PlanStatusChip status={status} />
          {children}
        </>
      )}
      {loading && <Skeleton width={50} animation="wave" />}
    </div>
  );
};

export default PlanPageStatusBar;
