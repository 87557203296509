import { useMemo } from 'react';
import { usePermissionSet } from './usePermissionSet';

export const useHasCpoEditPermission = (): boolean => {
  const permissionSet = usePermissionSet();

  return useMemo(
    () =>
      permissionSet.has('urn:replicon:cpo-action:edit-cost-price-optimizer') ||
      permissionSet.has(
        'urn:replicon-webui:smart-budget-action:edit-smart-budget'
      ),
    [permissionSet]
  );
};
