import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { UpcomingPanel } from './components/UpcomingPanel';
import { FutureEmptyState } from './components';

export const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1.5, 0, 8, 0)
  }
}));

const FuturePage: React.FC = () => {
  const [hasUpcoming, setHasUpcoming] = useState(true);

  return (
    <>
      {!hasUpcoming && <FutureEmptyState />}
      <UpcomingPanel setHasUpcoming={setHasUpcoming} />
    </>
  );
};

export default FuturePage;
