import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useUploadPath } from 'fileUpload';
import { useHasCpoEditPermission } from 'common/hooks';
import { ReactComponent as FutureEmptyStateIcon } from './FutureEmptyStateIcon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.grey[800],
    display: 'flex',
    minHeight: 500,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    whiteSpace: 'nowrap',
    paddingBottom: 10
  },
  info: {
    display: 'flex',
    whiteSpace: 'nowrap'
  },
  button: {
    display: 'flex',
    whiteSpace: 'nowrap',
    paddingTop: 10
  },
  text: {
    paddingRight: 20
  },
  link: {
    ...theme.typography.body1,
    padding: theme.spacing(0, 0.75, 0, 0),
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const FutureEmptyState: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const uploadPath = useUploadPath();
  const hasCpoEditPermission = useHasCpoEditPermission();

  const handleClick = useCallback(() => {
    history.replace(uploadPath);
  }, [history, uploadPath]);

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <div className={classes.title}>
          <FormattedMessage id="emptyState.futureTitle" />
        </div>
        {hasCpoEditPermission && (
          <div className={classes.button}>
            <Link
              variant="body2"
              component="button"
              color="primary"
              onClick={handleClick}
              className={classes.link}>
              <FormattedMessage id="emptyState.futureButton" />
            </Link>
            <FormattedMessage id="emptyState.futureButtonInfo" />
          </div>
        )}
      </div>
      <FutureEmptyStateIcon />
    </div>
  );
};

export default FutureEmptyState;
