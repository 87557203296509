import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { useRouteBase } from 'App/EmbeddedAppContext';
import { RouteTabs } from 'common/routes/components';
import { CpoRouteProps } from 'common/routes/Routes';
import { activePageRoute } from 'active';
import { historyPageRoute } from 'historyPage';
import { uploadRootRoute } from 'uploadTab';
import { futurePageRoute } from 'futurePage';
import { importPageRoute } from 'import';
import { useHasCpoEditPermission } from 'common/hooks';

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(1)
  }
}));

export const rateCardRoutes = (
  prefix: string,
  hasCpoEditPermission: boolean
): CpoRouteProps[] =>
  [
    activePageRoute(prefix),
    futurePageRoute(prefix),
    historyPageRoute(prefix),
    hasCpoEditPermission && importPageRoute(prefix),
    hasCpoEditPermission && uploadRootRoute(prefix)
  ].filter(route => route) as CpoRouteProps[];

export const RateCard: React.FC = () => {
  const baseRoute = useRouteBase();
  const hasCpoEditPermission = useHasCpoEditPermission();
  const routes = rateCardRoutes(baseRoute, hasCpoEditPermission);
  const classes = useStyles();
  return (
    <RouteTabs routes={routes} className={classes.tabs}>
      <Redirect exact from={baseRoute + '/'} to={baseRoute + '/active'} />
    </RouteTabs>
  );
};

export default RateCard;
