import { useCallback } from 'react';
import { MutationResult } from '@apollo/client';
import BigNumber from 'bignumber.js';
import { ExecutionResult } from 'graphql';
import {
  useSaveRateCardMutation,
  SaveRateCardMutation,
  SmartBudgetResult
} from 'generated-types';
import { RoleData } from 'common/types';

type UseSaveRateCardOptions = {
  costOptimizationResult: SmartBudgetResult;
  dirtyFields: Set<string>;
  getFormValues: () => RoleData;
  onSaveComplete?: () => void;
};

type UseSaveRateCardResult = [
  () => Promise<ExecutionResult<SaveRateCardMutation> | undefined>,
  MutationResult<SaveRateCardMutation>
];

const useSaveRateCard = ({
  costOptimizationResult,
  dirtyFields,
  getFormValues,
  onSaveComplete
}: UseSaveRateCardOptions): UseSaveRateCardResult => {
  const [saveRateCardMutation, data] = useSaveRateCardMutation();

  const saveRateCard = useCallback(async () => {
    if (!dirtyFields.size) {
      return;
    }

    const values = getFormValues();
    const parameters = Array.from(dirtyFields)
      .map(v => ({
        roleUri:
          costOptimizationResult.roleCostBreakdown?.find(r => r?.id === v)?.role
            ?.id ?? '',
        margin: new BigNumber(values[v].margin),
        utilization: new BigNumber(values[v].utilization)
      }))
      .filter(r => r.roleUri.length);

    const result = await saveRateCardMutation({
      variables: {
        smartBudgetId: costOptimizationResult.id,
        rateCardParameters: parameters
      }
    });

    if (!result.errors?.length) {
      onSaveComplete?.();
    }

    return result;
  }, [
    costOptimizationResult.id,
    costOptimizationResult.roleCostBreakdown,
    dirtyFields,
    getFormValues,
    onSaveComplete,
    saveRateCardMutation
  ]);

  return [saveRateCard, data];
};

export default useSaveRateCard;
