import {
  useCostOptimizationResultRoleBreakdownsQuery,
  CostOptimizationResult
} from 'generated-types';
import { RoleRateTableEntry } from 'common/components/UserSummary/components/RoleRateDialog/RoleRateDialog';

export const useCostOptimizationResultRoleBreakdowns = (
  id: string
): {
  loading: boolean;
  roleBreakdowns: {
    billable: RoleRateTableEntry[] | undefined;
    nonBillable: RoleRateTableEntry[] | undefined;
  };
  result: CostOptimizationResult | null | undefined;
} => {
  const { loading, data } = useCostOptimizationResultRoleBreakdownsQuery({
    variables: {
      id: id ?? ''
    }
  });

  const result = data?.costOptimizationResult;
  const billableRoleBreakdowns = result?.roleCostBreakdown?.map(r => {
    return {
      name: r?.role?.displayText,
      totalCost: r?.totalCost?.amount?.toNumber(),
      hours: r?.totalHours?.toNumber(),
      userCount: r?.userCount?.toNumber()
    };
  });
  const nonBillableRoleBreakdowns = result?.nonBillableRoles?.map(r => {
    return {
      name: r?.role?.displayText,
      totalCost: r?.cost?.toNumber(),
      hours: r?.hours?.toNumber(),
      userCount: r?.userCount?.toNumber()
    };
  });

  const roleBreakdowns = {
    billable: billableRoleBreakdowns,
    nonBillable: nonBillableRoleBreakdowns,
    result: result
  };

  return {
    loading: loading,
    roleBreakdowns: roleBreakdowns,
    result: result
  };
};
