import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[600],
    paddingRight: theme.spacing(1)
  }
}));

type RoleFileNameProps = {
  fileName: string;
};

export const RoleFileName: React.FC<RoleFileNameProps> = ({
  fileName
}: RoleFileNameProps) => {
  const classes = useStyles();
  return <span className={classes.root}>{fileName}</span>;
};
