import { useCallback, useState } from 'react';
import { useDialogState } from 'common/hooks';

type UseRoleTableNaviationBlockOptions = {
  saveRates?: () => Promise<void> | void;
};

type UseRoleTableNavigationBlockResult = {
  saveDialogOpen: boolean;
  openSaveDialog: (navigationCallback: () => void) => void;
  onSaveClick: () => Promise<void>;
  onDiscardClick: () => void;
  onClose: () => void;
};

const useRoleTableNavigationBlock = ({
  saveRates
}: UseRoleTableNaviationBlockOptions): UseRoleTableNavigationBlockResult => {
  const {
    open: saveDialogOpen,
    openDialog: openSaveDialog,
    closeDialog: closeSaveDialog
  } = useDialogState();
  const [navigateCallback, setNavigateCallback] = useState<() => void>();
  const openNavigationBlockDialog = useCallback(
    (cb: () => void): void => {
      // If you pass a function to a setState, it will
      // try to call that function. Pass it as a function to
      // get around this.
      setNavigateCallback(() => cb);
      openSaveDialog();
    },
    [openSaveDialog]
  );

  const onSaveClick = useCallback(async (): Promise<void> => {
    await saveRates?.();
    closeSaveDialog();
    navigateCallback && navigateCallback();
  }, [closeSaveDialog, navigateCallback, saveRates]);

  const onDiscardClick = useCallback((): void => {
    closeSaveDialog();
    navigateCallback && navigateCallback();
  }, [closeSaveDialog, navigateCallback]);

  return {
    saveDialogOpen,
    openSaveDialog: openNavigationBlockDialog,
    onSaveClick,
    onDiscardClick,
    onClose: closeSaveDialog
  };
};

export default useRoleTableNavigationBlock;
