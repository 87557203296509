import React, { useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Typography, makeStyles, TextField } from '@material-ui/core';
import { DateTime, DateObject } from 'luxon';
import classNames from 'classnames';

import { PlansTabs } from 'plans';
import { ProgressButton } from 'common/components';
import {
  useCreateNewSmartBudgetMutation,
  useCpoBackgroundWorkLazyQuery
} from 'generated-types';
import { useMeDateTimeFormats } from 'App/DateTimeFormatProvider';
import PageHeader from 'common/components/PageHeader';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  section: {
    width: '60%',
    textAlign: 'center'
  },
  formSection: {
    '& > div': {
      margin: theme.spacing(1)
    }
  }
}));

const ImportPage: React.FC = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<DateObject>();
  const [endDate, setEndDate] = useState<DateObject>();
  const [planName, setPlanName] = useState<string>('');

  const { dateFormat } = useMeDateTimeFormats();

  const [
    createNewSmartBudget,
    { loading, data }
  ] = useCreateNewSmartBudgetMutation();
  const [
    getBackgroundWork,
    { loading: bgLoading, data: bgData, refetch: bgRefetch }
  ] = useCpoBackgroundWorkLazyQuery();

  const canSubmit =
    startDate !== undefined &&
    endDate !== undefined &&
    planName.trim().length > 0;

  return (
    <>
      <PageHeader title="SmartBudget" />
      <PlansTabs />
      <div className={classes.root}>
        <div className={classes.section}>
          <Typography variant="h6">
            Calculate the best billing rates for your business by processing
            your Replicon data.
          </Typography>
        </div>
        <div className={classNames(classes.section, classes.formSection)}>
          <TextField
            label="Plan Name"
            variant="filled"
            onChange={e => setPlanName(e.target.value)}
            value={planName}
          />
        </div>
        <div className={classNames(classes.section, classes.formSection)}>
          <KeyboardDatePicker
            id="startDate"
            autoOk
            inputVariant="filled"
            label={'Start Date'}
            value={startDate ? DateTime.fromObject(startDate) : undefined}
            format={dateFormat}
            onChange={e => setStartDate(e?.toObject())}
          />
          <KeyboardDatePicker
            id="endDate"
            autoOk
            disabled={!Boolean(startDate)}
            inputVariant="filled"
            label={'End Date'}
            value={endDate ? DateTime.fromObject(endDate) : undefined}
            format={dateFormat}
            onChange={e => setEndDate(e?.toObject())}
          />
        </div>
        <div className={classes.section}>
          <ProgressButton
            variant="contained"
            color="primary"
            loading={loading}
            disabled={!canSubmit}
            onClick={() => {
              startDate &&
                endDate &&
                createNewSmartBudget({
                  variables: {
                    name: planName,
                    startDate: {
                      year: startDate.year,
                      month: startDate.month,
                      day: startDate.day
                    },
                    endDate: {
                      year: endDate.year,
                      month: endDate.month,
                      day: endDate.day
                    }
                  }
                });
            }}>
            Start Import
          </ProgressButton>
        </div>
        <div className={classes.section}>
          {data && (
            <p>Background work id: {data.createSmartBudget.backgroundWorkId}</p>
          )}
          {data && data.createSmartBudget.backgroundWorkId && (
            <ProgressButton
              loading={bgLoading}
              onClick={() => {
                if (bgData?.cpoBackgroundWork?.id) {
                  bgRefetch?.({
                    workId: data.createSmartBudget.backgroundWorkId ?? ''
                  });
                } else {
                  getBackgroundWork({
                    variables: {
                      workId: data.createSmartBudget.backgroundWorkId ?? ''
                    }
                  });
                }
              }}>
              Get Work Status
            </ProgressButton>
          )}
          {bgData?.cpoBackgroundWork?.workStatus && (
            <p>Status: {bgData.cpoBackgroundWork.workStatus}</p>
          )}
          {bgData?.cpoBackgroundWork?.message && (
            <p>Message: {bgData.cpoBackgroundWork.message}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ImportPage;
