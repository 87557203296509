import React, { memo } from 'react';
import { TableCell, TableHead, TableRow, InputLabel } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CellAlign } from 'common/types';
import { useLockedColumnContext } from 'common/context';
import LockButton from './LockButton';

const useStyles = makeStyles(theme => ({
  tableHeader: {
    borderColor: theme.palette.grey[300],
    borderWidth: 1,
    borderBottom: 'none',
    borderTop: 'none',
    padding: theme.spacing(1),
    verticalAlign: 'baseline'
  },
  lockIcon: {
    margin: theme.spacing(-0.5, 1, -0.5, -0.5)
  },
  boldHeader: {
    fontWeight: 'bold'
  },
  label: {
    ...theme.typography.body2,
    color: 'black'
  }
}));

const useCellStyle = makeStyles(theme => ({
  root: {
    '&:last-child': {
      paddingRight: theme.spacing(3)
    }
  }
}));

type Header = {
  id: string;
  align?: CellAlign;
  bold?: boolean;
  lockable?: boolean;
  formKey?: string;
};

const useHeaders = (): Header[] => {
  const numberColumnDirection = 'right';
  return [
    {
      id: 'expansionPanelDetailsTable.summary',
      bold: true
    },
    {
      id: 'expansionPanelDetailsTable.hours',
      align: numberColumnDirection
    },
    {
      id: 'expansionPanelDetailsTable.totalCost',
      align: numberColumnDirection
    },
    {
      id: 'expansionPanelDetailsTable.standardRate',
      align: numberColumnDirection
    },
    {
      id: 'expansionPanelDetailsTable.nonBillOverhead',
      align: numberColumnDirection
    },
    {
      id: 'expansionPanelDetailsTable.businessOverhead',
      align: numberColumnDirection
    },
    {
      id: 'expansionPanelDetailsTable.laborRate',
      align: numberColumnDirection
    },
    {
      id: 'expansionPanelDetailsTable.utilization',
      align: numberColumnDirection,
      lockable: true,
      formKey: 'utilization'
    },
    {
      id: 'expansionPanelDetailsTable.margin',
      align: numberColumnDirection,
      lockable: true,
      formKey: 'margin'
    },
    {
      id: 'expansionPanelDetailsTable.billingRate',
      align: numberColumnDirection,
      lockable: true,
      formKey: 'billingRate'
    },
    {
      id: 'expansionPanelDetailsTable.revenue',
      align: numberColumnDirection,
      lockable: true,
      formKey: 'revenue'
    }
  ];
};

type RoleDataTableHeaderProps = {
  cellClasses?: ClassNameMap;
};

export const RoleDataTableHeader: React.FC<RoleDataTableHeaderProps> = memo(
  ({ cellClasses }: RoleDataTableHeaderProps): React.ReactElement => {
    const classes = useStyles();
    const cellStyle = useCellStyle({ classes: cellClasses });
    const {
      canLockColumns,
      lockedColumn,
      setLockedColumn
    } = useLockedColumnContext();
    const headers = useHeaders();

    return (
      <TableHead>
        <TableRow>
          {headers.map(cell => {
            const shouldShowLock = Boolean(
              canLockColumns &&
                cell.lockable &&
                cell.formKey &&
                (!lockedColumn || lockedColumn === cell.formKey)
            );

            return (
              <TableCell
                classes={cellStyle}
                align={cell.align}
                key={cell.id}
                className={classnames(
                  {
                    [classes.boldHeader]: cell.bold
                  },
                  classes.tableHeader
                )}>
                {shouldShowLock ? (
                  <InputLabel htmlFor={cell.id} className={classes.label}>
                    <LockButton
                      className={classes.lockIcon}
                      locked={lockedColumn === cell.formKey}
                      lockKey={cell.formKey}
                      onClick={setLockedColumn}
                    />
                    <FormattedMessage id={cell.id} />
                  </InputLabel>
                ) : (
                  <FormattedMessage id={cell.id} />
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
);

export default RoleDataTableHeader;
