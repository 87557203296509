import { useState, useCallback, useEffect } from 'react';
import {
  usePutBusinessCostsMutation,
  useSaveRateCardMutation,
  PutBusinessCostsInput,
  PlanStatus,
  usePutSmartBudgetRoundingMutation
} from 'generated-types';
import {
  useSaveDraftContext,
  useLocalBusinessCostContext,
  usePlanContext
} from 'plans/plan/context';
import { useFeatureFlag } from 'common/hooks';
import { NEW_BUSINESS_COST_ID } from '../cards/constants';

export type SaveDraftResult = {
  saveSuccess: boolean;
  saveFailure: boolean;
  saving: boolean;
  canSave: boolean;
  resetChanges: () => void;
  saveDraft: () => Promise<void>;
};

export const useSaveDraft = (): SaveDraftResult => {
  const [saving, setSaving] = useState(false);
  const [saveFailure, setSaveFailure] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const [putBusinessCostMutation] = usePutBusinessCostsMutation();
  const [saveRateCardMutation] = useSaveRateCardMutation();
  const [putSmartBudgetRoundingMutation] = usePutSmartBudgetRoundingMutation();
  const {
    snapToNearestXValue,
    hasSnapToNearestChanges,
    setHasSnapToNearestChanges,
    roleDataParameters,
    hasBusinessCostChanges,
    hasRoleCostChanges,
    setHasBusinessCostChanges,
    setHasRoleCostChanges,
    resetRoleFormRef
  } = useSaveDraftContext();
  const [synchronizedAfterCommit, setSynchronizedAfterCommit] = useState(false);
  const { businessCosts } = useLocalBusinessCostContext();
  const { plan } = usePlanContext();

  useEffect(() => {
    (plan?.status === PlanStatus.Active ||
      plan?.status === PlanStatus.Upcoming) &&
    plan?.lastSynchronizedAt &&
    plan?.lastCommittedAt &&
    plan.lastSynchronizedAt > plan.lastCommittedAt
      ? setSynchronizedAfterCommit(true)
      : setSynchronizedAfterCommit(false);
  }, [plan, setSynchronizedAfterCommit]);
  useEffect(() => {
    setCanSave(
      hasBusinessCostChanges ||
        hasRoleCostChanges ||
        hasSnapToNearestChanges ||
        synchronizedAfterCommit
    );
  }, [
    hasBusinessCostChanges,
    hasRoleCostChanges,
    hasSnapToNearestChanges,
    synchronizedAfterCommit
  ]);

  const resetChanges = useCallback(() => {
    setHasBusinessCostChanges(false);
    setHasRoleCostChanges(false);
    setHasSnapToNearestChanges(false);
    resetRoleFormRef.current && resetRoleFormRef.current();
  }, [
    resetRoleFormRef,
    setHasBusinessCostChanges,
    setHasRoleCostChanges,
    setHasSnapToNearestChanges
  ]);

  const onSaveStart = useCallback(() => {
    setSaving(true);
  }, []);
  const onSaveSuccess = useCallback(() => {
    setSaving(false);
    setSaveSuccess(true);
    resetChanges();
  }, [resetChanges]);
  const onSaveFailure = useCallback(() => {
    setSaving(false);
    setSaveFailure(true);
    resetChanges();
  }, [resetChanges]);

  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const saveDraft = useCallback(async () => {
    if (!canSave) {
      return;
    }

    onSaveStart();

    const businessCostParameters = {
      id: plan?.id,
      businessCosts: businessCosts.map(bu => ({
        operationCost: bu.cost?.amount,
        operationName: bu.name,
        isNew: bu.id.includes(NEW_BUSINESS_COST_ID) || bu.isNew
      }))
    } as PutBusinessCostsInput;

    try {
      if (hasSnapToNearestChanges && !cpoNewUx) {
        await putSmartBudgetRoundingMutation({
          variables: {
            input: {
              smartBudgetId: plan?.id ?? '',
              nearestX: snapToNearestXValue
            }
          }
        });
      }
      await putBusinessCostMutation({
        variables: {
          input: {
            ...businessCostParameters
          }
        }
      });
      await saveRateCardMutation({
        variables: {
          smartBudgetId: plan?.id ?? '',
          rateCardParameters: roleDataParameters
        }
      });
      onSaveSuccess();
    } catch (e) {
      onSaveFailure();
    }
  }, [
    businessCosts,
    canSave,
    cpoNewUx,
    hasSnapToNearestChanges,
    onSaveFailure,
    onSaveStart,
    onSaveSuccess,
    plan,
    putBusinessCostMutation,
    putSmartBudgetRoundingMutation,
    roleDataParameters,
    saveRateCardMutation,
    snapToNearestXValue
  ]);

  return {
    saving,
    saveSuccess,
    saveFailure,
    canSave,
    resetChanges,
    saveDraft
  };
};
