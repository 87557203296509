import React from 'react';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  makeStyles
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  firstCell: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderWidth: theme.spacing(0, 0.125, 0.125, 0)
  },
  row: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderWidth: theme.spacing(0.125)
  }
}));

export const RoleTableSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Table>
      <TableBody>
        {[...Array(7)].map((__: number, rowIndex: number) => (
          <TableRow key={`Row_${rowIndex}`} className={classes.row}>
            <TableCell align="left" scope="row" className={classes.firstCell}>
              <Skeleton width="50%" />
            </TableCell>
            {[...Array(4)].map((___: number, cellIndex: number) => (
              <TableCell key={`Cell_${rowIndex}_${cellIndex}`} align="right">
                <Skeleton width="50%" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default RoleTableSkeleton;
