import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { Label, ProgressButton } from 'common/components';

type RoleDataSaveDialog = {
  open: boolean;
  onSaveClick: () => Promise<void> | void;
  onDiscardClick: () => Promise<void> | void;
  onClose: () => void;
};

const RoleDataSaveDialog: React.FC<RoleDataSaveDialog> = ({
  open,
  onDiscardClick,
  onSaveClick,
  onClose
}: RoleDataSaveDialog) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const [error, setError] = useState<string>();

  const onSaveClickHandler = useCallback(async () => {
    setSaveLoading(true);

    try {
      await onSaveClick();
    } catch (e) {
      setError(e.message);
      setDiscardLoading(false);
    }
  }, [onSaveClick]);

  const onDiscardClickHandler = useCallback(async () => {
    setDiscardLoading(true);

    try {
      await onDiscardClick();
    } catch (e) {
      setError(e.message);
      setDiscardLoading(false);
    }
  }, [onDiscardClick]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Label variant="h6" labelId="roleDataSaveDialog.title" />
      </DialogTitle>
      <DialogContent>
        <Label labelId="roleDataSaveDialog.content" />
        {error && <Label color="error">{error}</Label>}
      </DialogContent>
      <DialogActions>
        <ProgressButton
          loading={discardLoading}
          disabled={saveLoading || discardLoading}
          variant="text"
          onClick={onDiscardClickHandler}
          color="default"
          labelId="button.discardChanges"
        />
        <ProgressButton
          loading={saveLoading}
          disabled={saveLoading || discardLoading}
          variant="text"
          onClick={onSaveClickHandler}
          color="primary"
          labelId="button.saveChanges"
        />
      </DialogActions>
    </Dialog>
  );
};

export default RoleDataSaveDialog;
