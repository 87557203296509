import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import BigNumber from 'bignumber.js';
import RateCardModel, {
  RateCardColumn,
  RateCardModelState,
  RateCardModelOptions
} from './RateCardModel';

const rbrc = new RoleBillingRateCalculator();
const marginCalculationFunction = (
  changedColumn: RateCardColumn,
  state: RateCardModelState,
  options: RateCardModelOptions<RateCardModelState> | undefined
): BigNumber | undefined => {
  if (
    changedColumn.name === 'utilization' &&
    options?.lockedColumns?.includes('billingRate')
  ) {
    return rbrc.calculateMarginFromBillingRate(
      state['billingRate'],
      state['utilization'],
      state['loadedCost']
    );
  }
  return rbrc.calculateMargin(
    state['revenue'],
    state['loadedCost'],
    state['totalHours']
  );
};

const utilizationCalculationFunction = (
  _: RateCardColumn,
  state: RateCardModelState
): BigNumber | undefined => {
  return state['loadedCost'].dividedBy(
    state['billingRate'].minus(state['billingRate'].times(state['margin']))
  );
};

const billingRateCalculationFunction = (
  _: RateCardColumn,
  state: RateCardModelState
): BigNumber => {
  return rbrc.calculateBillingRateForRole(
    state['loadedCost'],
    state['utilization'],
    state['margin']
  );
};

const revenueCalculationFunction = (
  changedColumn: RateCardColumn,
  state: RateCardModelState
): BigNumber => {
  if (
    changedColumn.name === 'billingRate' ||
    changedColumn.name === 'utilization'
  ) {
    return rbrc.calculateRevenue(
      state['billingRate'],
      state['utilization'],
      state['totalHours']
    );
  }

  return state['totalHours']
    .times(state['loadedCost'])
    .dividedBy(new BigNumber(1).minus(state['margin']));
};

const loadedCostCalculationFunction = (
  _: RateCardColumn,
  state: RateCardModelState
) => {
  return state['standardRate']
    .plus(state['nonBillableOverhead'])
    .plus(state['businessOverhead']);
};

const utilizationMarginBillingRateModel = new RateCardModel();

// Columns are specified in the order they should be updated on a change
// to a dependant column.
const columns = [
  new RateCardColumn('standardRate'),
  new RateCardColumn('nonBillableOverhead'),
  new RateCardColumn('businessOverhead'),
  new RateCardColumn(
    'loadedCost',
    ['standardRate', 'nonBillableOverhead', 'businessOverhead'],
    loadedCostCalculationFunction
  ),
  new RateCardColumn('totalHours'),
  new RateCardColumn(
    'billingRate',
    ['loadedCost', 'utilization'],
    billingRateCalculationFunction
  ),
  new RateCardColumn(
    'margin',
    ['loadedCost', 'utilization'],
    marginCalculationFunction
  ),
  new RateCardColumn(
    'utilization',
    ['billingRate', 'margin'],
    utilizationCalculationFunction
  ),
  new RateCardColumn(
    'revenue',
    ['margin', 'billingRate', 'utilization'],
    revenueCalculationFunction
  )
];

columns.map(
  utilizationMarginBillingRateModel.registerColumn.bind(
    utilizationMarginBillingRateModel
  )
);

export default utilizationMarginBillingRateModel;
