import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { SummaryCard } from 'common/components';
import { useDialogState } from 'common/hooks';
import { CalculationInfoDialog } from 'rateCard/components/CalculationInfoDialog';

const useStyles = makeStyles(theme => ({
  title: {
    paddingLeft: theme.spacing(1),
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  container: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1.5),
      paddingLeft: 0
    },
    paddingLeft: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    },
    height: 136
  },
  info: {
    padding: theme.spacing(1, 1),
    display: 'flex',
    whiteSpace: 'nowrap'
  },
  button: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export const CostingModel: React.FC = () => {
  const classes = useStyles();

  const { open, openDialog, closeDialog } = useDialogState();
  return (
    <>
      <div className={classes.container}>
        <SummaryCard>
          <div className={classes.title}>
            <FormattedMessage id="costingModel.title" />
          </div>
          <Grid container>
            <Grid item xs={3}>
              <div className={classes.info}>
                <FormattedMessage id="costingModel.info" />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.button}>
            <Button component="button" color="primary" onClick={openDialog}>
              <FormattedMessage id="costingModel.button" />
            </Button>
          </Grid>
        </SummaryCard>
      </div>
      <CalculationInfoDialog open={open} closeDialog={closeDialog} />
    </>
  );
};

export default CostingModel;
