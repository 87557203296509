import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useIsBreakpointDown } from 'common/hooks';
import { useMessage } from 'App/MessageProvider';
import { Button } from '../Button';
import { Label } from '../Label';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: '0 0 20px 0'
  }
}));

export interface DiscardDataDialogProps {
  open: boolean;
  closeDialog: () => void;
  onDiscardClick: () => Promise<void>;
  variant?: 'upload' | 'edit';
}

export const DiscardDataDialog: React.FC<DiscardDataDialogProps> = ({
  open,
  closeDialog,
  onDiscardClick,
  variant = 'upload'
}: DiscardDataDialogProps) => {
  const classes = useStyles();
  const uploadMessage = useMessage('deleteDataDialog.upload') ?? '';
  const editMessage = useMessage('deleteDataDialog.edit') ?? '';
  const dialogMessage = variant === 'upload' ? uploadMessage : editMessage;
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Label variant="h6" labelId="deleteDataDialog.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Label
          variant="body1"
          className={classes.info}
          labelId="deleteDataDialog.info"
          values={{ processVariant: dialogMessage }}
        />
        <Label
          variant="body1"
          className={classes.info}
          labelId="deleteDataDialog.info2"
        />
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            <FormattedMessage id="deleteDataDialog.cancel" />
          </Button>
          <Button onClick={onDiscardClick} color="secondary">
            <FormattedMessage id="deleteDataDialog.delete" />
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DiscardDataDialog;
