import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteBase } from 'App/EmbeddedAppContext';
import { useIntl } from 'App/MessageProvider';
import PageHeader from 'common/components/PageHeader';
import { useHasCpoEditPermission } from 'common/hooks';
import { AddPlanButton } from 'plans/addPlan';
import { SimpleSearchBox } from 'common/components';
import { useSearchSettings } from 'common/hooks/useSearchSettings';
import {
  SearchCriteriaContextProvider,
  usePageTitleContext
} from 'common/context';
import PlansTabs from 'plans/PlansTabs';
import { usePlanTableSort } from './hooks';
import { PlansListTable } from './PlansListTable';

const PlansListPage: React.FC = () => {
  const intl = useIntl();
  const routeBase = useRouteBase();
  const history = useHistory();
  const navigateToPlanPage = (planId: string): void => {
    history.push(`${routeBase}/plans/${planId}`);
  };
  const PLANS_TABLE_KEY = 'cpo-plan-table';
  const hasSmartBudgetEditPermission = useHasCpoEditPermission();
  const {
    periodSortDirection,
    loadingTableSetting,
    togglePeriodSort
  } = usePlanTableSort();

  const { searchSettings } = useSearchSettings({
    key: PLANS_TABLE_KEY
  });

  usePageTitleContext([{ messageId: 'planListPage.list' }]);

  return (
    <SearchCriteriaContextProvider searchSettings={searchSettings}>
      <div>
        <PageHeader title={intl.formatMessage({ id: 'appName' })}>
          <SimpleSearchBox tableKey={PLANS_TABLE_KEY} />
        </PageHeader>
        <PlansTabs />
        <PlansListTable
          loadingTableSetting={loadingTableSetting}
          periodSortDirection={periodSortDirection}
          togglePeriodSort={togglePeriodSort}
          navigateToPlanPage={navigateToPlanPage}
        />
        {hasSmartBudgetEditPermission && <AddPlanButton />}
      </div>
    </SearchCriteriaContextProvider>
  );
};

export default PlansListPage;
