import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import { Label } from '../Label';
import { DateRange } from '../DateRange';
import { DateTime } from '../DateTime';

export interface FileUploadedDateProps {
  classes?: ClassNameMap;
  className?: string;
  filename: string;
  uploadDate?: string;
  effectiveDate?: string;
  endDate?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingBottom: theme.spacing(1.25)
  },
  fileNameContainer: {
    display: 'flex'
  },
  fileName: {
    ...theme.typography.body2,
    fontWeight: 'bold',
    fontSize: 16,
    color: 'black',
    paddingRight: theme.spacing(1)
  },
  uploaded: {
    ...theme.typography.body1,
    fontSize: theme.spacing(1.5),
    marginTop: theme.spacing(0.25),
    color: theme.palette.grey[700],
    paddingRight: theme.spacing(0.5)
  },
  dates: {
    display: 'flex',
    alignItems: 'center'
  },
  active: {
    display: 'flex',
    margin: theme.spacing(3, 0, 2, 0),
    paddingLeft: theme.spacing(6.5)
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  },
  hyphen: {
    color: theme.palette.grey[700],
    padding: theme.spacing(0, 0.5, 0, 0)
  },
  divider: {
    color: theme.palette.grey[700],
    padding: theme.spacing(0, 0.5, 0, 0)
  }
}));

const useDateStyles = makeStyles(theme => ({
  date: {
    ...theme.typography.body1,
    fontSize: theme.spacing(1.5),
    marginTop: theme.spacing(0.25),
    color: theme.palette.grey[700],
    paddingRight: theme.spacing(0.5)
  }
}));

export const FileUploadedDate: React.FC<FileUploadedDateProps> = ({
  classes,
  className,
  filename,
  uploadDate,
  effectiveDate,
  endDate
}: FileUploadedDateProps) => {
  const cls = useStyles({ classes });
  const dateClasses = useDateStyles();
  return (
    <>
      {effectiveDate ? (
        <Grid container className={cls.active}>
          <Grid item>
            <div className={cls.fileNameContainer}>
              <Label className={cls.fileName}>{filename}</Label>
            </div>
            <div className={cls.dates}>
              <Label
                className={classnames(cls.uploaded, cls.bold)}
                labelId="activePage.effective"
              />
              <DateRange
                classes={dateClasses}
                startDate={effectiveDate}
                endDate={endDate}
              />
              <Label className={cls.divider}>/</Label>
              <Label
                className={classnames(cls.uploaded, cls.bold)}
                labelId="activePage.uploaded"
              />
              {uploadDate ? (
                <DateTime className={cls.uploaded} dateTime={uploadDate} />
              ) : (
                <>&mdash;</>
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        <div className={classnames(cls.root, className)}>
          <Label className={cls.fileName}>{filename}</Label>
          -&nbsp;
          <Label className={cls.uploaded} labelId="billingPage.uploaded" />
          {uploadDate ? (
            <DateTime className={cls.uploaded} dateTime={uploadDate} />
          ) : (
            <>&mdash;</>
          )}
        </div>
      )}
    </>
  );
};
