import { useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Maybe, SmartBudgetResult } from 'generated-types';
import { useCommitSmartBudget } from './useCommitSmartBudget';
import { useSaveDraft } from './useSaveDraft';

export type UseSaveAndCommitOptions = {
  beforeSave?: () => void;
  afterSave?: () => void;
  beforeCommit?: () => void;
  afterCommit?: () => void;
};

export type UseSaveAndCommitResult = {
  onSaveClick: () => Promise<void>;
  loading: boolean;
  progressPercentage?: Maybe<BigNumber>;

  commitFailure: boolean;
  commitSuccess: boolean;
};

export const useSaveAndCommit = (
  plan: Maybe<SmartBudgetResult> | undefined,
  options: UseSaveAndCommitOptions
): UseSaveAndCommitResult => {
  const { canSave, saveDraft, saving } = useSaveDraft();
  const { beforeSave, afterSave, beforeCommit, afterCommit } = options;

  const [commitFailure, setCommitFailure] = useState(false);
  const [commitSuccess, setCommitSuccess] = useState(false);

  const {
    committing,
    progressPercentage,
    commitSmartBudget
  } = useCommitSmartBudget({
    id: plan?.id ?? '',
    onFailure: useCallback(() => {
      setCommitFailure(true);
      afterCommit?.();
    }, [afterCommit]),
    onSuccess: useCallback(() => {
      setCommitSuccess(true);
      afterCommit?.();
    }, [afterCommit])
  });

  const onSaveClick = useCallback(async () => {
    beforeSave?.();

    if (canSave) {
      await saveDraft();
    }

    afterSave?.();
    beforeCommit?.();

    await commitSmartBudget();
  }, [
    beforeSave,
    canSave,
    afterSave,
    beforeCommit,
    saveDraft,
    commitSmartBudget
  ]);

  return {
    onSaveClick,
    progressPercentage,
    commitFailure,
    commitSuccess,
    loading: Boolean(saving || committing)
  };
};
