import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(theme => ({
  root: {
    top: 0,
    left: 0,
    position: 'sticky',
    zIndex: 30,
    background: theme.palette.background.default
  },
  content: {},
  divider: {
    margin: theme.spacing(1, 0)
  }
}));

type DrawerHeaderProps = { classes?: ClassNameMap; children?: React.ReactNode };

export const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  classes,
  children
}: DrawerHeaderProps) => {
  const cls = useStyles({ classes });
  return (
    <div className={cls.root}>
      {children}
      <Divider className={cls.divider} />
    </div>
  );
};
