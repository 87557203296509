import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CpoRouteProps } from 'common/routes/Routes';
import ImportPage from './ImportPage';

export const importPageRoute = (prefix = ''): CpoRouteProps => ({
  path: `${prefix}/import`,
  component: ImportPage,
  exact: true,
  title: <FormattedMessage id="tabs.import" />
});
