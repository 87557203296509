import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Label, ProgressButton } from 'common/components';
import { TableColumnInstance } from 'common/types';
import { GroupColumn } from './GroupColumn';
import { useColumnSelectionActions } from './useColumnSelectionActions';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    padding: theme.spacing(2, 3)
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  cancelBtn: {
    color: theme.palette.grey[600],
    margin: theme.spacing(0, 1)
  },
  actionsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 2)
  },
  btnContainer: {
    display: 'flex'
  }
}));

interface ColumnSelectionDialogProps<T> {
  open: boolean;
  closeDialog: () => void;
  columns: TableColumnInstance<T>[];
  enabledColumns: Set<string>;
  defaultColumns: string[];
  tableKey: string;
}

const getColumnWeight = (id: string): 2 | 3 | 4 => {
  switch (id) {
    case 'loadFactor':
      return 4;
    case 'result':
      return 2;
    default:
      return 3;
  }
};

export const ColumnSelectionDialog: <T>(
  p: ColumnSelectionDialogProps<T>
) => React.ReactElement = ({
  open,
  closeDialog,
  columns,
  enabledColumns,
  defaultColumns,
  tableKey
}) => {
  const classes = useStyles();
  const [enabledFields, setEnabledFields] = useState<Set<string>>(
    enabledColumns
  );

  const {
    saving,
    pushTableSettings,
    resetToDefault,
    onChange
  } = useColumnSelectionActions({
    tableKey,
    columns: columns as TableColumnInstance[],
    defaultColumns,
    enabledFields,
    setEnabledFields,
    closeDialog
  });

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      maxWidth="md"
      disableBackdropClick>
      <DialogTitle className={classes.titleContainer} disableTypography>
        <Label
          variant="h6"
          labelId="planPage.billableResourcesCard.columnSelectionDialog.title"
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          {columns.map(column => (
            <Grid key={column.i18nKey} item xs={getColumnWeight(column.id)}>
              <GroupColumn
                column={column}
                enabledColumns={enabledFields}
                onChange={onChange}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button
          onClick={resetToDefault}
          color="default"
          disabled={saving}
          labelId="button.resetToDefaults"
        />
        <div className={classes.btnContainer}>
          <Button
            className={classes.cancelBtn}
            onClick={closeDialog}
            color="secondary"
            disabled={saving}
            labelId="button.cancel"
          />
          <ProgressButton
            onClick={pushTableSettings}
            loading={saving}
            color="primary"
            variant="text"
            labelId="button.save"
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};
