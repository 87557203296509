import React, { useCallback } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import { makeStyles, Direction } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { FieldValues } from 'react-hook-form';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useHistory } from 'react-router-dom';
import {
  RoleDataTableRow,
  RoleDataTableTotals,
  RoleDataTableHeader
} from 'rateCard/components';
import {
  BillingByRoleData,
  TotalCostBreakdown,
  ColumnOnChange
} from 'common/types';
import { useNavigationBlockContext } from 'common/context';
import { useDirection } from 'App/MuiThemeProvider/useDirection';
import { usePaginationLabelFn } from 'common/hooks';
import RoleDataSaveDialog from './RoleDataSaveDialog';
import useRoleTableNavigationBlock from './useRoleTableNavigationBlock';
import RoleTableColumns from './RoleTableColumns';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    width: '98%',
    minWidth: theme.spacing(170),
    tableLayout: 'fixed'
  }
}));

const useCellStyle = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(2)
    },
    '&:last-child': {
      paddingRight: theme.spacing(3)
    },
    '&:first-child': {
      paddingLeft: theme.spacing(4.5)
    },
    '&:nth-child(2)': {
      [theme.breakpoints.down('lg')]: {
        paddingRight: (props: { direction: Direction }): number =>
          props.direction === 'ltr' ? theme.spacing(4) : theme.spacing(1)
      },
      paddingRight: (props: { direction: Direction }): number =>
        props.direction === 'ltr' ? theme.spacing(3.5) : theme.spacing(1)
    },
    '&:nth-child(3)': {
      [theme.breakpoints.down('lg')]: {
        paddingRight: (props: { direction: Direction }): number =>
          props.direction === 'rtl' ? theme.spacing(4) : theme.spacing(1)
      },
      paddingRight: (props: { direction: Direction }): number =>
        props.direction === 'rtl' ? theme.spacing(3.5) : theme.spacing(1)
    },
    '&:nth-child(7)': {
      paddingRight: (props: { direction: Direction }): number =>
        props.direction === 'ltr' ? theme.spacing(3.5) : theme.spacing(1)
    },
    '&:nth-child(8)': {
      paddingRight: (props: { direction: Direction }): number =>
        props.direction === 'rtl' ? theme.spacing(3.5) : theme.spacing(1)
    }
  }
}));

export type RoleDataTableProps = {
  editing: boolean;
  canNavigateToRolePage?: boolean;
  dirty?: boolean;
  billingData: BillingByRoleData[];
  totalCostBreakdown: Maybe<TotalCostBreakdown>;
  totalRevenue: BigNumber;
  utilization: BigNumber;
  margin: BigNumber;
  saveRates?: () => Promise<void> | void;
  onUtilizationChangeHandler?: ColumnOnChange;
  onMarginChangeHandler?: ColumnOnChange;
  onBillingRateChangeHandler?: ColumnOnChange;
  onRevenueChangeHandler?: ColumnOnChange;
  getValues?: () => FieldValues;
};

export const RoleDataTable: React.FC<RoleDataTableProps> = ({
  editing,
  canNavigateToRolePage = true,
  dirty = false,
  billingData,
  totalCostBreakdown,
  totalRevenue,
  utilization,
  margin,
  saveRates,
  onUtilizationChangeHandler,
  onMarginChangeHandler,
  onBillingRateChangeHandler,
  onRevenueChangeHandler,
  getValues
}: RoleDataTableProps) => {
  const direction = useDirection();
  const classes = useStyles();
  const cellClasses = useCellStyle({ direction });
  const history = useHistory();
  const rowsPerPage = 10;
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };
  const navigationBlock = useNavigationBlockContext();

  const {
    saveDialogOpen,
    openSaveDialog,
    onClose,
    onSaveClick,
    onDiscardClick
  } = useRoleTableNavigationBlock({ saveRates });

  const navigateToRolePage = useCallback(
    (roleBreakdownId: string) => {
      const navigate = (): void => {
        if (canNavigateToRolePage) {
          navigationBlock?.unblockNavigation();
          history.push(`${history.location.pathname}/role/${roleBreakdownId}`);
        }
      };

      if (dirty && openSaveDialog) {
        openSaveDialog(navigate);
      } else {
        navigate();
      }
    },
    [canNavigateToRolePage, dirty, history, navigationBlock, openSaveDialog]
  );
  const paginationLabelFn = usePaginationLabelFn();
  return (
    <div>
      {billingData && (
        <>
          <TableContainer>
            <Table className={classes.root}>
              <RoleTableColumns />

              <RoleDataTableHeader cellClasses={cellClasses} />
              <RoleDataTableTotals
                cellClasses={cellClasses}
                totalCostBreakdown={totalCostBreakdown}
                totalRevenue={totalRevenue}
                margin={margin}
                utilization={utilization}
              />
              <TableBody>
                {billingData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(roleBreakdown => {
                    return (
                      <RoleDataTableRow
                        cellClasses={cellClasses}
                        key={roleBreakdown?.id}
                        roleBreakdown={roleBreakdown}
                        editMode={editing}
                        canNavigateToRolePage={canNavigateToRolePage}
                        onUtilizationChangeHandler={onUtilizationChangeHandler}
                        onMarginChangeHandler={onMarginChangeHandler}
                        onBillingRateChangeHandler={onBillingRateChangeHandler}
                        onRevenueChangeHandler={onRevenueChangeHandler}
                        onNavigationHandler={navigateToRolePage}
                        getValues={getValues}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            labelDisplayedRows={paginationLabelFn}
            count={billingData?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
          />

          <RoleDataSaveDialog
            open={saveDialogOpen}
            onClose={onClose}
            onSaveClick={onSaveClick}
            onDiscardClick={onDiscardClick}
          />
        </>
      )}
    </div>
  );
};
