import React, { createContext, useContext, useMemo } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useSessionStorage } from 'react-use';
import { useFeatureFlag } from 'common/hooks';

type TabNavigationContextValue = {
  tabValue: string;
  setTabValue: (value: string) => void;
};

const TabNavigationContext = createContext<Maybe<TabNavigationContextValue>>(
  null
);

export const TabNavigationContextProvider: React.FC = ({ children }) => {
  const dashboard = useFeatureFlag('cpoDashboard');
  const [tabValue, setTabValue] = useSessionStorage(
    'smartBudget-views',
    dashboard ? 'overview' : 'plans'
  );
  const value = useMemo(() => ({ tabValue, setTabValue }), [
    tabValue,
    setTabValue
  ]);

  return (
    <TabNavigationContext.Provider value={value}>
      {children}
    </TabNavigationContext.Provider>
  );
};

export const useTabNavigationContext = (): TabNavigationContextValue => {
  const contextValue = useContext(TabNavigationContext);

  if (!contextValue) {
    throw new Error(
      'useTabNavigationContext must be called within a TabNavigationContextProvider.'
    );
  }
  return contextValue;
};
