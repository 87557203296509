import { useCallback, useMemo, useReducer, useState } from 'react';
import BigNumber from 'bignumber.js';
import { SmartBudgetResult } from 'generated-types';

const ROUNDING_DECIMAL_PLACES = 2;

export type PlanFormStateEntryValue = {
  value: BigNumber;
  rounded: BigNumber;
};

export type PlanFormStateEntry<T = BigNumber> = {
  margin: T;
  utilization: T;
  billingRate: T;
  revenue: T;
  businessOverhead: T;
};

export type PlanFormState = {
  [roleBreakdownId: string]: PlanFormStateEntry<PlanFormStateEntryValue>;
};

export type PlanFormRawState<T = BigNumber> = {
  [roleBreakdownId: string]: PlanFormStateEntry<T>;
};

export enum PlanTableFormActionType {
  ReplaceEntry = 'ReplaceEntry',
  ReplaceState = 'ReplaceState'
}

type PlanTableFormAction<
  TPlanTableActionType extends PlanTableFormActionType,
  TPayload
> = {
  type: TPlanTableActionType;
  payload: TPayload;
};

type ReplaceEntryAction = PlanTableFormAction<
  PlanTableFormActionType.ReplaceEntry,
  {
    roleBreakdownId: string;
    entry: PlanFormStateEntry;
  }
>;

type ReplaceStateAction = PlanTableFormAction<
  PlanTableFormActionType.ReplaceState,
  {
    newState: PlanFormRawState<BigNumber | string>;
  }
>;

type FormReducerActions = ReplaceEntryAction | ReplaceStateAction;

const transformToPlanFormStateEntryValue = (
  value: BigNumber
): PlanFormStateEntryValue => ({
  rounded: value.decimalPlaces(ROUNDING_DECIMAL_PLACES),
  value
});

const transformRawStateEntryToStateEntry = (
  entry: PlanFormStateEntry<BigNumber | string>
): PlanFormStateEntry<PlanFormStateEntryValue> => {
  const newEntry = Object.keys(entry).reduce((obj, key) => {
    const k = key as keyof PlanFormStateEntry;
    const entryValue = entry[k];
    const castValue = BigNumber.isBigNumber(entryValue)
      ? entryValue
      : new BigNumber(entryValue);

    obj[k] = transformToPlanFormStateEntryValue(castValue);
    return obj;
  }, {} as PlanFormStateEntry<PlanFormStateEntryValue>);

  return newEntry;
};

const formReducer = (
  state: PlanFormState,
  action: FormReducerActions
): PlanFormState => {
  switch (action.type) {
    case PlanTableFormActionType.ReplaceEntry: {
      const { entry, roleBreakdownId } = action.payload;

      const newEntry = transformRawStateEntryToStateEntry(entry);
      const newState = {
        ...state,
        [roleBreakdownId]: newEntry
      };
      return newState;
    }
    case PlanTableFormActionType.ReplaceState: {
      const { newState } = action.payload;
      const allStates = Object.keys(newState).reduce((obj, roleBreakdownId) => {
        const entry = newState[roleBreakdownId];
        obj[roleBreakdownId] = transformRawStateEntryToStateEntry(entry);
        return obj;
      }, {} as PlanFormState);
      return allStates;
    }
    default:
      throw new Error('Unhandled action type.');
  }
};

const req = <T>(v: T | undefined | null): T => {
  if (v === undefined || v === null) {
    throw new Error('Expected value to be defined.');
  }

  return v;
};

const getInitialState = (input: {
  plan: SmartBudgetResult | null | undefined;
  businessCostHourly: BigNumber;
}): PlanFormState => {
  const { businessCostHourly, plan } = input;
  return (plan?.roleCostBreakdown ?? []).reduce((obj, item) => {
    if (!item) {
      return obj;
    }

    obj[item.id] = {
      billingRate: transformToPlanFormStateEntryValue(
        req(item.billingCost?.amount)
      ),
      revenue: transformToPlanFormStateEntryValue(req(item.revenue?.amount)),
      margin: transformToPlanFormStateEntryValue(req(item.margin)),
      utilization: transformToPlanFormStateEntryValue(req(item.utilization)),
      businessOverhead: transformToPlanFormStateEntryValue(businessCostHourly)
    };

    return obj;
  }, {} as PlanFormState);
};

type UsePlanTableFormReducerResult = {
  formState: PlanFormState;
  dirty: boolean;
  setValue: (
    roleBreakdownId: string,
    values: PlanFormStateEntry<string>
  ) => void;
  setValues: (state: PlanFormRawState<string>) => void;
  getValues: () => PlanFormRawState<string>;
  getAccurateValues: () => PlanFormRawState<string>;
  clearDirty: () => void;
};

export const usePlanTableFormReducer = (
  plan: SmartBudgetResult | null | undefined,
  businessCostHourly: BigNumber
): UsePlanTableFormReducerResult => {
  const [formState, dispatch] = useReducer(
    formReducer,
    { plan, businessCostHourly },
    getInitialState
  );
  const [dirty, setDirty] = useState(false);
  const clearDirty = useCallback(() => setDirty(false), []);

  // Currently returning the same signature as `usePlanTableForm` so that
  // this can be dropped in as a replacement.

  const setValue = useCallback(
    (roleBreakdownId: string, values: PlanFormStateEntry<string>) => {
      setDirty(true);
      dispatch({
        type: PlanTableFormActionType.ReplaceEntry,
        payload: {
          roleBreakdownId,
          entry: {
            billingRate: new BigNumber(values.billingRate),
            utilization: new BigNumber(values.utilization),
            revenue: new BigNumber(values.revenue),
            margin: new BigNumber(values.margin),
            businessOverhead: new BigNumber(values.businessOverhead)
          }
        }
      });
    },
    []
  );

  const setValues = useCallback((newState: PlanFormRawState<string>) => {
    setDirty(true);
    dispatch({
      type: PlanTableFormActionType.ReplaceState,
      payload: {
        newState: newState
      }
    });
  }, []);

  const values = useMemo(() => {
    const getValue = (
      key: keyof PlanFormStateEntry,
      entry: PlanFormStateEntry<PlanFormStateEntryValue>
    ): string => entry[key].rounded.toString();

    return Object.keys(formState).reduce((obj, k) => {
      const f = formState[k];
      const n: PlanFormStateEntry<string> = {
        billingRate: getValue('billingRate', f),
        margin: getValue('margin', f),
        revenue: getValue('revenue', f),
        utilization: getValue('utilization', f),
        businessOverhead: getValue('businessOverhead', f)
      };

      obj[k] = n;
      return obj;
    }, {} as PlanFormRawState<string>);
  }, [formState]);

  const accurateValues = useMemo(() => {
    const getValue = (
      key: keyof PlanFormStateEntry,
      entry: PlanFormStateEntry<PlanFormStateEntryValue>
    ): string => entry[key].value.toString();

    return Object.keys(formState).reduce((obj, k) => {
      const f = formState[k];
      const n: PlanFormStateEntry<string> = {
        billingRate: getValue('billingRate', f),
        margin: getValue('margin', f),
        revenue: getValue('revenue', f),
        utilization: getValue('utilization', f),
        businessOverhead: getValue('businessOverhead', f)
      };

      obj[k] = n;
      return obj;
    }, {} as PlanFormRawState<string>);
  }, [formState]);

  return {
    formState,
    dirty,
    clearDirty,
    setValue,
    setValues,
    getValues: useCallback(() => values, [values]),
    getAccurateValues: useCallback(() => accurateValues, [accurateValues])
  };
};
