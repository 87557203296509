import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  count: {
    ...theme.typography.body2,
    color: theme.palette.grey[600],
    whiteSpace: 'nowrap',
    display: 'flex',
    width: 'inherit',
    fontSize: 11,
    justifyContent: 'flex-end'
  },
  info: {
    padding: theme.spacing(0, 1)
  }
}));

export interface RoleListItemProps {
  messageId: string;
  children: React.ReactNode;
  className?: string;
}

const RoleCostItem: React.FC<RoleListItemProps> = ({
  messageId,
  children
}: RoleListItemProps) => {
  const classes = useStyles();

  return (
    <div className={classes.info}>
      <div className={classes.title}>
        <FormattedMessage id={messageId} />
      </div>
      <div className={classes.count}>{children}</div>
    </div>
  );
};

export default RoleCostItem;
