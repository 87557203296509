import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { Label, MoneyValue, NumberValue } from 'common/components';
import { RoleRateTableEntry } from './RoleRateDialog';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },
  table: {
    width: '100%',
    paddingBottom: theme.spacing(2),
    fontSize: 12
  },
  firstTitle: {
    textAlign: 'left',
    width: '28%'
  },
  total: {
    textAlign: 'left',
    width: '28%',
    fontSize: 12
  },
  roleData: {
    width: 155,
    display: 'block',
    paddingBottom: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  userData: {
    textAlign: 'end',
    paddingBottom: theme.spacing(1),
    width: '15%'
  },
  hourData: {
    textAlign: 'end',
    paddingBottom: theme.spacing(1),
    width: '23%'
  },
  align: {
    textAlign: 'end'
  },
  lastColumn: {
    paddingBottom: theme.spacing(1)
  },
  tableRow: {
    marginBottom: theme.spacing(2)
  }
}));

export interface RoleRateTableProps {
  roleBreakdowns: RoleRateTableEntry[] | undefined;
  billableUserCount?: number | null;
  nonBillableUserCount?: number | null;
  totalNonBillableHours?: number | null | undefined;
  totalBillableHours?: number | null | undefined;
  totalUserCount?: number | null | undefined;
  billableCost?: number | null;
  nonBillableCost?: number | null;
  billable: boolean;
  currency: string | null | undefined;
}

export const RoleRateTable: React.FC<RoleRateTableProps> = ({
  roleBreakdowns,
  billableUserCount,
  nonBillableUserCount,
  totalBillableHours,
  totalNonBillableHours,
  billableCost,
  nonBillableCost,
  billable,
  currency
}: RoleRateTableProps) => {
  const classes = useStyles();
  return (
    <table className={classes.table}>
      <thead>
        <tr className={classes.tableRow}>
          <th className={classes.firstTitle}>
            <Label
              variant="body1"
              className={classes.bold}
              labelId="roleRateDialog.role"
            />
          </th>
          <th>
            <Label
              variant="body1"
              className={classnames(classes.bold, classes.align)}
              labelId="roleRateDialog.users"
            />
          </th>
          <th>
            <Label
              variant="body1"
              className={classnames(classes.bold, classes.align)}
              labelId="roleRateDialog.hours"
            />
          </th>
          <th>
            <Label
              variant="body1"
              className={classnames(
                classes.bold,
                classes.align,
                classes.lastColumn
              )}
              labelId="roleRateDialog.cost">
              &nbsp;({currency})
            </Label>
          </th>
        </tr>
      </thead>
      <tbody>
        {roleBreakdowns?.map(r => {
          return (
            <tr key={r.name ?? ''} className={classes.tableRow}>
              <Tooltip title={r?.name ?? ''}>
                <td className={classes.roleData}>{r?.name}</td>
              </Tooltip>
              <td className={classes.userData}>
                <NumberValue value={r?.userCount ?? 0} />
              </td>
              <td className={classes.hourData}>
                <NumberValue value={r.hours ?? 0} />
              </td>
              <td className={classnames(classes.align, classes.lastColumn)}>
                <MoneyValue
                  value={r?.totalCost ?? 0}
                  currencySymbol={currency}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td className={classes.total}>
            <Label
              variant="body2"
              className={classes.bold}
              labelId="roleRateDialog.total"
            />
          </td>
          <td className={classnames(classes.bold, classes.align)}>
            <NumberValue
              value={
                billable ? billableUserCount ?? 0 : nonBillableUserCount ?? 0
              }
            />
          </td>
          <td className={classnames(classes.bold, classes.align)}>
            <NumberValue
              value={
                billable ? totalBillableHours ?? 0 : totalNonBillableHours ?? 0
              }
            />
          </td>
          <td
            className={classnames(
              classes.bold,
              classes.align,
              classes.lastColumn
            )}>
            <MoneyValue
              value={billable ? billableCost ?? 0 : nonBillableCost ?? 0}
              currencySymbol={currency}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default RoleRateTable;
