import BigNumber from 'bignumber.js';

export type RateCardModelState = Record<string, BigNumber>;
export type OnColumnChangeCallback = (newValue: BigNumber) => void;
export type RateCardModelOptions<T> = {
  lockedColumns?: (keyof T)[];
};
export type ColumnCalculationFunction = (
  changedColumn: RateCardColumn,
  state: RateCardModelState,
  options?: RateCardModelOptions<RateCardModelState>
) => BigNumber | undefined;

export class RateCardColumn {
  constructor(
    public name: string,
    public dependencies: string[] = [],
    private calculationFunction?: ColumnCalculationFunction
  ) {}

  update(
    changedColumn: RateCardColumn,
    state: RateCardModelState,
    options?: RateCardModelOptions<RateCardModelState>
  ): BigNumber | undefined {
    return this.calculationFunction?.(changedColumn, state, options);
  }
}

/**
 * A mathematical model of a rate card. Columns are calculated based on the
 * values of other columns, and can be re-calculated based on changes to the
 * "set" value of a given column.
 */
class RateCardModel {
  private columns: Record<string, RateCardColumn>;
  private onChangeCallbacks: Record<string, OnColumnChangeCallback[]>;

  constructor(rateCardModel?: RateCardModel) {
    this.columns = rateCardModel?.columns ?? {};
    this.onChangeCallbacks = {};
  }

  onColumnChange(columnName: string, callback: OnColumnChangeCallback): void {
    if (!this.onChangeCallbacks[columnName]) {
      this.onChangeCallbacks[columnName] = [];
    }

    this.onChangeCallbacks[columnName].push(callback);
  }

  registerColumn(column: RateCardColumn): void {
    this.columns[column.name] = column;
  }

  setColumnValue<T extends RateCardModelState>(
    state: T,
    columnName: string,
    value: BigNumber,
    options?: RateCardModelOptions<T>
  ): T {
    const column = this.columns[columnName];
    const updateColumns = this.getColumnsToUpdate(column).filter(
      c =>
        !options?.lockedColumns?.length ||
        !options.lockedColumns.includes(c.name)
    );

    const newState: RateCardModelState = {
      ...state,
      [column.name]: value
    };

    updateColumns.forEach(c => {
      newState[c.name] =
        c.update(
          column,
          newState,
          options as RateCardModelOptions<RateCardModelState>
        ) ?? newState[c.name];
    });

    updateColumns.forEach(c => this.notifyColumnChange(c, newState[c.name]));

    return newState as T;
  }

  getColumns(): RateCardColumn[] {
    return Object.values(this.columns);
  }

  private notifyColumnChange(
    column: RateCardColumn,
    newValue: BigNumber
  ): void {
    const callbacks = this.onChangeCallbacks[column.name];
    if (Array.isArray(callbacks) && callbacks.length > 0) {
      callbacks.forEach(cb => cb(newValue));
    }
  }

  /**
   * Breadth-first search through the dependency graph to determine which
   * attributes need to be updated, and discard any cycles.
   */
  private getColumnsToUpdate(
    changedColumn: RateCardColumn,
    visited: Set<string> = new Set()
  ): RateCardColumn[] {
    visited.add(changedColumn.name);
    const dependantNodes = Object.values(this.columns).filter(
      d => d.dependencies.includes(changedColumn.name) && !visited.has(d.name)
    );

    const graph = [...dependantNodes];
    graph.forEach(d => visited.add(d.name));
    for (const node of dependantNodes) {
      const children = this.getColumnsToUpdate(node, visited);
      graph.push(...children);
    }

    return graph;
  }
}

export default RateCardModel;
