import { ApolloError } from '@apollo/client';
import { DateObject } from 'luxon';
import {
  Maybe,
  SmartBudgetOverviewSummaryResult,
  useSmartBudgetOverviewSummaryQuery
} from 'generated-types';

export const useOverviewSummary = (
  startDate: DateObject,
  endDate: DateObject
): {
  overviewSummary?: Maybe<SmartBudgetOverviewSummaryResult>;
  loading: boolean;
  error?: Maybe<ApolloError>;
} => {
  const { loading, data, error } = useSmartBudgetOverviewSummaryQuery({
    variables: { startDate, endDate },
    fetchPolicy: 'no-cache'
  });

  return {
    loading,
    overviewSummary: data?.smartBudgetOverviewSummary,
    error: error
  };
};
