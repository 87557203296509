import React from 'react';
import { makeStyles, CardContent } from '@material-ui/core';
import { DataCardFooter } from '../../DataCard';
import { BusinessCostRowSkeleton } from '.';

const DEFAULT_RECORDS_VISIBLE = 4;

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(1.5, 2, 1, 2),
    '&:last-child': {
      paddingBottom: 35
    }
  },
  businessCardFooter: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5, 2, 0.5, 2)
  }
}));

const BusinessCostCardSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <CardContent className={classes.content}>
        {[...Array(DEFAULT_RECORDS_VISIBLE + 1)].map((_, idx) => (
          <BusinessCostRowSkeleton key={idx} />
        ))}
      </CardContent>
      <DataCardFooter>
        <div className={classes.businessCardFooter}>
          <BusinessCostRowSkeleton />
        </div>
      </DataCardFooter>
    </>
  );
};

export default BusinessCostCardSkeleton;
