import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import BigNumber from 'bignumber.js';
import RateCardModel, {
  RateCardColumn,
  RateCardModelState,
  RateCardModelOptions
} from './RateCardModel';

const rbrc = new RoleBillingRateCalculator();
const marginCalculationFunction = (
  _: RateCardColumn,
  state: RateCardModelState
): BigNumber => {
  return rbrc.calculateMargin(
    state['revenue'],
    state['loadedCost'],
    state['totalHours']
  );
};

const utilizationCalculationFunction = (
  _: RateCardColumn,
  state: RateCardModelState,
  options: RateCardModelOptions<RateCardModelState> | undefined
): BigNumber | undefined => {
  if (
    options?.lockedColumns?.includes('margin') ||
    options?.lockedColumns?.includes('revenue')
  ) {
    // Utilization should only change as a last-resort if margin cannot.
    // billingRate = (loadedCost / utilization) * (1 / (1 - m))
    // utilization = loadedCost / (billingRate - billingRate * margin)
    const newUtilization = state['loadedCost'].dividedBy(
      state['billingRate'].minus(state['billingRate'].times(state['margin']))
    );

    return newUtilization;
  } else if (options?.lockedColumns?.includes('billingRate')) {
    // A change to revenue leads to a change in margin. Since billing rate
    // cannot be altered to account for this change, we need to increase or
    // decrease utilization accordingly.
    // revenue = billingRate * totalHours * utilization
    // utilization = revenue / (billingRate * totalHours)
    const newUtilization = state['revenue'].dividedBy(
      state['billingRate'].times(state['totalHours'])
    );

    return newUtilization;
  }
};

const billingRateCalculationFunction = (
  _: RateCardColumn,
  state: RateCardModelState
): BigNumber => {
  return rbrc.calculateBillingRateForRole(
    state['loadedCost'],
    state['utilization'],
    state['margin']
  );
};

const revenueCalculationFunction = (
  changedColumn: RateCardColumn,
  state: RateCardModelState
): BigNumber => {
  if (
    changedColumn.name === 'billingRate' ||
    changedColumn.name === 'utilization'
  ) {
    return rbrc.calculateRevenue(
      state['billingRate'],
      state['utilization'],
      state['totalHours']
    );
  }

  return state['totalHours']
    .times(state['loadedCost'])
    .dividedBy(new BigNumber(1).minus(state['margin']));
};

const defaultRateCardModel = new RateCardModel();

// Columns are specified in the order they should be updated on a change
// to a dependant column.
const columns = [
  new RateCardColumn('loadedCost'),
  new RateCardColumn('totalHours'),
  new RateCardColumn('margin', ['revenue'], marginCalculationFunction),
  new RateCardColumn(
    'billingRate',
    ['margin', 'utilization'],
    billingRateCalculationFunction
  ),
  new RateCardColumn(
    'revenue',
    ['margin', 'billingRate', 'utilization'],
    revenueCalculationFunction
  ),
  new RateCardColumn(
    'utilization',
    ['billingRate', 'margin', 'revenue'],
    utilizationCalculationFunction
  )
];

columns.map(defaultRateCardModel.registerColumn.bind(defaultRateCardModel));

export default defaultRateCardModel;
