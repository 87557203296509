import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Warning } from '../../types';
import { PlanWarning } from './PlanWarning';

const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

type PlanWarningsProps = {
  warnings: Warning[];
};

export const PlanWarnings: React.FC<PlanWarningsProps> = ({ warnings }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {warnings.map(warning => (
        <PlanWarning key={warning.code} warning={warning} />
      ))}
    </div>
  );
};
