import { useEffect, useState, useMemo } from 'react';
import { buildMessages } from '@replicon/term-replacement';
import { CustomTerm } from '@replicon/term-replacement/dist/src/types';
import enMessages from './messages/en';

const LANGUAGE_CODE_FILE_MAP: Record<string, string> = {
  'en-us': 'en'
};

const useLocaleMessages = (
  locale: string,
  customTerms: CustomTerm[]
): Record<string, string> | null => {
  const baseMessages = useMemo(
    () =>
      buildMessages(
        {
          en: enMessages
        },
        enMessages
      )(locale, customTerms),
    [customTerms, locale]
  );
  const [messages, setMessages] = useState(
    locale === 'en-US' ? baseMessages : null
  );

  useEffect(() => {
    const lcLocale = locale.toLowerCase();

    const updateLanguageMessages = async (): Promise<void> => {
      const languageFile = LANGUAGE_CODE_FILE_MAP[lcLocale] || lcLocale;
      let cpoLocale;
      try {
        cpoLocale = await import(`./messages/${languageFile}`);
        // eslint-disable-next-line no-empty
      } catch (e) {}

      setMessages(
        buildMessages(
          {
            [lcLocale]: cpoLocale?.default
          },
          enMessages
        )(lcLocale, customTerms)
      );
    };

    updateLanguageMessages();
  }, [customTerms, locale]);

  return messages;
};

export default useLocaleMessages;
