import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { ReactComponent as CommittingIcon } from './Committing.svg';

const useStyles = makeStyles(() => ({
  '@keyframes tile1Animation': {
    '20%': { fill: '#3a76c4' },
    '40%': { fill: '#fff' }
  },
  '@keyframes tile5Animation': {
    '40%': { fill: '#3a76c4' },
    '60%': { fill: '#fff' }
  },
  '@keyframes tile3Animation': {
    '60%': { fill: '#3a76c4' },
    '80%': { fill: '#fff' }
  },
  '@keyframes tile7Animation': {
    '80%': { fill: '#3a76c4' },
    '100%': { fill: '#fff' }
  },
  animation: {
    '& g:nth-child(3)': {
      animation: `$tile1Animation 5s steps(1, end) infinite`
    },

    '& g:nth-child(7)': {
      animation: `$tile5Animation 5s steps(1, end) infinite`
    },

    '& g:nth-child(5)': {
      animation: `$tile3Animation 5s steps(1, end) infinite`
    },

    '& g:nth-child(9)': {
      animation: `$tile7Animation 5s steps(1, end) infinite`
    }
  }
}));

type CommittingAnimationIconProps = {
  className?: string;
};

export const CommittingAnimationIcon: React.FC<CommittingAnimationIconProps> = ({
  className
}: CommittingAnimationIconProps) => {
  const classes = useStyles();
  return (
    <CommittingIcon className={classnames(classes.animation, className)} />
  );
};
