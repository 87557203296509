import React from 'react';
import { CellProps } from 'react-table';
import { BillingByRoleData } from 'common/types';
import {
  usePlanEditContext,
  useRoleTableFormContext
} from 'plans/plan/context';
import { TableFormNumberEditField } from 'rateCard';
import { useLockedColumnContext } from 'common/context';
import { useMessage } from 'App/MessageProvider';
import { MoneyValue, TooltipMoney } from 'common/components';
import { useIsCalculatingAttribute } from 'plans/plan/hooks';

export const BillingRateCell: React.FC<CellProps<BillingByRoleData>> = ({
  row,
  column: { id }
}: CellProps<BillingByRoleData>) => {
  const { getValues, onBillingRateChangeHandler } = useRoleTableFormContext();
  const { lockedColumn } = useLockedColumnContext();
  const label = useMessage(
    'planPage.billableResourcesCard.billingRateFieldLabel',
    {
      roleName: row.original.roleName
    }
  );
  const { canEdit } = usePlanEditContext();
  const isCalculateForColumn = useIsCalculatingAttribute('billingRate');

  const Component = isCalculateForColumn ? TooltipMoney : MoneyValue;

  if (!canEdit || isCalculateForColumn) {
    return (
      <Component
        value={getValues?.()[row.original.id].billingRate}
        currencySymbol={row.original.billingRate?.currency?.symbol ?? ''}
        placement="right"
      />
    );
  }

  return (
    <TableFormNumberEditField
      id={row.original.id}
      attribute="billingRate"
      ariaLabel={label}
      fullWidth
      value={getValues?.()[row.original.id].billingRate}
      onChanged={onBillingRateChangeHandler}
      prefix={row.original.billingRate?.currency?.symbol ?? ''}
      disabled={lockedColumn === id}
    />
  );
};
