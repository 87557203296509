import React, { useMemo, useState } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  Table,
  TableContainer,
  makeStyles,
  TableBody
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { TotalCostBreakdown } from 'common/types';
import { useRoleUserBreakdowns } from 'roleSummaryPage/hooks/useRoleUserBreakdowns';
import { RoleTableSkeleton, RoleTableColumns } from 'rateCard/components';
import { RoleBreakdown } from 'generated-types';
import { getFlatUserBreakdown } from '../helpers';
import { RoleSummaryTablePagination } from './components/RoleSummaryTablePagination';
import { RoleSummaryTableBody, RoleSummaryTableHeader } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0, 0, 0),
    width: '100%',
    minWidth: theme.spacing(170),
    tableLayout: 'fixed'
  }
}));

const useCellStyle = makeStyles(theme => ({
  root: {
    '&:last-child': {
      paddingRight: theme.spacing(3)
    },
    '&:first-child': {
      paddingLeft: theme.spacing(4.5)
    }
  }
}));

export type OnChangeHandler = (
  value: string,
  updateRefs?: boolean | undefined
) => void;

type RoleSummaryTableProps = {
  activeCostOptimizationId: string;
  role: Maybe<RoleBreakdown>;
  totalCostBreakdown: Maybe<TotalCostBreakdown>;
  margin: BigNumber;
  utilization: BigNumber;
  editing: boolean;
  roleUri: string;
  handleMarginChange: OnChangeHandler;
  handleUtilizationChange: OnChangeHandler;
};

export const RoleSummaryTable: React.FC<RoleSummaryTableProps> = ({
  activeCostOptimizationId,
  totalCostBreakdown,
  role,
  roleUri,
  margin,
  utilization,
  editing,
  handleMarginChange,
  handleUtilizationChange
}: RoleSummaryTableProps) => {
  const classes = useStyles();
  const cellClasses = useCellStyle();
  const [cursor, setCursor] = useState('');
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const { loading, costOptimizationResult } = useRoleUserBreakdowns(
    activeCostOptimizationId,
    roleUri,
    rowsPerPage,
    cursor
  );

  const userBreakdown = useMemo(() => {
    return getFlatUserBreakdown(
      costOptimizationResult?.roleCostBreakdown ?? []
    );
  }, [costOptimizationResult]);

  const { pageInfo, users } = userBreakdown;

  return (
    <>
      <TableContainer>
        <Table className={classes.root}>
          <RoleTableColumns />
          <RoleSummaryTableHeader
            totalCostBreakdown={totalCostBreakdown}
            role={role}
            cellClasses={cellClasses}
            margin={margin}
            utilization={utilization}
          />
          {!loading && (
            <TableBody>
              {users.map(user => (
                <RoleSummaryTableBody
                  handleMarginChange={handleMarginChange}
                  handleUtilizationChange={handleUtilizationChange}
                  role={role}
                  key={user.id}
                  userBreakdown={user}
                  cellClasses={cellClasses}
                  editing={editing}
                  roleUri={roleUri}
                  costOptimizationResultId={activeCostOptimizationId}
                />
              ))}
            </TableBody>
          )}
        </Table>
        {loading && <RoleTableSkeleton />}
      </TableContainer>
      {!loading && (
        <RoleSummaryTablePagination
          setCursor={setCursor}
          setPage={setPage}
          page={page}
          pageInfo={pageInfo}
          rowsPerPage={rowsPerPage}
        />
      )}
    </>
  );
};

export default RoleSummaryTable;
