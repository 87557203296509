import { useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useCommitSmartBudgetMutation } from 'generated-types';
import { useWorkDataPolling } from 'billing/hooks/useWorkDataPolling';

export type UseCommitSmartBudgetInput = {
  id: string;
  onSuccess?: () => void;
  onFailure?: () => void;
};

export type UseCommitSmartBudgetResult = {
  committing: boolean;
  commitSmartBudget: () => Promise<void>;
  progressPercentage?: BigNumber | null;
};

export const useCommitSmartBudget = ({
  id,
  onSuccess,
  onFailure
}: UseCommitSmartBudgetInput): UseCommitSmartBudgetResult => {
  const [committing, setCommitting] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState<
    BigNumber | null | undefined
  >(undefined);

  const [commitSmartBudgetMutation] = useCommitSmartBudgetMutation();

  const onCommitSuccess = useCallback(() => {
    setCommitting(false);
    onSuccess?.();
  }, [onSuccess]);

  const onCommitFailure = useCallback(() => {
    setCommitting(false);
    onFailure?.();
  }, [onFailure]);

  const onUpdateProgress = useCallback(
    (progressPercentage?: BigNumber | null) => {
      setProgressPercentage(progressPercentage);
    },
    []
  );

  const startPollingWork = useWorkDataPolling(
    onCommitSuccess,
    onCommitFailure,
    true,
    onUpdateProgress
  );

  const commitSmartBudget = useCallback(async () => {
    setCommitting(true);
    try {
      const { data, errors } = await commitSmartBudgetMutation({
        variables: {
          input: {
            smartBudgetId: id
          }
        }
      });

      if (errors) {
        throw new Error();
      }

      if (data?.commitSmartBudget?.backgroundWorkId) {
        startPollingWork(data.commitSmartBudget.backgroundWorkId);
      } else {
        setCommitting(false);
      }
    } catch (e) {
      setCommitting(false);
      onCommitFailure();
      return;
    }
  }, [commitSmartBudgetMutation, id, onCommitFailure, startPollingWork]);

  return {
    committing,
    progressPercentage,
    commitSmartBudget
  };
};
