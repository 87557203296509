import { BillableHeader } from './BillableHeader';
import { BillingRateCell } from './BillingRateCell';
import { FooterTitleCell } from './FooterTitleCell';
import { MarginCell } from './MarginCell';
import { MarginFooter } from './MarginFooter';
import { RevenueCell } from './RevenueCell';
import { RevenueFooter } from './RevenueFooter';
import { UtilizationCell } from './UtilizationCell';
import { UtilizationFooter } from './UtilizationFooter';
import { makeCalculatedTotalCell } from './CalculatedTotalCell';
import { CalculatedProfitCell } from './CalculatedProfitCell';
import { CalculatedProfitFooter } from './CalculatedProfitFooter';
import { CurrentUtilizationCell } from './CurrentUtilizationCell';
import { CurrentUtilizationFooter } from './CurrentUtilizationFooter';
import { MarginHeader } from './MarginHeader';
import { BillingRateHeader } from './BillingRateHeader';
import { RoleNameCell } from './RoleNameCell';

export const BILLABLE_TABLE_SETTINGS = 'cpo-plan-billable-table';

export const ColumnKeys: Record<string, string> = {
  ROLE_NAME: 'roleName',
  TOTAL_HOURS: 'totalHours',
  TOTAL_COST: 'totalCost',
  STANDARD_RATE: 'standardRate',
  NON_BILLABLE_OVERHEAD: 'nonBillableOverhead',
  NON_BILLABLE_OVERHEAD_DIRECT_COST: 'nonBillableOverheadDirectCost',
  BUSINESS_OVERHEAD: 'businessOverhead',
  BUSINESS_OVERHEAD_DIRECT_COST: 'businessOverheadDirectCost',
  LOADED_COST: 'loadedCost',
  UTILIZATION: 'utilization',
  BILLING_RATE: 'billingRate',
  CURRENT_BILLING_RATE: 'currentBillingRate',
  MARGIN: 'margin',
  REVENUE: 'revenue',
  PROFIT: 'profit',
  CURRENT_UTILIZATION: 'currentUtilization'
};

export const billableTableColumns = [
  {
    Header: BillableHeader,
    id: 'empty',
    i18nKey: 'planPage.billableResourcesCard.headers.empty',
    columns: [
      {
        accessor: ColumnKeys.ROLE_NAME,
        Cell: RoleNameCell,
        Footer: FooterTitleCell,
        i18nKey: 'planPage.billableResourcesCard.headers.roleName',
        required: true
      },
      {
        accessor: ColumnKeys.TOTAL_HOURS,
        i18nKey: 'planPage.billableResourcesCard.headers.totalHours'
      }
    ]
  },
  {
    Header: BillableHeader,
    id: 'loadFactor',
    i18nKey: 'planPage.billableResourcesCard.headers.loadFactor',
    columns: [
      {
        accessor: ColumnKeys.TOTAL_COST,
        i18nKey: 'planPage.billableResourcesCard.headers.totalCost'
      },
      {
        accessor: ColumnKeys.STANDARD_RATE,
        i18nKey: 'planPage.billableResourcesCard.headers.standardRate'
      },
      {
        accessor: ColumnKeys.NON_BILLABLE_OVERHEAD,
        i18nKey: 'planPage.billableResourcesCard.headers.nonBillableOverhead'
      },
      {
        id: 'nonBillableOverheadDirectCost',
        accessor: ColumnKeys.NON_BILLABLE_OVERHEAD_DIRECT_COST,
        i18nKey:
          'planPage.billableResourcesCard.headers.nonBillableOverheadDirectCost',
        Cell: makeCalculatedTotalCell('nonBillableOverhead')
      },
      {
        accessor: ColumnKeys.BUSINESS_OVERHEAD,
        i18nKey: 'planPage.billableResourcesCard.headers.businessOverhead'
      },
      {
        id: 'businessOverheadDirectCost',
        accessor: ColumnKeys.BUSINESS_OVERHEAD_DIRECT_COST,
        i18nKey:
          'planPage.billableResourcesCard.headers.businessOverheadDirectCost',
        Cell: makeCalculatedTotalCell('businessOverhead')
      },
      {
        accessor: ColumnKeys.LOADED_COST,
        i18nKey: 'planPage.billableResourcesCard.headers.loadedCost'
      }
    ]
  },
  {
    Header: BillableHeader,
    id: 'calculate',
    i18nKey: 'planPage.billableResourcesCard.headers.calculate',
    columns: [
      {
        id: 'utilization',
        accessor: ColumnKeys.UTILIZATION,
        i18nKey: 'planPage.billableResourcesCard.headers.utilization',
        Footer: UtilizationFooter,
        Cell: UtilizationCell,
        required: true
      },
      {
        accessor: ColumnKeys.CURRENT_UTILIZATION,
        i18nKey: 'planPage.billableResourcesCard.headers.currentUtilization',
        Cell: CurrentUtilizationCell,
        Footer: CurrentUtilizationFooter
      },
      {
        accessor: ColumnKeys.BILLING_RATE,
        i18nKey: 'planPage.billableResourcesCard.headers.billingRate',
        Cell: BillingRateCell,
        Header: BillingRateHeader,
        required: true
      },
      {
        accessor: ColumnKeys.CURRENT_BILLING_RATE,
        i18nKey: 'planPage.billableResourcesCard.headers.currentBillingRate'
      },
      {
        accessor: ColumnKeys.MARGIN,
        i18nKey: 'planPage.billableResourcesCard.headers.margin',
        Footer: MarginFooter,
        Cell: MarginCell,
        Header: MarginHeader
      }
    ]
  },
  {
    Header: BillableHeader,
    id: 'result',
    i18nKey: 'planPage.billableResourcesCard.headers.result',
    columns: [
      {
        accessor: ColumnKeys.REVENUE,
        i18nKey: 'planPage.billableResourcesCard.headers.revenue',
        Footer: RevenueFooter,
        Cell: RevenueCell
      },
      {
        accessor: ColumnKeys.PROFIT,
        i18nKey: 'planPage.billableResourcesCard.headers.profit',
        Cell: CalculatedProfitCell,
        Footer: CalculatedProfitFooter
      }
    ]
  }
];

export const defaultColumns = [
  ColumnKeys.ROLE_NAME,
  ColumnKeys.TOTAL_HOURS,
  ColumnKeys.TOTAL_COST,
  ColumnKeys.STANDARD_RATE,
  ColumnKeys.NON_BILLABLE_OVERHEAD,
  ColumnKeys.BUSINESS_OVERHEAD,
  ColumnKeys.LOADED_COST,
  ColumnKeys.UTILIZATION,
  ColumnKeys.BILLING_RATE,
  ColumnKeys.MARGIN,
  ColumnKeys.REVENUE,
  ColumnKeys.PROFIT
];

export const allColumns = Object.keys(ColumnKeys).map(key => ColumnKeys[key]);
