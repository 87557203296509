import React, { useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { usePlanTableEditHandlers, usePlanTableFormReducer } from 'plans/hooks';
import {
  CalculateAttribute,
  PlanTotalContextProvider,
  RoleTableFormContextProvider,
  useCalculateAttributeContext,
  useLocalBusinessCostContext,
  usePlanContext
} from 'plans/plan/context';
import { useSaveDraftContext } from 'plans/plan/context';
import { useLockedColumnContext } from 'common/context';
import { getBillingByRoleData } from 'rateCard/helpers';
import { useSnapToNearestX } from 'plans/plan/cards/hooks';
import { useFeatureFlag } from 'common/hooks';
import { calculateForModel } from 'rateCard/model';
import { usePlanTotals, usePropagateBusinessCostChanges } from './hooks';

export const useStyles = makeStyles(() => ({
  root: {
    overflowX: 'auto'
  }
}));

export const BillingResourceTableContainer: React.FC = props => {
  const { plan } = usePlanContext();
  const { children } = props;
  const { businessCostsTotalHourly } = useLocalBusinessCostContext();
  const globalUtilization = plan?.utilization as BigNumber;
  const globalMargin = plan?.margin as BigNumber;
  const { lockedColumn } = useLockedColumnContext();
  const {
    clearDirty,
    dirty,
    getAccurateValues,
    getValues,
    setValue,
    setValues
  } = usePlanTableFormReducer(
    plan,
    businessCostsTotalHourly.amount ??
      plan?.businessOverheadRate?.amount ??
      new BigNumber(0)
  );

  const billingData = useMemo(() => {
    return getBillingByRoleData(
      plan?.roleCostBreakdown ?? [],
      getAccurateValues()
    );
  }, [getAccurateValues, plan]);

  const {
    setHasRoleCostChanges,
    resetRoleFormRef,
    setGlobalUtilization
  } = useSaveDraftContext();
  resetRoleFormRef.current = clearDirty;

  useEffect(() => {
    setHasRoleCostChanges(dirty);
  }, [dirty, setHasRoleCostChanges]);
  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const { lockedColumns } = useCalculateAttributeContext();
  const finalLockedColumns = useMemo(() => {
    const columns = cpoNewUx ? lockedColumns : [lockedColumn];
    return columns.filter(column => column) as CalculateAttribute[];
  }, [cpoNewUx, lockedColumn, lockedColumns]);
  const {
    utilization,
    margin,
    revenue: totalRevenue,
    profit,
    onGlobalUtilizationChangeHandler,
    onGlobalMarginChangeHandler,
    onGlobalBillingRateChangeHandler,
    onUtilizationChangeHandler,
    onBillingRateChangeHandler,
    onBusinessCostChangeHandler,
    onMarginChangeHandler,
    onNearestToXBillingRate
  } = usePlanTableEditHandlers({
    billingData,
    utilization: globalUtilization,
    margin: globalMargin,
    setValue,
    setValues,
    getValues: getAccurateValues,
    totalCost: plan?.totalCost?.amount ?? new BigNumber(0),
    lockedColumns: finalLockedColumns,
    rateCardModel: cpoNewUx ? calculateForModel : undefined,
    plan: plan
  });
  const { nearestXValue, snapToNearestX } = useSnapToNearestX({
    plan,
    onSnapBillingRates: onNearestToXBillingRate
  });

  useEffect(() => {
    setGlobalUtilization(utilization);
  }, [onNearestToXBillingRate, setGlobalUtilization, utilization]);

  usePropagateBusinessCostChanges(
    plan,
    businessCostsTotalHourly,
    onBusinessCostChangeHandler,
    getAccurateValues
  );

  const planTotals = usePlanTotals({
    totalRevenue,
    result: plan,
    roleData: billingData,
    utilization
  });
  const planTotalContextValue = useMemo(
    () => ({
      planTotals,
      margin: planTotals?.margin ?? margin,
      utilization,
      revenue: { amount: totalRevenue, currency: plan?.currency },
      profit: planTotals?.profit ?? {
        amount: profit,
        currency: plan?.currency
      },
      roleData: billingData
    }),
    [billingData, margin, plan, planTotals, profit, totalRevenue, utilization]
  );
  const roleTableFormContextValue = useMemo(
    () => ({
      getValues,
      onUtilizationChangeHandler,
      onBillingRateChangeHandler,
      onMarginChangeHandler,
      onGlobalUtilizationChangeHandler,
      onGlobalMarginChangeHandler,
      onGlobalBillingRateChangeHandler,
      nearestXValue,
      snapToNearestX,
      setValues
    }),
    [
      getValues,
      nearestXValue,
      onBillingRateChangeHandler,
      onGlobalMarginChangeHandler,
      onGlobalUtilizationChangeHandler,
      onGlobalBillingRateChangeHandler,
      onMarginChangeHandler,
      onUtilizationChangeHandler,
      setValues,
      snapToNearestX
    ]
  );

  return (
    <PlanTotalContextProvider value={planTotalContextValue}>
      <RoleTableFormContextProvider value={roleTableFormContextValue}>
        {children}
      </RoleTableFormContextProvider>
    </PlanTotalContextProvider>
  );
};
