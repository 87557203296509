import { useMemo } from 'react';
import { Maybe, Permission } from 'generated-types';
import { useMeContext } from 'App/Me';

const usePermissionSet = (): Map<string, Array<Maybe<string>>> => {
  const me = useMeContext();

  const permissionsSet: Map<string, Array<Maybe<string>>> = useMemo(
    () =>
      (me.permissions ?? []).reduce(
        (
          acc: Map<string, Array<Maybe<string>>>,
          current: Maybe<Permission>
        ) => {
          if (current && current.permissionActionUri) {
            acc.set(
              current.permissionActionUri,
              current.dataAccessLevelUris || []
            );
          }
          return acc;
        },
        new Map<string, Array<Maybe<string>>>()
      ),
    [me.permissions]
  );
  return permissionsSet;
};

export const useHasUserProjectRoleAssignmentsEditPermission = (): boolean => {
  const permissionSet = usePermissionSet();

  return useMemo(() => {
    const canAccessResourcePool =
      permissionSet.has('urn:replicon:user-action:view-user') &&
      permissionSet
        .get('urn:replicon:user-action:view-user')
        ?.includes('urn:replicon:user-data-access-level:resource-manager');
    const canEditProjectRoleAssignments =
      permissionSet.has('urn:replicon:user-action:edit-project-role') ||
      permissionSet.has('urn:replicon:resource-action:edit-resource');

    return Boolean(canAccessResourcePool && canEditProjectRoleAssignments);
  }, [permissionSet]);
};
