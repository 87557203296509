import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import BigNumber from 'bignumber.js';
import { DateTime } from 'luxon';
import { FieldValues, FormContextValues, useForm } from 'react-hook-form';
import {
  BillingByRoleData,
  FormData,
  UpdateRefs,
  RoleData
} from 'common/types';
import { RoleBreakdown } from 'generated-types';
import {
  roundFormDataValues,
  setFormValue as setValue,
  getBillingByRoleData,
  commitRates,
  getUpdateMarginAndUtilization,
  calculateRevenueForRole
} from 'rateCard/helpers';
import { CommitCostOptimizationResultOptions } from 'billing/hooks';

export type BillingForm = Omit<FormContextValues<FieldValues>, 'setValue'> & {
  setRoleData: (value: FieldValues) => void;
  getAccurateValues: () => FieldValues;
  setRates: (startDate: DateTime, endDate: DateTime) => Promise<void>;
  setValue: (id: string, value: FormData) => void;
};

const getInitialValues = (
  roleBreakdown: RoleBreakdown[],
  utilization: BigNumber,
  margin: BigNumber
): RoleData =>
  roleBreakdown.reduce((acc, data) => {
    const dataMargin = data.margin ?? margin;
    const dataUtilization = data.utilization ?? utilization;
    const revenue = calculateRevenueForRole(
      data.billingCost?.amount ?? new BigNumber(0),
      dataUtilization,
      data.totalHours ?? new BigNumber(0)
    );
    acc[data.id] = {
      margin: dataMargin.toString(),
      utilization: dataUtilization.toString(),
      billingRate: (data.billingCost?.amount ?? new BigNumber(0)).toString(),
      revenue: revenue.toString()
    };
    return acc;
  }, {} as RoleData);

export const useUpdateRefCurrent = (
  billingData: BillingByRoleData[],
  roleBreakdown: RoleBreakdown[],
  utilization: BigNumber,
  margin: BigNumber,
  lockedColumn: keyof FormData | undefined,
  getValues: () => FieldValues,
  setValue: (id: string, value: FormData) => void,
  setRoleData: (value: RoleData) => void,
  setBillingData: React.Dispatch<React.SetStateAction<BillingByRoleData[]>>
): UpdateRefs =>
  useMemo(
    () => ({
      ...getUpdateMarginAndUtilization(
        billingData,
        getValues,
        setValue,
        setBillingData,
        lockedColumn
      ),
      revertMarginUtilization: (): void => {
        const roleData = getInitialValues(roleBreakdown, utilization, margin);
        setRoleData(roleData);
        Object.keys(roleData).forEach(key => setValue(key, roleData[key]));
      }
    }),
    [
      billingData,
      getValues,
      lockedColumn,
      margin,
      roleBreakdown,
      setBillingData,
      setRoleData,
      setValue,
      utilization
    ]
  );

export const resetFormDirtyState = (
  control: FormContextValues['control']
): void => {
  // HACK: Reset the dirty state explicitly.
  // This allows us to properly disable the "Save" button
  // once the data has been persisted.
  control.isDirtyRef.current = false;
  control.dirtyFieldsRef.current = new Set();
  control.reRender();
};

export const useActiveForm = (
  roleBreakdown: RoleBreakdown[],
  utilization: BigNumber,
  margin: BigNumber,
  lockedColumn: keyof FormData | undefined,
  updateRef: MutableRefObject<UpdateRefs | undefined>,
  setBillingData: React.Dispatch<React.SetStateAction<BillingByRoleData[]>>,
  commitCostOptimizationResult: (
    options: CommitCostOptimizationResultOptions
  ) => Promise<void>
): BillingForm => {
  const initialValue = useMemo(() => {
    return getInitialValues(roleBreakdown, utilization, margin);
  }, [margin, roleBreakdown, utilization]);

  const [accurateRoleData, setAccurateRoleData] = useState(initialValue);

  const { setValue: setFormValue, ...restFormData } = useForm({
    defaultValues: Object.keys(accurateRoleData).reduce((acc, id) => {
      acc[id] = roundFormDataValues(accurateRoleData[id]);
      return acc;
    }, {} as RoleData)
  });

  const setAccurateValue = useCallback(
    (id: string, value: FormData) =>
      setValue(accurateRoleData, setFormValue, setAccurateRoleData)(id, value),
    [accurateRoleData, setFormValue, setAccurateRoleData]
  );

  const getAccurateValues = useCallback(() => accurateRoleData, [
    accurateRoleData
  ]);

  const billingData = useMemo(
    () => getBillingByRoleData(roleBreakdown, getAccurateValues()),
    [getAccurateValues, roleBreakdown]
  );

  useEffect(() => {
    billingData.forEach(data => restFormData.register(data.id));
    setBillingData(billingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  updateRef.current = useUpdateRefCurrent(
    billingData,
    roleBreakdown,
    utilization,
    margin,
    lockedColumn,
    getAccurateValues,
    setAccurateValue,
    setAccurateRoleData,
    setBillingData
  );

  const setRates = useCallback(
    (startDate: DateTime, endDate: DateTime) =>
      commitRates({
        getValues: getAccurateValues,
        commitCostOptimizationResult,
        startDate,
        endDate
      }),
    [commitCostOptimizationResult, getAccurateValues]
  );
  return {
    ...restFormData,
    setValue: setAccurateValue,
    setRoleData: setAccurateRoleData,
    getAccurateValues,
    setRates
  };
};
