import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { usePath } from './usePath';

type RedirectCallback = () => void;

export const useRedirect = (path: string): RedirectCallback => {
  const history = useHistory();
  const target = usePath(path);

  const redirectFn = useCallback((): void => {
    history.push(target);
  }, [history, target]);

  return redirectFn;
};
