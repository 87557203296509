import React, { useCallback } from 'react';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Maybe } from 'graphql/jsutils/Maybe';
import { TextAlignProperty } from 'csstype';
import { UserBreakdown, RoleBreakdown } from 'generated-types';
import { NumberValue, MoneyValue } from 'common/components';
import { TableFormNumberEditField } from 'rateCard/components';
import { useDirection } from 'App/MuiThemeProvider/useDirection';
import { usePutUserBreakdowns } from '../hooks/usePutUserBreakdowns';
import { OnChangeHandler } from '../RoleSummaryTable';
import {
  handleUserRevenueChange,
  handleUserBillingRateChange
} from '../hooks/userBillingRevenueHandlers';

type RoleSummaryTableBodyProps = {
  userBreakdown: UserBreakdown;
  cellClasses?: ClassNameMap;
  editing: boolean;
  roleUri: string;
  role?: Maybe<RoleBreakdown>;
  handleMarginChange: OnChangeHandler;
  handleUtilizationChange: OnChangeHandler;
  costOptimizationResultId: string;
};

const useStyles = makeStyles(theme => ({
  tableCell: {
    maxWidth: 100,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2, 1, 2, 1)
    }
  },
  percentage: {
    paddingLeft: 1,
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '80%'
    }
  }
}));

const useCellStyle = makeStyles(theme => ({
  root: {
    '&:last-child': {
      paddingRight: theme.spacing(3)
    }
  }
}));

const useInputClasses = makeStyles(() => ({
  input: {
    textAlign: ({ direction }: { direction: string }): TextAlignProperty =>
      direction === 'rtl' ? 'start' : 'right'
  }
}));

export const RoleSummaryTableBody: React.FC<RoleSummaryTableBodyProps> = ({
  userBreakdown,
  cellClasses,
  editing,
  roleUri,
  role,
  handleMarginChange,
  handleUtilizationChange,
  costOptimizationResultId
}: RoleSummaryTableBodyProps) => {
  const direction = useDirection();
  const classes = useStyles();
  const cellStyle = useCellStyle({ classes: cellClasses });
  const inputClasses = useInputClasses({ direction });

  const putUserBreakdowns = usePutUserBreakdowns({
    costOptimizationResultId: costOptimizationResultId,
    handleUtilizationChange: handleUtilizationChange,
    handleMarginChange: handleMarginChange,
    role: role,
    roleUri: roleUri,
    userBreakdown: userBreakdown
  });

  const utilizationOnChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (): any => putUserBreakdowns('utilization'),
    [putUserBreakdowns]
  );

  const marginOnChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (): any => putUserBreakdowns('margin'),
    [putUserBreakdowns]
  );

  const billingOnChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (): any => handleUserBillingRateChange(userBreakdown, putUserBreakdowns),
    [putUserBreakdowns, userBreakdown]
  );

  const revenueOnChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (): any => handleUserRevenueChange(userBreakdown, putUserBreakdowns),
    [putUserBreakdowns, userBreakdown]
  );
  const numberColumnDirection = 'right';
  return (
    <TableRow>
      <TableCell classes={cellStyle} className={classes.tableCell}>
        {userBreakdown.user?.displayText}
      </TableCell>
      <TableCell align={numberColumnDirection} className={classes.tableCell}>
        <NumberValue value={userBreakdown?.hours?.toNumber() ?? 0} />
      </TableCell>
      <TableCell align={numberColumnDirection} className={classes.tableCell}>
        <MoneyValue
          currencySymbol={userBreakdown.totalCost?.currency?.symbol ?? ''}
          value={userBreakdown.totalCost?.amount?.toNumber() ?? 0}
        />
      </TableCell>
      <TableCell align={numberColumnDirection} className={classes.tableCell}>
        <MoneyValue
          currencySymbol={
            userBreakdown.standardCostRate?.currency?.symbol ?? ''
          }
          value={userBreakdown.standardCostRate?.amount?.toNumber() ?? 0}
        />
      </TableCell>
      <TableCell align={numberColumnDirection} className={classes.tableCell}>
        <MoneyValue
          currencySymbol={
            userBreakdown.nonBillableOverhead?.currency?.symbol ?? ''
          }
          value={userBreakdown.nonBillableOverhead?.amount?.toNumber() ?? 0}
        />
      </TableCell>
      <TableCell align={numberColumnDirection} className={classes.tableCell}>
        <MoneyValue
          currencySymbol={
            userBreakdown.businessOverhead?.currency?.symbol ?? ''
          }
          value={userBreakdown.businessOverhead?.amount?.toNumber() ?? 0}
        />
      </TableCell>
      <TableCell align={numberColumnDirection} className={classes.tableCell}>
        <MoneyValue
          currencySymbol={userBreakdown.loadedCostRate?.currency?.symbol ?? ''}
          value={userBreakdown.loadedCostRate?.amount?.toNumber() ?? 0}
        />
      </TableCell>
      <TableCell align={numberColumnDirection} className={classes.tableCell}>
        {editing ? (
          <TableFormNumberEditField
            classes={inputClasses}
            className={classes.percentage}
            id={userBreakdown.id}
            attribute="utilization"
            value={userBreakdown.utilization?.toString() ?? '0'}
            suffix="%"
            onChanged={utilizationOnChange}
          />
        ) : (
          <NumberValue
            value={userBreakdown.utilization?.toNumber() ?? 0}
            suffix=" %"
            decimalScale={2}
            fixedDecimalScale
          />
        )}
      </TableCell>
      <TableCell align={numberColumnDirection} className={classes.tableCell}>
        {editing ? (
          <TableFormNumberEditField
            classes={inputClasses}
            className={classes.percentage}
            id={userBreakdown.id}
            attribute="margin"
            value={userBreakdown.margin?.toString() ?? '0'}
            suffix="%"
            onChanged={marginOnChange}
          />
        ) : (
          <NumberValue
            value={userBreakdown.margin?.toNumber() ?? 0}
            suffix=" %"
            decimalScale={2}
            fixedDecimalScale
          />
        )}
      </TableCell>
      <TableCell align={numberColumnDirection} className={classes.tableCell}>
        {editing ? (
          <TableFormNumberEditField
            classes={inputClasses}
            id={userBreakdown.id}
            attribute="billingRate"
            fullWidth
            value={userBreakdown.billingRate?.amount?.toString() ?? '0'}
            onChanged={billingOnChange}
            prefix={userBreakdown.billingRate?.currency?.symbol ?? ''}
          />
        ) : (
          <MoneyValue
            currencySymbol={userBreakdown.billingRate?.currency?.symbol ?? ''}
            value={userBreakdown.billingRate?.amount?.toNumber() ?? 0}
          />
        )}
      </TableCell>
      <TableCell
        align={numberColumnDirection}
        classes={cellStyle}
        className={classes.tableCell}>
        {editing ? (
          <TableFormNumberEditField
            classes={inputClasses}
            id={userBreakdown.id}
            attribute="revenue"
            fullWidth
            value={userBreakdown.revenue?.amount?.toString() ?? '0'}
            onChanged={revenueOnChange}
            prefix={userBreakdown.totalCost?.currency?.symbol ?? ''}
          />
        ) : (
          <MoneyValue
            currencySymbol={userBreakdown.revenue?.currency?.symbol ?? ''}
            value={userBreakdown.revenue?.amount?.toNumber() ?? 0}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default RoleSummaryTableBody;
