import {
  MutableRefObject,
  useCallback,
  useRef,
  useState,
  useMemo
} from 'react';
import BigNumber from 'bignumber.js';
import { UpdateRefs } from 'common/types';
import {
  DEFAULT_MARGIN,
  DEFAULT_UTILIZATION,
  getRevenueValue
} from 'rateCard/helpers';
import { RoleBreakdown, Maybe } from 'generated-types';

export interface UseUtilizationMarginResult {
  utilization: BigNumber;
  margin: BigNumber;
  totalRevenue: BigNumber;
  handleUtilizationChange: (value: string, updateRefs?: boolean) => void;
  handleMarginChange: (value: string, updateRefs?: boolean) => void;
  handleTotalRevenueChange: (totalRevenue: BigNumber) => void;
  reset: () => void;
  updateRefs: MutableRefObject<UpdateRefs | undefined>;
}

export const useUtilizationMargin = (
  initialMargin = DEFAULT_MARGIN,
  initialUtilization = DEFAULT_UTILIZATION,
  roleCostBreakdown: Maybe<RoleBreakdown>[] | null | undefined
): UseUtilizationMarginResult => {
  const revenueValue = useMemo(
    () => getRevenueValue(roleCostBreakdown, initialMargin),
    [initialMargin, roleCostBreakdown]
  );

  const updateRefs = useRef<UpdateRefs>();
  const [utilization, setUtilization] = useState(initialUtilization);
  const [margin, setMargin] = useState(initialMargin);
  const [totalRevenue, setTotalRevenue] = useState(revenueValue);

  const handleTotalRevenueChange = useCallback(
    (totalRevenue: BigNumber) => {
      setTotalRevenue(totalRevenue);
    },
    [setTotalRevenue]
  );

  const handleUtilizationChange = useCallback(
    (value: string, updateRef = true) => {
      setUtilization(new BigNumber(value));
      if (updateRef) {
        updateRefs.current?.updateUtilization(value);
      }
    },
    [setUtilization]
  );

  const handleMarginChange = useCallback(
    (value: string, updateRef = true) => {
      setMargin(new BigNumber(value));
      if (updateRef) {
        updateRefs.current?.updateMargin(value);
      }
      handleTotalRevenueChange(
        getRevenueValue(roleCostBreakdown, new BigNumber(value))
      );
    },
    [handleTotalRevenueChange, roleCostBreakdown]
  );

  const reset = useCallback(() => {
    setUtilization(initialUtilization);
    setMargin(initialMargin);
    setTotalRevenue(
      getRevenueValue(roleCostBreakdown, initialMargin).decimalPlaces(2)
    );
    updateRefs.current?.revertMarginUtilization?.();
  }, [initialMargin, initialUtilization, roleCostBreakdown]);

  return {
    utilization: new BigNumber(utilization),
    margin: new BigNumber(margin),
    totalRevenue: new BigNumber(totalRevenue),
    reset,
    handleUtilizationChange,
    handleMarginChange,
    handleTotalRevenueChange,
    updateRefs
  };
};
