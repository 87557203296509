import { useCallback, useState } from 'react';
import { MutationUpdaterFn } from '@apollo/client';
import {
  SmartBudgetDocument,
  DiscardSmartBudgetMutation,
  useDiscardSmartBudgetMutation
} from 'generated-types';
import { clearUtilizationAndMargin } from 'common/utilizationMarginUtil';

type CacheUpdater = (
  id: string
) => MutationUpdaterFn<DiscardSmartBudgetMutation>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const cacheUpdater: CacheUpdater = (id: string) => proxy => {
  proxy.writeQuery({
    query: SmartBudgetDocument,
    variables: {
      id: id
    },
    data: {
      costOptimizationResult: null
    }
  });
};

export const useDiscardCostOptimizationResult = (
  id: string,
  onDiscardComplete: () => void
): {
  discarding: boolean;
  discardCostOptimizationResult: () => Promise<void>;
} => {
  const [discarding, setDiscarding] = useState(false);

  const [discardSmartBudgetMutation] = useDiscardSmartBudgetMutation({
    variables: { input: { smartBudgetId: id } }
  });

  const discardCostOptimizationResult = useCallback(async () => {
    setDiscarding(true);
    const { data } = await discardSmartBudgetMutation({
      update: cacheUpdater(id)
    });
    setDiscarding(false);
    if (data?.discardSmartBudget) {
      clearUtilizationAndMargin();
      onDiscardComplete();
    }
  }, [discardSmartBudgetMutation, id, onDiscardComplete]);

  return { discarding, discardCostOptimizationResult };
};
