import React, { useMemo } from 'react';
import { VictoryTooltip, VictoryLabel } from 'victory';
import { DateTime } from 'luxon';
// eslint-disable-next-line import/named
import { VictoryTooltipProps } from 'victory-tooltip';
import { useIntl } from 'App/MessageProvider';
import { numberAbbreviatedTickFormat } from './util';
import { OverviewBarType } from './OverviewChart';

type VictorLabelAndTooltipProps = VictoryTooltipProps & {
  type: OverviewBarType;
};

type DataRecord = Record<string, string | number>;
export const showLabel = ({
  type,
  data
}: {
  type: OverviewBarType;
  data: DataRecord;
}): boolean => {
  if (type === 'budgeted') {
    return false;
  }
  const dataMonth = DateTime.fromISO(data.month as string).startOf('month');
  if (type === 'estimated') {
    const currentMonth = DateTime.local().startOf('month');
    return dataMonth >= currentMonth && data.estimated > 0;
  }

  return data[type] > 0;
};

export const VictoryLabelAndTooltip: React.FC<VictorLabelAndTooltipProps> = ({
  type,
  ...props
}: VictorLabelAndTooltipProps) => {
  const intl = useIntl();
  const { datum } = props;
  const labelProps = useMemo(() => {
    return {
      text: numberAbbreviatedTickFormat({
        intl,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tick: Math.abs((datum as any)[type])
      })
    };
  }, [datum, intl, type]);

  return (
    <g>
      <VictoryTooltip {...props} dy={-2} />
      {showLabel({ type, data: datum as DataRecord }) && (
        <VictoryLabel {...props} {...labelProps} dy={4} />
      )}
    </g>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(VictoryLabelAndTooltip as any).defaultEvents = (VictoryTooltip as any).defaultEvents;
