import React, { memo, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { Tooltip, TooltipProps } from '@material-ui/core';
import { Maybe } from 'generated-types';
import { useMeNumberFormats } from 'App/NumberFormatProvider';
import MoneyValue from '../MoneyValue';

export type TooltipMoneyProps = Omit<TooltipProps, 'title' | 'children'> & {
  value: number | string;
  currencySymbol?: Maybe<string>;
};

const nbsp = '\u00a0';

export const TooltipMoney: React.FC<TooltipMoneyProps> = memo(
  ({ value, currencySymbol, ...rest }) => {
    const { currencyFormat } = useMeNumberFormats();
    const numberView = useMemo(
      () => (
        <NumberFormat
          {...(currencySymbol && { prefix: `${currencySymbol}${nbsp}` })}
          thousandSeparator={currencyFormat.thousandSeparator}
          decimalSeparator={currencyFormat.decimalSeparator}
          thousandsGroupStyle={currencyFormat.thousandsGroupStyle}
          decimalScale={6}
          value={value}
          displayType="text"
        />
      ),
      [currencyFormat, currencySymbol, value]
    );
    return (
      <Tooltip title={numberView} {...rest}>
        <div>
          <MoneyValue value={value} currencySymbol={currencySymbol} />
        </div>
      </Tooltip>
    );
  }
);

TooltipMoney.displayName = 'TooltipMoney';
