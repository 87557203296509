import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2, 2)
  },
  skeleton: {
    margin: theme.spacing(0, 2)
  }
}));

export const UpcomingPanelRow: React.FC = () => {
  const classes = useRowStyles();
  return (
    <div className={classes.root}>
      <Skeleton className={classes.skeleton} width="1%" />
      <Skeleton className={classes.skeleton} width="10%" />
      <Skeleton className={classes.skeleton} width="15%" />
      <Skeleton className={classes.skeleton} width="10%" />
    </div>
  );
};

export const UpcomingPanelSkeleton: React.FC = () => {
  return (
    <>
      {[...Array(3)].map((__: undefined, index: number) => (
        <UpcomingPanelRow key={`Upcoming_row${index}`} />
      ))}
    </>
  );
};
