import React from 'react';
import { CardHeader, CardHeaderProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  action: {
    marginTop: 0
  }
}));

const DataCardHeader: React.FC<CardHeaderProps> = props => {
  const classes = useStyles();

  return <CardHeader classes={classes} {...props} />;
};

export default DataCardHeader;
