import React, { useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSmartBudgetQuery } from 'generated-types';
import { useFeatureFlag, useNavigationBlock } from 'common/hooks';
import {
  NavigationBlockContextProvider,
  LockedColumnContextProvider
} from 'common/context';
import { FormData as RateCardFormData } from 'common/types';
import {
  CalculateAttributeContextProvider,
  PlanEditContextProvider,
  PlanUndoContextProvider,
  SaveDraftContextProvider
} from './context';
import {
  PlanContextProvider,
  PlanContextValue,
  LocalBusinessCostContextProvider
} from './context';
import { BillingResourceTableContainer } from './components';

type PlanPageParams = {
  planId: string;
};

const PlanPageContainer: React.FC = ({ children }) => {
  const { params } = useRouteMatch<PlanPageParams>();
  const { unblockNavigation, unblockRef } = useNavigationBlock();
  const { planId } = params;
  if (!planId) {
    throw new Error('Requires a planId route parameter.');
  }
  const { data, loading, error, refetch } = useSmartBudgetQuery({
    variables: {
      id: planId
    },
    fetchPolicy: 'network-only'
  });
  const contextValue = useMemo<PlanContextValue>(
    () => ({
      plan: data?.smartBudget,
      error: error,
      loading: loading,
      refetch
    }),
    [data, error, loading, refetch]
  );

  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const [lockedColumn, setLockedColumn] = useState<
    keyof RateCardFormData | undefined
  >(cpoNewUx ? undefined : 'billingRate');

  return (
    <PlanEditContextProvider>
      <PlanContextProvider value={contextValue}>
        <NavigationBlockContextProvider
          unblockNavigation={unblockNavigation}
          unblockRef={unblockRef}>
          <LocalBusinessCostContextProvider
            key={data?.smartBudget?.lastSynchronizedAt ?? undefined}>
            <SaveDraftContextProvider>
              <PlanUndoContextProvider>
                <LockedColumnContextProvider
                  canLockColumns={true}
                  setLockedColumn={setLockedColumn}
                  lockedColumn={lockedColumn}>
                  <CalculateAttributeContextProvider>
                    <BillingResourceTableContainer>
                      {children}
                    </BillingResourceTableContainer>
                  </CalculateAttributeContextProvider>
                </LockedColumnContextProvider>
              </PlanUndoContextProvider>
            </SaveDraftContextProvider>
          </LocalBusinessCostContextProvider>
        </NavigationBlockContextProvider>
      </PlanContextProvider>
    </PlanEditContextProvider>
  );
};

export default PlanPageContainer;
