import { MutableRefObject, useCallback, useEffect } from 'react';
import {
  Action,
  History,
  Location,
  TransitionPromptHook,
  UnregisterCallback
} from 'history';
import { useHistory } from 'react-router-dom';
import { useRouteBase } from 'App/EmbeddedAppContext/useRouteBase';
import { BlockAction } from 'common/components';
import { useUploadPath } from 'fileUpload';

export const onBlock = (
  currentLocation: Location,
  setBlockedAction: (blockAction: BlockAction) => void,
  openDialog: () => void,
  basePath: string
) => (location: Location<History.PoorMansUnknown>, action: Action): boolean => {
  const regex = new RegExp(`^${basePath}/.*$`, 'g');
  if (
    currentLocation.pathname === location.pathname ||
    location.pathname.match(regex)
  ) {
    return true;
  }

  setBlockedAction({
    location,
    action
  });
  openDialog();
  return false;
};

export const usePlanBlock = (
  block: boolean,
  unblockRef: MutableRefObject<UnregisterCallback | undefined>,
  openDialog: () => void,
  setBlockedAction: (blockAction: BlockAction) => void
): { unblockNavigation: UnregisterCallback } => {
  const history = useHistory();
  const uploadPath = useUploadPath();
  const basePath = useRouteBase() + uploadPath;
  const currentLocation = history.location;

  const unblockNavigation = useCallback(
    () => unblockRef.current && unblockRef.current(),
    [unblockRef]
  );
  useEffect(() => {
    if (block) {
      unblockRef.current = history.block(
        onBlock(
          currentLocation,
          setBlockedAction,
          openDialog,
          basePath
        ) as TransitionPromptHook<History.PoorMansUnknown>
      );
    } else {
      if (typeof unblockRef.current === 'function') {
        unblockRef.current();
      }
    }
  }, [
    basePath,
    block,
    currentLocation,
    currentLocation.pathname,
    history,
    openDialog,
    setBlockedAction,
    unblockRef
  ]);

  return { unblockNavigation };
};
