import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  gridContainer: {
    alignItems: 'baseline'
  },
  paper: {
    margin: theme.spacing(1, 0)
  },
  expansionPanel: {
    ...theme.typography.body2,
    width: '100%',
    paddingRight: theme.spacing(0, 5)
  },
  expandIcon: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
  fileNameContainer: {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: '15%'
  },
  fileNameActive: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 700
  },
  commited: {
    display: 'flex'
  },
  dateContainer: {
    display: 'contents',
    paddingRight: theme.spacing(1.5)
  },
  dateTitle: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: theme.spacing(1.5)
  },
  date: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1.5)
  },
  commitedBy: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: theme.spacing(1.5)
  },
  uploaded: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary
  }
}));

export const useExpansionPanelSummaryStyles = makeStyles({
  expanded: {
    backgroundColor: '#E0E0E0',
    marginTop: 16
  }
});

export const useRateCardButtonRowStyles = makeStyles(theme => ({
  buttonRow: {
    paddingLeft: theme.spacing(4.5)
  }
}));
