import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Button, CircularProgress, ButtonProps } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { AlertSnackbar } from '../AlertSnackbar';

const useStyles = makeStyles({
  root: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)'
  },
  button: {}
});

interface ProgressButtonProps extends ButtonProps {
  classes?: ClassNameMap;
  loading: boolean;
  disable?: boolean;
  children?: React.ReactNode;
  onClick: (...args: unknown[]) => Promise<void> | void;
  labelId?: string;
  successSnackbarLabelId?: string;
  failureSnackbarLabelId?: string;
  circularProgressSize?: number;
}

const ProgressButton: React.FC<ProgressButtonProps> = ({
  classes,
  loading,
  children,
  labelId,
  disable = false,
  onClick,
  successSnackbarLabelId,
  failureSnackbarLabelId,
  circularProgressSize = 24,
  ...rest
}: ProgressButtonProps) => {
  const styles = useStyles({ classes });
  const [showSnackbar, setShowSnackbar] = useState<string | undefined>();
  const onClickHandler = useCallback(
    async (...args) => {
      try {
        const result = await onClick(...args);
        successSnackbarLabelId && setShowSnackbar(successSnackbarLabelId);
        return result;
      } catch (e) {
        failureSnackbarLabelId && setShowSnackbar(failureSnackbarLabelId);
      }
    },
    [failureSnackbarLabelId, onClick, successSnackbarLabelId]
  );
  const onSnackbarClose = useCallback(() => setShowSnackbar(undefined), []);

  return (
    <div className={styles.root}>
      <Button
        variant="contained"
        color="primary"
        className={styles.button}
        disabled={loading || disable}
        onClick={onClickHandler}
        {...rest}>
        {labelId && <FormattedMessage id={labelId} />}
        {children}
      </Button>
      {Boolean(successSnackbarLabelId || failureSnackbarLabelId) && (
        <AlertSnackbar
          labelId={showSnackbar}
          open={Boolean(showSnackbar)}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
          variant={
            showSnackbar === successSnackbarLabelId ? 'success' : 'error'
          }
        />
      )}
      {loading && (
        <CircularProgress
          size={circularProgressSize}
          className={styles.buttonProgress}
        />
      )}
    </div>
  );
};

export default ProgressButton;
