import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import BigNumber from 'bignumber.js';
import RateCardModel, {
  RateCardColumn,
  RateCardModelState,
  RateCardModelOptions
} from './RateCardModel';

const rbrc = new RoleBillingRateCalculator();
const marginCalculationFunction = (
  changedColumn: RateCardColumn,
  state: RateCardModelState,
  options: RateCardModelOptions<RateCardModelState> | undefined
): BigNumber | undefined => {
  if (options?.lockedColumns?.includes('margin')) {
    return state['margin'];
  }
  return rbrc.calculateMarginFromBillingRate(
    state['billingRate'],
    state['utilization'],
    state['loadedCost']
  );
};

const utilizationCalculationFunction = (
  _: RateCardColumn,
  state: RateCardModelState,
  options: RateCardModelOptions<RateCardModelState> | undefined
): BigNumber | undefined => {
  if (options?.lockedColumns?.includes('utilization')) {
    return state['utilization'];
  }
  return rbrc.calculateUtilizationFromBillingRate(
    state['loadedCost'],
    state['margin'],
    state['billingRate']
  );
};

const billingRateCalculationFunction = (
  _: RateCardColumn,
  state: RateCardModelState,
  options: RateCardModelOptions<RateCardModelState> | undefined
): BigNumber => {
  if (options?.lockedColumns?.includes('billingRate')) {
    return state['billingRate'];
  }
  return rbrc.calculateBillingRateForRole(
    state['loadedCost'],
    state['utilization'],
    state['margin']
  );
};

const revenueCalculationFunction = (
  changedColumn: RateCardColumn,
  state: RateCardModelState
): BigNumber => {
  return rbrc.calculateRevenue(
    state['billingRate'],
    state['utilization'],
    state['totalHours']
  );
};

const loadedCostCalculationFunction = (
  _: RateCardColumn,
  state: RateCardModelState
) => {
  return state['standardRate']
    .plus(state['nonBillableOverhead'])
    .plus(state['businessOverhead']);
};

const calculateForModel = new RateCardModel();

// Columns are specified in the order they should be updated on a change
// to a dependant column.
const columns = [
  new RateCardColumn('standardRate'),
  new RateCardColumn('nonBillableOverhead'),
  new RateCardColumn('businessOverhead'),
  new RateCardColumn(
    'loadedCost',
    ['standardRate', 'nonBillableOverhead', 'businessOverhead'],
    loadedCostCalculationFunction
  ),
  new RateCardColumn('totalHours'),
  new RateCardColumn(
    'billingRate',
    ['loadedCost', 'utilization', 'margin'],
    billingRateCalculationFunction
  ),
  new RateCardColumn(
    'margin',
    ['loadedCost', 'utilization', 'billingRate'],
    marginCalculationFunction
  ),
  new RateCardColumn(
    'utilization',
    ['billingRate', 'margin'],
    utilizationCalculationFunction
  ),
  new RateCardColumn(
    'revenue',
    ['margin', 'billingRate', 'utilization'],
    revenueCalculationFunction
  )
];

columns.map(calculateForModel.registerColumn.bind(calculateForModel));

export default calculateForModel;
