import { ChangeEvent, useCallback } from 'react';

type SliderChangeCallback = (
  event: React.ChangeEvent<unknown>,
  value: number | number[]
) => void;
type InputChangeCallback = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
) => void | Promise<void>;

export interface UseSliderHandlersResult {
  onSliderChange: SliderChangeCallback;
  onInputChange: InputChangeCallback;
}

export const useSliderHandlers = (
  onValueChange: (value: string) => void,
  min = 1,
  max = 100
): UseSliderHandlersResult => {
  const handleSliderChange = useCallback<SliderChangeCallback>(
    (_, newValue): void => {
      if (Array.isArray(newValue)) {
        throw new Error('Expected only a single value.');
      }

      onValueChange(newValue.toString());
    },
    [onValueChange]
  );

  const handleInputChange = useCallback<InputChangeCallback>(
    event => {
      const value =
        typeof event === 'string' ? event : event.target.value.toString();
      const castValue = Number.parseInt(value, 10);
      if (!isNaN(castValue)) {
        const newValue = Math.min(Math.max(castValue, min), max);
        onValueChange(newValue.toString());
      }
    },
    [max, min, onValueChange]
  );

  return {
    onInputChange: handleInputChange,
    onSliderChange: handleSliderChange
  };
};
