import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { SmartBudgetResult } from 'generated-types';
import { useTotalCostBreakdown, useRoleBreakdown } from 'common/hooks';
import { NoDataItem } from 'common/components/NoDataItem';
import { useActiveForm } from 'active/hooks';
import { DEFAULT_MARGIN, DEFAULT_UTILIZATION } from 'rateCard/helpers';
import { useCommitCostOptimizationResult } from 'billing/hooks';
import { BillingByRoleData } from 'common/types';
import { UtilizationMarginCard } from 'rateCard/components';
import { useRoleSummaryUtilizationMargin } from 'roleSummaryPage/hooks/useRoleSummaryUtilizationMargin';

import { UploadDiscardDialog } from 'common/components';
import { useNavigationBlockContext } from 'common/context';
import { RoleSummaryInfo } from '../RoleSummaryInfo';
import { RoleSummaryTable } from '../RoleSummaryCard/RoleSummaryTable';
import { getTargetRoleBreakdown } from '../RoleSummaryCard/helpers';

export type RoleSummaryContentProps = {
  costOptimizationResult: SmartBudgetResult;
  roleUri: string;
  navigationHandler: () => void;
};

const useMarginUtilizationStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(5, 0, 6, 6.5),
    width: '35%'
  }
}));

const useStyles = makeStyles(theme => ({
  noResourcesMessage: {
    flexGrow: 1,
    flexShrink: 1,
    lineHeight: 3.5,
    padding: theme.spacing(0, 0, 0, 2)
  },
  table: {
    margin: theme.spacing(2, 0, 2, 0),
    width: '100%'
  }
}));

export const RoleSummaryEditContent: React.FC<RoleSummaryContentProps> = ({
  costOptimizationResult,
  roleUri,
  navigationHandler
}: RoleSummaryContentProps) => {
  const marginUtilizationClasses = useMarginUtilizationStyles();
  const classes = useStyles();
  const { id, fileName } = costOptimizationResult;

  const roleCostBreakdowns = useRoleBreakdown(costOptimizationResult);
  const targetRoleCostBreakdown = useMemo(() => {
    return getTargetRoleBreakdown(roleCostBreakdowns, roleUri);
  }, [roleCostBreakdowns, roleUri]);

  const navigationBlock = useNavigationBlockContext();

  const {
    utilization,
    margin,
    handleUtilizationChange,
    handleMarginChange,
    handleTotalRevenueChange,
    reset,
    updateRefs
  } = useRoleSummaryUtilizationMargin(
    targetRoleCostBreakdown?.margin ?? DEFAULT_MARGIN,
    targetRoleCostBreakdown?.utilization ?? DEFAULT_UTILIZATION,
    costOptimizationResult.roleCostBreakdown
  );

  const { commitCostOptimizationResult } = useCommitCostOptimizationResult({
    id: targetRoleCostBreakdown?.id ?? '',
    utilization,
    margin
  });

  const [, setBillingData] = useState<BillingByRoleData[]>([]);

  useActiveForm(
    roleCostBreakdowns ?? [],
    utilization,
    margin,
    undefined,
    updateRefs,
    setBillingData,
    commitCostOptimizationResult
  );

  const totalCostBreakdown = useTotalCostBreakdown(costOptimizationResult);

  return (
    <>
      <RoleSummaryInfo
        roleData={targetRoleCostBreakdown?.role}
        fileName={fileName}
        costOptimizationResultId={costOptimizationResult.id}
        onNavigateBack={navigationHandler}
      />
      <UtilizationMarginCard
        classes={marginUtilizationClasses}
        utilization={utilization ?? new BigNumber(0)}
        handleUtilizationChange={handleUtilizationChange}
        margin={margin ?? new BigNumber(0)}
        handleMarginChange={handleMarginChange}
        revert={reset}
        totalCost={costOptimizationResult.totalCost}
        totalRevenue={
          targetRoleCostBreakdown?.revenue?.amount ?? new BigNumber(0)
        }
        handleTotalRevenueChange={handleTotalRevenueChange}
        loadedCost={totalCostBreakdown?.laborRate?.amount ?? new BigNumber(0)}
        totalHours={totalCostBreakdown?.totalBillableHours ?? new BigNumber(0)}
      />
      {!targetRoleCostBreakdown && (
        <div className={classes.noResourcesMessage}>
          <NoDataItem>
            <FormattedMessage id="roleUserBreakdownPage.noRoleBreakdownError" />
          </NoDataItem>
        </div>
      )}
      {targetRoleCostBreakdown && (
        <>
          <RoleSummaryTable
            activeCostOptimizationId={id}
            totalCostBreakdown={totalCostBreakdown}
            role={targetRoleCostBreakdown}
            roleUri={roleUri}
            margin={margin}
            utilization={utilization}
            editing={true}
            handleMarginChange={handleMarginChange}
            handleUtilizationChange={handleUtilizationChange}
          />
        </>
      )}

      {navigationBlock?.unblockRef && (
        <UploadDiscardDialog
          id={costOptimizationResult.id ?? ''}
          unblockRef={navigationBlock?.unblockRef}
        />
      )}
    </>
  );
};

export default RoleSummaryEditContent;
