import { ErrorHandler } from '@apollo/client/link/error';
import { ServerError, ServerParseError } from '@apollo/client';
import get from 'lodash.get';

type LogFunction = (arg: string) => void;

const RESPONSE_STATUS_HEADER = 'x-replicon-response-status';
const IP_FILTER_URI = 'urn:replicon:response-status:ip-filter-denied';

const isServerError = (err: unknown): err is ServerError => {
  return (
    err &&
    (err as ServerError).statusCode !== undefined &&
    (err as ServerError).response !== undefined &&
    (err as ServerParseError).bodyText === undefined
  );
};

const isServerParseError = (err: unknown): err is ServerParseError => {
  return err && (err as ServerParseError).bodyText !== undefined;
};

const handleUnauthorizedRedirect = (networkError: ServerError): void => {
  window.location.href = get(networkError, 'result.error.redirect');
};

const wasUserIpFiltered = (networkError: ServerParseError): boolean => {
  return (
    networkError.response.headers.get(RESPONSE_STATUS_HEADER) === IP_FILTER_URI
  );
};

const handleIpFilterRedirect = (networkError: ServerParseError): void => {
  // When a user is IP filtered, Envoy ships us an entire HTML blob which will redirect the user.
  // We can write that blob to the document.
  const content = networkError.bodyText;
  window.document.open();
  window.document.write(content);
  window.document.close();
};

export const unauthorizedHandlerMiddleware = (
  logger: LogFunction
): ErrorHandler => ({ networkError }): void => {
  if (networkError) {
    if (isServerError(networkError) && networkError.statusCode === 403) {
      if (get(networkError, 'result.error.redirect')) {
        handleUnauthorizedRedirect(networkError);
      } else {
        logger(`[Unhandled unauthorized error]: ${networkError}`);
      }
    } else if (
      isServerParseError(networkError) &&
      wasUserIpFiltered(networkError)
    ) {
      handleIpFilterRedirect(networkError);
    } else {
      logger(`[Network error]: ${networkError}`);
    }
  }
};
