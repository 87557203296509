import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useUploadPath } from 'fileUpload';
import { useHasCpoEditPermission } from 'common/hooks';
import { ReactComponent as HistoryEmptyStateIcon } from './HistoryEmptyStateIcon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.grey[800],
    display: 'flex',
    minHeight: 500,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    whiteSpace: 'nowrap',
    paddingBottom: 10
  },
  info: {
    display: 'flex',
    whiteSpace: 'nowrap'
  },
  buttonContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    paddingTop: 10
  },
  link: {
    ...theme.typography.body1,
    padding: 0,
    fontWeight: theme.typography.fontWeightBold
  },
  text: {
    paddingRight: 20
  }
}));

export const HistoryEmptyState: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const uploadPath = useUploadPath();
  const hasCpoEditPermission = useHasCpoEditPermission();

  const handleClick = useCallback(() => {
    history.replace(uploadPath);
  }, [history, uploadPath]);

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <div className={classes.title}>
          <FormattedMessage id="emptyState.historyTitle" />
        </div>
        <div className={classes.info}>
          <FormattedMessage id="emptyState.historyInfo1" />
        </div>
        {hasCpoEditPermission && (
          <>
            <div className={classes.info}>
              <FormattedMessage id="emptyState.historyInfo2" />
            </div>
            <div className={classes.buttonContainer}>
              <FormattedMessage id="emptyState.historyButtonInfo" />
              &nbsp;
              <Link
                className={classes.link}
                variant="body2"
                component="button"
                color="primary"
                onClick={handleClick}>
                <FormattedMessage id="emptyState.historyButton" />
              </Link>
            </div>
          </>
        )}
      </div>
      <HistoryEmptyStateIcon />
    </div>
  );
};

export default HistoryEmptyState;
