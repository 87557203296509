import { useMemo } from 'react';
import { Maybe, SmartBudgetResult } from 'generated-types';
import { TotalCostBreakdown } from 'common/types';

export const useTotalCostBreakdown = (
  result?: Maybe<SmartBudgetResult>
): Maybe<TotalCostBreakdown> => {
  const totalCostBreakdown = useMemo(() => {
    if (result) {
      return {
        billableCost: result?.billableCost,
        standardRate: result?.standardRate,
        laborRate: result?.laborRate,
        businessOverheadRate: result?.businessOverheadRate,
        nonBillableOverheadRate: result?.nonBillableOverheadRate,
        billingRate: result?.billingRate,
        utilization: result?.utilization,
        margin: result?.margin,
        totalBillableHours: result?.totalBillableHours,
        totalRevenue: result?.totalRevenue
      };
    }
  }, [result]);

  return totalCostBreakdown ?? null;
};
