import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Zoom from '@material-ui/core/Zoom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useIntl } from 'App/MessageProvider';
import { useDialogState } from 'common/hooks';

import { AddPlanDialog } from './AddPlanDialog';

const useStyles = makeStyles(theme => ({
  addBtn: {
    position: 'fixed',
    right: theme.spacing(3),
    bottom: theme.spacing(3)
  },
  root: {
    position: 'fixed',
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    zIndex: 12,
    width: 'auto',
    height: theme.spacing(7),
    minWidth: theme.spacing(7),
    borderRadius: theme.spacing(7 / 2),
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  },
  extended: {
    '& $textLabel': {
      marginLeft: theme.spacing(1),
      opacity: 1
    }
  },

  textLabel: {
    margin: 0,
    width: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    opacity: 0,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  }
}));

export const AddPlanButton: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const { open, openDialog, closeDialog } = useDialogState();
  const visible = true;
  const [variant, setVariant] = useState('extended');
  const ref = React.useRef<HTMLButtonElement>(null);
  const textLabelRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (textLabelRef.current) textLabelRef.current.style.width = '0';
    const requestID = requestAnimationFrame(() => {
      if (textLabelRef.current && variant === 'extended') {
        textLabelRef.current.style.width = `${textLabelRef.current.scrollWidth}px`;
      }
    });

    return (): void => {
      cancelAnimationFrame(requestID);
    };
  }, [variant, visible]);

  useEffect(() => {
    const scrollParent = document.getElementById('mainContent');
    let lastScroll = scrollParent?.scrollTop ?? 0;
    let scrollPivot = lastScroll;
    let down = true;

    const handleScroll = (): void => {
      const currentScroll = scrollParent?.scrollTop ?? 0;
      const scrollDiff = currentScroll - lastScroll;

      if ((down && scrollDiff < 0) || (!down && scrollDiff > 0)) {
        scrollPivot = lastScroll;
        down = scrollDiff > 0;
      }

      const pivotDiff = Math.abs(currentScroll - scrollPivot);
      if (pivotDiff > 32) {
        setVariant(lastVariant => {
          if (down && lastVariant !== 'round') return 'round';

          if (!down && lastVariant !== 'extended') return 'extended';

          return lastVariant;
        });
      }
      lastScroll = currentScroll;
    };

    document.addEventListener('scroll', handleScroll, true);

    return (): void => document.removeEventListener('scroll', handleScroll);
  }, [variant, visible]);

  return (
    <>
      <Zoom
        in={visible}
        timeout={{
          enter: theme.transitions.duration.enteringScreen,
          exit: theme.transitions.duration.leavingScreen
        }}
        unmountOnExit>
        <Fab
          ref={ref}
          color="primary"
          variant={variant === 'extended' ? 'extended' : 'round'}
          onClick={openDialog}
          data-qe-id="AddPlanButton"
          aria-label={intl.formatMessage({
            id: 'button.addSmartBudget'
          })}
          classes={{
            root: classes.root,
            extended: classes.extended
          }}>
          <AddIcon />
          <span ref={textLabelRef} className={classes.textLabel}>
            {intl.formatMessage({
              id: 'button.addSmartBudget'
            })}
          </span>
        </Fab>
      </Zoom>
      {open && <AddPlanDialog open={open} closeDialog={closeDialog} />}
    </>
  );
};
