import React, { useState, useCallback } from 'react';
import {
  Grid,
  makeStyles,
  Checkbox,
  FormControlLabel,
  Divider,
  Typography
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { FormContextValues, NestDataObject, FieldError } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { GetOverlappingPlansQuery, SmartBudgetResult } from 'generated-types';
import { Error } from './types';
import PlanSearchDropdown from './PlanSearchDropdown';
import { PlanSearchPartial } from './types';
import { CreatePlan } from './hooks';
import { AddPlan } from './AddPlan';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    padding: theme.spacing(2, 3, 1)
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.grey[500]
  },
  cancelBtn: {},
  dialogContent: {
    display: 'flex'
  },
  row: {
    display: 'flex',
    margin: theme.spacing(1, 0),
    justifyContent: 'space-between'
  },
  leftField: {
    width: '95%'
  },
  rightField: {
    width: '100%'
  },
  actions: {
    padding: theme.spacing(1, 2)
  },
  checkboxes: {
    display: 'grid',
    justifyContent: 'flex-start'
  },
  divider: {
    width: '100%'
  },
  error: {
    color: theme.palette.error.main,
    padding: theme.spacing(0.5, 0),
    fontSize: '0.95rem'
  },
  info: {
    ...theme.typography.body1,
    paddingLeft: 8
  }
}));

type CopyPlanProps = {
  register: FormContextValues['register'];
  errors: NestDataObject<CreatePlan, FieldError>;
  setValue: FormContextValues['setValue'];
  getValues: FormContextValues['getValues'];
  onStartDateChange: (date: DateTime | null) => void;
  onEndDateChange: (date: DateTime | null) => void;
  messages: Record<string, string | undefined>;
  triggerValidation: FormContextValues['triggerValidation'];
  hasOverlappingPlans: boolean;
  overlappingPlans: GetOverlappingPlansQuery | undefined;
  createPlanErrors: Error[];
};
export const CopyPlan: React.FC<CopyPlanProps> = ({
  register,
  errors,
  getValues,
  setValue,
  onStartDateChange,
  onEndDateChange,
  messages,
  triggerValidation,
  hasOverlappingPlans,
  overlappingPlans,
  createPlanErrors
}: CopyPlanProps) => {
  const classes = useStyles();
  const [sourcePlan, setSourcePlan] = useState<PlanSearchPartial>();
  const [utilizationChecked, setUtilizationChecked] = useState(true);
  const [ratesChecked, setRatesChecked] = useState(true);
  const { startDate, endDate } = getValues();

  const setCopyPlanId = useCallback(
    (smartBudgetResult: SmartBudgetResult) => {
      setValue('margin', smartBudgetResult.margin?.toFixed(2) ?? undefined);
      setValue(
        'utilization',
        smartBudgetResult.utilization?.toFixed(2) ?? undefined
      );

      setSourcePlan(smartBudgetResult);
      setValue('smartBudgetResultId', smartBudgetResult.id);
      triggerValidation('smartBudgetResultId');
    },
    [setValue, triggerValidation]
  );

  const handleUtilizationCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: boolean): void => {
      setUtilizationChecked(value);
    },
    [setUtilizationChecked]
  );

  const handleRatesCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: boolean): void => {
      setRatesChecked(value);
    },
    [setRatesChecked]
  );
  return (
    <Grid className={classes.dialogContent} container>
      <Grid item xs={12} className={classes.row}>
        <PlanSearchDropdown
          setSelectedPlan={setCopyPlanId}
          selectedPlan={sourcePlan}
        />
        {errors.smartBudgetResultId && (
          <Typography className={classes.error}>
            <FormattedMessage id="addPlanDialog.copyPlanError" />
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} className={classes.checkboxes}>
        <FormControlLabel
          control={
            <Checkbox
              id="copyRates"
              name="copyRates"
              onChange={handleRatesCheckbox}
              defaultChecked
              inputRef={register({
                validate: () => {
                  return getValues().copyRates || getValues().copyUtilization;
                }
              })}
              disabled={!getValues().smartBudgetResultId}
              size="small"
            />
          }
          label={<FormattedMessage id="addPlanDialog.copyRates" />}
        />
        <FormControlLabel
          control={
            <Checkbox
              id="copyUtilization"
              name="copyUtilization"
              onChange={handleUtilizationCheckbox}
              defaultChecked
              inputRef={register({
                validate: () => {
                  return getValues().copyRates || getValues().copyUtilization;
                }
              })}
              disabled={!getValues().smartBudgetResultId}
              size="small"
            />
          }
          label={<FormattedMessage id="addPlanDialog.copyUtilization" />}
        />
        {errors.copyRates && errors.copyUtilization && (
          <Typography className={classes.error}>
            <FormattedMessage id="addPlanDialog.checkBoxError" />
          </Typography>
        )}
      </Grid>
      <Divider className={classes.divider} />
      <AddPlan
        register={register}
        errors={errors}
        onEndDateChange={onEndDateChange}
        onStartDateChange={onStartDateChange}
        messages={messages}
        hasOverlappingPlans={hasOverlappingPlans}
        startDate={startDate}
        endDate={endDate}
        overlappingPlans={overlappingPlans}
        createPlanErrors={createPlanErrors}
        copyUtilization={utilizationChecked}
        copyRates={ratesChecked}
        sourcePlan={sourcePlan}
      />
    </Grid>
  );
};
