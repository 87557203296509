import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';

export const DEFAULT_UTILIZATION = RoleBillingRateCalculator.DEFAULT_UTILIZATION.multipliedBy(
  100
);
export const DEFAULT_MARGIN = RoleBillingRateCalculator.DEFAULT_MARGIN.multipliedBy(
  100
);

export const DECIMAL_PLACES = 2;
