import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableFooterCell,
  TableFooterRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow
} from '@replicon/react-data-table';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(2)
  },
  firstCell: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderWidth: theme.spacing(0, 0.125, 0.125, 0)
  },
  row: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderWidth: theme.spacing(0.125)
  }
}));

export const BillableResourceTableSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.title}>
        <Skeleton width="30%" />
      </div>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell align="center" colSpan={3}>
              <Skeleton width="20%" />
            </TableHeaderCell>
            <TableHeaderCell align="center" colSpan={5}>
              <Skeleton width="20%" />
            </TableHeaderCell>
            <TableHeaderCell align="center" colSpan={2}>
              <Skeleton width="50%" />
            </TableHeaderCell>
            <TableHeaderCell align="center" colSpan={2}>
              <Skeleton width="50%" />
            </TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {[...Array(7)].map((__: number, rowIndex: number) => (
            <TableRow key={`Row_${rowIndex}`} className={classes.row}>
              {[...Array(12)].map((___: number, cellIndex: number) => (
                <TableCell key={`Cell_${rowIndex}_${cellIndex}`} align="right">
                  <Skeleton width="70%" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableFooterRow>
            {[...Array(12)].map((___: number, cellIndex: number) => (
              <TableFooterCell key={`Footer_${cellIndex}`} align="right">
                <Skeleton width="70%" />
              </TableFooterCell>
            ))}
          </TableFooterRow>
        </TableFooter>
      </Table>
    </>
  );
};
