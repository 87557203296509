import React from 'react';
import { makeStyles, Theme, Chip } from '@material-ui/core';
import { PlanStatus as PlanStatusEnum } from 'generated-types';
import { useIntl } from 'App/MessageProvider';

export const PlanStatusColors = {
  DRAFT: '#B8B5B7',
  UPCOMING: '#FFA127',
  ACTIVE: '#00CD8C',
  EXPIRED: '#AA0414',
  ERROR: '#fcd4d0'
};

type PlanStatusProps = {
  status?: keyof typeof PlanStatusColors;
};

const useStyles = makeStyles<Theme, PlanStatusProps>(theme => ({
  root: {
    backgroundColor: ({ status }) => PlanStatusColors[status ?? 'ERROR'],
    color: ({ status }) =>
      status === PlanStatusEnum.Expired ? '#ffffff' : 'unset',
    height: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(0, 1),
    textTransform: 'none'
  },
  label: {
    padding: theme.spacing(0, 1.25)
  }
}));

const PlanStatusChip: React.FC<PlanStatusProps> = props => {
  const intl = useIntl();
  const classes = useStyles(props);
  const label = intl.formatMessage({ id: `planStatus.${props.status}` });

  return <Chip className={classes.root} label={label} />;
};

export default PlanStatusChip;
