import React, { useMemo, useCallback } from 'react';
import { useApolloClient, ApolloClient } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import BigNumber from 'bignumber.js';
import { FilledSearchDropdown } from 'common/components/SearchDropdown';
import { SearchPlansDocument, SearchPlansQuery } from 'generated-types';
import { PlanSearchPartial } from './types';

type Option = {
  id: string;
  label: string;
  margin: BigNumber | null | undefined;
  utilization: BigNumber | null | undefined;
};

type PlanSearchDropdownProps = {
  selectedPlan?: PlanSearchPartial;
  setSelectedPlan: (plan: PlanSearchPartial) => void;
};

export const fetchDropdownOptions = (
  apolloClient: ApolloClient<unknown>
) => async (searchText = ''): Promise<Option[]> => {
  const { data } = await apolloClient.query<SearchPlansQuery>({
    query: SearchPlansDocument,
    variables: {
      text: searchText.length ? searchText : undefined
    },
    fetchPolicy: 'no-cache'
  });

  return (
    data?.smartBudgets.edges.map(e => {
      return {
        id: e.node.id,
        label: e.node.name ?? '',
        margin: e.node.margin,
        utilization: e.node.utilization
      };
    }) ?? []
  );
};

const PlanSearchDropdown: React.FC<PlanSearchDropdownProps> = props => {
  const { selectedPlan, setSelectedPlan } = props;
  const apolloClient = useApolloClient();

  const selectedValue: Option | undefined = useMemo(
    () =>
      selectedPlan
        ? {
            id: selectedPlan.id,
            label: selectedPlan.name ?? '',
            margin: selectedPlan.margin ?? new BigNumber(0),
            utilization: selectedPlan.utilization ?? new BigNumber(0)
          }
        : undefined,
    [selectedPlan]
  );

  const onValueSelected = useCallback(
    (selected: Option) => {
      setSelectedPlan({
        id: selected.id,
        name: selected.label,
        margin: selected.margin,
        utilization: selected.utilization
      });
    },
    [setSelectedPlan]
  );

  const fetchOptions = useCallback(fetchDropdownOptions(apolloClient), [
    apolloClient
  ]);

  return (
    <FilledSearchDropdown
      value={selectedValue}
      fetchOptions={fetchOptions}
      placeholder={<FormattedMessage id="addPlanDialog.copyPlan" />}
      onChange={onValueSelected}
      ariaLabel="Search for Plan"
      attachMenuToRoot
      closeMenuOnSelect
      noOptionsMessage="No plans found"
      showLabel
    />
  );
};

export default PlanSearchDropdown;
