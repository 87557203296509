import { ChangeEvent, useCallback, useEffect } from 'react';
import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import BigNumber from 'bignumber.js';
import { FieldValues } from 'react-hook-form';
import {
  BillingByRoleData,
  FormData,
  RoleData,
  ColumnOnChange
} from 'common/types';
import { isValidNumber, clamp } from 'util/number';
import {
  calculateGlobalMargin as calculateMargin,
  DECIMAL_PLACES
} from 'rateCard/helpers';

import { RateCardModel, defaultRateCardModel } from '../model';
import { getModelState, getFormState } from '../helpers/getModelState';

const MIN_MARGIN = 1;
const MAX_MARGIN = 99.99;
const MIN_UTILIZATION = 0.01;
const MAX_UTILIZATION = 100;
const roleBillingRateCalculator = new RoleBillingRateCalculator();

const getValue = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
): string => (typeof event === 'string' ? event : event.target.value);

export const calculateTotalRevenue = (
  billingData: BillingByRoleData[],
  values: FieldValues
): BigNumber => {
  return billingData
    .map(data => new BigNumber(values[data.id].revenue))
    .reduce((acc, rev) => acc.plus(rev), new BigNumber(0));
};

export const calculateGlobalMargin = (
  billingData: BillingByRoleData[],
  values: FieldValues,
  totalCost: BigNumber,
  handleTotalRevenueChange?: (totalRevenue: BigNumber) => void
): BigNumber => {
  const totalRevenue = calculateTotalRevenue(billingData, values);
  handleTotalRevenueChange?.(totalRevenue);
  const totalHours = billingData.reduce(
    (total, hours) => total.plus(hours?.totalHours ?? new BigNumber(0)),
    new BigNumber(0)
  );
  const loadedCost = totalCost.dividedBy(totalHours);

  return calculateMargin(totalRevenue, loadedCost, totalHours);
};

export const setGlobalMargin = (
  billingData: BillingByRoleData[],
  values: FieldValues,
  totalCost: BigNumber,
  handleMarginChange: (margin: string, updateRefs: boolean) => void,
  handleTotalRevenueChange?: (totalRevenue: BigNumber) => void
): void => {
  handleMarginChange(
    calculateGlobalMargin(
      billingData,
      values,
      totalCost,
      handleTotalRevenueChange
    ).toString(),
    false
  );
};

export const calculateGlobalUtilization = (
  billingData: BillingByRoleData[],
  values: FieldValues
): BigNumber => {
  const totalHours = billingData
    .map(billing => ({
      workHours: billing.totalHours,
      likelyHours: billing.totalHours.multipliedBy(
        new BigNumber(values[billing.id].utilization).dividedBy(100)
      )
    }))
    .reduce(
      (acc, value) => ({
        totalHours: acc.totalHours.plus(value.workHours),
        totalLikelyHours: acc.totalLikelyHours.plus(value.likelyHours)
      }),
      {
        totalHours: new BigNumber(0),
        totalLikelyHours: new BigNumber(0)
      }
    );

  return roleBillingRateCalculator
    .calculateGlobalUtilization(
      totalHours.totalLikelyHours,
      totalHours.totalHours
    )
    .multipliedBy(100);
};

export const setGlobalUtilization = (
  billingData: BillingByRoleData[],
  values: FieldValues,
  handleUtilizationChange: (utilization: string, updateRefs: boolean) => void
): void => {
  handleUtilizationChange(
    calculateGlobalUtilization(billingData, values).toString(),
    false
  );
};

export const isValueModified = (
  id: string,
  key: keyof FormData,
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string,
  getValues: () => RoleData
): boolean => {
  const value = getValue(event);
  const formValue = getValues()[id][key];
  if (formValue === undefined) {
    return true;
  }

  if (isValidNumber(value)) {
    const previousValue = new BigNumber(formValue).decimalPlaces(
      DECIMAL_PLACES
    );
    const newValue = new BigNumber(value);
    return !previousValue.isEqualTo(newValue);
  }
  return true;
};

type RoleFieldEventHandler = (
  key: keyof FormData,
  id: string
) => (
  event: string | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => void;

const handleRateCardFieldChange = (
  billingData: BillingByRoleData[],
  setValue: (id: string, value: FormData) => void,
  getValues: () => RoleData,
  setRoleData: (value: FieldValues) => void,
  recalculateGlobalValues: () => void,
  rateCardModel: RateCardModel,
  valueTransformer?: (v: BigNumber) => BigNumber,
  lockedColumn?: keyof FormData
): RoleFieldEventHandler => {
  return (
    key: keyof FormData,
    id: string
  ): ((
    event: string | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void) => {
    const model = new RateCardModel(rateCardModel);
    const handler = (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
    ): void => {
      const roleData = billingData.find(data => data.id === id);
      if (!roleData) {
        return;
      }

      const currentFormState = getValues()[id];
      if (!isValueModified(id, key, event, getValues)) {
        return;
      }

      const newValue = new BigNumber(getValue(event));
      if (newValue.isNaN()) {
        return;
      }

      const transformedValue = valueTransformer
        ? valueTransformer(newValue)
        : newValue;

      const newState = model.setColumnValue(
        getModelState(roleData, currentFormState),
        key,
        transformedValue,
        {
          lockedColumns: lockedColumn ? [lockedColumn] : undefined
        }
      );

      setValue(id, {
        ...currentFormState,
        ...getFormState(newState)
      });

      setRoleData(getValues());
      recalculateGlobalValues();
    };
    return handler;
  };
};

export type BillingEditHandlers = {
  onUtilizationChangeHandler: ColumnOnChange;
  onMarginChangeHandler: ColumnOnChange;
  onBillingRateChangeHandler: ColumnOnChange;
  onRevenueChangeHandler: ColumnOnChange;
};

export const useBillingEditHandlers = (
  billingData: BillingByRoleData[],
  setValue: (id: string, value: FormData) => void,
  getValues: () => RoleData,
  totalCost: BigNumber,
  handleMarginChange: (margin: string, updateRefs: boolean) => void,
  handleUtilizationChange: (utilization: string, updateRefs: boolean) => void,
  setRoleData: (value: FieldValues) => void,
  handleTotalRevenueChange?: (totalRevenue: BigNumber) => void,
  lockedColumn?: keyof FormData,
  rateCardModel = defaultRateCardModel
): BillingEditHandlers => {
  const recalculateGlobalValues = useCallback(() => {
    if (billingData.length) {
      handleUtilizationChange(
        calculateGlobalUtilization(billingData, getValues()).toString(),
        false
      );
      handleMarginChange(
        calculateGlobalMargin(
          billingData,
          getValues(),
          totalCost,
          handleTotalRevenueChange
        ).toString(),
        false
      );
    }
  }, [
    billingData,
    getValues,
    handleMarginChange,
    handleTotalRevenueChange,
    handleUtilizationChange,
    totalCost
  ]);

  const makeHandler = useCallback(
    (
      transformationFunction?: (v: BigNumber) => BigNumber
    ): RoleFieldEventHandler =>
      handleRateCardFieldChange(
        billingData,
        setValue,
        getValues,
        setRoleData,
        recalculateGlobalValues,
        rateCardModel,
        transformationFunction,
        lockedColumn
      ),
    [
      billingData,
      getValues,
      lockedColumn,
      recalculateGlobalValues,
      setRoleData,
      setValue,
      rateCardModel
    ]
  );

  const onUtilizationChange = useCallback(
    makeHandler(v => {
      return clamp(
        v,
        new BigNumber(MIN_UTILIZATION),
        new BigNumber(MAX_UTILIZATION)
      ).dividedBy(100);
    }),
    [makeHandler]
  );
  const onMarginChange = useCallback(
    makeHandler(v => {
      return clamp(
        v,
        new BigNumber(MIN_MARGIN),
        new BigNumber(MAX_MARGIN)
      ).dividedBy(100);
    }),
    [makeHandler]
  );
  const onBillingRateChange = useCallback(makeHandler(), [makeHandler]);
  const onRevenueChange = useCallback(makeHandler(), [makeHandler]);

  useEffect(() => {
    recalculateGlobalValues();
  }, [billingData, recalculateGlobalValues]);

  return {
    onUtilizationChangeHandler: onUtilizationChange,
    onMarginChangeHandler: onMarginChange,
    onBillingRateChangeHandler: onBillingRateChange,
    onRevenueChangeHandler: onRevenueChange
  };
};
