import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(1)
  }
}));

type RoleNameProps = {
  displayText: string;
};
export const RoleName: React.FC<RoleNameProps> = ({
  displayText
}: RoleNameProps) => {
  const classes = useStyles();
  return <span className={classes.root}>{displayText}</span>;
};
