import React, { useMemo, useCallback } from 'react';
import { HeaderProps } from 'react-table';
import { makeStyles, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { BillingByRoleData } from 'common/types';
import { useLockedColumnContext } from 'common/context';
import LockButton from 'rateCard/components/RoleDataTable/LockButton';
import { usePlanEditContext } from 'plans/plan/context';
import { useFeatureFlag } from 'common/hooks';
import { BillableColumnInstance } from './BillableRoleTable';

const useStyles = makeStyles(theme => ({
  lockIcon: {
    margin: theme.spacing(-0.5, 1, -0.5, -0.5)
  },
  label: {
    ...theme.typography.body2,
    color: (prop: { isLocked: boolean }) =>
      !prop.isLocked ? theme.palette.grey[600] : theme.palette.grey[400],
    fontWeight: theme.typography.fontWeightMedium
  },
  button: {
    color: (prop: { isLocked: boolean }) =>
      !prop.isLocked ? theme.palette.grey[600] : theme.palette.grey[400],
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    marginRight: theme.spacing(-0.875),
    padding: theme.spacing(0)
  },
  text: {
    width: 75,
    marginLeft: 'auto',
    textAlign: 'right',
    overflowX: 'hidden'
  }
}));

type BillingRateHeaderProps<
  T extends BillingByRoleData = BillingByRoleData
> = HeaderProps<T> & {
  column: BillableColumnInstance<T>;
};

export const BillingRateHeader: React.FC<BillingRateHeaderProps> = ({
  column: { id, i18nKey }
}: BillingRateHeaderProps) => {
  const { lockedColumn, setLockedColumn } = useLockedColumnContext();
  const isLocked = useMemo(() => Boolean(lockedColumn === id), [
    id,
    lockedColumn
  ]);
  const { canEdit } = usePlanEditContext();
  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const classes = useStyles({ isLocked: isLocked && canEdit && !cpoNewUx });
  const onClickHandler = useCallback(
    () => setLockedColumn(isLocked ? 'margin' : 'billingRate'),
    [isLocked, setLockedColumn]
  );

  const lockBtn = useMemo(
    () => (
      <LockButton
        className={classes.lockIcon}
        locked={isLocked}
        lockKey={id}
        lockNext={'margin'}
        onClick={setLockedColumn}
      />
    ),
    [classes.lockIcon, id, isLocked, setLockedColumn]
  );
  const label = useMemo(
    () => (
      <div className={classes.text}>
        <FormattedMessage id={i18nKey} />
      </div>
    ),
    [classes.text, i18nKey]
  );

  if (!canEdit || cpoNewUx) {
    return label;
  }
  return (
    <Button
      aria-describedby={id}
      className={classes.button}
      variant="text"
      color="default"
      onClick={onClickHandler}
      startIcon={lockBtn}>
      {label}
    </Button>
  );
};
