import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { useDebounce } from 'react-use';
import { Popover } from '@material-ui/core';
import { BillingSlider } from 'rateCard/components/UtilizationMarginCard/BillingSlider';
import { useLockedColumnContext } from 'common/context';
import { useMessage } from 'App/MessageProvider';
import { useTemporaryState } from 'common/hooks';
import { Label, NumberEditField, NumberValue } from 'common/components';

const UPDATE_DEBOUNCE_TIME = 25;

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%'
  },
  popper: {
    zIndex: 100,
    width: theme.spacing(100)
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#6A6A6A'
  },
  value: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold
  }
}));

const useBillingSliderStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5),
    width: theme.spacing(30),
    height: theme.spacing(6)
  }
}));

const useSliderStyles = makeStyles(theme => ({
  root: {
    color: '#25D293',
    padding: 0
  },
  track: {
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5)
  },
  rail: {
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5)
  },
  thumb: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginTop: theme.spacing(-0.75),
    '&:hover': {
      boxShadow: 'none'
    }
  }
}));

const useInputStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(9)
  },
  underline: {
    '&$disabled:before': {
      borderBottomStyle: 'solid'
    }
  },
  disabled: {
    color: theme.palette.grey[900]
  },
  input: {
    width: theme.spacing(5.75),
    padding: 0
  }
}));

export type GlobalSliderProps = {
  labelId: string;
  value: BigNumber;
  readOnly: boolean;
  onChangeHandler: (value: string) => void;
};

export const GlobalPopoverSlider: React.FC<GlobalSliderProps> = ({
  labelId,
  value,
  readOnly,
  onChangeHandler
}: GlobalSliderProps) => {
  const classes = useStyles();
  const inputStyles = useInputStyles();
  const billingSliderStyles = useBillingSliderStyles();
  const sliderStyles = useSliderStyles();
  const [localValue, setLocalValue, discardLocalValue] = useTemporaryState(
    value
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    !readOnly && setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { open, id } = useMemo(() => {
    const open = Boolean(anchorEl);
    return { open, id: open ? labelId : undefined };
  }, [anchorEl, labelId]);

  const { lockedColumn } = useLockedColumnContext();
  const label = useMessage(labelId);
  const isLocked = useMemo(() => lockedColumn === label, [label, lockedColumn]);

  useDebounce(
    () => {
      if (localValue && !value.isEqualTo(localValue)) {
        onChangeHandler(localValue.toString());
        discardLocalValue();
      }
    },
    UPDATE_DEBOUNCE_TIME,
    [localValue]
  );
  const sliderOnChange = useCallback(v => setLocalValue(new BigNumber(v)), [
    setLocalValue
  ]);

  return (
    <>
      <div aria-describedby={id} onMouseEnter={handleClick}>
        {readOnly ? (
          <Label className={classes.value}>
            <NumberValue value={value?.toNumber() ?? 0} fixedDecimalScale />
            &nbsp;%
          </Label>
        ) : (
          <NumberEditField
            classes={inputStyles}
            value={value.toString()}
            suffix="%"
            disabled
            onClick={handleClick}
          />
        )}
      </div>
      {!readOnly && (
        <Popover
          className={classes.popper}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}>
          <BillingSlider
            classes={billingSliderStyles}
            sliderClasses={sliderStyles}
            value={value}
            labelId={labelId}
            onChange={sliderOnChange}
            max={99}
            disableSlider={isLocked}
            showSlider={true}
            disabled={isLocked}
          />
        </Popover>
      )}
    </>
  );
};
