import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AttachmentSharpIcon from '@material-ui/icons/AttachmentSharp';
import { Button, Label } from 'common/components';

const useStyles = makeStyles(theme => ({
  dropzoneContent: {
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    alignItems: 'center'
  },
  padding: {
    padding: theme.spacing(1, 0, 1.5, 0)
  }
}));

const attachmentIcon = <AttachmentSharpIcon />;
export interface FileDragAreaContentProps {
  rejectedFiles: File[];
  isFileTooLarge: boolean;
}

const FileDragAreaContent: React.FC<FileDragAreaContentProps> = ({
  rejectedFiles,
  isFileTooLarge
}: FileDragAreaContentProps) => {
  const classes = useStyles();

  return (
    <div className={classes.dropzoneContent}>
      <Label
        labelId="fileUploadDialog.dragFileHere"
        variant="h6"
        color="textPrimary"
      />
      <Label
        labelId="fileUploadDialog.or"
        variant="caption"
        color="textSecondary"
        className={classes.padding}
      />
      <Button
        labelId="fileUploadDialog.chooseFileToUpload"
        startIcon={attachmentIcon}
        variant="contained"
        color="primary"
      />

      {rejectedFiles.length > 0 ? (
        isFileTooLarge ? (
          <Label
            labelId="fileUploadDialog.fileExceedMaxSize"
            variant="caption"
            color="error"
            className={classes.padding}
          />
        ) : (
          <Label
            labelId="fileUploadDialog.acceptedFormats"
            variant="caption"
            color="error"
            className={classes.padding}
          />
        )
      ) : (
        <Label
          labelId="fileUploadDialog.maxFileSizeMessage"
          variant="caption"
          color="textSecondary"
          className={classes.padding}
        />
      )}
    </div>
  );
};

export default FileDragAreaContent;
