import React, { ChangeEvent, useCallback, useMemo } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useMessage } from 'App/MessageProvider';
import { TableColumnInstance } from 'common/types';

const useStyles = makeStyles(theme => ({
  title: {
    minHeight: theme.spacing(3.625),
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

type GroupColumnProps<T> = {
  column: TableColumnInstance<T>;
  enabledColumns: Set<string>;
  onChange: (id: string, checked: boolean) => void;
};

export const GroupColumn: <T>(p: GroupColumnProps<T>) => React.ReactElement = ({
  column,
  enabledColumns,
  onChange
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset">
      <FormLabel className={classes.title} component="legend">
        <FormattedMessage id={column.i18nKey} />
      </FormLabel>
      <FormGroup>
        {column.columns.map(leafColumn => (
          <GroupCheckItem
            key={(leafColumn as TableColumnInstance).i18nKey}
            column={leafColumn as TableColumnInstance}
            checked={enabledColumns.has(leafColumn.id)}
            onChange={onChange}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

type GroupCheckItemProps<T> = {
  column: TableColumnInstance<T>;
  checked: boolean;
  onChange: (id: string, checked: boolean) => void;
};

const useItemStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(0.5, 0) }
}));

export const GroupCheckItem: <T>(
  p: GroupCheckItemProps<T>
) => React.ReactElement = ({ column, checked, onChange }) => {
  const classes = useItemStyles();
  const onClick = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) =>
      onChange(column.id as string, checked),
    [column.id, onChange]
  );
  const checkbox = useMemo(
    () => <Checkbox checked={checked} onChange={onClick} color="primary" />,
    [checked, onClick]
  );
  const label = useMessage(column.i18nKey);
  return (
    <FormControlLabel
      className={classes.root}
      disabled={column.required}
      control={checkbox}
      label={label}
    />
  );
};
