/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SimpleSelect, { components as $components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { emphasize, fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import ClearIcon from '@material-ui/icons/ClearSharp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownSharp';
import { FormattedMessage } from 'react-intl';
import { NoDataItem } from '../NoDataItem';
// import useReloadOnRemountKey from './useReloadOnRemountKey';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    height: 'auto',
    display: 'flex'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  avatar: {},
  chip: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[400]
        : theme.palette.grey[700]
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  clearIndicatorOverride: {
    color: theme.palette.text.secondary,
    display: 'flex',
    cursor: 'pointer',
    '& svg': {
      fontSize: theme.typography.body1.fontSize
    }
  },
  dropdownIndicatorOverride: {
    color: theme.palette.text.secondary,
    display: 'flex',
    cursor: 'pointer'
  },
  indicatorFocused: {
    color: fade(theme.palette.common.black, 0.64)
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  },
  hasMore: {
    ...theme.typography.caption,
    fontStyle: 'italic',
    color: theme.palette.text.hint,
    padding: `0px ${theme.spacing(1)}px`
  }
}));

type NoOptionsMessageProps = {
  children: any;
  innerProps: any;
  selectProps: any;
};
const NoOptionsMessage: React.FC<NoOptionsMessageProps> = ({
  children,
  innerProps,
  selectProps
}: NoOptionsMessageProps) => (
  <div className={selectProps.classes.noOptionsMessage} {...innerProps}>
    <NoDataItem>{children}</NoDataItem>
  </div>
);

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

type InputComponentProps = {
  inputRef: any;
  props: any;
};
const inputComponent: React.FC<InputComponentProps> = ({
  inputRef,
  ...props
}: InputComponentProps) => <div ref={inputRef} {...props} />;

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

type ControlProps = {
  children: any;
  innerProps: any;
  innerRef: any;
  selectProps: any;
  isMulti: any;
  isFocused: any;
  hasValue: any;
};
const Control: React.FC<ControlProps> = ({
  children,
  innerProps,
  innerRef,
  selectProps: {
    classes,
    TextFieldProps = {
      variant: 'standard'
    },
    label,
    dataQeId,
    placeholder,
    value
  },
  isMulti,
  isFocused,
  hasValue
}) => {
  const inputProps = useMemo(
    () => ({
      inputComponent,
      inputProps: {
        className: classes.input,
        ref: innerRef,
        children,
        placeholder,
        ...innerProps
      }
    }),
    [classes.input, innerRef, children, placeholder, innerProps]
  );

  const hasNonNullValue =
    hasValue &&
    (isMulti ? value.length > 0 : Boolean(value.value || value.label));

  const textFieldProps = {
    ...TextFieldProps,
    label: label || TextFieldProps.label,
    InputLabelProps: {
      ...TextFieldProps.InputLabelProps,
      shrink: isFocused || hasNonNullValue
    }
  };

  return (
    <TextField
      data-qe-id={dataQeId}
      fullWidth
      InputProps={inputProps}
      {...textFieldProps}
    />
  );
};

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired
};

const useOptionStyles = makeStyles(theme => ({
  root: {
    fontWeight: 400
  },
  selected: { fontWeight: 500 },
  noneOption: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: theme.typography.fontWeightRegular
  }
}));

type OptionProps = {
  children: any;
  innerProps: any;
  innerRef: any;
  isFocused: any;
  isSelected: any;
  isDisabled: any;
  data: any;
};
const Option: React.FC<OptionProps> = ({
  children,
  innerProps,
  innerRef,
  isFocused,
  isSelected,
  isDisabled,
  data
}: OptionProps) => {
  const classes = useOptionStyles();

  const isNoneOption = data && data.value === null;
  const className = classNames(
    classes.root,
    isSelected && classes.selected,
    isNoneOption && classes.noneOption
  );

  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      disabled={isDisabled}
      component="div"
      className={className}
      {...innerProps}>
      {children}
    </MenuItem>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};

type PlaceHolderProps = {
  children: any;
  innerProps: any;
  selectProps: any;
};
const Placeholder: React.FC<PlaceHolderProps> = ({
  children,
  innerProps,
  selectProps
}: PlaceHolderProps) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.placeholder}
    {...innerProps}>
    {children}
  </Typography>
);

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

type SingleValueProps = {
  children: any;
  innerProps: any;
  selectProps: any;
};
export const SingleValue: React.FC<SingleValueProps> = ({
  children,
  innerProps,
  selectProps
}: SingleValueProps) => (
  <Typography className={selectProps.classes.singleValue} {...innerProps}>
    {children}
  </Typography>
);

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

type ValueContainerProps = {
  children: any;
  selectProps: any;
};
function ValueContainer({ children, selectProps }: ValueContainerProps) {
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

type MultiValueProps = {
  children: any;
  isFocused: any;
  removeProps: any;
  selectProps: any;
};
const MultiValue: React.FC<MultiValueProps> = ({
  children,
  isFocused,
  removeProps,
  selectProps
}: MultiValueProps) => {
  const deleteIcon = useMemo(() => <ClearIcon {...removeProps} />, [
    removeProps
  ]);

  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused
      })}
      onDelete={removeProps.onClick}
      deleteIcon={deleteIcon}
    />
  );
};

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
};

type MenuProps = {
  children: any;
  innerProps: any;
  selectProps: any;
};
const Menu: React.FC<MenuProps> = ({
  children,
  innerProps,
  selectProps
}: MenuProps) => (
  <Paper square className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
};

type DropdownIndicatorProps = {
  className: any;
  innerProps: any;
  selectProps: any;
  isFocused: any;
};
const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({
  className,
  innerProps,
  selectProps,
  isFocused
}: DropdownIndicatorProps) => (
  <div
    {...innerProps}
    className={classNames(
      selectProps.classes.dropdownIndicatorOverride,
      selectProps.classes.dropdownIndicator,
      {
        [selectProps.classes.indicatorFocused]: isFocused
      },
      className
    )}>
    <ArrowDropDownIcon />
  </div>
);

DropdownIndicator.propTypes = {
  className: PropTypes.string,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool
};

type ClearIndicatorProps = {
  className: any;
  innerProps: any;
  selectProps: any;
  isFocused: any;
};
const ClearIndicator: React.FC<ClearIndicatorProps> = ({
  className,
  innerProps,
  selectProps,
  isFocused
}: ClearIndicatorProps) => (
  <div
    {...innerProps}
    className={classNames(
      selectProps.classes.clearIndicatorOverride,
      selectProps.classes.clearIndicator,
      {
        [selectProps.classes.indicatorFocused]: isFocused
      },
      className
    )}>
    <ClearIcon />
  </div>
);

ClearIndicator.propTypes = {
  className: PropTypes.string,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool
};

type MenuListProps = {
  selectProps: any;
  children: any;
  props: any;
};
const MenuList: React.FC<MenuListProps> = ({
  selectProps,
  children,
  ...props
}: MenuListProps) => {
  const MenuList = ($components.MenuList as any) as React.ElementType;
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <MenuList as any selectProps={selectProps} {...props}>
      {children}
      {selectProps.hasMore && (
        <Typography className={selectProps.classes.hasMore}>
          <FormattedMessage id="search.hasMore" />
        </Typography>
      )}
    </MenuList>
  );
};

MenuList.propTypes = {
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node
};

const components = {
  Control,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
  ClearIndicator,
  MenuList
};

type FilterOptionProps = {
  label: any;
  searchTerm: any;
};
export const filterOption: React.FC<FilterOptionProps> = (
  { label },
  searchTerm
) =>
  !searchTerm ||
  searchTerm === '' ||
  label.toLowerCase().includes(searchTerm.toLowerCase());

type GetSelectComponentProps = {
  isAsync: any;
  creatable: any;
};
export const getSelectComponent: any = ({
  isAsync,
  creatable
}: GetSelectComponentProps) => {
  if (isAsync) {
    return creatable ? AsyncCreatableSelect : AsyncSelect;
  }

  return creatable ? CreatableSelect : SimpleSelect;
};

export type SearchDropdownProps = {
  fetchOptions: any;
  value: any;
  onChange: any;
  inputValue: any;
  onInputChange: any;
  multiple: any;
  classes: any;
  components: any;
  creatable: any;
  reloadOnMount: any;
  attachMenuToRoot: any;
  isClearable: any;
  hasMore: any;
  ariaLabel: any;
  inputRef: any;
  defaultOptions: any;
  placeholder: any;
  variant: any;
  showLabel: any;
  filterOptions: any;
  noOptionsMessage: any;
  closeMenuOnSelect: any;
  menuPlacement: any;
  onMenuOpen: any;
  rest: any;
  onMenuClose: any;
  [prop: string]: any;
};
const SearchDropdown: React.FC<Partial<SearchDropdownProps>> = ({
  fetchOptions,
  value,
  onChange,
  inputValue,
  onInputChange,
  multiple,
  classes = {},
  components: customComponents,
  placeholder,
  creatable,
  reloadOnMount = false,
  attachMenuToRoot = false,
  hasMore = false,
  ariaLabel,
  inputRef,
  defaultOptions = true,
  filterOptions: customFilterOption,
  ...rest
}: Partial<SearchDropdownProps>) => {
  const cssClasses = useStyles({
    classes: {
      root: classes.root,
      input: classes.input,
      valueContainer: classes.valueContainer,
      avatar: classes.avatar,
      chip: classes.chip,
      chipFocused: classes.chipFocused,
      clearIndicatorOverride: classes.clearIndicatorOverride,
      dropdownIndicatorOverride: classes.dropdownIndicatorOverride,
      indicatorFocused: classes.indicatorFocused,
      noOptionsMessage: classes.noOptionsMessage,
      singleValue: classes.singleValue,
      placeholder: classes.placeholder,
      paper: classes.paper,
      divider: classes.divider,
      hasMore: classes.hasMore
    }
  });
  const theme = useTheme();
  // const remountKey = useReloadOnRemountKey();

  const selectStyles = useMemo(
    () => ({
      input: (base: any) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      }),
      clearIndicator: (base: any) => ({
        ...base,
        cursor: 'pointer'
      }),
      dropdownIndicator: (base: any) => ({
        ...base,
        cursor: 'pointer'
      }),
      placeholder: (base: any) => ({
        ...base,
        cursor: 'pointer'
      }),
      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
      container: (base: any, state: any) => ({
        ...base,
        zIndex: state.isFocused ? 9999 : 1
      })
    }),
    [theme.palette.text.primary]
  );

  const renderComponents = useMemo(
    () => ({
      ...components,
      ...(customComponents || {})
    }),
    [customComponents]
  );

  const isAsync = Boolean(fetchOptions);

  const SelectComponent = getSelectComponent({ isAsync, creatable });

  return (
    <div className={cssClasses.root}>
      <SelectComponent
        aria-label={ariaLabel}
        key={reloadOnMount ? undefined : undefined}
        classes={cssClasses}
        styles={selectStyles}
        placeholder={placeholder}
        loadOptions={fetchOptions}
        defaultOptions={defaultOptions}
        components={renderComponents}
        value={value}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        isMulti={multiple}
        filterOption={customFilterOption || filterOption}
        menuPortalTarget={attachMenuToRoot ? document.body : undefined}
        hasMore={hasMore}
        ref={inputRef}
        {...rest}
      />
    </div>
  );
};

SearchDropdown.propTypes = {
  fetchOptions: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  classes: PropTypes.object,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  multiple: PropTypes.bool,
  components: PropTypes.object,
  creatable: PropTypes.bool,
  reloadOnMount: PropTypes.bool,
  attachMenuToRoot: PropTypes.bool,
  hasMore: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array])
};

export default SearchDropdown;
