import { useState, useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import { OperationOverhead, Money, SmartBudgetResult } from 'generated-types';
import { OnChange } from 'common/types';
import { useMessage } from 'App/MessageProvider';
import {
  useLocalBusinessCostContext,
  usePlanUndoContext,
  useSaveDraftContext
} from 'plans/plan/context';
import { NEW_BUSINESS_COST_ID } from '../../cards/constants';

export interface UseBusinessCostResults {
  businessCosts: Maybe<OperationOverhead>[];
  totalCost: Money | null | undefined;
  handleAddBusinessCost: () => void;
  handleCostChange: (id: string) => OnChange;
  handleNameChange: (
    id: string
  ) => (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCostRemove: (id: string) => void;
}

export const useBusinessCost = (
  plan: SmartBudgetResult
): UseBusinessCostResults => {
  const {
    businessCosts,
    setBusinessCosts,
    businessCostsTotal
  } = useLocalBusinessCostContext();
  const newCount = useMemo(
    () =>
      businessCosts.reduce((count: number, businessCost: OperationOverhead) => {
        if (businessCost.isNew) {
          return ++count;
        }
        return count;
      }, 1),
    [businessCosts]
  );
  const [uncategorizedCount, setUncategorizedCount] = useState(newCount);
  const uncategorizedBusinessCostName = useMessage(
    'planPage.businessCostCard.uncategorized'
  );
  const { setHasBusinessCostChanges } = useSaveDraftContext();
  const { push } = usePlanUndoContext();

  const handleAddBusinessCost = useCallback(() => {
    const newBusinessCost = {
      id: `${NEW_BUSINESS_COST_ID} ${uncategorizedCount}`,
      name: `${uncategorizedBusinessCostName} ${uncategorizedCount}`,
      cost: {
        amount: new BigNumber(0),
        currency: plan.currency
      }
    };
    push({ businessCostState: businessCosts });
    setBusinessCosts([...businessCosts, newBusinessCost]);
    setUncategorizedCount(uncategorizedCount + 1);
    setHasBusinessCostChanges(true);
  }, [
    uncategorizedCount,
    uncategorizedBusinessCostName,
    plan.currency,
    push,
    businessCosts,
    setBusinessCosts,
    setHasBusinessCostChanges
  ]);

  const handleNameChange = useCallback(
    (id: string) => (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const buIndex = businessCosts.findIndex(el => el?.id === id);
      if (buIndex >= 0) {
        const newBusinessCosts = [...businessCosts];
        if (newBusinessCosts[buIndex].name !== event.target.value) {
          newBusinessCosts[buIndex] = {
            ...newBusinessCosts[buIndex],
            id: id,
            name: event.target.value
          };
          push({ businessCostState: businessCosts });
          setBusinessCosts(newBusinessCosts);
        }
      }
    },
    [businessCosts, push, setBusinessCosts]
  );

  const handleCostChange = useCallback(
    (id: string) => (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
    ) => {
      const value = typeof event === 'string' ? event : event.target.value;
      const buIndex = businessCosts.findIndex(el => el?.id === id);
      if (buIndex >= 0) {
        const newBusinessCosts = [...businessCosts];
        if (
          !newBusinessCosts[buIndex].cost?.amount?.isEqualTo(
            new BigNumber(value)
          )
        ) {
          newBusinessCosts[buIndex] = {
            ...newBusinessCosts[buIndex],
            id: id,
            cost: {
              ...newBusinessCosts[buIndex]?.cost,
              amount: new BigNumber(value)
            }
          };
          setBusinessCosts(newBusinessCosts);
          push({ businessCostState: businessCosts });
        }
      }
    },
    [businessCosts, push, setBusinessCosts]
  );

  const handleCostRemove = useCallback(
    (id: string) => {
      const buIndex = businessCosts.findIndex(el => el?.id === id);
      if (buIndex >= 0) {
        const newBusinessCost = [...businessCosts];
        newBusinessCost.splice(buIndex, 1);
        const uncategorizedCostCount = newBusinessCost.filter(bu =>
          bu?.id.includes(NEW_BUSINESS_COST_ID)
        ).length;
        if (uncategorizedCostCount === 0) {
          setUncategorizedCount(1);
          setHasBusinessCostChanges(false);
        }
        setBusinessCosts(newBusinessCost);
        push({
          businessCostState: businessCosts
        });
      }
    },
    [businessCosts, push, setBusinessCosts, setHasBusinessCostChanges]
  );

  const totalCost = useMemo<Money>(
    () => ({
      ...businessCostsTotal,
      amount: businessCostsTotal.amount?.plus(
        plan.totalResourceCost?.amount ?? 0
      )
    }),
    [businessCostsTotal, plan.totalResourceCost]
  );

  return {
    businessCosts,
    totalCost,
    handleAddBusinessCost,
    handleCostChange,
    handleNameChange,
    handleCostRemove
  };
};
