import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Label } from 'common/components';
import { Warning, WarningCodes } from '../../types';
import { UsersCostWarning } from './UsersCostWarning';

const useAlertStyles = makeStyles(theme => ({
  message: {
    paddingBottom: theme.spacing(0.5)
  },
  icon: {
    '& > svg': {
      color: '#FF9736'
    }
  }
}));

type PlanWarningProps = {
  warning: Warning;
};

type PlanWarningData = {
  title: string;
};

const planWarnings: Record<string, PlanWarningData> = {
  NO_COST_FOR_USERS: {
    title: 'addPlanDialog.warnings.noUsersCost.title'
  }
};

export const PlanWarning: React.FC<PlanWarningProps> = ({ warning }) => {
  const alertClasses = useAlertStyles();
  const data = planWarnings[warning.code];
  return data ? (
    <Alert classes={alertClasses} severity="warning">
      <Label labelId={data.title} />
      {warning.code === WarningCodes.NO_COST_FOR_USERS && (
        <UsersCostWarning userWarning={warning.target} />
      )}
    </Alert>
  ) : null;
};
