/* eslint-disable react/jsx-key */
import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableFooterCell,
  TableFooterRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow
} from '@replicon/react-data-table';
import classnames from 'classnames';
import { ColumnInstance, useTable } from 'react-table';
import { BillingByRoleData, TableColumnInstance } from 'common/types';
import { useEnableColumnsContext } from 'common/context';
import { ColumnSelectionDialog } from '../../../../common/components/ColumnSelectionDialog';
import { BillableCell } from './BillableCell';
import {
  allColumns,
  billableTableColumns,
  ColumnKeys,
  defaultColumns as defaultEnabledColumns,
  BILLABLE_TABLE_SETTINGS
} from './billableColumns';
import { BillableFooter } from './BillableFooter';
import { BillableHeader } from './BillableHeader';
import { useTableStyles } from './useTableStyles';

export type BillableColumnInstance<
  T extends BillingByRoleData = BillingByRoleData
> = ColumnInstance<T> & {
  i18nKey: string;
  required?: boolean;
};

const defaultColumn = {
  Cell: BillableCell,
  Header: BillableHeader,
  Footer: BillableFooter
};

type BillableRoleTableProps = {
  billingData: BillingByRoleData[];
  open: boolean;
  closeTableConfigDialog: () => void;
};
const isBorderColumn = (
  key: string,
  columns: ColumnInstance<BillingByRoleData>[]
): boolean => {
  const filteredColumn = columns.filter(column => column.isVisible);
  if (filteredColumn.length === 0) {
    return true;
  }
  return filteredColumn[filteredColumn.length - 1].id === key;
};

export const BillableRoleTable: React.FC<BillableRoleTableProps> = ({
  billingData,
  open,
  closeTableConfigDialog
}: BillableRoleTableProps) => {
  const classes = useTableStyles();
  const enabledColumns = useEnableColumnsContext();
  const { headerGroups, rows, footerGroups, prepareRow, columns } = useTable<
    BillingByRoleData
  >({
    columns: billableTableColumns,
    data: billingData,
    defaultColumn,
    initialState: {
      hiddenColumns: allColumns.filter(id => !enabledColumns.has(id))
    }
  });
  const stickyClasses = useMemo(
    () => ({
      [classes.secondColumnStickyRow]: enabledColumns.has(
        ColumnKeys.TOTAL_HOURS
      )
    }),
    [classes.secondColumnStickyRow, enabledColumns]
  );

  return (
    <>
      <ColumnSelectionDialog<BillingByRoleData>
        open={open}
        closeDialog={closeTableConfigDialog}
        columns={columns as TableColumnInstance<BillingByRoleData>[]}
        enabledColumns={enabledColumns}
        defaultColumns={defaultEnabledColumns}
        tableKey={BILLABLE_TABLE_SETTINGS}
      />
      <Table className={classes.table}>
        <TableHeader>
          <TableHeaderRow
            className={classnames(
              classes.tableRow,
              classes.spanHeaderRow,
              classes.header1
            )}
            {...headerGroups[0].getHeaderGroupProps()}>
            {headerGroups[0].headers.map(column => (
              <TableHeaderCell
                {...column.getHeaderProps()}
                align="center"
                id={column.id}>
                {column.render('Header')}
              </TableHeaderCell>
            ))}
          </TableHeaderRow>
          <TableHeaderRow
            className={classnames(
              classes.tableRow,
              classes.headerRow,
              stickyClasses,
              classes.header2
            )}>
            {headerGroups[1].headers.map((column, index) => (
              <TableHeaderCell
                className={classnames({
                  [classes.borderCell]: isBorderColumn(
                    column.id,
                    column.parent.columns
                  )
                })}
                {...column.getHeaderProps()}
                align={index === 0 ? 'left' : 'right'}>
                {column.render('Header')}
              </TableHeaderCell>
            ))}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {rows.map(row => {
            prepareRow(row);
            return (
              <TableRow
                className={classnames(classes.tableRow, stickyClasses)}
                {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <TableCell
                    className={classnames({
                      [classes.borderCell]: isBorderColumn(
                        cell.column.id,
                        cell.column.parent.columns
                      )
                    })}
                    {...cell.getCellProps()}
                    align={index === 0 ? 'left' : 'right'}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableFooterRow
            className={classnames(classes.tableRow, stickyClasses)}>
            {footerGroups[0].headers.map((column, index) => (
              <TableFooterCell
                className={classnames({
                  [classes.borderCell]: isBorderColumn(
                    column.id,
                    column.parent.columns
                  )
                })}
                {...column.getFooterProps()}
                align={index === 0 ? 'left' : 'right'}>
                {column.render('Footer')}
              </TableFooterCell>
            ))}
          </TableFooterRow>
        </TableFooter>
      </Table>
    </>
  );
};
