import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { usePlanTotalContext } from 'plans/plan/context';
import { RTLOverride } from '../RTLOverride';

const useStyles = makeStyles(theme => ({
  headCount: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontSize: 10
  }
}));
export const FooterTitleCell: React.FC = () => {
  const classes = useStyles();
  const { planTotals } = usePlanTotalContext() ?? {};
  const value = planTotals?.['userCount'] as BigNumber;
  return (
    <>
      <FormattedMessage id="planPage.billableResourcesCard.roleNameCell.total" />
      <div className={classes.headCount}>
        <RTLOverride>
          <FormattedMessage id="planPage.billableResourcesCard.roleNameCell.headCount" />
          {': '}
          {value.toNumber()}
        </RTLOverride>
      </div>
    </>
  );
};
