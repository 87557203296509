import React from 'react';
import { DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    overflowY: 'visible'
  }
});
export interface CalculationInfoDialogContentProps {
  children: React.ReactNode[];
}

export const CalculationInfoDialogContent: React.FC<CalculationInfoDialogContentProps> = ({
  children
}: CalculationInfoDialogContentProps) => {
  const classes = useStyles();
  return (
    <DialogContent dividers className={classes.root}>
      {children}
    </DialogContent>
  );
};

export default CalculationInfoDialogContent;
