import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Link
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Maybe } from 'graphql/jsutils/Maybe';
import { DateRangeBase, Label } from 'common/components';
import { useRouteBase } from 'App/EmbeddedAppContext';
import { OverlappingPlansWarning } from 'generated-types';

const useExpansionPanelStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      marginTop: 0,
      marginBottom: theme.spacing(2)
    }
  },
  expanded: {}
}));

const useExpansionPanelSummaryStyles = makeStyles(theme => ({
  root: ({ severity }: { severity: 'error' | 'warning' }) => ({
    backgroundColor:
      severity === 'warning' ? 'rgb(255, 250, 215)' : 'rgb(253, 236, 234)',
    minHeight: theme.spacing(5),
    '&$expanded': {
      minHeight: theme.spacing(5)
    },
    padding: theme.spacing(0, 2, 0, 4)
  }),
  content: {
    '&$expanded': {
      margin: theme.spacing(0.5, 0)
    }
  },
  expanded: {}
}));

const useExpansionPanelDetailsStyles = makeStyles(theme => ({
  root: ({ severity }: { severity: 'error' | 'warning' }) => {
    return {
      backgroundColor:
        severity === 'warning' ? 'rgb(255, 250, 215)' : 'rgb(253, 236, 234)',
      padding: theme.spacing(0, 2, 1, 4)
    };
  }
}));

const useStyles = makeStyles(theme => ({
  skeleton: {
    width: '100%'
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  },
  plan: {
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  }
}));

type OverlappingPlansWarningProps = {
  severity: 'error' | 'warning';
  overlappingPlans: Maybe<OverlappingPlansWarning>[] | undefined;
};

export const OverlappingPlanWarnings: React.FC<OverlappingPlansWarningProps> = ({
  severity,
  overlappingPlans
}: OverlappingPlansWarningProps) => {
  const classes = useStyles();
  const expansionPanelClasses = useExpansionPanelStyles();
  const expansionPanelSummaryClasses = useExpansionPanelSummaryStyles({
    severity
  });
  const expansionPanelDetailsClasses = useExpansionPanelDetailsStyles({
    severity
  });
  const baseRoute = useRouteBase();

  const titleValue = useMemo(
    () => ({
      count: (
        <span className={classes.bold}>
          &nbsp;
          {overlappingPlans?.length.toString()}
          &nbsp;
        </span>
      )
    }),
    [classes.bold, overlappingPlans]
  );

  return (
    <Accordion classes={expansionPanelClasses}>
      <AccordionSummary
        classes={expansionPanelSummaryClasses}
        expandIcon={<ArrowDropDownIcon />}>
        <Label variant="body2">
          <FormattedPlural
            one={
              <FormattedMessage
                id={
                  severity === 'error'
                    ? 'overlappingPlansWarning.title.one'
                    : 'overlappingPlansWarning.title.draftOne'
                }
                values={titleValue}
              />
            }
            other={
              <FormattedMessage
                id={
                  severity === 'error'
                    ? 'overlappingPlansWarning.title.other'
                    : 'overlappingPlansWarning.title.draftOther'
                }
                values={titleValue}
              />
            }
            value={overlappingPlans?.length ?? 0}
          />
        </Label>
      </AccordionSummary>
      <AccordionDetails classes={expansionPanelDetailsClasses}>
        <div>
          {severity === 'error' && (
            <Label
              variant="body2"
              labelId="overlappingPlansWarning.body"></Label>
          )}
          {overlappingPlans?.map(w => (
            <Link
              key={w?.planId}
              href={`${baseRoute}/plans/${w?.planId}`}
              target="_blank">
              <Label variant="body2">
                {w?.planName}&nbsp;-&nbsp;
                <DateRangeBase
                  startDate={w?.planStartDate}
                  endDate={w?.planEndDate}
                />
              </Label>
            </Link>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
