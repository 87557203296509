import { useCallback, useMemo } from 'react';
import { DateObject, DateTime } from 'luxon';
import { useMeContext } from 'App/Me';

type useXAxisTickProps = {
  startDate: DateTime;
  endDate: DateTime;
  overviewGraphData:
    | {
        month: string;
        budgeted: number;
        estimated: number;
        actual: number;
        dateRange: {
          startDate?: DateObject;
          endDate?: DateObject;
        };
      }[]
    | undefined;
};

const formattedStartDateRange = (
  currentMonth: DateTime,
  startDate: DateTime,
  endDate: DateTime,
  languageCode: string
): string => {
  if (startDate.toFormat('yyyy-MM') === endDate.toFormat('yyyy-MM')) {
    return startDate.day === endDate.day
      ? `${startDate.setLocale(languageCode).toLocaleString({
          month: 'short',
          day: 'numeric'
        })}`
      : `${startDate.setLocale(languageCode).toLocaleString({
          month: 'short',
          day: 'numeric'
        })} - ${endDate.setLocale(languageCode).toLocaleString({
          day: 'numeric'
        })}`;
  }

  return `${startDate.setLocale(languageCode).toLocaleString({
    month: 'short',
    day: 'numeric'
  })} - ${startDate
    .endOf('month')
    .setLocale(languageCode)
    .toLocaleString({
      day: 'numeric'
    })}`;
};

const formattedEndDateRange = (
  currentMonth: DateTime,
  date: DateTime,
  languageCode: string
): string => {
  return currentMonth.endOf('month').toISODate() === date.toISODate()
    ? `${date.setLocale(languageCode).toLocaleString({
        month: 'short',
        day: 'numeric'
      })}`
    : `${DateTime.fromISO(currentMonth.startOf('month').toISODate())
        .setLocale(languageCode)
        .toLocaleString({
          month: 'short',
          day: 'numeric'
        })} - ${date.setLocale(languageCode).toLocaleString({
        day: 'numeric'
      })}`;
};

export const useXAxisTick = ({
  startDate,
  endDate,
  overviewGraphData
}: useXAxisTickProps): ((t: any, index: number) => string) => {
  const me = useMeContext();
  const languageCode = useMemo(
    () => me?.locale?.language?.languageCode ?? 'en-US',
    [me]
  );
  return useCallback(
    (t, index) => {
      const currentMonth = DateTime.fromISO(t);
      if (t !== currentMonth.startOf('month').toISODate() && index === 0) {
        return formattedStartDateRange(
          currentMonth,
          startDate,
          endDate,
          languageCode
        );
      } else if (
        currentMonth.endOf('month').toISODate() !== endDate.toISODate() &&
        index + 1 === overviewGraphData?.length
      ) {
        return formattedEndDateRange(currentMonth, endDate, languageCode);
      }

      return DateTime.fromISO(t).setLocale(languageCode).monthShort;
    },
    [endDate, languageCode, overviewGraphData, startDate]
  );
};
