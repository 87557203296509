import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RoleCostSkeleton } from 'common/components/RoleCostSummary/components/RoleCostSkeleton';
import { RoleTableSkeleton } from 'rateCard/components';
import { useNavigationBlock } from 'common/hooks';
import { useUploadPath } from 'fileUpload';
import { NavigationBlockContextProvider } from 'common/context';
import { useUploadData } from './useUploadData';
import { UploadDataContent } from './UploadDataContent';

export const UploadDataPage: React.FC = () => {
  const { unblockNavigation, unblockRef } = useNavigationBlock();
  const { id } = useParams();
  const { loading, costOptimizationResult } = useUploadData(id ?? '');

  const history = useHistory();
  const uploadRootPath = useUploadPath();

  if (!costOptimizationResult && !loading) {
    unblockNavigation();
    history.replace(uploadRootPath);
  }

  return (
    <NavigationBlockContextProvider
      unblockRef={unblockRef}
      unblockNavigation={unblockNavigation}>
      {loading && (
        <>
          <RoleCostSkeleton />
          <RoleTableSkeleton />
        </>
      )}
      {costOptimizationResult && (
        <UploadDataContent
          costOptimizationResult={costOptimizationResult}
          unblockRef={unblockRef}
          unblockNavigation={unblockNavigation}
        />
      )}
    </NavigationBlockContextProvider>
  );
};

export default React.memo(UploadDataPage);
