import { DateTime } from 'luxon';
import { CostOptimizationResult } from 'generated-types';

export const validateRateCardOverlap = (
  endDate: DateTime | null,
  startDate: DateTime | null,
  result?: CostOptimizationResult | null
): CostOptimizationResult | null => {
  if (startDate && result?.endDate && result?.effectiveDate && endDate) {
    if (
      startDate?.toISODate() <= result.endDate &&
      endDate?.toISODate() >= result.effectiveDate
    ) {
      return result;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
