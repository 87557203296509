import React, { useMemo } from 'react';
import { Grid, makeStyles, TextField, InputAdornment } from '@material-ui/core';
import { DatePicker, DatePickerView } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { FormContextValues, NestDataObject, FieldError } from 'react-hook-form';
import {
  GetOverlappingPlansQuery,
  CostOptimizationResult
} from 'generated-types';
import { PLAN_NAME_CHARACTER_LIMIT } from 'plans/constants';
import { useFeatureFlag } from 'common/hooks';
import { Error } from './types';
import OverlappingPlanWarning from './OverlappingPlanWarning';
import { PlanErrors } from './components';
import { CreatePlan } from './hooks';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    padding: theme.spacing(2, 3, 1)
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.grey[500]
  },
  cancelBtn: {},
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  row: {
    display: 'flex',
    margin: theme.spacing(1, 0),
    justifyContent: 'space-between'
  },
  leftField: {
    width: '95%'
  },
  rightField: {
    width: '100%'
  },
  actions: {
    padding: theme.spacing(1, 2)
  },
  checkboxes: {
    display: 'grid',
    justifyContent: 'flex-start'
  },
  divider: {
    width: '100%'
  },
  error: {
    marginBottom: theme.spacing(2)
  }
}));

const percentAdornment = {
  endAdornment: <InputAdornment position="start">%</InputAdornment>
};

const extractUtilizationError = (
  errors: NestDataObject<CreatePlan, FieldError>,
  messages: Record<string, string | undefined>
): string | undefined => {
  if (!errors.utilization) return undefined;
  if (errors.utilization.type === 'min')
    return messages['addPlanDialog.utilizationNonNegative'];
  if (errors.utilization.type === 'required')
    return messages['addPlanDialog.utilizationRequired'];
};

const extractMarginError = (
  errors: NestDataObject<CreatePlan, FieldError>,
  messages: Record<string, string | undefined>
): string | undefined => {
  if (!errors.margin) return undefined;
  if (errors.margin.type === 'min')
    return messages['addPlanDialog.marginNonNegative'];
  if (errors.margin.type === 'max')
    return messages['addPlanDialog.marginOver100'];
  if (errors.margin.type === 'required')
    return messages['addPlanDialog.marginRequired'];
};

type AddPlanProps = {
  register: FormContextValues['register'];
  errors: NestDataObject<CreatePlan, FieldError>;
  onStartDateChange: (date: DateTime | null) => void;
  onEndDateChange: (date: DateTime | null) => void;
  messages: Record<string, string | undefined>;
  hasOverlappingPlans: boolean;
  startDate: DateTime | null | undefined;
  endDate: DateTime | null | undefined;
  overlappingPlans: GetOverlappingPlansQuery | undefined;
  createPlanErrors: Error[];
  copyUtilization?: boolean;
  copyRates?: boolean;
  sourcePlan?: Pick<
    CostOptimizationResult,
    'name' | 'id' | 'utilization' | 'margin'
  >;
};
export const AddPlan: React.FC<AddPlanProps> = ({
  register,
  errors,
  onStartDateChange,
  onEndDateChange,
  messages,
  hasOverlappingPlans,
  startDate,
  endDate,
  overlappingPlans,
  createPlanErrors,
  copyUtilization,
  copyRates
}: AddPlanProps) => {
  const classes = useStyles();
  const targetBillability = useFeatureFlag('cpoTargetBillability');
  const pickerProps = useMemo(
    () =>
      targetBillability
        ? {
            views: ['year', 'month'] as DatePickerView[],
            openTo: 'month' as DatePickerView
          }
        : {},
    [targetBillability]
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          required
          id="name"
          name="name"
          className={classes.row}
          label={messages['addPlanDialog.name']}
          variant="filled"
          inputRef={register({ required: true })}
          error={Boolean(errors.name)}
          helperText={
            Boolean(errors.name) && messages['addPlanDialog.nameRequired']
          }
          inputProps={{ maxLength: PLAN_NAME_CHARACTER_LIMIT }}
        />
      </Grid>
      <Grid container className={classes.row}>
        <Grid item xs={6}>
          <DatePicker
            {...pickerProps}
            name="startDate"
            id="startDate"
            required
            className={classes.leftField}
            autoOk
            inputVariant="filled"
            label={
              messages[
                targetBillability
                  ? 'addPlanDialog.startMonth'
                  : 'addPlanDialog.startDate'
              ]
            }
            value={startDate}
            onChange={onStartDateChange}
            error={Boolean(errors.startDate)}
            helperText={
              errors.startDate &&
              messages[
                targetBillability
                  ? 'addPlanDialog.startMonthRequired'
                  : 'addPlanDialog.startDateRequired'
              ]
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            {...pickerProps}
            name="endDate"
            id="endDate"
            required
            className={classes.rightField}
            autoOk
            inputVariant="filled"
            label={
              messages[
                targetBillability
                  ? 'addPlanDialog.endMonth'
                  : 'addPlanDialog.endDate'
              ]
            }
            value={endDate}
            onChange={onEndDateChange}
            error={Boolean(errors.endDate)}
            helperText={
              errors.endDate &&
              messages[
                targetBillability
                  ? 'addPlanDialog.endMonthRequired'
                  : 'addPlanDialog.endDateRequired'
              ]
            }
          />
        </Grid>
      </Grid>
      <Grid container className={classes.row}>
        <Grid item xs={6}>
          <TextField
            required
            disabled={copyUtilization}
            id="utilization"
            name="utilization"
            className={classes.leftField}
            label={messages['addPlanDialog.utilization']}
            variant="filled"
            type="number"
            InputProps={percentAdornment}
            inputRef={register({ min: 1, required: true })}
            error={Boolean(errors.utilization)}
            helperText={extractUtilizationError(errors, messages)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            disabled={copyRates}
            id="margin"
            name="margin"
            className={classes.rightField}
            label={messages['addPlanDialog.margin']}
            variant="filled"
            type="number"
            InputProps={percentAdornment}
            inputRef={register({ max: 100, min: 0, required: true })}
            error={Boolean(errors.margin)}
            helperText={extractMarginError(errors, messages)}
          />
        </Grid>
      </Grid>
      {hasOverlappingPlans && (
        <Grid item xs={12}>
          <OverlappingPlanWarning
            overlappingPlans={overlappingPlans?.smartBudgets.edges ?? []}
          />
        </Grid>
      )}
      {Boolean(createPlanErrors?.length) && (
        <Grid item xs={12} className={classes.error}>
          <PlanErrors errors={createPlanErrors} />
        </Grid>
      )}
    </Grid>
  );
};
