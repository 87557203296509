import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, TypographyProps } from '@material-ui/core';

export interface LabelProps extends TypographyProps {
  labelId?: string;
  values?: Record<string, string | React.ReactElement>;
}

const Label: React.FC<LabelProps> = ({
  labelId: messageId,
  children,
  values,
  ...rest
}: LabelProps) => {
  return (
    <Typography {...rest}>
      {messageId !== undefined && (
        <FormattedMessage id={messageId} values={values} />
      )}
      {children}
    </Typography>
  );
};

export default Label;
