import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { CsvValidationError, CsvValidationErrorType } from 'generated-types';

export type ValidationLabelProps = CsvValidationError & {
  labelId: string;
};

const useStyles = makeStyles(theme => ({
  grid: {
    ...theme.typography.body1,
    maxWidth: '10%'
  },
  row: {
    color: theme.palette.error.main
  },
  message: {
    color: theme.palette.grey[900]
  }
}));

export const useLabelValue = (
  type: CsvValidationErrorType,
  cellValue?: string | null
): { [key: string]: string | null | undefined } => {
  return useMemo(() => {
    return { cellValue: cellValue };
  }, [cellValue]);
};

export const ValidationLabel: React.FC<ValidationLabelProps> = ({
  labelId,
  line,
  type,
  cellValue
}: ValidationLabelProps) => {
  const values = useLabelValue(type, cellValue);
  const lineValues = useMemo(() => ({ line }), [line]);
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center" spacing={3}>
      <Grid className={classes.grid} item xs={3}>
        <Typography variant="subtitle2" className={classes.row}>
          <FormattedMessage id="validationLabel.row" values={lineValues} />
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body2" className={classes.message}>
          <FormattedMessage id={labelId} values={values} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ValidationLabel;
