import React, { useCallback } from 'react';
import classNames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core';
import { LockTwoTone, LockOpenTwoTone } from '@material-ui/icons';

export type LockButtonProps = {
  locked: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (key?: any) => void;
  lockKey?: string;
  lockNext?: string;
  className?: string;
};

const useStyles = makeStyles<Theme, LockButtonProps>({
  root: {
    opacity: (props): string => (props.locked ? '100%' : '60%'),
    '&:hover': {
      opacity: (props): string => (props.locked ? '80%' : '40%'),
      cursor: 'pointer'
    }
  }
});

const LockButton: React.FC<LockButtonProps> = props => {
  const classes = useStyles(props);
  const { onClick, locked, lockKey, lockNext, className } = props;

  const onClickHandler = useCallback(
    () => onClick(locked ? lockNext : lockKey),
    [lockKey, lockNext, locked, onClick]
  );

  if (locked) {
    return (
      <LockTwoTone
        onClick={onClickHandler}
        className={classNames(classes.root, className)}
      />
    );
  } else {
    return (
      <LockOpenTwoTone
        onClick={onClickHandler}
        className={classNames(classes.root, className)}
      />
    );
  }
};

export default LockButton;
