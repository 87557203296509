import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: theme.spacing(0, 0, 0, 2)
  },
  square: {
    display: 'flex',
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    alignSelf: 'center',
    backgroundColor: ({ color }: Partial<LegendProps>) => color
  },
  legendText: {
    ...theme.typography.body2,
    padding: theme.spacing(0, 0, 0, 1),
    color: theme.palette.text.primary
  }
}));

export type LegendProps = {
  classes?: ClassNameMap;
  label: React.ReactNode;
  color: string;
};

export const Legend: React.FC<LegendProps> = ({
  classes: classesOverride,
  label,
  color
}: LegendProps) => {
  const classes = useStyles({
    classes: classesOverride,
    color
  });

  return (
    <div className={classes.container}>
      <span className={classes.square} />
      <Typography className={classes.legendText}>{label}</Typography>
    </div>
  );
};
