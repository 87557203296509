import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  businessCostRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    alignItems: 'center'
  },
  businessCostName: {
    width: '60%',
    height: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center'
  },
  businessCostAmount: {
    width: '40%',
    height: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  businessCostNameSkeleton: {
    width: '100%'
  },
  businessCostAmountSkeleton: {
    width: '80%'
  }
}));

const BusinessCostRowSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.businessCostRow}>
      <div className={classes.businessCostName}>
        <Skeleton className={classes.businessCostNameSkeleton} />
      </div>
      <div className={classes.businessCostAmount}>
        <Skeleton className={classes.businessCostAmountSkeleton} />
      </div>
    </div>
  );
};

export default BusinessCostRowSkeleton;
