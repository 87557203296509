import React, { useContext } from 'react';
// For some reason eslint isn't able to see these type exports...
// eslint-disable-next-line import/named
import { injectIntl, InjectedIntl, InjectedIntlProps } from 'react-intl';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const UseIntlContext = React.createContext<InjectedIntl>(undefined!);

// eslint-disable-next-line @typescript-eslint/ban-types
type UseIntlProviderTypes = React.PropsWithChildren<{}> & InjectedIntlProps;

const UseIntlProvider = injectIntl<UseIntlProviderTypes>(
  ({ intl, children }) => {
    return (
      <UseIntlContext.Provider value={intl}>{children}</UseIntlContext.Provider>
    );
  }
);

const useIntl = (): InjectedIntl => {
  const intl = useContext(UseIntlContext);
  return intl;
};

const useMessage = (
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: { [key: string]: any }
): string | undefined => {
  const intl = useIntl();
  return intl.formatMessage({ id }, values);
};

const useMessages = (ids: string[]): Record<string, string | undefined> => {
  const intl = useIntl();
  return ids.reduce(
    (obj, id) => Object.assign(obj, { [id]: intl.formatMessage({ id }) }),
    {}
  );
};

export default UseIntlContext;
export { UseIntlProvider, useIntl, useMessage, useMessages };
