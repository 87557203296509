import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { SmartBudgetResult } from 'generated-types';
import {
  useTotalCostBreakdown,
  useRoleBreakdown,
  useHasCpoEditPermission
} from 'common/hooks';
import { NoDataItem } from 'common/components/NoDataItem';
import { useCloneCostOptimizationResult } from 'active/hooks';
import { DEFAULT_MARGIN, DEFAULT_UTILIZATION } from 'rateCard/helpers';
import { useRoleSummaryUtilizationMargin } from 'roleSummaryPage/hooks/useRoleSummaryUtilizationMargin';
import { ActiveActions } from 'active/components';
import { RoleSummaryInfo } from '../RoleSummaryInfo';
import { RoleSummaryTable } from './RoleSummaryTable';
import { getTargetRoleBreakdown } from './helpers';

export type RoleSummaryContentProps = {
  costOptimizationResult: SmartBudgetResult;
  roleUri: string;
  onNavigateBack: () => void;
  editable?: boolean;
};

const useStyles = makeStyles(theme => ({
  noResourcesMessage: {
    flexGrow: 1,
    flexShrink: 1,
    lineHeight: 3.5,
    padding: theme.spacing(0, 0, 0, 2)
  }
}));

export const RoleSummaryContent: React.FC<RoleSummaryContentProps> = ({
  costOptimizationResult,
  roleUri,
  editable = true,
  onNavigateBack
}: RoleSummaryContentProps) => {
  const classes = useStyles();
  const { id, fileName } = costOptimizationResult;

  const roleCostBreakdown = useRoleBreakdown(costOptimizationResult);
  const targetRoleCostBreakdown = useMemo(() => {
    return getTargetRoleBreakdown(roleCostBreakdown, roleUri);
  }, [roleCostBreakdown, roleUri]);

  const {
    utilization,
    margin,
    handleMarginChange,
    handleUtilizationChange
  } = useRoleSummaryUtilizationMargin(
    targetRoleCostBreakdown?.margin ?? DEFAULT_MARGIN,
    targetRoleCostBreakdown?.utilization ?? DEFAULT_UTILIZATION,
    costOptimizationResult.roleCostBreakdown
  );

  const totalCostBreakdown = useTotalCostBreakdown(costOptimizationResult);

  const {
    cloneCostOptimizationResult,
    cloning
  } = useCloneCostOptimizationResult(costOptimizationResult);

  const hasCpoEditPermission = useHasCpoEditPermission();
  const canEditRoleSummary = hasCpoEditPermission && editable;

  return (
    <>
      <RoleSummaryInfo
        costOptimizationResultId={costOptimizationResult.id}
        roleData={targetRoleCostBreakdown?.role}
        fileName={fileName}
        onNavigateBack={onNavigateBack}
      />
      {!targetRoleCostBreakdown && (
        <div className={classes.noResourcesMessage}>
          <NoDataItem>
            <FormattedMessage id="roleUserBreakdownPage.noRoleBreakdownError" />
          </NoDataItem>
        </div>
      )}
      {targetRoleCostBreakdown && (
        <>
          <RoleSummaryTable
            activeCostOptimizationId={id}
            totalCostBreakdown={totalCostBreakdown}
            role={targetRoleCostBreakdown}
            roleUri={roleUri}
            margin={margin}
            utilization={utilization}
            editing={false}
            handleUtilizationChange={handleUtilizationChange}
            handleMarginChange={handleMarginChange}
          />
          {canEditRoleSummary && (
            <ActiveActions
              cloneRateCard={cloneCostOptimizationResult}
              cloning={cloning}
              editSubpath={`/role/${roleUri}`}
            />
          )}
        </>
      )}
    </>
  );
};

export default RoleSummaryContent;
