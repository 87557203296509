import React, { useMemo, memo } from 'react';
import { Card, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { useMessage } from 'App/MessageProvider';
import {
  TableSettingsContextProvider,
  useLockedColumnContext
} from 'common/context';
import { useDialogState, useFeatureFlag, useTableSettings } from 'common/hooks';
import { useIntl } from 'App/MessageProvider';
import {
  BillableResourceTableSkeleton,
  SnapToNearestXBillingRateButton,
  SnapToNearestXBillingRateSelect
} from '../components';
import {
  BILLABLE_TABLE_SETTINGS,
  defaultColumns
} from '../components/BillableRoleTable/billableColumns';
import {
  usePlanContext,
  usePlanTotalContext,
  usePlanEditContext,
  useRoleTableFormContext
} from '../context';
import { DataCardHeader } from '../DataCard';
import { BillableRoleTable } from '../components/BillableRoleTable/BillableRoleTable';
import { useIsCalculatingAttribute } from '../hooks';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'unset'
  },
  billableTable: {
    overflowX: 'auto',
    maxHeight: theme.spacing(80)
  }
}));

export const useCardHeaderStyles = makeStyles(theme => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  content: {},
  action: {
    display: 'flex',
    position: 'sticky',
    right: 400
  },
  title: {
    left: theme.spacing(4),
    hyphens: 'auto',
    position: 'sticky',
    display: 'inline',
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

export const BillableResourcesCard: React.FC = memo(() => {
  const { plan, loading } = usePlanContext();
  const { roleData } = usePlanTotalContext();
  const title = useMessage('planPage.billableResourcesCard.title');
  const classes = useStyles();
  const headerClasses = useCardHeaderStyles();
  const { open, closeDialog, openDialog } = useDialogState();
  const { nearestXValue, snapToNearestX } = useRoleTableFormContext();
  const { lockedColumn } = useLockedColumnContext();
  const isBillingRateLocked = Boolean(lockedColumn === 'billingRate');
  const { canEdit } = usePlanEditContext();
  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const isCalculatingBillingRate = useIsCalculatingAttribute('billingRate');

  const intl = useIntl();
  const actions = useMemo(() => {
    return (
      <>
        {plan &&
          canEdit &&
          (cpoNewUx ? (
            <SnapToNearestXBillingRateButton
              setNearestValue={snapToNearestX}
              currencySymbol={plan.currency?.symbol}
              disabled={isCalculatingBillingRate}
            />
          ) : (
            <SnapToNearestXBillingRateSelect
              nearestValue={nearestXValue}
              setNearestValue={snapToNearestX}
              currencySymbol={plan.currency?.symbol}
              disabled={isBillingRateLocked}
            />
          ))}
        <IconButton
          onClick={openDialog}
          aria-label={intl.formatMessage({
            id: 'planPage.billableResourcesCard.tableSettings'
          })}>
          <SettingsIcon />
        </IconButton>
      </>
    );
  }, [
    canEdit,
    cpoNewUx,
    intl,
    isBillingRateLocked,
    isCalculatingBillingRate,
    nearestXValue,
    openDialog,
    plan,
    snapToNearestX
  ]);

  const { loading: tableSettingLoading, tableSettings } = useTableSettings({
    tableKey: BILLABLE_TABLE_SETTINGS,
    defaultColumns
  });

  return (
    <Card className={classes.root}>
      {loading || tableSettingLoading || !plan ? (
        <BillableResourceTableSkeleton />
      ) : (
        <>
          <DataCardHeader
            classes={headerClasses}
            title={title}
            titleTypographyProps={{ variant: 'h6' }}
            action={actions}
          />
          <TableSettingsContextProvider tableSettings={tableSettings}>
            <BillableRoleTable
              billingData={roleData}
              open={open}
              closeTableConfigDialog={closeDialog}
            />
          </TableSettingsContextProvider>
        </>
      )}
    </Card>
  );
});
