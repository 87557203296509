import React from 'react';
import { Card } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import { DataCardHeader } from 'plans/plan/DataCard';
import { useMessage } from 'App/MessageProvider';
import { OverviewChart } from './components';

const avatar = <BarChartIcon />;
export const OverviewChartCard: React.FC = () => {
  const title = useMessage('overviewPage.chart.title');
  return (
    <Card>
      <DataCardHeader
        title={title}
        avatar={avatar}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <OverviewChart />
    </Card>
  );
};
