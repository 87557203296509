import { useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { FieldValues } from 'react-hook-form';

import { Money, SmartBudgetResult } from 'generated-types';

export function usePropagateBusinessCostChanges(
  plan: SmartBudgetResult | null | undefined,
  businessCostsTotalHourly: Money,
  onBusinessCostChangeHandler: (newBusinessCost: BigNumber) => void,
  getAccurateValues: () => FieldValues
): void {
  useEffect(() => {
    if (plan) {
      const billingData = Object.values(getAccurateValues());
      const newAmount = businessCostsTotalHourly.amount;
      const needsUpdate = billingData.find(
        b =>
          !new BigNumber(b.businessOverhead).isEqualTo(
            businessCostsTotalHourly.amount ?? 0
          )
      );

      if (BigNumber.isBigNumber(newAmount) && needsUpdate) {
        onBusinessCostChangeHandler(newAmount);
      }
    }
  }, [
    plan,
    businessCostsTotalHourly,
    onBusinessCostChangeHandler,
    getAccurateValues
  ]);
}
