import React, { memo } from 'react';
import { Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { NumberEditField } from 'common/components';
import { useIntl } from 'App/MessageProvider';
import { useSliderHandlers } from './useSliderHandlers';

export interface BillingSliderProps {
  value: BigNumber;
  labelId: string;
  onChange: (value: string) => void;
  max?: number;
  min?: number;
  disabled?: boolean;
  classes?: ClassNameMap;
  sliderClasses?: ClassNameMap;
  showSlider?: boolean;
  disableSlider?: boolean;
  component?: JSX.Element;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    flexDirection: 'row-reverse'
  },
  sliderContainer: {
    width: '80%',
    marginLeft: theme.spacing(3),
    opacity: 0,
    transition: 'opacity 300ms ease-in-out'
  },
  fadeIn: {
    opacity: 1,
    transition: 'opacity 300ms ease-in-out'
  }
}));

const useSliderStyles = makeStyles(() => ({
  root: {},
  track: {},
  rail: {},
  thumb: {}
}));

const useInputStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  input: {
    width: theme.spacing(5.75),
    padding: 0
  }
}));

export const BillingSlider: React.FC<BillingSliderProps> = memo(
  ({
    value,
    labelId,
    onChange,
    max,
    min,
    disabled,
    classes,
    sliderClasses,
    showSlider = false,
    disableSlider
  }: BillingSliderProps) => {
    const intl = useIntl();
    const { onInputChange, onSliderChange } = useSliderHandlers(
      onChange,
      min ?? 1,
      max
    );
    const sliderCls = useSliderStyles({ classes: sliderClasses });

    const cls = useStyles({ classes });
    return (
      <div className={cls.root}>
        <div
          className={classNames(cls.sliderContainer, {
            [cls.fadeIn]: showSlider
          })}>
          <Slider
            classes={sliderCls}
            aria-labelledby={labelId}
            onChange={onSliderChange}
            value={value.toNumber()}
            defaultValue={0}
            min={min ?? 1}
            max={max}
            disabled={disableSlider}
          />
        </div>
        <div>
          <NumberEditField
            id={labelId}
            ariaLabel={
              labelId === 'planPage.planSummary.slider.utilization'
                ? intl.formatMessage({
                    id: 'planPage.planSummary.slider.utilization'
                  })
                : intl.formatMessage({
                    id: 'planPage.planSummary.slider.margin'
                  })
            }
            classes={useInputStyles()}
            value={value.toString()}
            onBlur={onInputChange}
            suffix="%"
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
);

BillingSlider.displayName = 'BillingSlider';
