import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Button } from 'common/components';
import { useIsBreakpointDown } from 'common/hooks';
import { useOnFileDrop, useUploadFiles } from '../../hooks';
import FileDragAreaContent from './FileDragAreaContent';
import FileUploadIcon from './FileUploadIcon';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  dropzone: {
    display: 'flex',
    padding: theme.spacing(3, 0),
    border: '2px dashed rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    alignSelf: 'center',
    minHeight: 150,
    [theme.breakpoints.down('sm')]: {
      height: '90%'
    },
    justifyContent: 'center',
    alignItems: 'center'
  },
  fileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  filename: {
    alignSelf: 'center',
    padding: theme.spacing(0.5, 0)
  },
  icon: {
    alignSelf: 'center',
    fontSize: theme.spacing(11),
    width: theme.spacing(15)
  },
  uploadProgress: {
    alignSelf: 'center',
    width: theme.spacing(12),
    height: theme.spacing(0.75)
  }
}));

export interface FileUploadDialogProps {
  open: boolean;
  closeDialog: () => void;
  onUploadSuccess: (uploadedFileUri: string) => void;
  onUploadFailure: (errorMsg: string) => void;
}

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({
  open,
  closeDialog,
  onUploadSuccess,
  onUploadFailure
}: FileUploadDialogProps) => {
  const classes = useStyles();
  const { uploading, uploadedFiles, onFileUpload } = useUploadFiles({
    onUploadSuccess,
    onUploadFailure
  });

  const {
    getRootProps,
    getInputProps,
    rejectedFiles,
    isFileTooLarge
  } = useOnFileDrop({
    maximumFileSizeInMB: 5,
    onFileUpload,
    disabled: Boolean(uploadedFiles && uploadedFiles.length)
  });

  return (
    <Dialog
      aria-hidden="true"
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}
      disableBackdropClick={uploading}
      disableEscapeKeyDown={uploading}>
      <DialogTitle className={classes.dialogTitle}>
        <FormattedMessage id="fileUploadDialog.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div
          {...getRootProps({
            className: classes.dropzone
          })}>
          <input {...getInputProps()} type="file" />
          {Boolean(uploadedFiles && uploadedFiles.length) ? (
            <div className={classes.fileRoot}>
              <FileUploadIcon className={classes.icon} />
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.filename}>
                {uploadedFiles[0].filename}
              </Typography>
              <LinearProgress className={classes.uploadProgress} />
            </div>
          ) : (
            <FileDragAreaContent
              rejectedFiles={rejectedFiles}
              isFileTooLarge={isFileTooLarge}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {!uploading && (
          <Button onClick={closeDialog} labelId="fileUploadDialog.cancel" />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
