import React, { useCallback } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';
import { DateRangeBase } from 'common/components';
import { useIntl } from 'App/MessageProvider';
import { usePath } from 'common/hooks';
import { useDirection } from 'App/MuiThemeProvider/useDirection';
import { PlanName } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1
  },
  backArrow: {
    fontSize: '1.5rem'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: theme.spacing(2.375),
    [theme.breakpoints.down(1090)]: {
      fontSize: theme.spacing(2)
    },
    [theme.breakpoints.down(1020)]: {
      fontSize: theme.spacing(1.875)
    }
  },
  dateRange: {
    [theme.breakpoints.down(990)]: {
      fontSize: theme.spacing(1.75)
    }
  }
}));

type PlanPageTitleBarProps = {
  name?: string;
  startDate?: string;
  endDate?: string;
  loading?: boolean;
};

const PlanPageTitleBar: React.FC<PlanPageTitleBarProps> = ({
  children,
  name,
  endDate,
  loading,
  startDate
}) => {
  const direction = useDirection();
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const path = usePath('plans');
  const onClick = useCallback(() => {
    history.push(path);
  }, [history, path]);

  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        onClick={onClick}
        aria-label={intl.formatMessage({ id: 'planPage.toListPage' })}>
        {direction === 'ltr' ? (
          <ArrowBackIcon className={classes.backArrow} />
        ) : (
          <ArrowForwardIcon className={classes.backArrow} />
        )}
      </IconButton>

      <Typography variant="h6">
        {!loading && (
          <div className={classes.title}>
            <PlanName name={name} />
            &nbsp;-&nbsp;
            <div className={classnames(classes.title, classes.dateRange)}>
              <DateRangeBase startDate={startDate} endDate={endDate} />
            </div>
          </div>
        )}
        {loading && <Skeleton width={320} animation="wave" />}
      </Typography>
      {children}
    </div>
  );
};

export default PlanPageTitleBar;
