import React, { useCallback } from 'react';
import SearchBox from '@replicon/react-search-box';
import { makeStyles } from '@material-ui/core/styles';
import { useSearchCriteriaContext, SearchCriteria } from 'common/context';
import { useIntl } from 'App/MessageProvider';
import { useSaveSearchSettings } from 'common/hooks/useSaveSearchSettings';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 600,
    flexGrow: 1,
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4)
  }
}));

interface SimpleSearchBoxProps {
  tableKey: string;
}
const SimpleSearchBox: React.FC<SimpleSearchBoxProps> = ({
  tableKey
}: SimpleSearchBoxProps) => {
  const { searchCriteria } = useSearchCriteriaContext();
  const classes = useStyles();
  const intl = useIntl();
  const { saveSearchSettings } = useSaveSearchSettings();

  const setSearchCriteria = useCallback(
    (searchCriteria: SearchCriteria) => {
      saveSearchSettings(tableKey, { id: tableKey, ...searchCriteria });
    },
    [saveSearchSettings, tableKey]
  );

  return (
    <SearchBox
      searchHintLabel={intl.formatMessage({
        id: 'search.title'
      })}
      classes={classes}
      facets={[]}
      searchCriteria={searchCriteria}
      setSearchCriteria={setSearchCriteria}
      searchInputAriaLabel={intl.formatMessage({
        id: 'search.searchSmartBudget'
      })}
      searchIconAriaLabel={intl.formatMessage({
        id: 'search.advanceSearch'
      })}
    />
  );
};

export default SimpleSearchBox;
