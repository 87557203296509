import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UndoIcon from '@material-ui/icons/Undo';
import { ButtonProps } from '@material-ui/core';
import { Button } from 'common/components';
import {
  useCalculateAttributeContext,
  useLocalBusinessCostContext,
  usePlanTotalContext,
  usePlanUndoContext,
  useRoleTableFormContext,
  useSaveDraftContext
} from 'plans/plan/context';
import { useSaveDraft } from 'plans/plan/hooks';
import { formValuesToRoleDataParameters } from 'plans/hooks';
import { CALCULATE_ATTRIBUTES } from '../CalculateAttributeButton';

const undoIcon = <UndoIcon />;

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(2)
  }
}));

export const PlanUndoButton: React.FC<ButtonProps> = ({
  disabled,
  ...rest
}: ButtonProps) => {
  const classes = useStyles();
  const { setValues } = useRoleTableFormContext();
  const { setBusinessCosts } = useLocalBusinessCostContext();
  const {
    setHasRoleCostChanges,
    setHasBusinessCostChanges,
    setRoleDataParameters,
    setBillingRateValue
  } = useSaveDraftContext();
  const { resetChanges } = useSaveDraft();
  const { roleData } = usePlanTotalContext();
  const {
    setCalculateAttribute,
    setLockedColumns
  } = useCalculateAttributeContext();

  const { pop, history } = usePlanUndoContext();

  const popData = useCallback(() => {
    const item = pop();
    if (item?.billingRatePercent) {
      setBillingRateValue(item.billingRatePercent);
    }
    if (item?.billableState) {
      setHasRoleCostChanges(true);
      setValues({ ...item.billableState });
      setRoleDataParameters(
        formValuesToRoleDataParameters(item.billableState, roleData)
      );
    }
    if (item?.businessCostState) {
      setHasBusinessCostChanges(true);
      setBusinessCosts([...item.businessCostState]);
    }
    if (item?.calculateForItem) {
      setCalculateAttribute(item.calculateForItem);
      setLockedColumns(
        CALCULATE_ATTRIBUTES.filter(
          attribute => item.calculateForItem !== attribute
        )
      );
    }
    if (history.length === 0) {
      resetChanges();
    }
  }, [
    history.length,
    pop,
    resetChanges,
    roleData,
    setBillingRateValue,
    setBusinessCosts,
    setCalculateAttribute,
    setHasBusinessCostChanges,
    setHasRoleCostChanges,
    setLockedColumns,
    setRoleDataParameters,
    setValues
  ]);
  return (
    <Button
      className={classes.root}
      variant="outlined"
      startIcon={undoIcon}
      labelId="button.undo"
      onClick={popData}
      disabled={!Boolean(history.length) || disabled}
      {...rest}
    />
  );
};
