import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useEmbeddedAppContext } from './EmbeddedAppContext';

const useStyles = makeStyles<Theme>({
  root: {
    backgroundColor: '#fafafa',
    minHeight: '100%',
    overflow: 'auto'
  }
});

const BasePage: React.FC = ({ children }) => {
  const { isEmbedded } = useEmbeddedAppContext();
  const classes = useStyles();

  return isEmbedded ? (
    <>{children}</>
  ) : (
    <div className={classes.root}>{children}</div>
  );
};

export default BasePage;
