import BigNumber from 'bignumber.js';

export const serialize = (value: BigNumber): string => {
  return value.toString();
};

export const parseValue = (value: string | number | null): BigNumber | null => {
  return value ? new BigNumber(value) : null;
};

export default {
  BigNumber: {
    serialize,
    parseValue
  }
};
