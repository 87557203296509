import { useCallback } from 'react';
import {
  SmartBudgetResult,
  useUpdateSmartBudgetNameMutation
} from 'generated-types';
import { usePlanContext } from '../context';

export type RenamePlanResult = {
  renamePlan: (planName: string) => void;
};

export const useRenamePlan = (): RenamePlanResult => {
  const [updateSmartBudgetNameMutation] = useUpdateSmartBudgetNameMutation();
  const { plan } = usePlanContext();
  const renamePlan = useCallback(
    (planName: string) => {
      updateSmartBudgetNameMutation({
        variables: {
          input: {
            smartBudgetId: plan?.id ?? '',
            name: planName
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSmartBudgetName: {
            __typename: 'UpdateSmartBudgetNameResult',
            smartBudgetResult: {
              id: plan?.id,
              name: planName
            } as SmartBudgetResult
          }
        }
      });
    },
    [plan, updateSmartBudgetNameMutation]
  );

  return {
    renamePlan: renamePlan
  };
};
