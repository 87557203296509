import { useCallback, MutableRefObject, useState } from 'react';
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { History, UnregisterCallback } from 'history';
import { useHistory } from 'react-router-dom';
import {
  useDialogState,
  useIsBreakpointDown,
  usePlanBlock
} from 'common/hooks';
import { Label } from '../Label';
import { BlockAction } from '../UploadDiscardDialog';
import { ProgressButton } from '..';

type DiscardDialogProps = {
  unblockRef: MutableRefObject<UnregisterCallback | undefined>;
  block?: boolean;
  onSave?: () => Promise<void>;
  saving?: boolean;
};

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: '0 0 20px 0'
  }
}));

export const handleDiscard = (
  closeDialog: () => void,
  history: History,
  unblockNavigation: () => void
): void => {
  closeDialog();
  unblockNavigation();
  history.goBack();
};

export const DiscardDialog: React.FC<DiscardDialogProps> = ({
  unblockRef,
  block = true,
  onSave,
  saving = false
}: DiscardDialogProps) => {
  const classes = useStyles();
  const { open, openDialog, closeDialog } = useDialogState();
  const blockActionState = useState<BlockAction | null>(null);
  const history = useHistory();
  const { unblockNavigation } = usePlanBlock(
    block,
    unblockRef,
    openDialog,
    blockActionState[1]
  );

  const onDiscard = useCallback(
    () => handleDiscard(closeDialog, history, unblockNavigation),
    [closeDialog, history, unblockNavigation]
  );

  const onSaveAndDiscard = useCallback(async () => {
    await onSave?.();
    handleDiscard(closeDialog, history, unblockNavigation);
  }, [closeDialog, history, onSave, unblockNavigation]);

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Label variant="h6" labelId="discardDialog.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Label
          variant="body1"
          labelId="discardDialog.info"
          className={classes.info}
        />
        <Label
          variant="body1"
          labelId="discardDialog.info2"
          className={classes.info}
        />
        <DialogActions>
          <Button onClick={onDiscard} disabled={saving}>
            <FormattedMessage id="discardDialog.leave" />
          </Button>
          <ProgressButton
            color="primary"
            variant="text"
            labelId="discardDialog.saveAndLeave"
            loading={saving}
            onClick={onSaveAndDiscard}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DiscardDialog;
