import { makeStyles } from '@material-ui/core/styles';

export const useModalStyles = makeStyles(theme => ({
  cancel: {
    color: theme.palette.grey[600]
  },
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2, 2)
  },
  dialogAction: {
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: theme.spacing(0, 0, 4.5, 0)
  },
  dateDivider: {
    marginRight: theme.spacing(3)
  }
}));
