import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useEmbeddedAppContext } from 'App/EmbeddedAppContext';

const titleSeparator = ' - ';

export type PageTitlePart = {
  title?: string;
  messageId?: string;
};

export type PageTitleContextValue = {
  pushTitleParts: (newTitleParts?: PageTitlePart[]) => void;
  popTitleParts: (newTitleParts?: PageTitlePart[]) => void;
};

const PageTitleContext = React.createContext<PageTitleContextValue | undefined>(
  undefined
);

const setDocumentTitle = (title: string): void => {
  document.title = title;
};

type PageTitleContextProviderProps = {
  intl: ReactIntl.InjectedIntl;
  setTitle?: (title: string) => void;
  children: React.ReactNode;
};

export const usePageTitleContext = (newParts: PageTitlePart[] = []): void => {
  const val = useContext(PageTitleContext);

  if (!val) {
    throw new Error(
      'usePageTitleContext must be called within a PageTitleContextProvider'
    );
  }

  const { pushTitleParts, popTitleParts } = val;

  useEffect(() => {
    pushTitleParts(newParts);

    return (): void => {
      popTitleParts(newParts);
    };
  }, [newParts, popTitleParts, pushTitleParts, val]);
};

export const PageTitleContextProvider: React.FC<PageTitleContextProviderProps> = ({
  intl,
  setTitle = setDocumentTitle,
  children
}: PageTitleContextProviderProps) => {
  const { isEmbedded } = useEmbeddedAppContext();
  const tabName = document.title;
  const translatedAppName = intl.formatMessage({
    id: 'appName'
  });

  const getInitialTitle = (): string => {
    return isEmbedded
      ? `${tabName}${titleSeparator}${translatedAppName}`
      : tabName;
  };

  const { current: titleParts } = useRef([getInitialTitle()]);

  const renderTitle = useCallback(() => {
    const title = titleParts.join(titleSeparator);

    setTitle(title);
  }, [setTitle, titleParts]);

  const pushTitleParts = useCallback(
    (newParts: PageTitlePart[] = []) => {
      const newTitleParts = newParts
        .map(
          p =>
            p.title ||
            (p.messageId ? intl.formatMessage({ id: p.messageId }) : '')
        )
        .filter(p => p.length > 0);

      titleParts.splice(1, titleParts.length, ...newTitleParts);
      renderTitle();
    },
    [intl, renderTitle, titleParts]
  );

  const popTitleParts = useCallback(
    (newParts: PageTitlePart[] = []) => {
      if (document.title.includes(translatedAppName)) {
        titleParts.splice(1, newParts.length);
        renderTitle();
      }
    },
    [renderTitle, titleParts, translatedAppName]
  );

  const contextValue = useMemo<PageTitleContextValue>(
    () => ({
      pushTitleParts: pushTitleParts,
      popTitleParts: popTitleParts
    }),
    [popTitleParts, pushTitleParts]
  );
  return (
    <PageTitleContext.Provider value={contextValue}>
      {children}
    </PageTitleContext.Provider>
  );
};

export const PageTitleContextConsumer = PageTitleContext.Consumer;
