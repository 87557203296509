import React, { createContext } from 'react';
import { useNumberFormat } from './useNumberFormat';

export type ThousandGroupStyle = 'thousand' | 'lakh' | 'wan';

export type NumberValueSettings = {
  thousandSeparator: string;
  decimalSeparator: string;
  thousandsGroupStyle: ThousandGroupStyle;
  decimalScale: number;
};

export type NumberFormatProviderData = {
  numberFormat: NumberValueSettings;
  currencyFormat: NumberValueSettings;
};

const defaultNumberFormat: NumberValueSettings = {
  thousandSeparator: ',',
  decimalSeparator: '.',
  thousandsGroupStyle: 'thousand',
  decimalScale: 2
};

export const NumberFormatContext = createContext<NumberFormatProviderData>({
  numberFormat: defaultNumberFormat,
  currencyFormat: defaultNumberFormat
});

export const NumberFormatProvider: React.FC = ({ children }) => {
  const formats = useNumberFormat();
  return (
    <NumberFormatContext.Provider value={formats}>
      {children}
    </NumberFormatContext.Provider>
  );
};
