import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useMeContext } from 'App/Me';
import { getLocale } from 'App/MessageProvider/MessageProvider';
import { useLuxonUtil } from './useLuxonUtil';

export const CpoMuiPickersUtilProvider: React.FC = ({ children }) => {
  const LuxonUtil = useLuxonUtil();
  const me = useMeContext();
  const locale = getLocale(me);
  return (
    <MuiPickersUtilsProvider utils={LuxonUtil} locale={locale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};
