import BigNumber from 'bignumber.js';
import { Maybe, RoleBreakdown } from 'generated-types';
import {
  calculateBillingRateForRole,
  calculateRevenueForRole
} from './billingFormHelper';
import { DEFAULT_UTILIZATION, DEFAULT_MARGIN } from './constants';

const calculateTotalRevenueCallback = (margin: BigNumber) => (
  totalRevenue: BigNumber,
  item: Maybe<RoleBreakdown>
): BigNumber => {
  if (item?.loadedCost?.amount) {
    const billingRate = calculateBillingRateForRole(
      item.loadedCost.amount,
      DEFAULT_UTILIZATION,
      margin
    );
    const revenue = calculateRevenueForRole(
      billingRate,
      DEFAULT_UTILIZATION,
      item.totalHours ?? new BigNumber(0)
    );

    return totalRevenue.plus(revenue);
  }
  return totalRevenue;
};

export const getRevenueValue = (
  roleCostBreakdown: Maybe<RoleBreakdown>[] | null | undefined,
  margin = DEFAULT_MARGIN
): BigNumber =>
  (roleCostBreakdown ?? []).reduce(
    calculateTotalRevenueCallback(margin),
    new BigNumber(0)
  );
