import BigNumber from 'bignumber.js';
import { BillingByRoleData, FormData } from 'common/types';
import { RateCardModelState } from '../model';

export const getModelState = (
  data: BillingByRoleData,
  formValues: FormData
): RateCardModelState => {
  return {
    standardRate: data.standardRate.amount ?? new BigNumber(0),
    nonBillableOverhead: data.nonBillableOverhead.amount ?? new BigNumber(0),
    loadedCost: data.loadedCost.amount ?? new BigNumber(0),
    totalHours: data.totalHours,
    businessOverhead: new BigNumber(
      formValues.businessOverhead ??
        data.businessOverhead.amount ??
        new BigNumber(0)
    ),
    margin: new BigNumber(formValues.margin).dividedBy(100),
    utilization: new BigNumber(formValues.utilization).dividedBy(100),
    billingRate: new BigNumber(formValues.billingRate),
    revenue: new BigNumber(formValues.revenue)
  };
};

export const getFormState = (model: RateCardModelState): FormData => {
  return {
    margin: model.margin.times(100).toString(),
    utilization: model.utilization.times(100).toString(),
    billingRate: model.billingRate.toString(),
    revenue: model.revenue.toString(),
    businessOverhead: model.businessOverhead.toString()
  };
};
