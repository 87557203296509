import { useCallback } from 'react';
import {
  usePutTableSettingMutation,
  TableSetting,
  Maybe,
  SortSettingInput
} from 'generated-types';

export const useSaveTableSettings = (): {
  saving: boolean;
  saveTableSettings: (
    tableKey: string,
    columns: string[],
    sort?: SortSettingInput
  ) => Promise<Maybe<TableSetting> | undefined>;
} => {
  const [putTableSettings, { loading: saving }] = usePutTableSettingMutation();

  const saveTableSettings = useCallback(
    async (tableKey: string, columns: string[], sort?: SortSettingInput) => {
      const result = await putTableSettings({
        variables: { input: { tableKey, columns, sort } }
      });
      return result.data?.putTableSetting?.tableSetting;
    },
    [putTableSettings]
  );

  return { saving, saveTableSettings };
};
