import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  Typography,
  AccordionSummary,
  Grid,
  Tooltip
} from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { SmartBudgetResultEdge } from 'generated-types';
import {
  HistoryExpansionDetailsSummary,
  DateRange,
  Date
} from 'common/components';
import { useHasCpoEditPermission } from 'common/hooks';
import HistoryDetailsDownload from './HistoryDetailsDownload';

const useDateStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(3)
  },
  date: {
    ...theme.typography.body1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
    width: '100%',
    paddingRight: theme.spacing(0, 5)
  },
  gridContainer: {
    alignItems: 'flex-end'
  },
  fileNameContainer: {
    display: 'flex',
    paddingLeft: theme.spacing(0.375)
  },
  fileNameActive: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
    marginRight: theme.spacing(1),
    fontWeight: 700
  },
  fileName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
    fontWeight: 700
  },
  info: {
    ...theme.typography.body1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: theme.spacing(3),
    color: theme.palette.text.secondary
  },
  activeIcon: {
    height: 'inherit',
    margin: 'inherit'
  },
  expandIcon: {
    marginRight: theme.spacing(1)
  },
  infoContainer: {
    display: 'contents'
  },
  infoTitle: {
    ...theme.typography.body1,
    fontWeight: 700,
    paddingRight: theme.spacing(1)
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 19,
    marginBottom: 16
  }
}));

const useExpansionPanelSummaryStyles = makeStyles({
  expanded: {
    backgroundColor: '#E0E0E0',
    marginTop: 16
  }
});

export interface HistoryExpansionPanelContentProps {
  file: SmartBudgetResultEdge;
}

export const HistoryExpansionPanelContent: React.FC<HistoryExpansionPanelContentProps> = ({
  file
}: HistoryExpansionPanelContentProps): React.ReactElement => {
  const classes = useStyles();
  const dateStyle = useDateStyles();
  const expansionPanelSummaryClasses = useExpansionPanelSummaryStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  const expansionPanelChangeHandler = useCallback(
    (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpanded(isExpanded);
    },
    [setExpanded]
  );
  const hasCpoEditPermission = useHasCpoEditPermission();
  return (
    <Accordion
      key={file.node.id}
      className={classes.root}
      onChange={expansionPanelChangeHandler}
      TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        aria-controls="panel1bh-content"
        id={`${file.node.fileName} - ${file.node.effectiveDate} / ${file.node.endDate}`}
        classes={expansionPanelSummaryClasses}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={2} className={classes.fileNameContainer}>
            {expanded ? (
              <ExpandLessIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandIcon} />
            )}
            <Tooltip title={file.node.fileName ?? ''}>
              <Typography noWrap className={classes.fileName}>
                {file.node.fileName}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3} className={classes.infoContainer}>
            <Typography className={classes.infoTitle}>
              <FormattedMessage id="historyTable.effectiveDate" />
            </Typography>
            <DateRange
              startDate={file.node.effectiveDate ?? ''}
              endDate={file.node.endDate ?? ''}
              classes={dateStyle}
            />
          </Grid>
          <Grid item xs={2} className={classes.infoContainer}>
            <Typography className={classes.infoTitle}>
              <FormattedMessage id="historyTable.uploaded" />
            </Typography>
            {file.node.lastModified ? (
              <Date className={classes.info} date={file.node.lastModified} />
            ) : (
              <>&mdash;</>
            )}
          </Grid>
          <Grid item xs={2} className={classes.infoContainer}>
            <Typography className={classes.infoTitle}>
              <FormattedMessage id="historyTable.committedBy" />
            </Typography>
            <Typography noWrap className={classes.info}>
              {file.node.uploadedBy?.displayText ?? '-'}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <HistoryExpansionDetailsSummary id={file.node.id} />
      {hasCpoEditPermission && (
        <div className={classes.button}>
          <HistoryDetailsDownload url={file.node.downloadUrl ?? ''} />
        </div>
      )}
    </Accordion>
  );
};

export default HistoryExpansionPanelContent;
