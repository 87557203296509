import { useMemo } from 'react';
import { useRouteBase } from 'App/EmbeddedAppContext';

export const urlJoin = (baseUrl: string, suffix: string): string => {
  const base = baseUrl.endsWith('/')
    ? baseUrl.substr(0, baseUrl.length - 1)
    : baseUrl;
  const end = suffix.startsWith('/') ? suffix.substr(1) : suffix;
  return `${base}/${end}`;
};

export const usePath = (path: string): string => {
  const prefix = useRouteBase() ?? '';
  return useMemo(() => `${urlJoin(prefix, path)}`, [path, prefix]);
};
