import { useRef, useCallback, MutableRefObject, useEffect } from 'react';
import { UnregisterCallback } from 'history';

export const useNavigationBlock = (): {
  unblockRef: MutableRefObject<UnregisterCallback | undefined>;
  unblockNavigation: () => void;
} => {
  const unblockRef = useRef<UnregisterCallback>();
  const unblockNavigation = useCallback(
    () => unblockRef.current && unblockRef.current(),
    []
  );

  useEffect(() => {
    return (): void => {
      unblockNavigation();
    };
  }, [unblockNavigation]);
  return { unblockNavigation, unblockRef };
};
