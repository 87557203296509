import React, { useContext, useState, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { RoleCostRateCalculator } from '@replicon/cost-price-optimizer-models';
import {
  OperationOverhead,
  Money,
  Currency,
  SmartBudgetResult
} from 'generated-types';
import { usePlanContext } from './PlanContext';

type LocalBusinessCostContextValue = {
  businessCosts: OperationOverhead[];
  businessCostsTotal: Money;
  businessCostsTotalHourly: Money;
  setBusinessCosts: (businessCosts: OperationOverhead[]) => void;
};

export const LocalBusinessCostContext = React.createContext<
  LocalBusinessCostContextValue | undefined
>(undefined);

const crc = new RoleCostRateCalculator();

export const useLocalBusinessCostContext = (): LocalBusinessCostContextValue => {
  const value = useContext(LocalBusinessCostContext);
  if (!value) {
    throw new Error(
      'Must be called within a LocalBusinessCostContext provider'
    );
  }

  return value;
};

type LocalBusinessCostContextProviderProps = {
  plan?: SmartBudgetResult;
};

const calculateTotalBusinessCost = (
  baseCurrency: Currency,
  businessCosts: OperationOverhead[]
): Money => {
  return {
    amount: businessCosts.reduce(
      (acc, c) => acc.plus(c.cost?.amount ?? new BigNumber(0)),
      new BigNumber(0)
    ),
    currency: baseCurrency
  };
};

export const LocalBusinessCostContextProvider: React.FC<LocalBusinessCostContextProviderProps> = props => {
  const { plan } = usePlanContext();
  const { children } = props;
  const [businessCosts, setBusinessCosts] = useState(
    plan?.operationOverhead ?? undefined
  );

  if (plan?.operationOverhead && businessCosts === undefined) {
    setBusinessCosts(plan.operationOverhead);
  }

  const contextValue = useMemo<LocalBusinessCostContextValue>(() => {
    const perHour: Money = {
      amount: plan?.totalBillableHours?.isGreaterThan(0)
        ? crc.calculateOverheadRate(
            plan.totalBillableHours,
            businessCosts?.map(b => b.cost?.amount ?? new BigNumber(0)) ?? []
          )
        : new BigNumber(0),
      currency: plan?.currency ?? {}
    };
    return {
      businessCosts: businessCosts ?? [],
      businessCostsTotal: calculateTotalBusinessCost(
        plan?.currency ?? {},
        businessCosts ?? []
      ),
      businessCostsTotalHourly: perHour,
      setBusinessCosts: setBusinessCosts
    };
  }, [businessCosts, plan]);

  return (
    <LocalBusinessCostContext.Provider value={contextValue}>
      {children}
    </LocalBusinessCostContext.Provider>
  );
};
