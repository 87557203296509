import React, { useMemo } from 'react';
import { getFormattedDate } from 'util/date';
import { useMeDateTimeFormats } from 'App/DateTimeFormatProvider';
import { getLocale } from 'App/MessageProvider/MessageProvider';
import { useMeContext } from 'App/Me';

type DateBase = {
  format?: string;
  date?: string | null;
};

const DateBase: React.FC<DateBase> = ({ date, format }) => {
  const { dateFormat } = useMeDateTimeFormats();
  const me = useMeContext();
  const locale = getLocale(me);

  const dateTxt = useMemo(
    () => getFormattedDate(format ?? dateFormat, locale, date),
    [date, dateFormat, format, locale]
  );

  if (!date) {
    return null;
  }

  return <>{dateTxt}</>;
};

export default DateBase;
