import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import BigNumber from 'bignumber.js';
import { Label, NumberEditField } from 'common/components';
import { Money } from 'generated-types';
import { useTotalRevenueChange } from './useTotalRevenueChange';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 13, 1.25, 2),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(9)
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(8.5)
    }
  },
  label: {
    ...theme.typography.body2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.grey[900]
  },
  numberField: {
    paddingLeft: theme.spacing(17.5),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(10.5)
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(7)
    }
  }
}));
const useNumberStyles = makeStyles(theme => ({
  input: {
    ...theme.typography.body1
  }
}));

export interface TotalRevenueProps {
  classes?: ClassNameMap;
  revenueValue: BigNumber;
  loadedCost: BigNumber;
  totalHours: BigNumber;
  totalCost?: Money | null;
  disabled?: boolean;
  handleTotalRevenueChange: (totalRevenue: BigNumber) => void;
  handleMarginChange: (value: string, updateRefs?: boolean) => void;
}

export const TotalRevenue: React.FC<TotalRevenueProps> = ({
  classes,
  revenueValue,
  totalCost,
  loadedCost,
  totalHours,
  disabled,
  handleTotalRevenueChange,
  handleMarginChange
}: TotalRevenueProps) => {
  const cls = useStyles({ classes });
  const inputClasses = useNumberStyles();

  const onTotalRevenueChange = useTotalRevenueChange(
    handleTotalRevenueChange,
    handleMarginChange,
    totalHours,
    loadedCost,
    revenueValue
  );
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={cls.root}>
      <Grid item xs={3}>
        <InputLabel htmlFor="revenue">
          <Label className={cls.label} labelId="billingPage.revenue" />
        </InputLabel>
      </Grid>
      <Grid item xs={9} className={cls.numberField}>
        <NumberEditField
          attribute="revenue"
          id="revenue"
          classes={inputClasses}
          value={revenueValue.toFixed(2)}
          prefix={totalCost?.currency?.symbol ?? ''}
          fullWidth
          allowNegative={false}
          onBlur={onTotalRevenueChange}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
