import React, { useCallback } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';

import { PlanStatus } from 'generated-types';
import {
  usePlanContext,
  usePlanEditContext,
  usePlanUndoContext,
  useSaveDraftContext
} from 'plans/plan/context';
import { ProgressButton } from 'common/components';
import { useDialogState } from 'common/hooks';
import { CommittingDialog, UpdateActivePlanDialog } from 'rateCard';
import { useSaveAndCommit, useSaveDraft } from 'plans/plan/hooks';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2)
  }
}));

const voidFn = () => undefined;

const startIcon = <DoneIcon />;

type DonePlanEditButtonProps = { settingValue?: (value: boolean) => void };

export const DonePlanEditButton: React.FC<DonePlanEditButtonProps> = ({
  settingValue
}: DonePlanEditButtonProps) => {
  const { disablePlanEdit } = usePlanEditContext();
  const { plan } = usePlanContext();
  const classes = useStyles();

  const {
    open: updateOpen,
    openDialog: openUpdateDialog,
    closeDialog: closeUpdateDialog
  } = useDialogState();

  const {
    open: committingDialogOpen,
    openDialog: openCommittingDialog,
    closeDialog: closeCommittingDialog
  } = useDialogState();

  const { reset: resetHistory } = usePlanUndoContext();
  const { setBillingRateValue } = useSaveDraftContext();

  const handleCommitSuccess = useCallback(() => {
    disablePlanEdit();
    setBillingRateValue(new BigNumber(0));
    resetHistory();
  }, [disablePlanEdit, resetHistory, setBillingRateValue]);

  const { canSave, saveDraft, saving } = useSaveDraft();
  const savePlan = useCallback(async () => {
    switch (plan?.status) {
      case PlanStatus.Draft:
        if (canSave) {
          settingValue?.(true);
          await saveDraft();
          settingValue?.(false);
        }
        disablePlanEdit();
        setBillingRateValue(new BigNumber(0));
        resetHistory();
        break;
      case PlanStatus.Active:
      case PlanStatus.Upcoming:
        if (canSave) {
          openUpdateDialog();
        } else {
          disablePlanEdit();
          setBillingRateValue(new BigNumber(0));
          resetHistory();
        }
        break;
      default:
        disablePlanEdit();
        setBillingRateValue(new BigNumber(0));
        resetHistory();
    }
  }, [
    canSave,
    disablePlanEdit,
    plan,
    resetHistory,
    saveDraft,
    setBillingRateValue,
    settingValue,
    openUpdateDialog
  ]);

  const {
    commitFailure,
    commitSuccess,
    loading,
    onSaveClick,
    progressPercentage
  } = useSaveAndCommit(plan, {
    beforeSave: () => {
      closeUpdateDialog();
      openCommittingDialog();
    },
    afterCommit: closeCommittingDialog
  });

  return (
    <>
      <ProgressButton
        loading={saving}
        className={classes.button}
        startIcon={startIcon}
        onClick={savePlan}
        labelId={'button.save'}
        variant="contained"
        color={'primary'}
      />
      <UpdateActivePlanDialog
        open={updateOpen}
        closeDialog={closeUpdateDialog}
        onSaveClick={onSaveClick}
      />
      <CommittingDialog
        open={committingDialogOpen}
        committing={loading}
        closeDialog={closeCommittingDialog}
        commitFailure={commitFailure}
        commitSuccess={commitSuccess}
        handleFailure={voidFn}
        handleSuccess={handleCommitSuccess}
        progressPercentage={progressPercentage}
      />
    </>
  );
};
