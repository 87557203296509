import { useCallback } from 'react';
import {
  usePutSearchSettingsMutation,
  SearchSettings,
  Maybe
} from 'generated-types';

export const useSaveSearchSettings = (): {
  saving: boolean;
  saveSearchSettings: (
    key: string,
    searchSettings: SearchSettings
  ) => Promise<Maybe<SearchSettings> | undefined>;
} => {
  const [
    putSearchSettings,
    { loading: saving }
  ] = usePutSearchSettingsMutation();

  const saveSearchSettings = useCallback(
    async (key: string, searchSettings: SearchSettings) => {
      putSearchSettings({
        variables: {
          input: {
            key,
            settings: {
              criterions: searchSettings.criterions,
              keywords: searchSettings.keywords
            }
          }
        },
        optimisticResponse: {
          putSearchSettings: {
            searchSettings: {
              id: key,
              criterions: searchSettings.criterions,
              keywords: searchSettings.keywords
            }
          }
        }
      });

      return searchSettings;
    },
    [putSearchSettings]
  );

  return { saving, saveSearchSettings };
};
