import React, { useCallback } from 'react';
import { useDebounce } from 'react-use';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import ReplayIcon from '@material-ui/icons/Replay';
import BigNumber from 'bignumber.js';
import { FormattedMessage } from 'react-intl';

import { SummaryCard } from 'common/components';
import { Money } from 'generated-types';
import { useLockedColumnContext } from 'common/context';
import { useTemporaryState } from 'common/hooks';
import { TotalRevenue } from '../TotalRevenue';
import { BillingSlider } from './BillingSlider';

const useStyles = makeStyles({
  root: {
    minWidth: '35%',
    position: 'relative',
    paddingTop: 25
  },
  buttonContainer: {
    position: 'absolute',
    top: 0,
    right: 0
  }
});

export interface UtilizationMarginCardProps {
  classes?: ClassNameMap;
  utilization: BigNumber;
  margin: BigNumber;
  handleUtilizationChange: (value: string) => void;
  handleMarginChange: (value: string) => void;
  handleTotalRevenueChange: (totalRevenue: BigNumber) => void;
  revert: () => void;
  children?: React.ReactNode;
  totalRevenue: BigNumber;
  totalCost?: Money | null;
  loadedCost: BigNumber;
  totalHours: BigNumber;
}

const UPDATE_DEBOUNCE_TIME = 25;

export const UtilizationMarginCard: React.FC<UtilizationMarginCardProps> = ({
  classes,
  utilization,
  handleUtilizationChange,
  margin,
  handleMarginChange,
  handleTotalRevenueChange,
  totalRevenue,
  totalCost,
  totalHours,
  loadedCost,
  revert,
  children
}: UtilizationMarginCardProps) => {
  const cls = useStyles({ classes });
  const { setLockedColumn, lockedColumn } = useLockedColumnContext();
  const onRevertClick = useCallback(() => {
    setLockedColumn(undefined);
    revert();
  }, [revert, setLockedColumn]);

  const [localMargin, setLocalMargin, discardLocalMargin] = useTemporaryState(
    margin
  );
  const [
    localUtilization,
    setLocalUtilization,
    discardLocalUtilization
  ] = useTemporaryState(utilization);

  useDebounce(
    () => {
      if (localMargin) {
        handleMarginChange(localMargin.toString());
        discardLocalMargin();
      }
    },
    UPDATE_DEBOUNCE_TIME,
    [localMargin]
  );

  useDebounce(
    () => {
      if (localUtilization) {
        handleUtilizationChange(localUtilization.toString());
        discardLocalUtilization();
      }
    },
    UPDATE_DEBOUNCE_TIME,
    [localUtilization]
  );

  return (
    <SummaryCard className={cls.root}>
      <div className={cls.buttonContainer}>
        <Tooltip title={<FormattedMessage id="button.revert" />}>
          <IconButton onClick={onRevertClick} aria-label="revert">
            <ReplayIcon fontSize="default" />
          </IconButton>
        </Tooltip>
      </div>
      <BillingSlider
        aria-label="Choose a utilization value"
        aria-valuemin="1"
        aria-valuemax="100"
        value={localUtilization ?? utilization}
        labelId="billingPage.businessUtilizationGoal"
        onChange={useCallback(v => setLocalUtilization(new BigNumber(v)), [
          setLocalUtilization
        ])}
        disabled={lockedColumn === 'utilization'}
      />
      <BillingSlider
        aria-label="Choose a margin value"
        aria-valuemin="1"
        aria-valuemax="100"
        value={localMargin ?? margin}
        labelId="billingPage.businessMargin"
        onChange={useCallback(m => setLocalMargin(new BigNumber(m)), [
          setLocalMargin
        ])}
        max={99}
        disabled={lockedColumn === 'margin' || lockedColumn === 'revenue'}
      />
      <TotalRevenue
        totalHours={totalHours}
        loadedCost={loadedCost}
        totalCost={totalCost}
        revenueValue={totalRevenue}
        handleTotalRevenueChange={handleTotalRevenueChange}
        handleMarginChange={handleMarginChange}
        disabled={lockedColumn === 'margin' || lockedColumn === 'revenue'}
      />
      {children}
    </SummaryCard>
  );
};
