import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useUploadPath } from 'fileUpload';
import { useHasCpoEditPermission } from 'common/hooks';
import { ReactComponent as ActiveEmptyStateIcon } from './ActiveEmptyStateIcon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.grey[800],
    display: 'flex',
    minHeight: 500,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    whiteSpace: 'nowrap',
    paddingBottom: 10
  },
  info: {
    display: 'flex',
    whiteSpace: 'nowrap'
  },
  buttonInfo: {
    display: 'flex',
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(2)
  },
  button: {
    ...theme.typography.body1,
    padding: 0,
    fontWeight: theme.typography.fontWeightBold
  },
  text: {
    paddingRight: 20
  }
}));

export const ActiveEmptyState: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const uploadPath = useUploadPath();

  const hasCpoEditPermission = useHasCpoEditPermission();
  const handleClick = useCallback(() => {
    history.push(uploadPath);
  }, [history, uploadPath]);

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <div className={classes.title}>
          <FormattedMessage id="emptyState.activeTitle" />
        </div>
        <div className={classes.info}>
          <FormattedMessage id="emptyState.activeInfo1" />
        </div>
        <div className={classes.info}>
          <FormattedMessage id="emptyState.activeInfo2" />
        </div>

        {hasCpoEditPermission && (
          <>
            <div className={classes.info}>
              <FormattedMessage id="emptyState.activeInfo3" />
            </div>
            <div className={classes.buttonInfo}>
              <Link
                className={classes.button}
                component="button"
                color="primary"
                onClick={handleClick}>
                <FormattedMessage id="emptyState.activeButton" />
              </Link>
              &nbsp;
              <FormattedMessage id="emptyState.activeButtonInfo" />
            </div>
          </>
        )}
      </div>
      <ActiveEmptyStateIcon />
    </div>
  );
};

export default ActiveEmptyState;
