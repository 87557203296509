import React, { ChangeEvent, useCallback } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useEmbeddedAppContext } from 'App/EmbeddedAppContext';
import StickyHeader from 'common/components/StickyHeader';
import { useTabNavigationContext } from 'common/context';
import { useFeatureFlag } from 'common/hooks';

enum TabValues {
  overview,
  plans
}

type ActualTabs = {
  url: string;
  label: JSX.Element;
};

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1099,
    top: 48
  }
}));
const getUrl = (baseUrl: string, suffix: string) =>
  baseUrl.length ? `/${baseUrl}/${suffix}` : `/${suffix}`;

const tabs = (baseUrl: string, dashboardEnabled: boolean): ActualTabs[] =>
  [
    dashboardEnabled && {
      url: getUrl(baseUrl, 'overview'),
      label: <FormattedMessage id="tabs.overview" />
    },
    {
      url: getUrl(baseUrl, 'plans'),
      label: <FormattedMessage id="tabs.plansList" />
    }
  ].filter((route): route is ActualTabs => Boolean(route));

const PlansTabs: React.FC = () => {
  const dashboard = useFeatureFlag('cpoDashboard');
  const { setTabValue } = useTabNavigationContext();
  const match = useRouteMatch();
  const appContext = useEmbeddedAppContext();
  const actualTabs = tabs(appContext.baseRoute, dashboard);
  const idx = actualTabs.findIndex(t => t.url === match.url);
  const classes = useStyles();
  const tabSelect = useCallback(
    (e: ChangeEvent<unknown>, value: number): void => {
      setTabValue(TabValues[value]);
    },
    [setTabValue]
  );
  return (
    <StickyHeader className={classes.root}>
      <Tabs value={idx} indicatorColor="primary" onChange={tabSelect}>
        {actualTabs.map(t => (
          <Tab key={t.url} component={Link} label={t.label} to={t.url} />
        ))}
      </Tabs>
    </StickyHeader>
  );
};

export default PlansTabs;
