import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Button } from '@material-ui/core';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { RoleRateDialog } from 'common/components/UserSummary/components/RoleRateDialog';
import { ExchangeRateDialog } from 'common/components/RoleCostSummary/components/ExchangeRateDialog';
import {
  useCostOptimizationResultRoleBreakdowns,
  useCostOptimizationResultCurrencyTotals,
  useDialogState
} from 'common/hooks';
import { CalculationInfoDialog } from '../CalculationInfoDialog';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 0, 1, 6.5)
  },
  button: {
    fontWeight: 'bold'
  }
}));

type RateCardButtonRowProps = {
  costOptimizationResultId: string;
  className?: string;
};

const RateCardButtonRow: React.FC<RateCardButtonRowProps> = ({
  costOptimizationResultId,
  className
}) => {
  const classes = useStyles();

  const {
    loading: roleBreakdownsLoading,
    roleBreakdowns,
    result
  } = useCostOptimizationResultRoleBreakdowns(costOptimizationResultId);

  const today = DateTime.local().toObject();

  const {
    loading: currencyLoading,
    costOptimizationResult: currencies
  } = useCostOptimizationResultCurrencyTotals(costOptimizationResultId, today);

  const {
    open: resourceDataOpen,
    openDialog: resourceDataOpenDialog,
    closeDialog: resourceDataCloseDialog
  } = useDialogState();

  const {
    open: currencyDataOpen,
    openDialog: currencyDataOpenDialog,
    closeDialog: currencyDataCloseDialog
  } = useDialogState();

  const {
    open: openCostingModel,
    openDialog: costingModelOpenDialog,
    closeDialog: closeCostingModel
  } = useDialogState();

  return (
    <>
      <div className={classNames(classes.root, className)}>
        <Button
          className={classes.button}
          component="button"
          color="primary"
          disabled={roleBreakdownsLoading}
          onClick={resourceDataOpenDialog}>
          <FormattedMessage id="activePage.resourceButton" />
        </Button>
        <Button
          className={classes.button}
          component="button"
          color="primary"
          disabled={currencyLoading}
          onClick={currencyDataOpenDialog}>
          <FormattedMessage id="activePage.exchangeButton" />
        </Button>
        <Button
          className={classes.button}
          component="button"
          color="primary"
          onClick={costingModelOpenDialog}>
          <FormattedMessage id="activePage.modelButton" />
        </Button>
      </div>

      {!roleBreakdownsLoading && (
        <RoleRateDialog
          open={resourceDataOpen}
          closeDialog={resourceDataCloseDialog}
          roleBreakdowns={roleBreakdowns}
          billableUserCount={result?.billableUserCount}
          nonBillableUserCount={result?.nonBillableUserCount}
          usersCount={result?.usersCount}
          totalHours={result?.totalHours?.toNumber()}
          totalBillableHours={result?.totalBillableHours?.toNumber()}
          totalNonBillableHours={result?.totalNonBillableHours?.toNumber()}
          billableCost={result?.billableCost?.amount?.toNumber()}
          nonBillableCost={result?.nonBillableCost?.amount?.toNumber()}
          totalCost={result?.totalCost?.amount?.toNumber()}
          currency={result?.totalCost?.currency?.symbol}
        />
      )}
      {!currencyLoading && (
        <ExchangeRateDialog
          open={currencyDataOpen}
          closeDialog={currencyDataCloseDialog}
          currencies={currencies?.currencyTotals}
          baseCurrency={currencies?.totalCost?.currency?.symbol?.toString()}
          totalCost={currencies?.totalCost}
        />
      )}
      <CalculationInfoDialog
        open={openCostingModel}
        closeDialog={closeCostingModel}
      />
    </>
  );
};

RateCardButtonRow.propTypes = {
  costOptimizationResultId: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default RateCardButtonRow;
