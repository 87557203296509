import { useCallback, useEffect } from 'react';
import { useForm, FormContextValues } from 'react-hook-form';
import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import { DateTime } from 'luxon';
import { useFeatureFlag } from 'common/hooks';
import { TabValue } from '../AddPlanDialog';

export type CreatePlan = {
  name: string;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  utilization: string;
  margin: string;
  smartBudgetResultId?: string;
  copyRates?: boolean;
  copyUtilization?: boolean;
};

export type AddPlanFormData = FormContextValues<CreatePlan> & {
  onStartDateChange: (date: DateTime | null) => void;
  onEndDateChange: (date: DateTime | null) => void;
};

const useStartAndEndDateField = ({
  register,
  unregister,
  watch,
  tabValue
}: {
  register: FormContextValues['register'];
  unregister: FormContextValues['unregister'];
  watch: FormContextValues['watch'];
  tabValue: TabValue;
}): void => {
  useEffect(() => {
    register(
      { name: 'startDate', required: true },
      { validate: value => value !== null }
    );
    register(
      { name: 'endDate', required: true },
      { validate: value => value !== null }
    );
    register(
      { name: 'smartBudgetResultId', required: true },
      {
        validate: value => {
          return tabValue === TabValue.copyPlan
            ? value !== undefined
            : undefined;
        }
      }
    );
    return (): void => {
      unregister('startDate');
      unregister('endDate');
      unregister('smartBudgetResultId');
    };
  }, [register, tabValue, unregister]);
  watch('startDate');
  watch('endDate');
  watch('smartBudgetResultId');
};

export const useAddPlanForm = (tabValue: TabValue): AddPlanFormData => {
  const {
    register,
    unregister,
    watch,
    triggerValidation,
    setValue,
    getValues,
    ...rest
  } = useForm<CreatePlan>({
    defaultValues: {
      name: '',
      startDate: null,
      endDate: null,
      utilization: RoleBillingRateCalculator.DEFAULT_UTILIZATION.multipliedBy(
        100
      ).toFixed(2),
      margin: RoleBillingRateCalculator.DEFAULT_MARGIN.multipliedBy(
        100
      ).toFixed(2),
      copyRates: true,
      copyUtilization: true
    }
  });
  useStartAndEndDateField({ register, unregister, watch, tabValue });
  const targetBillability = useFeatureFlag('cpoTargetBillability');

  const onStartDateChange = useCallback(
    (startDate: DateTime | null) => {
      if (targetBillability && startDate) {
        startDate = startDate.startOf('month');
      }
      setValue('startDate', startDate);
      triggerValidation('startDate');
      const endDate = getValues().endDate;
      if (endDate && endDate < (startDate as DateTime)) {
        setValue(
          'endDate',
          targetBillability ? startDate?.endOf('month') : startDate
        );
      }
    },
    [getValues, setValue, targetBillability, triggerValidation]
  );

  const onEndDateChange = useCallback(
    (endDate: DateTime | null) => {
      if (targetBillability && endDate) {
        endDate = endDate.endOf('month');
      }
      setValue('endDate', endDate);
      triggerValidation('endDate');
      const startDate = getValues().startDate;
      if (startDate && (endDate as DateTime) < startDate) {
        setValue(
          'startDate',
          targetBillability ? endDate?.startOf('month') : endDate
        );
      }
    },
    [getValues, setValue, targetBillability, triggerValidation]
  );

  return {
    ...rest,
    register,
    unregister,
    setValue,
    getValues,
    watch,
    triggerValidation,
    onStartDateChange,
    onEndDateChange
  };
};
