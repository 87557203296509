import React, { useState, useCallback } from 'react';
import { makeStyles, Tooltip, CardContent } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { AddCircle, Help } from '@material-ui/icons';
import { SmartBudgetResult } from 'generated-types';
import { Button } from 'common/components';
import { usePlanEditContext } from 'plans/plan/context';
import { useMessages } from 'App/MessageProvider';
import { useBusinessCost } from '../hooks';
import { DataCardFooter } from '../../DataCard';
import { NEW_BUSINESS_COST_ID } from '../../cards/constants';
import BusinessCostRow from './BusinessCostRow';

type BusinessCostCardContentProps = {
  plan: SmartBudgetResult;
};

const DEFAULT_RECORDS_VISIBLE = 10;

const useStyles = makeStyles(theme => ({
  content: {
    paddingRight: theme.spacing(1),
    paddingBottom: ({ showPagination }: { showPagination: boolean }) =>
      showPagination ? theme.spacing(0) : theme.spacing(4.5)
  },
  addCostButton: {
    ...theme.typography.body2,
    textTransform: 'none',
    color: theme.palette.action.active,
    float: 'right',
    marginBottom: ({ showPagination }: { showPagination: boolean }) =>
      showPagination ? theme.spacing(0) : theme.spacing(5)
  },
  showMore: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: theme.spacing(3.125)
  },
  businessCardFooter: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5, 2, 0.5, 2)
  }
}));

const useFooterCostNameStyles = makeStyles(theme => ({
  costName: {
    ...theme.typography.caption
  }
}));

const addIcon = <AddCircle color="action" />;

const BusinessCostCardContent: React.FC<BusinessCostCardContentProps> = ({
  plan
}: BusinessCostCardContentProps) => {
  const footerClasses = useFooterCostNameStyles();
  const [showAll, setShowAll] = useState(false);
  const expand = useCallback(() => setShowAll(true), []);
  const collapse = useCallback(() => setShowAll(false), []);
  const {
    businessCosts,
    totalCost,
    handleAddBusinessCost,
    handleCostChange,
    handleNameChange,
    handleCostRemove
  } = useBusinessCost(plan);
  const showPagination = Boolean(
    businessCosts.length && businessCosts.length > DEFAULT_RECORDS_VISIBLE
  );
  const classes = useStyles({ showPagination });
  const visibleRecords =
    showAll === false
      ? businessCosts.slice(0, DEFAULT_RECORDS_VISIBLE)
      : businessCosts;
  const onAddCost = useCallback(() => {
    handleAddBusinessCost();
    expand();
  }, [expand, handleAddBusinessCost]);
  const { canEdit } = usePlanEditContext();
  const messages = useMessages([
    'planPage.businessCostCard.totalResourceCost',
    'planPage.businessCostCard.totalBusinessCost'
  ]);
  return (
    <>
      <CardContent className={classes.content}>
        {visibleRecords?.map(businessCost => (
          <BusinessCostRow
            key={businessCost?.id}
            id={businessCost?.id}
            costName={businessCost?.name ?? ''}
            amount={businessCost?.cost}
            editValue={
              canEdit &&
              Boolean(
                businessCost?.id.includes(NEW_BUSINESS_COST_ID) ||
                  businessCost?.isNew
              )
            }
            editName={
              canEdit &&
              Boolean(
                businessCost?.id.includes(NEW_BUSINESS_COST_ID) ||
                  businessCost?.isNew
              )
            }
            onNameChange={handleNameChange}
            onCostChange={handleCostChange}
            handleCostRemove={handleCostRemove}
          />
        ))}
        <BusinessCostRow
          costName={
            messages['planPage.businessCostCard.totalResourceCost'] ?? ''
          }
          amount={plan?.totalResourceCost}
          costNameIcon={
            <Tooltip
              title={
                <FormattedMessage id="planPage.businessCostCard.totalResourceCostTip" />
              }>
              <Help color="action" />
            </Tooltip>
          }
        />
        {canEdit && (
          <Button
            labelId="planPage.businessCostCard.addCost"
            endIcon={addIcon}
            className={classes.addCostButton}
            onClick={onAddCost}
          />
        )}
        {showPagination && (
          <div className={classes.showMore}>
            {!showAll && (
              <Button
                variant="text"
                color="primary"
                onClick={expand}
                labelId="button.showMore"
              />
            )}
            {showAll && (
              <Button
                variant="text"
                color="primary"
                onClick={collapse}
                labelId="button.showLess"
              />
            )}
          </div>
        )}
      </CardContent>
      <DataCardFooter>
        <div className={classes.businessCardFooter}>
          <BusinessCostRow
            costName={
              messages['planPage.businessCostCard.totalBusinessCost'] ?? ''
            }
            amount={totalCost}
            classes={footerClasses}
          />
        </div>
      </DataCardFooter>
    </>
  );
};

export default BusinessCostCardContent;
