import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Maybe } from 'generated-types';
import { useMeNumberFormats } from 'App/NumberFormatProvider';

export type MoneyValueProps = {
  value: number | string;
  currencySymbol?: Maybe<string>;
  minValue?: number;
  className?: string;
  dataQeId?: string;
  precision?: number;
} & NumberFormatProps;

const nbsp = '\u00a0';

export const MoneyValue: React.FC<MoneyValueProps> = memo(
  ({ value, currencySymbol, minValue, className, dataQeId, ...rest }) => {
    const { currencyFormat } = useMeNumberFormats();
    const amount =
      (minValue || minValue === 0) && value < minValue ? minValue : value;

    return (
      <NumberFormat
        {...(currencySymbol && { prefix: `${currencySymbol}${nbsp}` })}
        {...currencyFormat}
        fixedDecimalScale
        value={amount}
        displayType="text"
        className={className}
        data-qe-id={dataQeId}
        {...rest}
      />
    );
  }
);

MoneyValue.displayName = 'MoneyValue';
MoneyValue.propTypes = {
  currencySymbol: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  minValue: PropTypes.number,
  dataQeId: PropTypes.string,
  precision: PropTypes.number
};

export default MoneyValue;
