import React from 'react';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from 'common/components';
import { costTemplateData } from './costTemplateData';

const useStyles = makeStyles(theme => ({
  csvLink: {
    textDecoration: 'none'
  },
  templateBtn: {
    textTransform: 'none',
    background: 'white',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`
  }
}));

const downloadIcon = <GetAppIcon />;

export const DownloadTemplateButton: React.FC = () => {
  const classes = useStyles();

  return (
    <CSVLink
      data={costTemplateData}
      filename="Cost Template.csv"
      uFEFF={true}
      className={classes.csvLink}>
      <Button
        variant="contained"
        labelId="fileUploadPage.downloadCsvTemplate"
        startIcon={downloadIcon}
        className={classes.templateBtn}
      />
    </CSVLink>
  );
};
