import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDialogState, useFeatureFlag } from 'common/hooks';
import { PlanStatus } from 'generated-types';
import {
  usePlanContext,
  usePlanEditContext,
  usePlanUndoContext
} from 'plans/plan/context';
import PlanSetRatesButton from 'plans/plan/PlanSetRatesButton';
import { Button, SimpleDialog } from 'common/components';
import { useSaveDraft } from 'plans/plan/hooks';
import { DonePlanEditButton } from '../DonePlanEditButton';
import { PlanEditButton } from '../PlanEditButton';
import { PlanUndoButton } from '../PlanUndoButton';

const useStyles = makeStyles(theme => ({
  revert: {
    marginRight: theme.spacing(2)
  },
  undo: {
    marginRight: theme.spacing(2)
  }
}));
export const PlanActions: React.FC = () => {
  const { plan } = usePlanContext();
  const { canEdit, disablePlanEdit, resetPlan } = usePlanEditContext();
  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const classes = useStyles();
  const { canSave } = useSaveDraft();
  const { open, openDialog, closeDialog } = useDialogState();
  const { reset: resetHistory } = usePlanUndoContext();
  const cancelClickAction = useCallback(() => {
    resetPlan();
    disablePlanEdit();
    resetHistory();
  }, [disablePlanEdit, resetHistory, resetPlan]);

  const cancelActionHandler = useCallback(() => {
    if (canSave) {
      openDialog();
    } else {
      cancelClickAction();
    }
  }, [canSave, cancelClickAction, openDialog]);
  const revertClickAction = useCallback(() => {
    resetPlan();
    resetHistory();
  }, [resetHistory, resetPlan]);

  const [setting, settingValue] = useState(false);
  return (
    <>
      {canEdit ? (
        <>
          {cpoNewUx && <PlanUndoButton disabled={setting} />}
          <Button
            className={classes.revert}
            onClick={revertClickAction}
            variant="outlined"
            color="primary"
            labelId="button.reset"
            disabled={setting}
          />
          <Button
            onClick={cancelActionHandler}
            variant="contained"
            labelId="button.cancel"
            disabled={setting}
          />
          <DonePlanEditButton settingValue={settingValue} />
          <SimpleDialog
            titleMessageId="planPage.cancelPlan.title"
            message1Id="planPage.cancelPlan.message1"
            message2Id="planPage.cancelPlan.message2"
            confirmAction={cancelClickAction}
            closeDialog={closeDialog}
            open={open}
          />
        </>
      ) : (
        <>
          {plan?.status === PlanStatus.Draft && <PlanSetRatesButton />}
          {plan?.status !== PlanStatus.Expired && <PlanEditButton />}
        </>
      )}
    </>
  );
};
