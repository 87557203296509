import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useDirection } from 'App/MuiThemeProvider/useDirection';

type OpenInDrawerIconProps = SvgIconProps;

export const OpenInDrawerIcon: React.FC<OpenInDrawerIconProps> = (
  props: OpenInDrawerIconProps
) => {
  const direction = useDirection();
  return (
    <>
      {direction === 'ltr' ? (
        <SvgIcon {...props}>
          <React.Fragment>
            <path d="m 16,3 v 18 h 6 V 3 Z M 9.5,7 8.08984375,8.41015625 10.669921875,11 H 1 v 2 h 9.669921875 L 8.08984375,15.58984375 9.5,17 l 5,-5 z" />
          </React.Fragment>
        </SvgIcon>
      ) : (
        <SvgIcon {...props}>
          <React.Fragment>
            <path d="M7,3V21H1V3Zm6.5,4,1.41,1.41L12.33,11H22v2H12.33l2.58,2.59L13.5,17l-5-5Z" />
          </React.Fragment>
        </SvgIcon>
      )}
    </>
  );
};
