import React, { useCallback, useMemo } from 'react';
import { Button as MuiLinkButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { ProgressButton } from 'common/components';
import { useDeleteCostOptimizationResult, useDialogState } from 'common/hooks';
import { DownloadIcon } from 'common/icons';
import { CostOptimizationResult } from 'generated-types';
import { DeleteConfirmationDialog } from '../DeleteConfirmationDialog';

const downloadIcon = <DownloadIcon />;

const useStyles = makeStyles(theme => ({
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 3, 3, 3)
  },
  downloadBtn: {
    background: 'white',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`
  }
}));

const useDeleteButtonSytles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(4)
  },
  button: {
    background: theme.palette.grey[400],
    color: theme.palette.common.black
  },
  buttonProgress: {
    top: '50%',
    left: '50%',
    marginTop: theme.spacing(-1.5),
    marginLeft: theme.spacing(-1.5)
  }
}));

type UpcominPanelActionsProps = {
  costOptimizationResult: CostOptimizationResult;
  getNextRateCard: (id: string) => CostOptimizationResult | null;
};

export const UpcomingPanelActions: React.FC<UpcominPanelActionsProps> = ({
  costOptimizationResult,
  getNextRateCard
}: UpcominPanelActionsProps) => {
  const classes = useStyles();
  const { closeDialog, open, openDialog } = useDialogState();
  const {
    deleting,
    deleteCostOptimizationResult
  } = useDeleteCostOptimizationResult();
  const nextCostOptimizationResult = useMemo(
    () => getNextRateCard(costOptimizationResult.id),
    [costOptimizationResult.id, getNextRateCard]
  );
  const onDeleteClick = useCallback(() => {
    closeDialog();
    deleteCostOptimizationResult(costOptimizationResult.id);
  }, [closeDialog, costOptimizationResult.id, deleteCostOptimizationResult]);

  return (
    <div className={classes.actionContainer}>
      <MuiLinkButton
        variant="contained"
        startIcon={downloadIcon}
        className={classes.downloadBtn}
        href={costOptimizationResult.downloadUrl ?? ''}
        target="_blank"
        rel="noopener noreferrer">
        <FormattedMessage id="upcoming.download" />
      </MuiLinkButton>
      <ProgressButton
        variant="contained"
        color="default"
        loading={deleting}
        classes={useDeleteButtonSytles()}
        labelId="upcoming.delete"
        onClick={openDialog}
      />
      <DeleteConfirmationDialog
        closeDialog={closeDialog}
        open={open}
        nextCostOptimizationResult={nextCostOptimizationResult}
        onDeleteClick={onDeleteClick}
      />
    </div>
  );
};
