import { useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useHistory } from 'react-router-dom';
import { useCreateSmartBudgetMutation } from 'generated-types';
import { useWorkDataPolling } from 'billing/hooks/useWorkDataPolling';
import { useRouteBase } from 'App/EmbeddedAppContext';
import { urlJoin } from 'common/hooks';
import { CreatePlanWorkData, Error, Warning } from '../types';
import { CreatePlan } from './useAddPlanForm';

export const useCreatePlan = (
  closeDialog: () => void
): {
  creatingPlan: boolean;
  createPlanFailure: boolean;
  createPlanErrors: Error[];
  createPlanWarnings: Warning[];
  ignoreWarning: boolean;
  onCreatePlan: (date: CreatePlan) => void;
} => {
  const [creatingPlan, setCreatingPlan] = useState<boolean>(false);
  const [createPlanFailure, setCreatePlanFailure] = useState<boolean>(false);
  const [createPlanErrors, setCreatePlanErrors] = useState<Error[]>([]);
  const [createPlanWarnings, setCreatePlanWarnings] = useState<Warning[]>([]);
  const [ignoreWarning, setIgnoreWarning] = useState<boolean>(false);
  const [createSmartBudgetMutation] = useCreateSmartBudgetMutation();
  const history = useHistory();
  const basePath = useRouteBase();

  const onCreatePlanFailure = useCallback(
    (workResult?: CreatePlanWorkData) => {
      if (workResult?.errors?.length || workResult?.warnings?.length) {
        setCreatingPlan(false);
        setIgnoreWarning(
          Boolean(!workResult.errors?.length && workResult.warnings?.length)
        );
        workResult?.errors?.length && setCreatePlanErrors(workResult.errors);
        workResult?.warnings?.length &&
          setCreatePlanWarnings(workResult.warnings);

        return;
      }
      setCreatingPlan(false);
      setCreatePlanFailure(true);
    },
    [setCreatingPlan, setCreatePlanFailure]
  );
  const onCreatePlanSuccess = useCallback(
    (workResult?: CreatePlanWorkData) => {
      if (!workResult?.planId) {
        onCreatePlanFailure(workResult);
        return;
      }
      closeDialog();
      setCreatingPlan(false);
      setCreatePlanFailure(false);
      history.push(
        urlJoin(basePath, `plans/${workResult?.planId}?newPlan=true`)
      );
    },
    [closeDialog, basePath, history, onCreatePlanFailure]
  );
  const startPoolingWork = useWorkDataPolling(
    onCreatePlanSuccess,
    onCreatePlanFailure
  );

  const onCreatePlan = useCallback(
    async (createPlan: CreatePlan) => {
      try {
        setCreatingPlan(true);
        setCreatePlanFailure(false);
        setCreatePlanErrors([]);
        setCreatePlanWarnings([]);
        const { data } = await createSmartBudgetMutation({
          variables: {
            input: {
              name: createPlan.name,
              utilization: new BigNumber(createPlan.utilization),
              margin: new BigNumber(createPlan.margin),
              dateRange: {
                startDate: {
                  day: createPlan.startDate?.day,
                  month: createPlan.startDate?.month,
                  year: createPlan.startDate?.year
                },
                endDate: {
                  day: createPlan.endDate?.day,
                  month: createPlan.endDate?.month,
                  year: createPlan.endDate?.year
                }
              },
              ignoreWarning,
              smartBudgetId: createPlan.smartBudgetResultId,
              copyRates: createPlan.copyRates,
              copyUtilization: createPlan.copyUtilization
            }
          }
        });
        if (data?.createSmartBudget?.backgroundWorkId) {
          startPoolingWork(data.createSmartBudget.backgroundWorkId);
        } else {
          setCreatingPlan(false);
        }
      } catch (e) {
        onCreatePlanFailure();
      }
    },
    [
      createSmartBudgetMutation,
      ignoreWarning,
      startPoolingWork,
      onCreatePlanFailure
    ]
  );
  return {
    creatingPlan,
    createPlanFailure,
    createPlanErrors,
    createPlanWarnings,
    ignoreWarning,
    onCreatePlan
  };
};
