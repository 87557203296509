import React from 'react';
import { makeStyles } from '@material-ui/core';
import DateBase from '../Date/DateBase';

type DateRangeBaseProps = {
  startDate?: string | null;
  endDate?: string | null;
  dateClassName?: string;
  hyphenClassName?: string;
};

const useStyles = makeStyles({
  hyphen: {
    padding: '0px 4px 0px 4px'
  }
});

const DateRangeBase: React.FC<DateRangeBaseProps> = ({
  startDate,
  endDate
}) => {
  const classes = useStyles();

  return (
    <>
      <DateBase date={startDate} />
      <span className={classes.hyphen}>&mdash;</span>
      <DateBase date={endDate} />
    </>
  );
};

export default DateRangeBase;
