import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import BigNumber from 'bignumber.js';
import { SummaryCard } from 'common/components';
import { useDialogState } from 'common/hooks';
import { useCostOptimizationResultRoleBreakdownsQuery } from 'generated-types';
import UserSummaryItem from './UserSummaryItem';
import { RoleRateDialog } from './components/RoleRateDialog';
import { RoleRateSkeleton } from './components/RoleRateSkeleton';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(1)
  },
  userContainer: {
    display: 'flex',
    maxWidth: 265,
    height: 136
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
export interface UserSummaryProps {
  costDataId: string;
}

export const UserSummary: React.FC<UserSummaryProps> = ({
  costDataId
}: UserSummaryProps) => {
  const classes = useStyles();

  const { loading, data } = useCostOptimizationResultRoleBreakdownsQuery({
    variables: {
      id: costDataId
    }
  });

  const result = data?.costOptimizationResult;
  const billableRoleBreakdowns = result?.roleCostBreakdown?.map(r => {
    return {
      name: r?.role?.displayText,
      totalCost: r?.totalCost?.amount?.toNumber(),
      hours: r?.totalHours?.toNumber(),
      userCount: r?.userCount?.toNumber()
    };
  });
  const nonBillableRoleBreakdowns = result?.nonBillableRoles?.map(r => {
    return {
      name: r?.role?.displayText,
      totalCost: r?.cost?.toNumber(),
      hours: r?.hours?.toNumber(),
      userCount: r?.userCount?.toNumber()
    };
  });

  const roleBreakdowns = {
    billable: billableRoleBreakdowns,
    nonBillable: nonBillableRoleBreakdowns
  };

  const { open, openDialog, closeDialog } = useDialogState();
  const totalCost = new BigNumber(result?.nonBillableCost?.amount ?? 0).plus(
    result?.billableCost?.amount ?? 0
  );
  return (
    <>
      {loading && <RoleRateSkeleton />}
      {roleBreakdowns && loading === false && (
        <div className={classes.userContainer}>
          <SummaryCard>
            <div className={classes.title}>
              <FormattedMessage id="userDataSummary.title" />
            </div>
            <Grid container>
              <Grid item xs={5}>
                <UserSummaryItem messageId="userDataSummary.billableCount">
                  {result?.billableUserCount}
                </UserSummaryItem>
                <UserSummaryItem messageId="userDataSummary.userCount">
                  {result?.usersCount}
                </UserSummaryItem>
              </Grid>
              <Grid item xs={7}>
                <UserSummaryItem messageId="userDataSummary.nonBillableCount">
                  {result?.nonBillableUserCount}
                </UserSummaryItem>
              </Grid>
              <Grid item xs={12} className={classes.button}>
                <Button component="button" color="primary" onClick={openDialog}>
                  <FormattedMessage id="userDataSummary.userInfoButton" />
                </Button>
              </Grid>
            </Grid>
          </SummaryCard>
        </div>
      )}
      <RoleRateDialog
        open={open}
        closeDialog={closeDialog}
        roleBreakdowns={roleBreakdowns}
        billableUserCount={result?.billableUserCount}
        nonBillableUserCount={result?.nonBillableUserCount}
        usersCount={result?.usersCount}
        totalHours={result?.totalHours?.toNumber()}
        totalBillableHours={result?.totalBillableHours?.toNumber()}
        totalNonBillableHours={result?.totalNonBillableHours?.toNumber()}
        billableCost={result?.billableCost?.amount?.toNumber()}
        nonBillableCost={result?.nonBillableCost?.amount?.toNumber()}
        totalCost={totalCost?.toNumber()}
        currency={result?.totalCost?.currency?.symbol}
      />
    </>
  );
};

export default UserSummary;
