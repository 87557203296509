import React from 'react';
import { makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Skeleton } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { Label, MoneyValue, NumberValue } from 'common/components';
import { Maybe } from 'generated-types';

export type OverviewSummaryItemProps = {
  labelId: string;
  type: 'money' | 'percent';
  goal: number;
  actual: number;
  delta: number;
  loading: boolean;
  align?: 'left' | 'right';
  currencySymbol?: Maybe<string>;
};

const useItemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      textAlign: (props: { align: 'left' | 'right' }) => props.align
    }
  },
  title: {
    ...theme.typography.body2,
    color: theme.palette.grey[700]
  },
  value: {
    ...theme.typography.body1,
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex'
  },
  skeleton: {
    alignSelf: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignSelf: (props: { align: 'left' | 'right' }) =>
        props.align === 'left' ? 'flex-start' : 'flex-end'
    }
  },
  goal: {
    ...theme.typography.body2,
    fontSize: 12,
    color: theme.palette.text.secondary
  }
}));

export const OverviewSummaryItem: React.FC<OverviewSummaryItemProps> = ({
  align = 'left',
  labelId,
  actual,
  goal,
  type,
  loading,
  delta,
  currencySymbol
}: OverviewSummaryItemProps) => {
  const classes = useItemStyles({ align: align });
  if (loading) {
    return (
      <div className={classes.root}>
        <Label className={classes.title} labelId={labelId} />
        <Skeleton className={classes.skeleton} width={100} />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Label className={classes.title} labelId={labelId} />
      <div className={classes.value}>
        {type === 'money' ? (
          <>
            <MoneyValue
              className={classes.value}
              value={actual ?? 0}
              currencySymbol={currencySymbol ?? ''}
            />
            <PercentDifference
              delta={delta}
              actualValue={actual}
              goalValue={goal}
            />
          </>
        ) : (
          <div className={classes.value}>
            <NumberValue value={actual ?? 0} fixedDecimalScale />
            &nbsp;%
            <PercentDifference
              delta={delta}
              actualValue={actual}
              goalValue={goal}
            />
          </div>
        )}
      </div>
      <div className={classes.goal}>
        <FormattedMessage id="overviewPage.overviewSummary.goal" />
        :&nbsp;
        {type === 'money' ? (
          <MoneyValue value={goal ?? 0} currencySymbol={currencySymbol ?? ''} />
        ) : (
          <>
            <NumberValue value={goal ?? 0} fixedDecimalScale />
            &nbsp;%
          </>
        )}
      </div>
    </div>
  );
};

type percentDifferenceProps = {
  actualValue: number;
  goalValue: number;
  delta: number;
};

const usePercentDifferenceStyles = makeStyles(() => ({
  root: {
    color: (props: { valueDifference: boolean }): string => {
      return props.valueDifference ? '#4FAF5C' : '#F94943';
    },
    display: 'flex'
  }
}));
export const PercentDifference: React.FC<percentDifferenceProps> = ({
  actualValue,
  goalValue,
  delta
}: percentDifferenceProps) => {
  const classes = usePercentDifferenceStyles({
    valueDifference: actualValue > goalValue
  });
  return (
    <div className={classes.root}>
      {actualValue > goalValue ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      {delta.toFixed(2)}&nbsp;%
    </div>
  );
};
