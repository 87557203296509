import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Label } from 'common/components';
import { UserCostWarning } from '../../types';

const MAX_USERS_COUNT = 10;
const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export type UsersCostWarningProps = {
  userWarning: UserCostWarning;
};

export const UsersCostWarning: React.FC<UsersCostWarningProps> = ({
  userWarning
}) => {
  const classes = useStyles();
  const users = useMemo(() => userWarning.users.slice(0, MAX_USERS_COUNT), [
    userWarning.users
  ]);
  const values = useMemo(
    () => ({
      userCount: Number(userWarning.userCount - MAX_USERS_COUNT).toString()
    }),
    [userWarning.userCount]
  );
  return (
    <>
      {users.map((user: { displayText: string; uri: string }) => (
        <Label key={user.uri} className={classes.bold}>
          {user.displayText}
        </Label>
      ))}
      {userWarning.userCount > MAX_USERS_COUNT && (
        <Label
          labelId="addPlanDialog.warnings.noUsersCost.more"
          values={values}
        />
      )}
    </>
  );
};
