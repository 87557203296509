import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import BigNumber from 'bignumber.js';

import { isMoney } from 'util/money';
import { BillingByRoleData } from 'common/types';
import { TooltipMoney } from 'common/components';

type CalculatedTotalCellComponent = React.FC<CellProps<BillingByRoleData>>;

export const makeCalculatedTotalCell = (
  rateAttributeKey: keyof BillingByRoleData
): CalculatedTotalCellComponent => {
  const CalculatedTotalComponent: CalculatedTotalCellComponent = props => {
    const { row } = props;
    const totalHours = row.values.totalHours;
    const rateValue = row.values[rateAttributeKey];
    if (!isMoney(rateValue)) {
      throw new Error('Can only use a CalculatedTotalCell on Money attributes');
    }
    if (!BigNumber.isBigNumber(totalHours)) {
      throw new Error('Must have totalHours value to calculate.');
    }

    const calculatedValue = useMemo(() => {
      return (rateValue.amount ?? new BigNumber(0)).times(totalHours);
    }, [rateValue.amount, totalHours]);

    return (
      <TooltipMoney
        value={calculatedValue.toNumber()}
        currencySymbol={rateValue.currency?.symbol}
        placement="right"
      />
    );
  };

  return CalculatedTotalComponent;
};
