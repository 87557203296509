import { useCallback, useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { useCpoBackgroundWorkQuery, WorkStatus } from 'generated-types';

export const POLLING_INTERVAL = 5000;

export const useWorkDataPolling = (
  onCommitSuccess: (workResult?: any) => void,
  onCommitFailure: (workResult?: any) => void,
  getProgress?: boolean,
  onUpdateProgress?: (progressPercentage?: BigNumber | null) => void
): ((workId: string, interval?: number) => void) => {
  const [workId, setWorkId] = useState<string | undefined>();
  const {
    startPolling,
    stopPolling,
    data,
    error,
    loading
  } = useCpoBackgroundWorkQuery({
    variables: { workId: workId as string },
    skip: typeof workId !== 'string' || workId.length <= 0
  });
  const reset = useCallback(() => {
    setWorkId(undefined);
    stopPolling();
  }, [stopPolling]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      reset();
      onCommitFailure();
    }

    if (data?.cpoBackgroundWork?.workStatus === WorkStatus.Success) {
      reset();
      onCommitSuccess(data.cpoBackgroundWork.workResult);
    } else if (
      data?.cpoBackgroundWork?.workStatus === WorkStatus.InProgress &&
      getProgress
    ) {
      onUpdateProgress?.(data.cpoBackgroundWork?.progressPercentage);
    } else if (data?.cpoBackgroundWork?.workStatus === WorkStatus.Failed) {
      reset();
      onCommitFailure(data.cpoBackgroundWork.workResult);
    }
  }, [
    data,
    error,
    getProgress,
    loading,
    onCommitFailure,
    onCommitSuccess,
    onUpdateProgress,
    reset
  ]);

  return useCallback(
    (workId: string, interval = POLLING_INTERVAL) => {
      setWorkId(workId);
      startPolling(interval);
    },
    [startPolling]
  );
};
