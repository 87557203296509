import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import { Label } from '../Label';
import { Date } from '../Date';

export interface DateRangeProps {
  classes?: ClassNameMap;
  startDate?: string;
  endDate?: string;
  className?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    color: theme.palette.grey[700],
    alignItems: 'center'
  },
  date: {},
  hyphen: {
    padding: theme.spacing(0, 0.5, 0, 0)
  }
}));

export const DateRange: React.FC<DateRangeProps> = ({
  classes,
  startDate,
  endDate,
  className
}: DateRangeProps) => {
  const cls = useStyles({ classes });

  return (
    <div className={classnames(className, cls.root)}>
      <Date date={startDate} className={cls.date} />
      <Label className={cls.hyphen}>&mdash;</Label>
      <Date date={endDate} className={cls.date} />
    </div>
  );
};
