import React, { useContext } from 'react';
import { ApolloError } from '@apollo/client';

import { CostOptimizationResult, Maybe } from 'generated-types';

export type PlanWarningContextValue = {
  plan?: Maybe<CostOptimizationResult>;
  loading: boolean;
  error?: Maybe<ApolloError>;
};

export const PlanWarningContext = React.createContext<
  Maybe<PlanWarningContextValue>
>(null);

export const usePlanWarningContext = (): PlanWarningContextValue => {
  const val = useContext(PlanWarningContext);
  if (!val) {
    throw new Error(
      'usePlanWarningContext must be called within a PlanContextProvider with a resolved CostOptimizationResult.'
    );
  }

  return val;
};

export const PlanWarningContextProvider = PlanWarningContext.Provider;
export const PlanWarningContextConsumer = PlanWarningContext.Consumer;
