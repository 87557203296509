import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';
import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import BigNumber from 'bignumber.js';
import { FieldValues } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import {
  BillingByRoleData,
  FormData,
  RoleData,
  ColumnOnChange
} from 'common/types';
import { isValidNumber, clamp, roundUpToX } from 'util/number';
import {
  calculateGlobalMargin as calculateMargin,
  DECIMAL_PLACES
} from 'rateCard/helpers';
import {
  RateCardModel,
  utilizationMarginBillingRateModel
} from 'rateCard/model';
import { getModelState, getFormState } from 'rateCard/helpers/getModelState';
import {
  CalculateAttribute,
  PlanStateItem,
  usePlanEditContext,
  usePlanUndoContext,
  useSaveDraftContext
} from 'plans/plan/context';
import { useFeatureFlag } from 'common/hooks';
import { RoleBreakdownParameters, SmartBudgetResult } from 'generated-types';
import { PlanFormStateEntry } from './usePlanTableFormReducer';

const MIN_MARGIN = 1;
const MAX_MARGIN = 99.99;
const MIN_UTILIZATION = 0.01;
const MAX_UTILIZATION = 100;
const roleBillingRateCalculator = new RoleBillingRateCalculator();

const getValue = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
): string => (typeof event === 'string' ? event : event.target.value);

type IntermediateFormState = {
  [roleBreakdownId: string]: PlanFormStateEntry<string>;
};

export const calculateTotalRevenue = (
  billingData: BillingByRoleData[],
  values: FieldValues
): BigNumber => {
  return billingData
    .map(data => new BigNumber(values[data.id].revenue))
    .reduce((acc, rev) => acc.plus(rev), new BigNumber(0));
};

export const calculateGlobalMargin = (
  billingData: BillingByRoleData[],
  values: FieldValues,
  totalCost: BigNumber,
  handleTotalRevenueChange?: (totalRevenue: BigNumber) => void,
  handleTotalProfitChange?: (totalRevenue: BigNumber) => void
): BigNumber => {
  const totalRevenue = calculateTotalRevenue(billingData, values);
  handleTotalRevenueChange?.(totalRevenue);
  handleTotalProfitChange?.(totalRevenue.minus(totalCost));
  const totalHours = billingData.reduce(
    (total, hours) => total.plus(hours?.totalHours ?? new BigNumber(0)),
    new BigNumber(0)
  );
  const loadedCost = totalCost.dividedBy(totalHours);

  return calculateMargin(totalRevenue, loadedCost, totalHours);
};

export const calculateGlobalUtilization = (
  billingData: BillingByRoleData[],
  values: FieldValues
): BigNumber => {
  const totalHours = billingData
    .map(billing => ({
      workHours: billing.totalHours,
      likelyHours: billing.totalHours.multipliedBy(
        new BigNumber(values[billing.id].utilization).dividedBy(100)
      )
    }))
    .reduce(
      (acc, value) => ({
        totalHours: acc.totalHours.plus(value.workHours),
        totalLikelyHours: acc.totalLikelyHours.plus(value.likelyHours)
      }),
      {
        totalHours: new BigNumber(0),
        totalLikelyHours: new BigNumber(0)
      }
    );

  return roleBillingRateCalculator
    .calculateGlobalUtilization(
      totalHours.totalLikelyHours,
      totalHours.totalHours
    )
    .multipliedBy(100);
};

export const isValueModified = (
  id: string,
  key: keyof FormData,
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string,
  getValues: () => RoleData
): boolean => {
  const value = getValue(event);
  const formValue = getValues()[id][key];
  if (formValue === undefined) {
    return true;
  }

  if (isValidNumber(value)) {
    const previousValue = new BigNumber(formValue).decimalPlaces(
      DECIMAL_PLACES
    );
    const newValue = new BigNumber(value);
    return !previousValue.isEqualTo(newValue);
  }
  return true;
};

export const formValuesToRoleDataParameters = (
  formData: RoleData,
  billingData: BillingByRoleData[]
): {
  roleUri: string;
  utilization: BigNumber;
  margin: BigNumber;
}[] => {
  return Object.keys(formData).map(k => {
    const f = formData[k];
    return {
      roleUri: billingData.find(data => data.id === k)?.roleId ?? '',
      utilization: new BigNumber(f.utilization),
      margin: new BigNumber(f.margin)
    };
  });
};

type RoleFieldEventHandler = (
  key: keyof FormData,
  id: string
) => (
  event: string | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => void;

const handleRateCardFieldChange = ({
  billingData,
  setValue,
  getValues,
  setRoleDataParameters,
  recalculateGlobalValues,
  valueTransformer,
  rateCardModel,
  pushPlanState,
  lockedColumns
}: {
  billingData: BillingByRoleData[];
  setValue: (id: string, value: PlanFormStateEntry<string>) => void;
  getValues: () => RoleData;
  setRoleDataParameters: (parameters: RoleBreakdownParameters[]) => void;
  recalculateGlobalValues: () => void;
  rateCardModel: RateCardModel;
  valueTransformer?: (v: BigNumber) => BigNumber;
  pushPlanState: (item: PlanStateItem) => void;
  lockedColumns: CalculateAttribute[];
}): RoleFieldEventHandler => {
  return (
    key: keyof FormData,
    id: string
  ): ((
    event: string | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void) => {
    const model = new RateCardModel(rateCardModel);
    const handler = (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
    ): void => {
      const roleData = billingData.find(data => data.id === id);
      if (!roleData) {
        return;
      }

      const currentFormState = getValues()[id];
      if (!isValueModified(id, key, event, getValues)) {
        return;
      }

      const newValue = new BigNumber(getValue(event));
      if (newValue.isNaN()) {
        return;
      }

      const transformedValue = valueTransformer
        ? valueTransformer(newValue)
        : newValue;

      const newState = model.setColumnValue(
        getModelState(roleData, currentFormState),
        key,
        transformedValue,
        {
          lockedColumns
        }
      );

      const newStateValue = {
        ...currentFormState,
        ...getFormState(newState)
      } as PlanFormStateEntry<string>;
      setValue(id, newStateValue);

      recalculateGlobalValues();
      pushPlanState({
        billableState: { ...getValues() }
      });
      setRoleDataParameters(
        formValuesToRoleDataParameters(
          {
            ...getValues(),
            [id]: newStateValue
          },
          billingData
        )
      );
    };
    return handler;
  };
};

const onBusinessCostChange = ({
  billingData,
  rateCardModel,
  getValues,
  setValues,
  setRoleDataParameters,
  recalculateGlobalValues,
  pushPlanState,
  lockedColumns
}: {
  billingData: BillingByRoleData[];
  rateCardModel: RateCardModel;
  getValues: () => RoleData;
  setValues: (values: FieldValues) => void;
  setRoleDataParameters: (parameters: RoleBreakdownParameters[]) => void;
  recalculateGlobalValues: () => void;
  pushPlanState: (item: PlanStateItem) => void;
  lockedColumns: CalculateAttribute[];
}) => (newBusinessCost: BigNumber) => {
  const model = new RateCardModel(rateCardModel);
  const newValues: IntermediateFormState = {};

  billingData.forEach(r => {
    const currentFormState = getValues()[r.id];
    const newState = model.setColumnValue(
      getModelState(r, currentFormState),
      'businessOverhead',
      newBusinessCost,
      {
        lockedColumns
      }
    );

    newValues[r.id] = {
      ...currentFormState,
      ...getFormState(newState)
    } as PlanFormStateEntry<string>;
  });

  pushPlanState({
    billableState: getValues(),
    popAgain: true
  });
  setValues(newValues);
  setRoleDataParameters(formValuesToRoleDataParameters(newValues, billingData));
  recalculateGlobalValues();
};

const onNearestXBillingRate = ({
  billingData,
  rateCardModel,
  getValues,
  setValues,
  setRoleDataParameters,
  recalculateGlobalValues,
  pushPlanState,
  lockedColumns
}: {
  billingData: BillingByRoleData[];
  rateCardModel: RateCardModel;
  getValues: () => RoleData;
  setValues: (values: FieldValues) => void;
  setRoleDataParameters: (parameters: RoleBreakdownParameters[]) => void;
  recalculateGlobalValues: () => void;
  lockedColumn?: keyof FormData;
  pushPlanState: (item: PlanStateItem) => void;
  lockedColumns: CalculateAttribute[];
}) => (nearestXValue: BigNumber) => {
  if (nearestXValue.isNaN()) {
    return;
  }
  const model = new RateCardModel(rateCardModel);
  const newValues: IntermediateFormState = {};

  billingData.forEach(r => {
    const currentFormState = getValues()[r.id];
    const newState = model.setColumnValue(
      getModelState(r, currentFormState),
      'billingRate',
      roundUpToX(new BigNumber(currentFormState.billingRate), nearestXValue),
      {
        lockedColumns
      }
    );

    newValues[r.id] = {
      ...currentFormState,
      ...getFormState(newState)
    } as PlanFormStateEntry<string>;
  });
  pushPlanState({
    billableState: getValues()
  });
  setValues(newValues);
  setRoleDataParameters(formValuesToRoleDataParameters(newValues, billingData));
  recalculateGlobalValues();
};

const calculateBillingRateByPercent = (
  billingRate: BigNumber | null | undefined,
  percent: BigNumber
): BigNumber => {
  return new BigNumber(
    billingRate?.plus(billingRate.multipliedBy(percent.dividedBy(100))) ?? 0
  );
};

const billingRateUpdate = (
  plan: SmartBudgetResult | null | undefined,
  percent: BigNumber,
  roleId: string
): BigNumber => {
  const roleDetails = plan?.roleCostBreakdown?.find(role => role.id === roleId);
  return roleDetails
    ? calculateBillingRateByPercent(roleDetails?.billingCost?.amount, percent)
    : calculateBillingRateByPercent(plan?.billingRate?.amount, percent);
};

export const onGlobalValueChange = ({
  getValues,
  setValues,
  valueKey,
  setKeyValue,
  billingData,
  rateCardModel,
  setRoleDataParameters,
  pushPlanState,
  lockedColumns,
  plan
}: {
  getValues: () => FieldValues;
  setValues: (values: FieldValues) => void;
  billingData: BillingByRoleData[];
  valueKey: 'margin' | 'utilization' | 'billingRate';
  setKeyValue: Dispatch<SetStateAction<BigNumber>>;
  setRoleDataParameters: (parameters: RoleBreakdownParameters[]) => void;
  pushPlanState: (item: PlanStateItem) => void;
  rateCardModel?: RateCardModel;
  lockedColumns: CalculateAttribute[];
  plan?: SmartBudgetResult | null | undefined;
}): ((value: string) => void) => {
  return (value: string) => {
    const values = getValues();
    const model = new RateCardModel(rateCardModel);
    const newValues: IntermediateFormState = {};
    const percent = new BigNumber(value);
    billingData.forEach(data => {
      const newState = model.setColumnValue(
        getModelState(data, values[data.id]),
        valueKey,
        valueKey !== 'billingRate'
          ? percent.dividedBy(100)
          : billingRateUpdate(plan, percent, data.id),
        {
          lockedColumns
        }
      );

      newValues[data.id] = {
        ...values[data.id],
        ...getFormState(newState)
      };
    });
    setValues(newValues);
    pushPlanState({
      billableState: values,
      popAgain: valueKey === 'billingRate'
    });
    setKeyValue(new BigNumber(value));
    setRoleDataParameters(
      formValuesToRoleDataParameters(newValues, billingData)
    );
  };
};

export type BillingEditHandlers = {
  onGlobalUtilizationChangeHandler: (value: string) => void;
  onGlobalMarginChangeHandler: (value: string) => void;
  onGlobalBillingRateChangeHandler: (value: string) => void;
  onUtilizationChangeHandler: ColumnOnChange;
  onMarginChangeHandler: ColumnOnChange;
  onBillingRateChangeHandler: ColumnOnChange;
  onRevenueChangeHandler: ColumnOnChange;
  onBusinessCostChangeHandler: (newBusinessCost: BigNumber) => void;
  onNearestToXBillingRate: (nearestToXValue: BigNumber) => void;
  utilization: BigNumber;
  margin: BigNumber;
  billingRate: BigNumber;
  revenue: BigNumber;
  profit: BigNumber;
};

export const usePlanTableEditHandlers = ({
  billingData,
  utilization: initialUtilization,
  margin: initialMargin,
  setValue,
  setValues,
  getValues,
  totalCost,
  lockedColumns,
  rateCardModel = utilizationMarginBillingRateModel,
  plan
}: {
  billingData: BillingByRoleData[];
  utilization: BigNumber;
  margin: BigNumber;
  setValue: (id: string, value: PlanFormStateEntry<string>) => void;
  setValues: (values: FieldValues) => void;
  getValues: () => RoleData;
  totalCost: BigNumber;
  lockedColumns: CalculateAttribute[];
  rateCardModel?: RateCardModel;
  plan: SmartBudgetResult | null | undefined;
}): BillingEditHandlers => {
  const totalRevenue = useMemo(
    () => calculateTotalRevenue(billingData, getValues()),
    [billingData, getValues]
  );
  const totalProfit = useMemo(() => totalRevenue.minus(totalCost), [
    totalCost,
    totalRevenue
  ]);
  const { push } = usePlanUndoContext();
  const cpoNewUx = useFeatureFlag('cpoNewUx');

  const [billingRate, setBillingRate] = useState<BigNumber>(new BigNumber(0));
  const [utilization, setUtilization] = useState<BigNumber>(initialUtilization);
  const [margin, setMargin] = useState<BigNumber>(initialMargin);
  const [revenue, setRevenue] = useState<BigNumber>(totalRevenue);
  const [profit, setProfit] = useState<BigNumber>(totalProfit);
  const recalculateGlobalValues = useCallback(() => {
    if (billingData.length) {
      setUtilization(calculateGlobalUtilization(billingData, getValues()));
      setMargin(
        calculateGlobalMargin(
          billingData,
          getValues(),
          totalCost,
          setRevenue,
          setProfit
        )
      );
    }
  }, [billingData, getValues, totalCost]);

  const { setRoleDataParameters, snapToNearestXValue } = useSaveDraftContext();

  const makeHandler = useCallback(
    (
      transformationFunction?: (v: BigNumber) => BigNumber
    ): RoleFieldEventHandler =>
      handleRateCardFieldChange({
        billingData,
        setValue,
        getValues,
        setRoleDataParameters,
        recalculateGlobalValues,
        rateCardModel,
        valueTransformer: transformationFunction,
        pushPlanState: push,
        lockedColumns
      }),
    [
      billingData,
      getValues,
      lockedColumns,
      push,
      rateCardModel,
      recalculateGlobalValues,
      setRoleDataParameters,
      setValue
    ]
  );

  const onUtilizationChange = useCallback(
    makeHandler(v => {
      return clamp(
        v,
        new BigNumber(MIN_UTILIZATION),
        new BigNumber(MAX_UTILIZATION)
      ).dividedBy(100);
    }),
    [makeHandler]
  );
  const onMarginChange = useCallback(
    makeHandler(v => {
      return clamp(
        v,
        new BigNumber(MIN_MARGIN),
        new BigNumber(MAX_MARGIN)
      ).dividedBy(100);
    }),
    [makeHandler]
  );
  const onBillingRateChange = useCallback(
    makeHandler(v => {
      if (cpoNewUx) {
        return v;
      }
      return roundUpToX(v, snapToNearestXValue);
    }),
    [makeHandler, snapToNearestXValue]
  );
  const onRevenueChange = useCallback(makeHandler(), [makeHandler]);
  const { canEdit } = usePlanEditContext();

  useEffect(() => {
    recalculateGlobalValues();
  }, [billingData, recalculateGlobalValues]);

  useEffectOnce(() => {
    if (!canEdit) {
      setRoleDataParameters(
        formValuesToRoleDataParameters(getValues(), billingData)
      );
    }
  });

  const onGlobalBillingRateChange = useCallback(
    onGlobalValueChange({
      getValues,
      setValues,
      valueKey: 'billingRate',
      setKeyValue: setBillingRate,
      setRoleDataParameters,
      pushPlanState: push,
      billingData,
      rateCardModel,
      plan,
      lockedColumns
    }),
    [
      billingData,
      getValues,
      rateCardModel,
      setRoleDataParameters,
      setValue,
      push,
      lockedColumns
    ]
  );
  const onGlobalUtilizationChange = useCallback(
    onGlobalValueChange({
      getValues,
      setValues,
      valueKey: 'utilization',
      setKeyValue: setUtilization,
      billingData,
      rateCardModel,
      setRoleDataParameters,
      pushPlanState: push,
      lockedColumns
    }),
    [
      billingData,
      getValues,
      rateCardModel,
      setRoleDataParameters,
      setValue,
      lockedColumns
    ]
  );

  const onGlobalMarginChange = useCallback(
    onGlobalValueChange({
      getValues,
      setValues,
      valueKey: 'margin',
      setKeyValue: setMargin,
      billingData,
      rateCardModel,
      setRoleDataParameters,
      pushPlanState: push,
      lockedColumns
    }),
    [
      billingData,
      getValues,
      rateCardModel,
      setRoleDataParameters,
      setValue,
      lockedColumns
    ]
  );
  return {
    onGlobalUtilizationChangeHandler: onGlobalUtilizationChange,
    onGlobalBillingRateChangeHandler: onGlobalBillingRateChange,
    onGlobalMarginChangeHandler: onGlobalMarginChange,
    onUtilizationChangeHandler: onUtilizationChange,
    onMarginChangeHandler: onMarginChange,
    onBillingRateChangeHandler: onBillingRateChange,
    onRevenueChangeHandler: onRevenueChange,
    onBusinessCostChangeHandler: useCallback(
      onBusinessCostChange({
        billingData,
        rateCardModel,
        getValues,
        setValues,
        setRoleDataParameters,
        recalculateGlobalValues,
        pushPlanState: push,
        lockedColumns
      }),
      [billingData, lockedColumns]
    ),
    onNearestToXBillingRate: useCallback(
      onNearestXBillingRate({
        billingData,
        rateCardModel,
        getValues,
        setValues,
        setRoleDataParameters,
        recalculateGlobalValues,
        pushPlanState: push,
        lockedColumns
      }),
      [billingData, lockedColumns]
    ),
    utilization,
    billingRate,
    margin,
    revenue,
    profit
  };
};
