import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import classnames from 'classnames';
import { useMessage } from 'App/MessageProvider';
import { PLAN_NAME_CHARACTER_LIMIT } from 'plans/constants';
import { usePlanEditContext } from 'plans/plan/context';
import { useRenamePlan } from 'plans/plan/hooks';

const useStyles = makeStyles(theme => ({
  nonEditableName: {
    maxWidth: '17em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  nameField: {
    width: '17em'
  },
  nameInput: {
    ...theme.typography.h6
  },
  dotted: {
    borderBottom: '1px dotted #000'
  }
}));

type PlanNameProps = {
  name?: string;
};

export const PlanName: React.FC<PlanNameProps> = ({ name }) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [planName, setPlanName] = useState(name);
  const errorMessage = useMessage('planPage.header.nameRequired');

  const { canEdit } = usePlanEditContext();
  useEffect(() => {
    setPlanName(name);
  }, [name]);

  const onNameClick = useCallback(() => {
    setEditing?.(true);
  }, []);
  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      setPlanName(newName);
    },
    []
  );
  const { renamePlan } = useRenamePlan();
  const onSaveName = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
    ) => {
      const value = typeof event === 'string' ? event : event.target.value;
      if (value) {
        renamePlan(value);
        setEditing?.(false);
      }
    },
    [renamePlan]
  );

  if (!canEdit) {
    return <span className={classes.nonEditableName}>{planName}</span>;
  }

  return !editing ? (
    <span
      className={classnames(classes.nonEditableName, classes.dotted)}
      onClick={onNameClick}>
      {planName}
    </span>
  ) : (
    <TextField
      autoFocus
      required
      className={classes.nameField}
      id="name"
      name="name"
      variant="standard"
      defaultValue={planName}
      error={Boolean(!planName)}
      helperText={Boolean(!planName) && errorMessage}
      inputProps={{
        maxLength: PLAN_NAME_CHARACTER_LIMIT,
        className: classes.nameInput
      }}
      onChange={onNameChange}
      onBlur={onSaveName}
    />
  );
};
