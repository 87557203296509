import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  quotient: {
    display: 'flex',
    alignItems: 'center'
  },
  formula: {
    textAlign: 'right'
  }
}));

export interface CalculationInfoAdditionProps {
  standardRate: string;
  nonBillableOverhead: string;
  businessCostOverhead: string;
  quotient: string;
}

export const CalculationInfoAddition: React.FC<CalculationInfoAdditionProps> = ({
  standardRate,
  nonBillableOverhead,
  businessCostOverhead,
  quotient
}: CalculationInfoAdditionProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.quotient}>
        <FormattedMessage id={quotient} />
      </div>
      <div>&nbsp;=&nbsp;</div>
      <div className={classes.formula}>
        <FormattedMessage id={standardRate} /> +{' '}
        <FormattedMessage id={nonBillableOverhead} /> +{' '}
        <FormattedMessage id={businessCostOverhead} />
      </div>
    </div>
  );
};

export default CalculationInfoAddition;
