import React, { useState, useCallback, memo } from 'react';
import {
  Table,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TableFooterRow,
  TableFooterCell
} from '@replicon/react-data-table';
import {
  makeStyles,
  Card,
  CardContent,
  TableCellProps,
  Typography,
  Button,
  TableFooter
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Skeleton } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'App/MessageProvider';
import { Maybe, SmartBudgetResult } from 'generated-types';
import { NumberValue, TooltipMoney } from 'common/components';
import { NoDataItem } from 'common/components/NoDataItem';
import { DataCardHeader } from '../DataCard';
import { RTLOverride } from '../components';

type NonBillableResourcesCardProps = {
  plan?: Maybe<SmartBudgetResult>;
  loading?: boolean;
};

const TOTAL_COLUMN_COUNT = 4;
const DEFAULT_RECORDS_VISIBLE = 10;
const RESOURCES_CARD_WIDTH = '100%';
const ROLE_COLUMN_WIDTH = 150;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: RESOURCES_CARD_WIDTH,
    marginLeft: 12,
    marginTop: 12,
    minHeight: 'unset',
    height: '100%'
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  tableRow: {
    '& > td': {
      overflow: 'hidden'
    },
    '& > td:first-child, th:first-child': {
      paddingLeft: 24,
      [theme.breakpoints.down('lg')]: {
        width: 120,
        maxWidth: 120
      },
      width: ROLE_COLUMN_WIDTH,
      maxWidth: ROLE_COLUMN_WIDTH
    },
    '& > td:last-child, th:last-child': {
      [theme.breakpoints.down('lg')]: {
        paddingRight: 14
      },
      paddingRight: 32
    },
    '& > td:nth-child(2), th:nth-child(2)': {
      [theme.breakpoints.down('lg')]: {
        paddingRight: 5,
        width: 200,
        maxWidth: 200
      },
      width: 100,
      maxWidth: 100
    },
    '& > td:nth-child(3), th:nth-child(3)': {
      width: 172,
      maxWidth: 172
    }
  },
  showMore: {
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 16
  },
  headCount: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontSize: 10
  }
}));

const SkeletonCell: React.FC<TableCellProps> = props => (
  <TableCell {...props}>
    <Skeleton />
  </TableCell>
);

const SkeletonFooterCell: React.FC<TableCellProps> = props => (
  <TableFooterCell {...props}>
    <Skeleton />
  </TableFooterCell>
);

export const NonBillableResourcesCard: React.FC<NonBillableResourcesCardProps> = props => {
  const { plan, loading } = props;
  const intl = useIntl();

  const [showAll, setShowAll] = useState(false);

  const expand = useCallback(() => setShowAll(true), []);
  const collapse = useCallback(() => setShowAll(false), []);

  const hasNonBillableResources = Boolean(plan?.nonBillableRoles?.length);
  const showPagination = Boolean(
    plan?.nonBillableRoles?.length &&
      plan.nonBillableRoles.length > DEFAULT_RECORDS_VISIBLE
  );
  const classes = useStyles({ showPagination });
  const visibleRecords =
    showAll === false
      ? plan?.nonBillableRoles?.slice(0, DEFAULT_RECORDS_VISIBLE)
      : plan?.nonBillableRoles;

  return (
    <Card className={classes.root}>
      <DataCardHeader
        title={intl.formatMessage({
          id: 'planPage.nonBillableResourcesCard.title'
        })}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent className={classes.content}>
        <Table>
          <TableHeader>
            <TableHeaderRow className={classes.tableRow}>
              <TableHeaderCell>
                <FormattedMessage id="planPage.nonBillableResourcesCard.headerRole" />
              </TableHeaderCell>
              <TableHeaderCell align="right">
                <FormattedMessage id="planPage.nonBillableResourcesCard.headerHours" />
              </TableHeaderCell>
              <TableHeaderCell align="right">
                <FormattedMessage id="planPage.nonBillableResourcesCard.headerTotalCost" />
              </TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {/* Loading State 
                We add one to the list to avoid layout shift when the data loads and we may have
                the show-more button
            */}
            {loading &&
              [...Array(DEFAULT_RECORDS_VISIBLE + 1)].map((_, idx) => (
                <TableRow className={classes.tableRow} key={idx}>
                  <SkeletonCell />
                  <SkeletonCell />
                  <SkeletonCell />
                </TableRow>
              ))}

            {/* Empty State */}
            {!loading && !hasNonBillableResources && (
              <TableRow className={classes.tableRow}>
                <TableCell colSpan={TOTAL_COLUMN_COUNT}>
                  <NoDataItem>
                    <FormattedMessage id="planPage.nonBillableResourcesCard.empty" />
                  </NoDataItem>
                </TableCell>
              </TableRow>
            )}

            {/* Data state */}
            {hasNonBillableResources &&
              visibleRecords?.map(role => (
                <TableRow className={classes.tableRow} key={role?.id}>
                  <TableCell>
                    <Tooltip title={role?.role?.displayText ?? ''}>
                      <div>
                        <Typography variant="body2" noWrap>
                          {role?.role?.displayText}
                        </Typography>
                        <div className={classes.headCount}>
                          <RTLOverride>
                            <FormattedMessage id="planPage.billableResourcesCard.roleNameCell.headCount" />
                            {': '}
                            <NumberValue
                              value={role?.userCount?.toNumber() ?? 0}
                            />
                          </RTLOverride>
                        </div>
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <NumberValue value={role?.hours?.toNumber() ?? 0} />
                  </TableCell>
                  <TableCell align="right">
                    <TooltipMoney
                      value={role?.cost?.decimalPlaces(6).toNumber() ?? 0}
                      currencySymbol={plan?.totalCost?.currency?.symbol}
                      placement="right"
                    />
                  </TableCell>
                </TableRow>
              ))}

            {showPagination && (
              <TableRow>
                <TableCell colSpan={TOTAL_COLUMN_COUNT}>
                  <div className={classes.showMore}>
                    {!showAll && (
                      <Button variant="text" color="primary" onClick={expand}>
                        <FormattedMessage id="button.showMore" />
                      </Button>
                    )}
                    {showAll && (
                      <Button variant="text" color="primary" onClick={collapse}>
                        <FormattedMessage id="button.showLess" />
                      </Button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            {loading && (
              <TableFooterRow className={classes.tableRow}>
                <SkeletonFooterCell />
                <SkeletonFooterCell />
                <SkeletonFooterCell />
              </TableFooterRow>
            )}
            {!loading && (
              <TableFooterRow className={classes.tableRow}>
                <TableFooterCell>
                  <FormattedMessage id="planPage.nonBillableResourcesCard.footerTotalCost" />
                  <div className={classes.headCount}>
                    <RTLOverride>
                      <FormattedMessage id="planPage.billableResourcesCard.roleNameCell.headCount" />
                      {': '}
                      <NumberValue value={plan?.nonBillableUserCount ?? 0} />
                    </RTLOverride>
                  </div>
                </TableFooterCell>
                <TableFooterCell align="right">
                  <NumberValue
                    value={plan?.totalNonBillableHours?.toNumber() ?? 0}
                  />
                </TableFooterCell>
                <TableFooterCell align="right">
                  <TooltipMoney
                    value={plan?.nonBillableCost?.amount?.toNumber() ?? 0}
                    currencySymbol={plan?.nonBillableCost?.currency?.symbol}
                    placement="right"
                  />
                </TableFooterCell>
              </TableFooterRow>
            )}
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
};

export default memo(NonBillableResourcesCard);
