import React, { useMemo } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Label } from 'common/components';
import { useMeContext } from 'App/Me';
import { useHasUserProjectRoleAssignmentsEditPermission } from 'common/hooks';
import { Error, ErrorCodes } from '../../types';
import { RoleError } from './RoleError';

const useStyles = makeStyles(theme => ({
  body: {
    fontWeight: theme.typography.fontWeightBold
  },
  link: {
    ...theme.typography.body1,
    marginTop: theme.spacing(0.5),
    textDecoration: 'none'
  }
}));

const useAlertStyles = makeStyles(theme => ({
  message: {
    paddingBottom: theme.spacing(0.5)
  }
}));

type PlanErrorProps = {
  error: Error;
};

type PlanErrorData = {
  title: string;
  body: string;
  bodyReadOnly?: string;
  link?: string;
  subUrl?: string;
  isLinkToPlatfrom?: boolean;
  showErrorDetails?: boolean;
};

const planErrors: Record<string, PlanErrorData> = {
  NO_BILLABLE_ROLES: {
    title: 'addPlanDialog.errors.noBillableRoles.title',
    body: 'addPlanDialog.errors.noBillableRoles.body',
    link: 'addPlanDialog.errors.noBillableRoles.link',
    subUrl: 'administration/project/roles',
    isLinkToPlatfrom: true
  },
  USERS_WITH_DISABLED_ROLE: {
    title: 'addPlanDialog.errors.usersWithDisabledPrimaryRole.title',
    body: 'addPlanDialog.errors.usersWithDisabledPrimaryRole.body',
    link: 'addPlanDialog.errors.usersWithDisabledPrimaryRole.link',
    subUrl: 'administration/project/roles',
    isLinkToPlatfrom: true
  },
  NO_USERS_WITH_BILLABLE_ROLES: {
    title: 'addPlanDialog.errors.noUserWithBillableRoles.title',
    body: 'addPlanDialog.errors.noUserWithBillableRoles.body',
    bodyReadOnly: 'addPlanDialog.errors.noUserWithBillableRoles.bodyReadOnly',
    link: 'addPlanDialog.errors.noUserWithBillableRoles.link',
    subUrl: '/resource-pool?view=LIST',
    isLinkToPlatfrom: false
  },
  ATLEAST_ONE_USER_WITH_DIRECT_COST: {
    title: 'addPlanDialog.errors.atleastOneUserWithBusinessCost.title',
    body: 'addPlanDialog.errors.atleastOneUserWithBusinessCost.body',
    link: 'addPlanDialog.errors.atleastOneUserWithBusinessCost.link',
    subUrl: 'administration/users',
    isLinkToPlatfrom: true
  }
};
export const PlanError: React.FC<PlanErrorProps> = ({ error }) => {
  const classes = useStyles();
  const alertClasses = useAlertStyles();
  const data = planErrors[error.code];
  const { links } = useMeContext();
  const hasUserProjectRoleEditPermission = useHasUserProjectRoleAssignmentsEditPermission();
  const href = useMemo(() => {
    const baseUrl = data.isLinkToPlatfrom ? links?.uiRootUrl : links?.psaUrl;
    return `${baseUrl}${data.subUrl}`;
  }, [data.isLinkToPlatfrom, data.subUrl, links]);

  const hasPermissionToAccessErrorLink =
    error.code === ErrorCodes.NO_USERS_WITH_BILLABLE_ROLES
      ? hasUserProjectRoleEditPermission
      : true;
  return data ? (
    <Alert classes={alertClasses} severity="error">
      <Label labelId={data.title} />
      {Boolean(hasPermissionToAccessErrorLink) ? (
        <>
          <Label className={classes.body} labelId={data.body} />
          {error.code === ErrorCodes.USERS_WITH_DISABLED_ROLE &&
            Boolean(error.target) && <RoleError roleCostError={error.target} />}
          {data.link && href && (
            <Link href={href} target="_blank">
              <Label className={classes.link} labelId={data.link} />
            </Link>
          )}
        </>
      ) : (
        <Label className={classes.body} labelId={data.bodyReadOnly} />
      )}
    </Alert>
  ) : (
    <></>
  );
};
