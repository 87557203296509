import React, { useContext, useMemo } from 'react';
import { Maybe, SearchSettings } from 'generated-types';

export type SearchCriteria = {
  keywords?: string[];
  criterions: Record<string, string>;
};

export type SearchCriteriaContextValue = {
  searchCriteria: SearchCriteria;
};

export const SearchCriteriaContext = React.createContext<
  Maybe<SearchCriteriaContextValue>
>(null);

export const useSearchCriteriaContext = (): SearchCriteriaContextValue => {
  const val = useContext(SearchCriteriaContext);
  if (!val) {
    throw new Error(
      'useSearchCriteriaContext must be called within a SearchCriteriaContextProvider.'
    );
  }

  return val;
};

type SearchCriteriaContextProviderProps = {
  children: React.ReactNode;
  searchSettings?: Maybe<SearchSettings>;
};

export const SearchCriteriaContextProvider: React.FC<SearchCriteriaContextProviderProps> = ({
  children,
  searchSettings
}: SearchCriteriaContextProviderProps) => {
  const searchCriteriaResult = useMemo<SearchCriteriaContextValue>(() => {
    return {
      searchCriteria: (searchSettings as SearchCriteria) ?? {
        criterions: {},
        keywords: []
      }
    };
  }, [searchSettings]);

  return (
    <SearchCriteriaContext.Provider value={searchCriteriaResult}>
      {children}
    </SearchCriteriaContext.Provider>
  );
};
export const SearchCriteriaContextConsumer = SearchCriteriaContext.Consumer;
