import React, { useMemo } from 'react';

import { useMeDateTimeFormats } from 'App/DateTimeFormatProvider';
import { getFormattedDate } from 'util/date';
import { useMeContext } from 'App/Me';
import { getLocale } from 'App/MessageProvider/MessageProvider';

export type DateTimeProps = {
  dateTime?: string | null;
  format?: string;
};

export const DateTimeBase: React.FC<DateTimeProps> = ({ format, dateTime }) => {
  const { dateTimeFormat } = useMeDateTimeFormats();
  const me = useMeContext();
  const locale = getLocale(me);

  const dateTxt = useMemo(
    () => getFormattedDate(format ?? dateTimeFormat, locale, dateTime),
    [dateTime, dateTimeFormat, format, locale]
  );

  if (!dateTime) {
    return null;
  }
  return <>{dateTxt}</>;
};
