import React from 'react';
import { ErrorBoundary } from 'common/components';
import PlanPageContainer from './PlanPageContainer';
import PlanPageHeader from './PlanPageHeader';
import PlanPageContent from './PlanPageContent';

const PlanPage: React.FC = () => {
  return (
    <PlanPageContainer>
      <PlanPageHeader />
      <ErrorBoundary>
        <PlanPageContent />
      </ErrorBoundary>
    </PlanPageContainer>
  );
};

export default PlanPage;
