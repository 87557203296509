import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Label, ProgressButton, Button } from 'common/components';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2, 2)
  },
  actions: {
    padding: theme.spacing(1, 2)
  },
  info: {
    ...theme.typography.body1,
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[500]
  }
}));

export interface UpdateActivePlanDialogProps {
  open: boolean;
  onSaveClick: () => Promise<void>;
  onClose?: () => void;

  closeDialog: () => void;
}

export const UpdateActivePlanDialog: React.FC<UpdateActivePlanDialogProps> = ({
  open,
  closeDialog,
  onClose,
  onSaveClick
}: UpdateActivePlanDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle disableTypography>
        <Label variant="h6" labelId="updateActivePlan.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.info}>
          <FormattedMessage id="updateActivePlan.info1" />
        </div>
        <div className={classes.info}>
          <FormattedMessage id="updateActivePlan.info2" />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          labelId="updateActivePlan.cancel"
          onClick={closeDialog}
        />
        <ProgressButton
          loading={false}
          onClick={onSaveClick}
          variant="text"
          color="primary"
          labelId="updateActivePlan.setPlan"
        />
      </DialogActions>
    </Dialog>
  );
};
