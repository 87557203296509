import BigNumber from 'bignumber.js';
import { Money } from 'generated-types';

export const isMoney = (value: unknown): value is Money => {
  return (
    typeof value === 'object' &&
    value !== null &&
    BigNumber.isBigNumber((value as Money).amount) &&
    (value as Money).currency !== undefined
  );
};

export const rateToTotal = (rate: Money, hours: BigNumber): Money => {
  return {
    ...rate,
    amount: (rate.amount ?? new BigNumber(0)).times(hours)
  };
};
