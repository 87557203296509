import React, { useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';
import { makeStyles } from '@material-ui/core/styles';
import {
  useActiveForm,
  useUtilizationMargin,
  useCloneCostOptimizationResult
} from 'active/hooks';
import { useCommitCostOptimizationResult } from 'billing/hooks';
import { FileUploadedDate } from 'common/components';
import {
  useRoleBreakdown,
  useTotalCostBreakdown,
  useHasCpoEditPermission
} from 'common/hooks';
import { BillingByRoleData } from 'common/types';
import { SmartBudgetResult } from 'generated-types';
import { DEFAULT_MARGIN, DEFAULT_UTILIZATION } from 'rateCard/helpers';
import { RoleDataTable } from 'rateCard/components';
import { RateCardButtonRow } from 'rateCard';
import { ActiveActions } from './ActiveActions';

const useStyles = makeStyles(theme => ({
  buttonRow: {
    paddingLeft: theme.spacing(5.5)
  }
}));

type ActiveCardContentProps = {
  costOptimizationResult: SmartBudgetResult;
};

export const ActiveCardContent: React.FC<ActiveCardContentProps> = ({
  costOptimizationResult
}: ActiveCardContentProps) => {
  const classes = useStyles();
  const {
    cloning,
    cloneCostOptimizationResult
  } = useCloneCostOptimizationResult(costOptimizationResult);
  const roleCostBreakdown = useRoleBreakdown(costOptimizationResult);
  const totalCostBreakdown = useTotalCostBreakdown(costOptimizationResult);

  const { utilization, margin, updateRefs } = useUtilizationMargin(
    costOptimizationResult.margin ?? DEFAULT_MARGIN,
    costOptimizationResult.utilization ?? DEFAULT_UTILIZATION,
    costOptimizationResult.roleCostBreakdown
  );

  const { commitCostOptimizationResult } = useCommitCostOptimizationResult({
    id: costOptimizationResult.id,
    utilization,
    margin
  });

  const [billingData, setBillingData] = useState<BillingByRoleData[]>([]);

  useActiveForm(
    roleCostBreakdown ?? [],
    utilization,
    margin,
    undefined,
    updateRefs,
    setBillingData,
    commitCostOptimizationResult
  );

  const cloneActiveRateCard = useCallback(async () => {
    return await cloneCostOptimizationResult();
  }, [cloneCostOptimizationResult]);

  const hasCpoEditPermission = useHasCpoEditPermission();
  return (
    <>
      <FileUploadedDate
        filename={costOptimizationResult.fileName ?? ''}
        uploadDate={costOptimizationResult.lastModified ?? ''}
        effectiveDate={costOptimizationResult.effectiveDate ?? ''}
        endDate={costOptimizationResult.endDate ?? ''}
      />
      {billingData && (
        <>
          <RateCardButtonRow
            costOptimizationResultId={costOptimizationResult.id}
            className={classes.buttonRow}
          />
          <RoleDataTable
            editing={false}
            billingData={billingData}
            totalCostBreakdown={totalCostBreakdown}
            totalRevenue={
              costOptimizationResult.totalRevenue?.amount ?? new BigNumber(0)
            }
            utilization={utilization}
            margin={margin}
          />
          {hasCpoEditPermission && (
            <ActiveActions
              cloning={cloning}
              cloneRateCard={cloneActiveRateCard}
            />
          )}
        </>
      )}
    </>
  );
};
