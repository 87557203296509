import React, { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { HeaderProps } from 'react-table';
import { makeStyles, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TooltipMoney } from 'common/components';
import { BillingByRoleData } from 'common/types';
import { isMoney } from 'util/money';
import { usePlanEditContext, usePlanTotalContext } from 'plans/plan/context';
import { useLockedColumnContext } from 'common/context';
import { useFeatureFlag } from 'common/hooks';
import { PlanTotals } from './hooks';

const useStyles = makeStyles(theme => ({
  label: {
    color: (prop: { isLocked: boolean }) =>
      prop.isLocked ? theme.palette.grey[400] : 'inherit'
  }
}));

export const BillableFooter: React.FC<HeaderProps<BillingByRoleData>> = ({
  column: { id: key }
}: HeaderProps<BillingByRoleData>) => {
  const { planTotals } = usePlanTotalContext() ?? {};
  const { lockedColumn } = useLockedColumnContext();
  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const isLocked = useMemo(() => Boolean(lockedColumn === key && !cpoNewUx), [
    cpoNewUx,
    key,
    lockedColumn
  ]);
  const { canEdit } = usePlanEditContext();
  const classes = useStyles({ isLocked: isLocked && canEdit });

  const value = planTotals?.[key as keyof PlanTotals];

  if (BigNumber.isBigNumber(value)) {
    return <div>{value.toString()}</div>;
  } else if (!value) {
    return (
      <Tooltip title={<FormattedMessage id="noDataAvailable" />}>
        <span>&mdash;</span>
      </Tooltip>
    );
  } else if (isMoney(value)) {
    return (
      <TooltipMoney
        currencySymbol={value?.currency?.symbol ?? ''}
        value={value?.amount?.toNumber() ?? 0}
        className={classes.label}
        placement="right"
      />
    );
  } else {
    return <></>;
  }
};
