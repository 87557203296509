import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormData } from 'common/types';

type LockedColumnContextValue = {
  canLockColumns: boolean;
  lockedColumn?: keyof FormData;
  setLockedColumn: (column?: keyof FormData) => void;
};

export const LockedColumnContext = React.createContext<
  LockedColumnContextValue
>({
  canLockColumns: false,
  setLockedColumn: () => undefined
});

type LockedColumnContextProviderProps = {
  canLockColumns: boolean;
  lockedColumn?: keyof FormData;
  setLockedColumn: (value?: keyof FormData) => void;
};

export const LockedColumnContextProvider: React.FC<LockedColumnContextProviderProps> = ({
  canLockColumns,
  setLockedColumn,
  lockedColumn,
  children
}) => {
  const contextValue = useMemo(
    () => ({
      lockedColumn,
      setLockedColumn,
      canLockColumns
    }),
    [canLockColumns, lockedColumn, setLockedColumn]
  );

  return (
    <LockedColumnContext.Provider value={contextValue}>
      {children}
    </LockedColumnContext.Provider>
  );
};

export const useLockedColumnContext = (): LockedColumnContextValue =>
  useContext(LockedColumnContext);

LockedColumnContextProvider.propTypes = {
  canLockColumns: PropTypes.bool.isRequired,
  setLockedColumn: PropTypes.func.isRequired,
  lockedColumn: PropTypes.any
};
