import React from 'react';
import { Dialog, Typography, Button, DialogActions } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBreakpointDown } from 'common/hooks';
import { CalculationInfoDialogContent } from './CalculationInfoDialogContent';
import { CalculationInfoDialogTitle } from './CalculationInfoDialogTitle';
import { CalculationInfoDivision } from './CalculationInfoDivision';
import { CalculationInfoAddition } from './CalculationInfoAddition';

export interface CalculationInfoDialogProps {
  open: boolean;
  closeDialog: () => void;
}

const useStyles = makeStyles({
  header: {
    fontWeight: 'bold',
    fontSize: '1rem'
  },
  content: {
    overflow: 'auto'
  }
});

export const CalculationInfoDialog: React.FC<CalculationInfoDialogProps> = ({
  open,
  closeDialog
}: CalculationInfoDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}
      maxWidth="md">
      <CalculationInfoDialogTitle>
        <FormattedMessage id="calculationInfoDialog.title" />
      </CalculationInfoDialogTitle>
      <div className={classes.content}>
        <CalculationInfoDialogContent>
          <Typography variant="h6" className={classes.header} gutterBottom>
            <FormattedMessage id="calculationInfoDialog.standardRate" />
          </Typography>
          <Typography variant="body1" gutterBottom>
            <FormattedMessage id="calculationInfoDialog.standardRateInfo" />
          </Typography>
          <CalculationInfoDivision
            quotient="calculationInfoDialog.standardRate"
            numerator="calculationInfoDialog.standardRateNumerator"
            denominator="calculationInfoDialog.standardRateDenominator"
          />
        </CalculationInfoDialogContent>
        <CalculationInfoDialogContent>
          <Typography variant="h6" className={classes.header} gutterBottom>
            <FormattedMessage id="calculationInfoDialog.nonBillableOverhead" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="calculationInfoDialog.nonBillableOverheadInfo" />
          </Typography>
          <CalculationInfoDivision
            quotient="calculationInfoDialog.nonBillableOverhead"
            numerator="calculationInfoDialog.nonBillableOverheadNumerator"
            denominator="calculationInfoDialog.nonBillableOverheadDenominator"
          />
        </CalculationInfoDialogContent>
        <CalculationInfoDialogContent>
          <Typography variant="h6" className={classes.header} gutterBottom>
            <FormattedMessage id="calculationInfoDialog.businessCostOverhead" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="calculationInfoDialog.businessCostOverheadInfo" />
          </Typography>
          <CalculationInfoDivision
            quotient="calculationInfoDialog.businessCostOverhead"
            numerator="calculationInfoDialog.businessCostOverheadNumerator"
            denominator="calculationInfoDialog.businessCostOverheadDenominator"
          />
        </CalculationInfoDialogContent>
        <CalculationInfoDialogContent>
          <Typography variant="h6" className={classes.header} gutterBottom>
            <FormattedMessage id="calculationInfoDialog.loadedLaborCost" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="calculationInfoDialog.loadedLaborCostInfo" />
          </Typography>
          <CalculationInfoAddition
            standardRate="calculationInfoDialog.standardRate"
            nonBillableOverhead="calculationInfoDialog.nonBillableOverhead"
            businessCostOverhead="calculationInfoDialog.businessCostOverhead"
            quotient="calculationInfoDialog.loadedLaborCost"
          />
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              <FormattedMessage id="exchangeRateDialog.close" />
            </Button>
          </DialogActions>
        </CalculationInfoDialogContent>
      </div>
    </Dialog>
  );
};

export default CalculationInfoDialog;
