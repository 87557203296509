export const MARGIN_KEY = 'margin';
export const UTILIZATION_KEY = 'utilization';
export const ROLE_DATA = 'role_data';
export const TOTAL_REVENUE_KEY = 'total_revenue';

export const clearUtilizationAndMargin = (): void => {
  try {
    sessionStorage.removeItem(MARGIN_KEY);
    sessionStorage.removeItem(UTILIZATION_KEY);
    sessionStorage.removeItem(ROLE_DATA);
    sessionStorage.removeItem(TOTAL_REVENUE_KEY);
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
