import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useUpcomingCostOptimizationResult,
  useNextRateCard
} from 'futurePage/hooks';
import { UpcomingPanelContent } from './UpcomingPanelContent';
import { UpcomingPanelSkeleton } from './UpcomingPanelSkeleton';

export const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1.5, 0, 8, 0)
  }
}));
type UpcomingPanelProps = {
  setHasUpcoming: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UpcomingPanel: React.FC<UpcomingPanelProps> = ({
  setHasUpcoming
}: UpcomingPanelProps) => {
  const classes = useStyles();

  const {
    loading,
    costOptimizationResults
  } = useUpcomingCostOptimizationResult();

  useEffect(() => {
    if (!loading) {
      setHasUpcoming(
        Boolean(costOptimizationResults && costOptimizationResults.length > 0)
      );
    }
  }, [costOptimizationResults, loading, setHasUpcoming]);
  const getNextRateCard = useNextRateCard(costOptimizationResults);

  return (
    <div className={classes.root}>
      {loading && <UpcomingPanelSkeleton />}
      {costOptimizationResults &&
        costOptimizationResults.map(costOptimizationResult => (
          <UpcomingPanelContent
            key={costOptimizationResult.id}
            costOptimizationResult={costOptimizationResult}
            getNextRateCard={getNextRateCard}
          />
        ))}
    </div>
  );
};
