import React, { memo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { useNavigationBlockContext, usePageTitleContext } from 'common/context';
import { DiscardDialog } from 'common/components/DiscardDialog';
import { usePlanContext } from './context/PlanContext';
import {
  NonBillableResourcesCard,
  BusinessCostsCard,
  BillableResourcesCard,
  PlanSummaryCard
} from './cards';
import {
  NOT_BILLABLE_CARD_WIDTH,
  PLAN_CARD_DETAIL_HEIGHT
} from './cards/constants';
import { useSaveDraft } from './hooks';
import { WarningTitleTile } from './components';

type StyleProps = {
  loading: boolean;
};
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fafafa'
  },
  cards: {
    padding: theme.spacing(1),
    display: 'flex',
    '& > div': {
      margin: theme.spacing(1.5)
    }
  },
  secondaryCards: {
    flexBasis: NOT_BILLABLE_CARD_WIDTH,
    width: NOT_BILLABLE_CARD_WIDTH,
    minHeight: PLAN_CARD_DETAIL_HEIGHT,
    flexGrow: 0,
    flexShrink: 0,
    padding: theme.spacing(0, 1),
    position: 'sticky',
    right: 0,
    zIndex: 100,
    backgroundColor: '#fafafa'
  },
  businessCostCard: {
    margin: theme.spacing(0, 2.5, 0, 0)
  },
  nonBillRoleCard: {
    margin: theme.spacing(2, 2.5, 0, 0)
  },
  billableRoleCard: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '75%'
  },
  emptyDiv: {
    flexBasis: theme.spacing(2),
    width: theme.spacing(2),
    flexGrow: 0,
    flexShrink: 0,
    position: 'sticky',
    left: 0,
    zIndex: 100,
    backgroundColor: '#fafafa'
  },
  container: {
    display: 'flex'
  },
  cardContainer: {
    width: '100%',
    padding: theme.spacing(2, 0, 2, 0),
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    backgroundColor: '#fafafa'
  },
  summaryContainer: {
    padding: theme.spacing(2, 2, 0, 2),
    position: 'sticky',
    left: theme.spacing(0),
    top: theme.spacing(2.25),
    zIndex: 200,
    background: '#fafafa'
  },
  safariSticky: {
    position: '-webkit-sticky'
  },
  billableCardGutter: {
    padding: theme.spacing(0, 0, 2, 2)
  },
  // HACK: Added below styles specific for firefox to scroll billable role card to farthest right
  '@supports ( -moz-appearance:none )': {
    billableRoleCard: {
      paddingRight: ({ loading }: StyleProps) => (loading ? 0 : 400)
    }
  }
}));

const useWarningBannerStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3.5),
    margin: theme.spacing(0),
    position: 'sticky',
    width: '100%',
    left: theme.spacing(0)
  },
  label: {
    justifyContent: 'left'
  },
  startIcon: {
    paddingRight: theme.spacing(1)
  },
  endIcon: {
    paddingLeft: theme.spacing(1)
  }
}));

const PlanPageContent: React.FC = () => {
  const warningBannerStyles = useWarningBannerStyles();
  const { plan, loading } = usePlanContext();
  const classes = useStyles({ loading });
  const navigationBlock = useNavigationBlockContext();
  const { canSave, saveDraft, saving } = useSaveDraft();
  usePageTitleContext(loading ? [] : [{ title: plan?.name ?? '' }]);

  useEffect(() => {
    if (!canSave) {
      navigationBlock?.unblockNavigation();
    }
  }, [canSave, navigationBlock]);

  // Key is used to remount the forms and cards when synchronization occurs.
  const key = plan?.lastSynchronizedAt ?? undefined;
  return (
    <>
      <WarningTitleTile
        classes={warningBannerStyles}
        disableRipple
        key={`warning_${key}`}
      />
      {plan && (
        <div
          className={classnames(classes.summaryContainer, classes.safariSticky)}
          key={`summary_${key}`}>
          <PlanSummaryCard plan={plan} />
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.emptyDiv} />
        <div className={classes.cardContainer} key={`cards_${key}`}>
          <div className={classnames(classes.billableRoleCard)}>
            <BillableResourcesCard />
          </div>
          <div
            className={classnames(
              classes.secondaryCards,
              classes.safariSticky
            )}>
            <div className={classes.businessCostCard}>
              <BusinessCostsCard plan={plan} loading={loading} />
            </div>
            <div className={classes.nonBillRoleCard}>
              <NonBillableResourcesCard plan={plan} loading={loading} />
            </div>
          </div>
        </div>
      </div>

      {canSave && navigationBlock?.unblockRef && (
        <DiscardDialog
          unblockRef={navigationBlock?.unblockRef}
          onSave={saveDraft}
          saving={saving}
        />
      )}
    </>
  );
};

export default memo(PlanPageContent);
