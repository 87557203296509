import React from 'react';
import { Drawer, DrawerProps, makeStyles } from '@material-ui/core';

const useContentStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(70)
  }
}));

type SideDrawerProps = DrawerProps;

export const SideDrawer: React.FC<SideDrawerProps> = ({
  children,
  ...rest
}: SideDrawerProps) => {
  const classes = useContentStyles();
  return (
    <Drawer {...rest}>
      <div className={classes.root}>{children}</div>
    </Drawer>
  );
};
