import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Divider
} from '@material-ui/core';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import { Maybe } from 'generated-types';
import { useIsBreakpointDown } from 'common/hooks';
import { Label, MoneyValue, NumberValue } from 'common/components';
import RoleRateTable from './RoleRateTable';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    ...theme.typography.h6,
    fontWeight: 'bold'
  },
  dialogContent: {
    ...theme.typography.body2,
    padding: theme.spacing(0, 3)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[500],
    padding: '0 0 24px 0'
  },
  percent: {
    textAlign: 'left',
    color: theme.palette.grey[500],
    paddingLeft: 3
  },
  number: {
    justifyContent: 'flex-end',
    color: theme.palette.grey[600],
    fontSize: 14,
    overflow: 'nowrap',
    paddingRight: theme.spacing(9)
  },
  total: {
    paddingRight: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2),
    height: 2,
    width: '100%'
  },
  summary: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  },
  summaryRow: {
    width: '100%',
    paddingTop: theme.spacing(2)
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  bold: {
    fontWeight: 'bold'
  },
  firstTitle: {
    textAlign: 'left',
    width: '30%'
  },
  userData: {
    textAlign: 'end',
    width: '15%'
  },
  hourData: {
    textAlign: 'end',
    width: '22%'
  },
  align: {
    textAlign: 'end'
  },
  button: {
    paddingRight: 19
  }
}));

export type RoleRateTableEntry = {
  name?: Maybe<string>;
  totalCost?: Maybe<number>;
  hours?: Maybe<number>;
  userCount?: Maybe<number>;
};

export interface RoleRateDialogProps {
  open: boolean;
  closeDialog: () => void;
  roleBreakdowns: {
    billable: RoleRateTableEntry[] | undefined;
    nonBillable: RoleRateTableEntry[] | undefined;
  };
  nonBillableUserCount: number | null | undefined;
  usersCount: number | null | undefined;
  billableUserCount: number | null | undefined;
  totalHours: number | null | undefined;
  totalCost: number | null | undefined;
  totalNonBillableHours: number | null | undefined;
  totalBillableHours: number | null | undefined;
  billableCost: number | null | undefined;
  nonBillableCost: number | null | undefined;
  currency: string | null | undefined;
}

export const RoleRateDialog: React.FC<RoleRateDialogProps> = ({
  open,
  closeDialog,
  roleBreakdowns,
  nonBillableUserCount,
  usersCount,
  billableUserCount,
  totalBillableHours,
  totalHours,
  totalNonBillableHours,
  billableCost,
  nonBillableCost,
  totalCost,
  currency
}: RoleRateDialogProps) => {
  const classes = useStyles();
  let billablePercent = 0;
  let nonBillablePercent = 0;
  if (billableUserCount && usersCount && nonBillableUserCount) {
    billablePercent = (billableUserCount / usersCount) * 100;
    nonBillablePercent = (nonBillableUserCount / usersCount) * 100;
  }
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}>
      <DialogTitle disableTypography>
        <Label
          className={classes.dialogTitle}
          variant="h6"
          labelId="roleRateDialog.title"
        />
        <Label
          variant="body1"
          className={classes.info}
          labelId="exchangeRateDialog.baseCurrency">{` ${currency}`}</Label>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Label
          variant="body1"
          className={classes.percent}
          labelId="roleRateDialog.billable">
          :&nbsp;{billablePercent.toFixed(2)} %
        </Label>
        <RoleRateTable
          roleBreakdowns={roleBreakdowns.billable}
          billableUserCount={billableUserCount}
          nonBillableUserCount={nonBillableUserCount}
          billable={true}
          totalBillableHours={totalBillableHours}
          billableCost={billableCost}
          currency={currency}
        />
        <Label
          variant="body1"
          className={classes.percent}
          labelId="roleRateDialog.nonBillable">
          :&nbsp;{nonBillablePercent.toFixed(2)} %
        </Label>
        <RoleRateTable
          roleBreakdowns={roleBreakdowns.nonBillable}
          nonBillableUserCount={nonBillableUserCount}
          totalBillableHours={totalBillableHours}
          totalNonBillableHours={totalNonBillableHours}
          nonBillableCost={nonBillableCost}
          billable={false}
          currency={currency}
        />
        <Divider className={classes.divider} />
        <table className={classes.summaryRow}>
          <thead>
            <tr>
              <td className={classes.firstTitle}>
                <Label
                  variant="body1"
                  className={classes.rowTitle}
                  labelId="roleRateDialog.total"
                />
              </td>
              <td className={classnames(classes.bold, classes.userData)}>
                <NumberValue value={usersCount ?? 0} />
              </td>
              <td className={classnames(classes.bold, classes.hourData)}>
                <NumberValue value={totalHours ?? 0} />
              </td>
              <td>
                <div className={classnames(classes.bold, classes.align)}>
                  <MoneyValue
                    value={totalCost ?? 0}
                    currencySymbol={currency}
                  />
                </div>
              </td>
            </tr>
          </thead>
        </table>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={closeDialog}
          color="primary">
          <FormattedMessage id="exchangeRateDialog.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoleRateDialog;
