import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HeaderProps } from 'react-table';
import { BillingByRoleData } from 'common/types';
import { BillableColumnInstance } from './BillableRoleTable';

type BillableHeaderProps<
  T extends BillingByRoleData = BillingByRoleData
> = HeaderProps<T> & {
  column: BillableColumnInstance<T>;
};

export const BillableHeader: React.FC<BillableHeaderProps> = ({
  column: { i18nKey }
}: BillableHeaderProps) => {
  return <FormattedMessage id={i18nKey} />;
};
