import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Label, ValidationLabel } from 'common/components';
import { CsvValidationError } from 'generated-types';

const MAX_ERROR_COUNT = 10;
const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
    padding: theme.spacing(0.5, 0),
    fontSize: '0.95rem'
  },
  root: {
    marginBottom: 20,
    background: '#fff5f4',
    padding: theme.spacing(2)
  },
  title: {
    color: theme.palette.error.main,
    fontWeight: 600,
    paddingBottom: theme.spacing(0.75)
  },
  footer: {
    color: theme.palette.grey[700],
    fontSize: theme.spacing(1.75),
    paddingTop: theme.spacing(2)
  }
}));

export interface UploadErrorProps {
  classes?: { [key: string]: string };
  validationErrors: CsvValidationError[];
}

const linkValue = {
  link: (
    <a href="mailto:support@replicon.com">
      <FormattedMessage id="validationLabel.repliconSupport" />
    </a>
  )
};

const ValidationErrors: React.FC<UploadErrorProps> = ({
  classes,
  validationErrors
}: UploadErrorProps) => {
  const styles = useStyles({ classes });

  return (
    <div className={styles.root}>
      <Label className={styles.title} labelId="validationError.uploadError" />
      {validationErrors.length > MAX_ERROR_COUNT ? (
        <Typography className={styles.error}>
          <FormattedMessage
            id="validationLabel.tooManyErrors"
            values={linkValue}
          />
        </Typography>
      ) : (
        validationErrors.map((e, index) => (
          <ValidationLabel
            key={e.line ?? `Validation_${index}`}
            labelId={'validationLabel.' + e.type}
            {...e}
          />
        ))
      )}
      {Boolean(validationErrors.length <= MAX_ERROR_COUNT) && (
        <Label
          variant="body2"
          className={styles.footer}
          labelId="validationError.reUpload"
        />
      )}
    </div>
  );
};

export default ValidationErrors;
