import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Label } from 'common/components';
import { PlanStatus } from 'generated-types';
import { usePlanWarningContext } from 'plans/plan/context';
import { useHasBlockingErrors } from 'plans/hooks';
import { NoBusinessCostWarning } from './NoBusinessCostWarning';
import { UsersDirectCostWarning } from './UsersDirectCostWarning';
import { OverlappingPlanWarnings } from './OverlappingPlanWarnings';

const useStyles = makeStyles(theme => ({
  message: {
    padding: theme.spacing(2, 3),
    color: theme.palette.grey[800]
  }
}));

type PlanDrawerContentProps = {
  classes?: ClassNameMap;
};

export const PlanDrawerContent: React.FC<PlanDrawerContentProps> = ({
  classes
}: PlanDrawerContentProps) => {
  const cls = useStyles({ classes });
  const { plan } = usePlanWarningContext();
  const activeOverlappingPlans = plan?.overlappingPlansWarnings?.filter(
    overlappingPlan => {
      return overlappingPlan?.planStatus !== PlanStatus.Draft;
    }
  );

  const draftOverlappingPlans = plan?.overlappingPlansWarnings?.filter(
    overlappingPlan => {
      return overlappingPlan?.planStatus === PlanStatus.Draft;
    }
  );

  const hasBlockingErrors = useHasBlockingErrors(
    plan?.overlappingPlansWarnings
  );

  const hasOverlappingDraftPlans = Boolean(draftOverlappingPlans?.length);
  return (
    <div>
      {plan && !hasBlockingErrors && (
        <Label
          className={cls.message}
          variant="body2"
          labelId="planWarningDrawer.contentMessage"
        />
      )}
      {hasBlockingErrors && (
        <OverlappingPlanWarnings
          severity="error"
          overlappingPlans={activeOverlappingPlans}
        />
      )}
      {hasOverlappingDraftPlans && (
        <OverlappingPlanWarnings
          severity="warning"
          overlappingPlans={draftOverlappingPlans}
        />
      )}
      {Boolean(plan?.warningsCount?.userDirectCostWarningCount?.toNumber()) && (
        <UsersDirectCostWarning />
      )}
      {!Boolean(plan?.operationOverhead?.length) && <NoBusinessCostWarning />}
    </div>
  );
};
