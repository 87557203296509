import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  ChangeEvent
} from 'react';
import { InputAdornment, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues
} from 'react-number-format';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import BigNumber from 'bignumber.js';
import { useMeNumberFormats } from 'App/NumberFormatProvider';

const numericInputProps = {
  type: 'text',
  pattern: '[0-9]*.[0-9]*'
};

const useInputStyles = makeStyles(theme => ({
  root: {
    width: (props: { fullWidth: boolean; classes?: ClassNameMap }): string =>
      props.fullWidth ? '100%' : '50%'
  },
  input: {
    ...theme.typography.body2,
    textAlign: 'right',
    transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  }
}));

export type NumberEditFieldProps = {
  classes?: ClassNameMap;
  name?: string;
  prefix?: string | null;
  suffix?: string | null;
  ariaLabel?: string;
  value: string;
  onBlur?: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
  ) => void | Promise<void>;
  fullWidth?: boolean;
  precision?: number;
} & NumberFormatProps;

const roundValue = (value: string, precision: number): string =>
  new BigNumber(value).toFixed(precision);

export const NumberEditField: React.FC<NumberEditFieldProps> = ({
  classes,
  name,
  prefix,
  ariaLabel,
  suffix,
  value: initialValue,
  onBlur,
  fullWidth = false,
  precision = 2,
  ...rest
}: NumberEditFieldProps) => {
  const { numberFormat } = useMeNumberFormats();
  const inputClasses = useInputStyles({ fullWidth, classes });
  const prefixAdronment = useMemo(() => {
    if (prefix) {
      return <InputAdornment position="start">{prefix}</InputAdornment>;
    }
    return undefined;
  }, [prefix]);
  const suffixAdronment = useMemo(() => {
    if (suffix) {
      return <InputAdornment position="end">{suffix}</InputAdornment>;
    }
    return undefined;
  }, [suffix]);
  const [value, setValue] = useState(roundValue(initialValue, precision));
  useEffect(() => setValue(roundValue(initialValue, precision)), [
    initialValue,
    precision
  ]);

  const onValueChange = useCallback(
    (value: NumberFormatValues) => {
      setValue(value.value);
    },
    [setValue]
  );
  const onEditComplete = useCallback(() => {
    onBlur?.(value);
  }, [onBlur, value]);

  const inputProps = useMemo(
    () => ({
      ...numericInputProps,
      'aria-label': ariaLabel
    }),
    [ariaLabel]
  );

  return (
    <NumberFormat
      customInput={Input}
      classes={inputClasses}
      inputProps={inputProps}
      type="text"
      inputMode="numeric"
      name={name}
      value={value}
      onValueChange={onValueChange}
      onBlur={onEditComplete}
      startAdornment={prefixAdronment}
      endAdornment={suffixAdronment}
      {...numberFormat}
      {...rest}
    />
  );
};
