import { useMemo } from 'react';
import { RoleBreakdown, SmartBudgetResult } from 'generated-types';

export const useRoleBreakdown = (
  result?: SmartBudgetResult | null
): RoleBreakdown[] | undefined => {
  const roleBreakdown = useMemo(() => {
    if (result && result.roleCostBreakdown) {
      return result.roleCostBreakdown
        .filter(role => role && role.id && role.id.length)
        .map(role => ({ ...role } as RoleBreakdown));
    }
  }, [result]);
  return roleBreakdown;
};
