import { useMemo } from 'react';
import { useMeContext } from 'App/Me';
import { Maybe, Permission } from 'generated-types';

export const usePermissionSet = (): Set<string> => {
  const me = useMeContext();

  const permissionsSet: Set<string> = useMemo(
    () =>
      (me.permissions ?? []).reduce(
        (acc: Set<string>, current: Maybe<Permission>) => {
          if (current && current.permissionActionUri) {
            acc.add(current.permissionActionUri);
          }
          return acc;
        },
        new Set()
      ),
    [me.permissions]
  );
  return permissionsSet;
};
