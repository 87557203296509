import BigNumber from 'bignumber.js';

export const isValidNumber = (num: string): boolean => {
  const decimalRegex = /^(\d+(\.\d+)?)$/;
  return decimalRegex.test(num);
};

export const clamp = (
  v: BigNumber,
  min: BigNumber,
  max: BigNumber
): BigNumber => {
  if (v.isGreaterThan(max)) {
    return max;
  } else if (v.isLessThan(min)) {
    return min;
  }

  return v;
};

export const roundUpToX = (
  value: BigNumber,
  nearestX: BigNumber
): BigNumber => {
  if (
    nearestX.isNaN() ||
    nearestX.isZero() ||
    value.isZero() ||
    value.isNaN()
  ) {
    return value;
  }
  return value
    .dividedBy(nearestX)
    .dp(0, BigNumber.ROUND_CEIL)
    .multipliedBy(nearestX);
};
