import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography,
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '0.875rem'
  }
}));

type NoDataItemProps = {
  children: React.ReactNode;
  classes?: ClassNameMap;
};

export const NoDataItem: React.FC<NoDataItemProps> = ({
  classes,
  children
}: NoDataItemProps) => {
  const cls = useStyles({ classes });
  return <span className={cls.root}>{children}</span>;
};

export default NoDataItem;
