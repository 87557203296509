import {
  RoleBillingRateCalculator,
  RoleCostRateCalculator
} from '@replicon/cost-price-optimizer-models';
import BigNumber from 'bignumber.js';
import { FieldValues, FormContextValues } from 'react-hook-form';
import { DateTime } from 'luxon';
import { RoleBreakdown, RoleCommitInput } from 'generated-types';
import {
  BillingByRoleData,
  RoleData,
  FormData,
  UpdateRefs
} from 'common/types';
import { RateCardModel, defaultRateCardModel } from 'rateCard/model';
import { CommitCostOptimizationResultOptions } from 'billing/hooks';
import { DECIMAL_PLACES } from './constants';
import { getModelState, getFormState } from './getModelState';

const roleCostRateCalculator = new RoleCostRateCalculator();
const roleBillingRateCalculator = new RoleBillingRateCalculator();

export const calculateBillingRateForRole = (
  cost: BigNumber,
  utilization: BigNumber,
  margin: BigNumber
): BigNumber =>
  roleBillingRateCalculator.calculateBillingRateForRole(
    cost,
    utilization.dividedBy(100),
    margin.dividedBy(100)
  );

export const calculateRevenueForRole = (
  billingRate: BigNumber,
  utilization: BigNumber,
  workHours: BigNumber
): BigNumber =>
  roleBillingRateCalculator.calculateRevenue(
    billingRate,
    utilization.dividedBy(100),
    workHours
  );

export const calculateGlobalMargin = (
  totalRevenue: BigNumber,
  loadedCost: BigNumber,
  totalHours: BigNumber
): BigNumber =>
  roleBillingRateCalculator
    .calculateGlobalMargin(totalRevenue, loadedCost, totalHours)
    .multipliedBy(100);

export const getBillingByRoleData = (
  roleBreakdown: RoleBreakdown[],
  values: FieldValues
): BillingByRoleData[] =>
  roleBreakdown &&
  roleBreakdown.map(role => {
    const roleMargin = new BigNumber(values[role.id].margin);
    const roleUtilization = new BigNumber(values[role.id].utilization);
    const roleBusinessOverhead = new BigNumber(
      values[role.id].businessOverhead ?? role.businessOverhead?.amount
    );
    const roleLoadedCost = roleCostRateCalculator.calculateLoadedCost(
      role.employeeCost?.amount ?? new BigNumber(0),
      [
        role.nonBillableOverhead?.amount ?? new BigNumber(0),
        roleBusinessOverhead
      ]
    );
    const billingRate = new BigNumber(
      values[role.id].billingRate ??
        calculateBillingRateForRole(
          role?.loadedCost?.amount ?? new BigNumber(0),
          roleUtilization,
          roleMargin
        )
    );
    const totalHours = new BigNumber(role?.totalHours ?? 0);
    return {
      id: role.id,
      roleName: role?.role?.displayText ?? '',
      roleId: role?.role?.id ?? '',
      totalHours: totalHours,
      standardRate:
        role && role.employeeCost
          ? role.employeeCost
          : { amount: new BigNumber(0) },
      nonBillableOverhead:
        role && role.nonBillableOverhead
          ? role.nonBillableOverhead
          : { amount: new BigNumber(0) },
      // TODO: Pass the plan base currency down.
      businessOverhead: {
        amount: roleBusinessOverhead ?? new BigNumber(0),
        currency: role?.employeeCost?.currency
      },
      loadedCost: {
        amount: roleLoadedCost,
        currency: role?.employeeCost?.currency
      },
      utilization: roleUtilization,
      currentUtilization: role.currentUtilization ?? undefined,
      margin: roleMargin,
      billingRate: {
        amount: billingRate,
        currency: role?.loadedCost?.currency
      },
      revenue: {
        amount: calculateRevenueForRole(
          billingRate,
          roleUtilization,
          totalHours
        ),
        currency: role?.loadedCost?.currency
      },
      totalCost: role.totalCost ?? { amount: new BigNumber(0) },
      userCount: role.userCount ?? new BigNumber(0),
      currentBillingRate: role.currentBillingRate ?? undefined
    };
  });

export const updateRoleValues = (
  getValues: () => FieldValues,
  setValue: (id: string, value: FormData) => void,
  valueKey: 'margin' | 'utilization',
  value: string,
  billingData: BillingByRoleData[],
  setBillingData?: React.Dispatch<React.SetStateAction<BillingByRoleData[]>>,
  lockedColumn?: keyof FormData
): void => {
  const values = getValues();
  const model = new RateCardModel(defaultRateCardModel);

  billingData.forEach(data => {
    const newState = model.setColumnValue(
      getModelState(data, values[data.id]),
      valueKey,
      new BigNumber(value).dividedBy(100),
      {
        lockedColumns: lockedColumn ? [lockedColumn] : []
      }
    );

    setValue(data.id, {
      ...values[data.id],
      ...getFormState(newState)
    });

    if (data.revenue && setBillingData) {
      data.revenue.amount = newState.revenue;
      setBillingData([...billingData]);
    }
  });
};

export const getUpdateMarginAndUtilization = (
  billingData: BillingByRoleData[],
  getValues: () => FieldValues,
  setValue: (id: string, value: FormData) => void,
  setBillingData?: React.Dispatch<React.SetStateAction<BillingByRoleData[]>>,
  lockedColumn?: keyof FormData
): UpdateRefs => ({
  updateMargin: (margin: string): void => {
    updateRoleValues(
      getValues,
      setValue,
      'margin',
      margin,
      billingData,
      setBillingData,
      lockedColumn
    );
  },
  updateUtilization: (utilization: string): void => {
    updateRoleValues(
      getValues,
      setValue,
      'utilization',
      utilization,
      billingData,
      setBillingData,
      lockedColumn
    );
  }
});

export const roundFormDataValues = (value: FormData): FormData =>
  (Object.keys(value) as Array<keyof FormData>).reduce((acc, key) => {
    const fieldValue = new BigNumber(value[key] ?? 0);
    acc[key] =
      key === 'billingRate'
        ? fieldValue.toFixed(DECIMAL_PLACES)
        : fieldValue.decimalPlaces(DECIMAL_PLACES).toString();

    return acc;
  }, {} as FormData);

export const setFormValue = (
  roleData: RoleData,
  setValue: FormContextValues['setValue'],
  setRoleData: (value: RoleData) => void
) => (id: string, value: FormData): void => {
  setValue(id, roundFormDataValues(value));
  roleData[id] = (Object.keys(value) as Array<keyof FormData>).reduce(
    (acc, key) => {
      const v = value[key];
      if (v === undefined) {
        return acc;
      }

      acc[key] = new BigNumber(v).toString();
      return acc;
    },
    {} as FormData
  );
  setRoleData(roleData);
};

type CommitRatesOptions = {
  getValues: () => FieldValues;
  commitCostOptimizationResult: (
    commitOptions: CommitCostOptimizationResultOptions
  ) => Promise<void>;
  startDate: DateTime;
  endDate: DateTime;
};
export const commitRates = async ({
  getValues,
  commitCostOptimizationResult,
  startDate,
  endDate
}: CommitRatesOptions): Promise<void> => {
  const values = getValues();
  const roleInput: RoleCommitInput[] = Object.keys(values).map(key => ({
    id: key,
    margin: new BigNumber(values[key].margin),
    utilization: new BigNumber(values[key].utilization)
  }));
  await commitCostOptimizationResult({
    startDate,
    roleData: roleInput,
    endDate
  });
};
