import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Error } from '../../types';
import { PlanError } from './PlanError';

const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

type PlanErrorProps = {
  errors: Error[];
};

export const PlanErrors: React.FC<PlanErrorProps> = ({ errors }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {errors.map(error => (
        <PlanError key={error.code} error={error} />
      ))}
    </div>
  );
};
