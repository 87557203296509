import { flattenMessages } from './util';

const messages = {
  appName: 'SmartBudget',
  errorOccurred: 'An error has occurred',
  table: {
    paginationMsg: '{from}-{to} of {count}'
  },
  abbreviatedNumberSuffix: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  tabs: {
    globalRateCard: 'Global Rate Card',
    active: 'Active',
    future: 'Future',
    history: 'History',
    upload: 'Upload',
    roleSummary: 'Role',
    import: 'Janky Test Import',
    plansList: 'List',
    overview: 'Overview'
  },
  button: {
    revert: 'Revert',
    reset: 'Reset',
    saveChanges: 'Save Changes',
    discardChanges: 'Discard Changes',
    showMore: 'Show More...',
    showLess: 'Show Less...',
    resetToDefaults: 'Reset to defaults',
    cancel: 'Cancel',
    save: 'Save',
    addSmartBudget: 'Add SmartBudget',
    ok: 'Ok',
    undo: 'Undo'
  },
  fileUploadDialog: {
    title: 'Import Data',
    cancel: 'Cancel',
    dragFileHere: 'Drag file here',
    or: 'or',
    chooseFileToUpload: 'Choose file to upload',
    fileExceedMaxSize: 'File exceeded the maximum file size of 5MB',
    acceptedFormats: 'Accepts only .csv file',
    maxFileSizeMessage: 'Maximum file size: 5 MB'
  },
  fileUploadPage: {
    title:
      'To import data into PSA, please upload the relevant data using provided template',
    step1Title: 'Step 1. Download template',
    step2Title: 'Step 2. Upload the CSV file',
    downloadCsvTemplate: 'Download CSV Template',
    uploadCsv: 'Upload CSV',
    reupload: 'Reupload',
    filename: 'File name',
    fileUploadError: 'Upload failed. Please upload the proper CSV'
  },
  fileUpload: {
    title: 'Upload file',
    upload: 'Upload'
  },
  activePage: {
    uploaded: 'Uploaded',
    effective: 'Effective',
    active: 'Active',
    resourceButton: 'Resource Data',
    exchangeButton: 'Exchange Rates',
    modelButton: 'Model Logic',
    editRates: 'Edit Rates',
    setRates: 'Set Rates',
    saveRates: 'Save Changes',
    saveRatesSuccess: 'Changes saved successfully.',
    saveRatesFailure: 'Failed to save changes.',
    cancel: 'Cancel',
    editDialog: {
      title: 'Edit Rate Card',
      info1:
        'Edit allow you to edit the billing rate for the business cost by adjusting margin and utilization.',
      info2:
        'If you want to change the business cost you will need to Upload a new file.',
      cancel: 'Cancel',
      edit: 'Edit'
    },
    setRatesDialog: {
      title: 'Effective Time Period',
      content: 'To adjust the time period select a new end date.',
      cancel: 'Cancel',
      setRates: 'Set Rates'
    }
  },
  roleUserBreakdownPage: {
    noRoleBreakdownError: 'No matching role breakdown found.',
    roleSummary: '{roleName} Summary'
  },
  costTable: {
    standardRate: 'Standard Rate',
    overheadRate: 'Overhead Rate',
    rate: 'Rate',
    roleName: 'Role Name',
    employeeCost: 'Standard Billing Rate',
    nonBillableCost: 'Non-billable Overhead',
    businessOverhead: 'Business Overhead',
    loadedCost: 'Loaded Cost Rate'
  },
  validationLabel: {
    row: 'Row {line}',
    columnHeader: ' Column ',
    ERR_MIXED_DATATYPES:
      'Data types cannot be mixed, please seperate user and business data',
    ERR_INVALID_USER_RECORD: 'User Data Missing',
    ERR_INVALID_COST_RECORD: 'Business Data Missing',
    ERR_USER_NOT_EXIST: 'This User does not exist in this environment',
    ERR_NEGATIVE_VALUE: 'Cannot upload a negative value',
    ERR_ROLE_NOT_EXIST: 'This role does not exist in this environment',
    ERR_ROLE_DISABLED: '{cellValue} is a disabled role',
    tooManyErrors:
      'Too many errors in the file. Please contact {link} for assistance.',
    repliconSupport: 'Replicon Support',
    ERR_CURRENCY_NOT_EXIST: 'This currency does not exist in this environment',
    ERR_CURRENCY_DISABLED: '{cellValue} is a disabled currency',
    ERR_INVALID_ENCODING: 'The file encoding is incorrect: must be in UTF-8',
    ERR_DUPLICATE_USER: "Found duplicate user '{cellValue}'"
  },
  userDataSummary: {
    title: 'User Summary',
    userCount: 'Total Users',
    billableCount: 'Billable Users',
    nonBillableCount: 'Non-Billable Users',
    userInfoButton: 'Resource Data'
  },
  costDataSummary: {
    title: 'Cost Summary',
    currencyCount: 'Currencies',
    nonBillable: 'Non-billable',
    billable: 'Billable HR',
    overhead: 'Overhead',
    totalCost: 'Total Cost',
    costInfoButton: 'Exchange Rates',
    noInfo: 'No Currency information is available'
  },
  progressIndicator: {
    upload: 'Upload data',
    uploadError: 'Upload failed',
    staging: 'Review cost data',
    billing: 'Review billing data and set rate'
  },
  validationError: {
    uploadError: 'Upload failed',
    reUpload: 'Please fix the errors in the CSV file and upload again'
  },
  costPage: {
    setCosts: 'Set Costs',
    goToBilling: 'Next',
    discard: 'Discard',
    calculationInfo: 'How did we get these rates?'
  },
  deleteDataDialog: {
    upload: 'upload',
    edit: 'edit',
    title: 'Discard Data',
    cancel: 'cancel',
    delete: 'Discard data',
    info:
      'This will remove all data from the current {processVariant} process.',
    info2: 'Are you sure you want to discard?'
  },
  billingPage: {
    uploaded: 'Uploaded',
    businessUtilizationGoal: 'Utilization',
    businessMargin: 'Margin',
    revenue: 'Revenue',
    commit: 'Set Rates',
    commitSuccess: 'Success!',
    commitFailure: 'Failed to set rates. Try again.'
  },
  billingTable: {
    roleName: 'Role Name',
    totalHours: 'Total Hours',
    loadedCostRate: 'Loaded Cost Rate',
    utilization: 'Utilization',
    desiredMargin: 'Margin',
    billingRate: 'Billing Rate',
    revenue: 'Revenue'
  },
  calculationInfoDialog: {
    title: 'How did we get these rates?',
    basicInfo:
      'Our Calculation starts with an analysis of total compensation and overhead expenses, then moves on to an analysis of billable staff hours.',
    standardRate: 'Standard Rate',
    standardRateInfo:
      'The standard billing rate used for each role will be an average based on available billable hours of all the individuals in the that category.',
    standardRateNumerator: 'Total compensation per role per year',
    standardRateDenominator: 'Total billable hours per role per year',
    nonBillableOverhead: 'Non-billable Overhead',
    nonBillableOverheadInfo:
      'The non-billable overhead is the total amount of compensation for all non revenue generating employees allocated across all the available billable hours.',
    nonBillableOverheadNumerator:
      'Total compensation on non billable employees',
    nonBillableOverheadDenominator: 'Total billable hours per year',
    businessCostOverhead: 'Business Cost Overhead',
    businessCostOverheadInfo:
      'The Business cost overhead is the total amount of all business costs allocated across all the available billable hours.',
    businessCostOverheadNumerator: 'Total business costs per year',
    businessCostOverheadDenominator: 'Total billable hours per year',
    loadedLaborCost: 'Loaded Labor Cost',
    loadedLaborCostInfo: 'This is the sum of the other 3 columns per role.'
  },
  planListPage: {
    name: 'Name',
    period: 'Period',
    status: 'Status',
    hours: 'Hours',
    totalHeadcount: 'Total Headcount',
    totalCost: 'Total Cost',
    loadedLaborRate: 'Loaded Labor Rate',
    utilization: 'Utilization',
    billingRate: 'Billing Rate',
    margin: 'Margin',
    revenue: 'Revenue',
    profit: 'Profit',
    list: 'List'
  },
  overviewPage: {
    overview: 'Overview',
    overviewSummary: {
      title: 'SmartBudget Summary',
      utilization: 'Utilization',
      margin: 'Margin',
      billingRate: 'Billing Rate',
      revenue: 'Revenue',
      totalCost: 'Total Cost',
      profit: 'Profit',
      goal: 'Goal'
    },
    chart: {
      title: 'Actual vs Budget vs Plan',
      yAxisLabel: 'Revenue ({baseCurrency})',
      legend: {
        actual: 'Actual Revenue',
        budgeted: 'SmartBudget',
        estimated: 'Estimated Revenue'
      },
      tooltip: {
        budget: 'Budgeted:',
        estimated: 'Estimated:',
        actual: 'Actual:'
      }
    }
  },
  navigationTabs: {
    uploadTab: 'Upload',
    historyTab: 'History'
  },
  historyTable: {
    uploaded: 'Uploaded',
    effectiveDate: 'Effective Date Range',
    committedBy: 'Committed By',
    download: 'Download',
    noTable: 'Table was unable to be loaded'
  },
  effectiveDateDialog: {
    title: 'Effective date',
    info: {
      selectMsg:
        'Please choose a start and end date to make these default rates effective for this time period.',
      line1:
        "The cost rates will be changed on the effective date for everyone, billing rates on current projects won't be updated, but new projects will take the new billing rates.",
      line2:
        'These billing rates, margin, utilization and revenue targets will be set as your business targets for the following period:',
      futureOverlapError:
        'This effective date range conflicts with a future rate card: ',
      activeOverlapError:
        'This effective date range conflicts with the current active rate card: '
    },
    cancel: 'Cancel',
    setRates: 'Set Rates',
    startDate: 'Start Date',
    endDate: 'End Date'
  },
  uploadDiscard: {
    title: 'Discard Data',
    cancel: 'cancel',
    delete: 'Discard Data',
    info: 'Data will not be saved if you navigate away.',
    info2: 'Are you sure you want to leave and discard data?'
  },
  discardDialog: {
    title: 'Unsaved Changes',
    info:
      'You have unsaved changes. If you leave before saving, your changes will be lost.',
    info2: 'Do you want to continue?',
    leave: 'leave without saving',
    saveAndLeave: 'save and leave'
  },
  expansionPanelDetailsTable: {
    businessSummary: 'Global Summary',
    summary: 'Summary',
    hours: 'Hours',
    totalCost: 'Total Cost',
    standardRate: 'Standard Rate',
    nonBillOverhead: 'Non-bill Overhead',
    businessOverhead: 'Business Overhead',
    laborRate: 'Loaded Labor Rate',
    utilization: 'Utilization',
    margin: 'Margin',
    billingRate: 'Billing Rate',
    revenue: 'Revenue'
  },
  emptyState: {
    activeTitle: 'Handle cost more efficiently than you ever have before',
    activeInfo1: 'See a comprehensive view of every role in your company.',
    activeInfo2:
      'Control the utilization and margin across the board or individually.',
    activeInfo3: 'Edit active rates and set them for the future.',
    activeButton: 'Upload new data',
    activeButtonInfo: 'to get started',
    futureTitle: 'Keep track of future dated rate cards here',
    futureButton: 'Upload',
    futureButtonInfo: 'a future dated rate card',
    historyTitle: 'Reference past rates fast and easily',
    historyInfo1: 'See a history of your past rate cards.',
    historyInfo2: 'Download to edit and make changes.',
    historyButtonInfo: 'History will appear after data has been',
    historyButton: 'uploaded'
  },
  upcoming: {
    uploadedOn: 'Uploaded {date}',
    committedBy: 'Committed by',
    download: 'Download',
    delete: 'Delete',
    uploaded: 'Uploaded',
    effectiveDate: 'Effective Date Range',
    deleteConfirmation: {
      title: 'Delete Rate Card',
      cancel: 'Cancel',
      delete: 'Delete',
      noFutureCard: {
        info1:
          'You are deleting a future rate card. This means the current rates will continue until you either add a new rate or edit the currently active rate card.',
        info2: 'Are you sure you want to delete?'
      },
      hasFutureCard: {
        info1:
          'You are deleting a future rate card but have subsequent rate cards scheduled. Deleting this card will persist the previous rates until the next rate card effective date.',
        info2: 'Are you sure you want to delete?',
        info3: 'Next effective rate: '
      }
    }
  },
  exchangeRateDialog: {
    title: 'Currency Exchange Rates',
    baseCurrency: 'Your base currency is in ',
    totalLoaded: 'Total loaded currency',
    exchangeRate: 'Exchange Rate',
    totalBase: 'Total ',
    close: 'Close'
  },
  roleRateDialog: {
    title: 'Resource Data',
    role: 'Role',
    users: 'Users',
    hours: 'Hours',
    cost: 'Cost',
    total: 'Total',
    billable: 'Billable Users',
    nonBillable: 'Non-Billable Users',
    button: 'Close'
  },
  costingModel: {
    title: 'Costing Model',
    info: 'Role Based Modeling',
    button: 'View Model Logic'
  },
  roleDataSaveDialog: {
    title: 'Save Changes?',
    content: 'You must save your changes before you can view the selected role.'
  },
  committingDialog: {
    title: 'Committing SmartBudget',
    message: {
      prepare: 'Preparing to commit your SmartBudget...',
      progress: 'Committing your SmartBudget...',
      success: 'Success!',
      failure: 'Oops, something went wrong... try again.'
    }
  },
  planMoreActions: {
    cancel: 'Cancel',
    removePlan: 'Remove SmartBudget',
    removePlanConfirmation: 'Are you sure you want to remove this SmartBudget?',
    deletePlanConfirmation:
      'This will remove the SmartBudget and all associated rates and business costs.',
    moreActions: 'more actions'
  },
  planSaveAction: {
    save: 'Save Draft'
  },
  planSetRatesAction: {
    label: 'Commit'
  },
  updateActivePlan: {
    title: 'Update SmartBudget',
    info1:
      'Changes made to the SmartBudget will override the current rates for the same date range.',
    info2: 'Are you sure you want to publish these changes?',
    cancel: 'Cancel',
    setPlan: 'Commit'
  },
  setPlanWarningDialog: {
    setPlan: 'Commit',
    cancel: 'Cancel',
    info1:
      "This will push all of the SmartBudget's cost and resource data to Polaris and make it active for the selected date range.",
    info2: 'Are you sure you want to set this SmartBudget?'
  },
  planUpdateCostsAction: {
    lastUpdate: 'Last update: {lastUpdate}',
    label: 'Update Costs'
  },
  search: {
    hasWords: 'Has Words',
    title: 'Search',
    advanceSearch: 'Advance search',
    searchSmartBudget: 'Search SmartBudget'
  },
  searchBox: {
    searchBy: 'Search by "{phrase}"',
    search: 'Search',
    applySearch: 'Apply Search',
    clear: 'Clear'
  },
  addPlanDialog: {
    title: 'Add SmartBudget',
    copyTab: 'Copy SmartBudget',
    newPlan: 'To this new SmartBudget',
    cancel: 'Cancel',
    addPlan: 'Add SmartBudget',
    copyRates: 'Copy Rates',
    copyUtilization: 'Copy Utilization',
    copyPlan: 'Copy From SmartBudget',
    copyPlanError: 'SmartBudget is Required',
    checkBoxError:
      'At least one of "Copy Rates" or "Copy Utilization" must be selected to copy a SmartBudget.',
    continue: 'Continue',
    name: 'Name',
    nameRequired: 'Field is required',
    startDate: 'Start Date',
    startDateRequired: 'Start date is required',
    endDate: 'End Date',
    endDateRequired: 'End date is required',
    startMonth: 'Start Month',
    startMonthRequired: 'Start month is required',
    endMonth: 'End Month',
    endMonthRequired: 'End month is required',
    utilization: 'Utilization',
    utilizationRequired: 'Utilization is required',
    utilizationNonNegative: 'Utilization must be greater than zero',
    utilMargInfo: 'Apply to roles without existing utilization and margin',
    margin: 'Margin',
    marginRequired: 'Margin is required',
    marginNonNegative: 'Margin must be positive',
    marginOver100: 'Margin must be less than 100%',
    failureMsg: 'SmartBudget creation failed.',
    overlappingPlanWarning: 'This date range overlaps with SmartBudget:',
    errors: {
      more: '...and {count} more',
      noBillableRoles: {
        title: 'At least 1 billable role is required:',
        body: 'Add or change billable project roles',
        link: 'Go to project roles'
      },
      usersWithDisabledPrimaryRole: {
        title:
          'One, or more, of the users in the system is assigned a disabled role:',
        body: 'Enable roles assigned to users',
        link: 'Go to project roles'
      },
      noUserWithBillableRoles: {
        title: 'No users found for billable roles:',
        body: 'Assign billable role to users',
        bodyReadOnly:
          'No users have been assigned a billable role. SmartBudget requires this cost data to run successfully. Please contact your system administrator.',
        link: 'Go to resource pool'
      },
      atleastOneUserWithBusinessCost: {
        title: 'None of your billable users have been assigned a direct cost:',
        body: 'At least one billable user must be assigned a direct cost',
        link: 'Add direct cost to users'
      }
    },
    warnings: {
      noUsersCost: {
        title: 'Costs missing for following users:',
        more: '...and {userCount} more'
      }
    }
  },
  planPage: {
    edit: 'Edit',
    toListPage: 'to list page',
    doneEditing: 'Done Editing',
    header: {
      nameRequired: 'SmartBudget name is required.'
    },
    nonBillableResourcesCard: {
      title: 'Non-Billable Resources',
      empty: 'No non-billable roles are defined for this SmartBudget.',
      headerRole: 'Role',
      headerTotalHeadcount: 'Total Headcount',
      headerHours: 'Hours',
      headerTotalCost: 'Total Cost',
      footerTotalCost: 'Totals',
      headCount: 'Headcount'
    },
    billableResourcesCard: {
      title: 'Billable Resource Costs',
      tableSettings: 'billable resource table settings',
      headers: {
        empty: ' ',
        loadFactor: 'Load Factor',
        calculate: 'Calculate',
        result: 'Results',
        roleName: 'Role',
        userCount: 'Headcount #',
        totalHours: 'Hours',
        totalCost: 'Direct Cost',
        standardRate: 'Direct Hourly Cost',
        nonBillableOverhead: 'Non-bill Overhead Hourly Cost',
        nonBillableOverheadDirectCost: 'Non-bill Overhead Direct Cost',
        businessOverhead: 'Business Overhead Hourly Cost',
        businessOverheadDirectCost: 'Business Overhead Direct Cost',
        loadedCost: 'Loaded Labor Cost',
        utilization: 'New Utilization Goal',
        billingRate: 'New Billing Rate Goal',
        currentBillingRate: 'Current Billing Rate Goal',
        currentUtilization: 'Current Utilization Goal',
        margin: 'New Margin Goal',
        revenue: 'Revenue',
        profit: 'Profit'
      },
      roleNameCell: {
        headCount: 'Headcount',
        total: 'Totals'
      },
      footerTitle: 'Totals for billable resources',
      columnSelectionDialog: {
        title: 'Table Settings'
      },
      currentUtilizationField: {
        noValueTooltipTitle: 'No data available'
      },
      utilizationFieldLabel: '{roleName} utilization',
      billingRateFieldLabel: '{roleName} billing rate',
      marginFieldLabel: '{roleName} margin'
    },
    businessCostCard: {
      title: 'Business Costs',
      totalResourceCost: 'Total Cost of Resources',
      totalBusinessCost: 'Total Business Cost',
      totalResourceCostTip:
        'Total cost of non-billable resources + total cost of billable resources',
      addCost: 'Add Cost',
      uncategorized: 'Uncategorized'
    },
    warning: {
      text: {
        one: 'There is an error in this SmartBudget',
        other: 'There are {count} errors in this SmartBudget'
      }
    },
    planSummary: {
      title: 'SmartBudget Summary',
      totalCost: 'Total Cost',
      utilizationGoal: 'Utilization Goal',
      avgBillingRateGoal: 'Average Billing Rate Goal',
      marginGoal: 'Margin Goal',
      revenueGoal: 'Revenue Goal',
      profitGoal: 'Profit Goal',
      slider: {
        title: 'Global Slider',
        utilization: 'Utilization',
        margin: 'margin',
        avgBillingRateGoal: 'Billing Rate'
      }
    },
    cancelPlan: {
      title: 'Cancel Changes',
      message1:
        'This will revert all changes made and restore the SmartBudget to the last save.',
      message2: 'Are you sure?'
    },
    calculateFor: 'Calculate for:',
    planningForBtn: {
      utilization: 'Utilization',
      margin: 'Margin',
      billingRate: 'Billing Rate'
    }
  },
  planStatus: {
    DRAFT: 'Draft',
    ACTIVE: 'Active',
    EXPIRED: 'Expired',
    UPCOMING: 'Upcoming',
    ERROR: 'Error'
  },
  nearestXDropdown: {
    title: 'Rounding up billing rates',
    noneOption: 'No rounding'
  },
  planWarningDrawer: {
    title: 'SmartBudget Errors',
    contentMessage:
      "These errors won't block you from setting a SmartBudget but means it will not have the most current and accurate data."
  },
  userDirectCostWarning: {
    title: {
      one: '{count} User missing direct cost:',
      other: '{count} Users missing direct costs:'
    }
  },
  overlappingPlansWarning: {
    body:
      'To set SmartBudget delete the overlapping SmartBudget or create a new one without an overlapping date range',
    title: {
      draftOne:
        'The current date range is overlapping {count} other draft SmartBudget:',
      draftOther:
        'The current date range is overlapping {count} other draft SmartBudgets:',
      one: 'The current date range is overlapping {count} other SmartBudget:',
      other: 'The current date range is overlapping {count} other SmartBudgets:'
    }
  },
  noBusinessCostWarning: {
    warningText:
      'There are no business costs detected for the current date range in this SmartBudget',
    gotoBusinessCosts: 'Go to Business Costs'
  },
  noDataAvailable: 'No data available',
  notSupportedPage: {
    title: 'Unfortunately this view does not support small screens',
    description: 'Sorry for the inconvenience',
    imageAlt: 'Small screens not supported'
  }
};

export default flattenMessages(messages);
