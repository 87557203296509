import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CpoRouteProps } from 'common/routes/Routes';
import PlansRouteProxy from './PlansRouteProxy';

export const plansPageRoute = (prefix = ''): CpoRouteProps => ({
  path: `${prefix}/plans`,
  component: PlansRouteProxy,
  title: <FormattedMessage id="tabs.plansList" />
});
