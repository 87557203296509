import React, { useCallback } from 'react';
import { TableCell, TableRow, Theme, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { TextAlignProperty } from 'csstype';
import { FieldValues } from 'react-hook-form';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { MoneyValue, NumberValue } from 'common/components';
import { BillingByRoleData, ColumnOnChange } from 'common/types';
import { useLockedColumnContext } from 'common/context';
import { useDirection } from 'App/MuiThemeProvider/useDirection';
import { TableFormNumberEditField } from './TableFormNumberEditField';

type RoleDataTableRowStyleProps = {
  canNavigateToRolePage: boolean;
};

const useStyles = makeStyles<Theme, RoleDataTableRowStyleProps>(theme => ({
  divider: {
    borderRight: 'solid',
    borderRightColor: theme.palette.grey[300],
    borderWidth: 1
  },
  cell: {
    width: '10%',
    [theme.breakpoints.down('lg')]: {
      padding: 8
    }
  },
  buttonCell: {
    padding: 0,
    '& > button': {
      padding: theme.spacing(2, 0, 2, 4.5),
      width: '100%',
      justifyContent: 'left',
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(1, 0, 1, 4.5)
      }
    }
  },
  role: {
    width: '13%'
  },
  percentage: {
    paddingLeft: 1,
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '80%'
    }
  },
  text: {
    textAlign: 'left'
  }
}));

const useCellStyle = makeStyles(theme => ({
  root: {
    '&:last-child': {
      paddingRight: theme.spacing(3)
    }
  }
}));

const useInputClasses = makeStyles(() => ({
  input: {
    textAlign: ({ direction }: { direction: string }): TextAlignProperty =>
      direction === 'rtl' ? 'start' : 'right'
  }
}));

export interface RoleDataTableRowProps {
  cellClasses?: ClassNameMap;
  roleBreakdown: BillingByRoleData;
  editMode?: boolean;
  canNavigateToRolePage?: boolean;
  onUtilizationChangeHandler?: ColumnOnChange;
  onMarginChangeHandler?: ColumnOnChange;
  onBillingRateChangeHandler?: ColumnOnChange;
  onRevenueChangeHandler?: ColumnOnChange;
  onNavigationHandler?: (roleBreakdownId: string) => void;
  getValues?: () => FieldValues;
}

export const RoleDataTableRow: React.FC<RoleDataTableRowProps> = ({
  cellClasses,
  roleBreakdown,
  editMode = false,
  canNavigateToRolePage = false,
  onUtilizationChangeHandler,
  onMarginChangeHandler,
  onBillingRateChangeHandler,
  onRevenueChangeHandler,
  onNavigationHandler,
  getValues
}: RoleDataTableRowProps): React.ReactElement => {
  const direction = useDirection();
  const classes = useStyles({ canNavigateToRolePage });
  const cellStyle = useCellStyle({ classes: cellClasses });
  const inputClasses = useInputClasses({ direction });

  const navigateToRolePage = useCallback(() => {
    if (canNavigateToRolePage) {
      onNavigationHandler?.(roleBreakdown.roleId);
    }
  }, [canNavigateToRolePage, onNavigationHandler, roleBreakdown.roleId]);

  const { lockedColumn } = useLockedColumnContext();
  const numberColumnDirection = 'right';

  return (
    <TableRow key={roleBreakdown.id} hover={canNavigateToRolePage}>
      <TableCell
        className={classnames(classes.buttonCell, classes.role)}
        align="left">
        <ButtonBase className={classes.text} onClick={navigateToRolePage}>
          {roleBreakdown.roleName}
        </ButtonBase>
      </TableCell>
      <TableCell
        classes={cellStyle}
        className={classnames(classes.divider, classes.cell)}
        align={numberColumnDirection}>
        <NumberValue value={roleBreakdown?.totalHours?.toNumber() ?? 0} />
      </TableCell>
      <TableCell
        classes={cellStyle}
        className={classes.cell}
        align={numberColumnDirection}>
        <MoneyValue
          currencySymbol={roleBreakdown.standardRate?.currency?.symbol ?? ''}
          value={roleBreakdown?.totalCost?.amount?.toNumber() ?? 0}
        />
      </TableCell>
      <TableCell className={classes.cell} align={numberColumnDirection}>
        <MoneyValue
          currencySymbol={roleBreakdown.standardRate?.currency?.symbol ?? ''}
          value={roleBreakdown?.standardRate?.amount?.toNumber() ?? 0}
        />
      </TableCell>
      <TableCell className={classes.cell} align={numberColumnDirection}>
        <MoneyValue
          currencySymbol={
            roleBreakdown.nonBillableOverhead?.currency?.symbol ?? ''
          }
          value={roleBreakdown?.nonBillableOverhead?.amount?.toNumber() ?? 0}
        />
      </TableCell>
      <TableCell className={classes.cell} align={numberColumnDirection}>
        <MoneyValue
          currencySymbol={
            roleBreakdown.businessOverhead?.currency?.symbol ?? ''
          }
          value={roleBreakdown?.businessOverhead?.amount?.toNumber() ?? 0}
        />
      </TableCell>
      <TableCell
        classes={cellStyle}
        className={classnames(classes.divider, classes.cell)}
        align={numberColumnDirection}>
        <MoneyValue
          currencySymbol={roleBreakdown.loadedCost?.currency?.symbol ?? ''}
          value={roleBreakdown?.loadedCost?.amount?.toNumber() ?? 0}
        />
      </TableCell>
      <TableCell
        classes={cellStyle}
        className={classes.cell}
        align={numberColumnDirection}>
        {editMode ? (
          <TableFormNumberEditField
            classes={inputClasses}
            className={classes.percentage}
            id={roleBreakdown.id}
            attribute="utilization"
            value={getValues?.()[roleBreakdown.id].utilization}
            onChanged={onUtilizationChangeHandler}
            disabled={lockedColumn === 'utilization'}
            suffix="%"
          />
        ) : (
          <>{roleBreakdown?.utilization?.toFixed(2)}&nbsp;%</>
        )}
      </TableCell>
      <TableCell className={classes.cell} align={numberColumnDirection}>
        {editMode ? (
          <TableFormNumberEditField
            classes={inputClasses}
            className={classes.percentage}
            id={roleBreakdown.id}
            attribute="margin"
            value={getValues?.()[roleBreakdown.id].margin}
            onChanged={onMarginChangeHandler}
            disabled={lockedColumn === 'margin' || lockedColumn === 'revenue'}
            suffix="%"
          />
        ) : (
          <>{roleBreakdown?.margin?.toFixed(2)}&nbsp;%</>
        )}
      </TableCell>
      <TableCell className={classes.cell} align={numberColumnDirection}>
        {editMode ? (
          <TableFormNumberEditField
            classes={inputClasses}
            id={roleBreakdown.id}
            attribute="billingRate"
            fullWidth
            value={getValues?.()[roleBreakdown.id].billingRate}
            onChanged={onBillingRateChangeHandler}
            prefix={roleBreakdown.billingRate?.currency?.symbol ?? ''}
            disabled={lockedColumn === 'billingRate'}
          />
        ) : (
          <MoneyValue
            currencySymbol={roleBreakdown.billingRate?.currency?.symbol ?? ''}
            value={roleBreakdown.billingRate?.amount?.toNumber() ?? 0}
          />
        )}
      </TableCell>
      <TableCell
        classes={cellStyle}
        className={classes.cell}
        align={numberColumnDirection}>
        {editMode ? (
          <TableFormNumberEditField
            classes={inputClasses}
            id={roleBreakdown.id}
            attribute="revenue"
            fullWidth
            value={getValues?.()[roleBreakdown.id].revenue}
            onChanged={onRevenueChangeHandler}
            prefix={roleBreakdown.revenue?.currency?.symbol ?? ''}
            disabled={lockedColumn === 'revenue' || lockedColumn === 'margin'}
          />
        ) : (
          <MoneyValue
            currencySymbol={roleBreakdown.revenue?.currency?.symbol ?? ''}
            value={roleBreakdown.revenue?.amount?.toNumber() ?? 0}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default RoleDataTableRow;
