import React, { useCallback } from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {
  makeStyles,
  Typography,
  TextField,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import { Money } from 'generated-types';
import { NumberEditField, TooltipMoney } from 'common/components';
import { OnChange } from 'common/types';

type BusinessCostRowProps = {
  costName: string;
  amount: Money | null | undefined;
  classes?: ClassNameMap;
  costNameIcon?: React.ReactNode;
  editValue?: boolean;
  editName?: boolean;
  id?: string;
  onNameChange?: (
    id: string
  ) => (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onCostChange?: (id: string) => OnChange;
  handleCostRemove?: (id: string) => void;
};

const useStyles = makeStyles(theme => ({
  businessCostRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    alignItems: 'center'
  },
  businessCostName: {
    width: '60%',
    height: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center'
  },
  businessCostAmount: {
    width: '40%',
    height: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  costName: {
    marginRight: theme.spacing(2)
  },
  costReadOnlyValue: {
    marginRight: theme.spacing(1)
  },
  removeButton: {
    marginLeft: theme.spacing(0.5)
  }
}));

const useInputStyles = makeStyles(() => ({
  root: {
    width: '65%'
  }
}));

const useTextFieldStyles = makeStyles(theme => ({
  input: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary
  }
}));

const BusinessCostRow: React.FC<BusinessCostRowProps> = ({
  classes: overriddenClasses,
  id,
  costName,
  amount,
  costNameIcon,
  editValue = false,
  editName = false,
  onCostChange,
  onNameChange,
  handleCostRemove
}: BusinessCostRowProps) => {
  const classes = useStyles({ classes: overriddenClasses });
  const inputClasses = useInputStyles();
  const textFiledClasses = useTextFieldStyles();
  const removeBusinessCost = useCallback(() => {
    handleCostRemove?.(id ?? '');
  }, [handleCostRemove, id]);
  const MoneyComponent = TooltipMoney;

  const businessNameComponent = (
    <Typography
      variant="body2"
      noWrap
      color="textSecondary"
      className={classes.costName}>
      {costName}
    </Typography>
  );
  return (
    <div className={classes.businessCostRow}>
      <div className={classes.businessCostName}>
        {editName ? (
          <TextField
            key={costName}
            type="text"
            defaultValue={costName ?? ''}
            variant="standard"
            size="small"
            InputProps={{
              className: textFiledClasses.input
            }}
            onBlur={onNameChange?.(id ?? '')}></TextField>
        ) : (
          <>
            <Tooltip title={costName ?? ''}>{businessNameComponent}</Tooltip>
            {costNameIcon}
          </>
        )}
      </div>
      <div className={classes.businessCostAmount}>
        {editValue ? (
          <>
            <NumberEditField
              attribute="businessCost"
              value={amount?.amount?.toString() ?? '0'}
              prefix={amount?.currency?.symbol ?? ''}
              classes={inputClasses}
              onBlur={onCostChange?.(id ?? '')}
            />
            <IconButton
              aria-label="remove"
              size="small"
              className={classes.removeButton}
              onClick={removeBusinessCost}>
              <RemoveCircle color="action" />
            </IconButton>
          </>
        ) : (
          <MoneyComponent
            value={amount?.amount?.toNumber() ?? 0}
            currencySymbol={amount?.currency?.symbol}
            className={classes.costReadOnlyValue}
            placement="right"
          />
        )}
      </div>
    </div>
  );
};

export default BusinessCostRow;
