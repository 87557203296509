import React, { createContext } from 'react';
import { useDateTimeFormat } from './useDateTimeFormat';

export const defaultDateFormat = 'MMM d, yyyy';
export const defaultDateTimeFormat = "MMM d, yyyy h':'mm tt";

export type DateTimeFormatProviderData = {
  dateFormat: string;
  dateTimeFormat: string;
};

export const DateTimeFormatContext = createContext<DateTimeFormatProviderData>({
  dateFormat: defaultDateFormat,
  dateTimeFormat: defaultDateTimeFormat
});

export const DateTimeFormatProvider: React.FC = ({ children }) => {
  const value = useDateTimeFormat();
  return (
    <DateTimeFormatContext.Provider value={value}>
      {children}
    </DateTimeFormatContext.Provider>
  );
};
