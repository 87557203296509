import React from 'react';
import { CellProps } from 'react-table';
import { makeStyles } from '@material-ui/core';
import { usePlanEditContext, usePlanTotalContext } from 'plans/plan/context';
import { BillingByRoleData } from 'common/types';
import { useLockedColumnContext } from 'common/context';
import { useFeatureFlag } from 'common/hooks';

const useStyles = makeStyles(theme => ({
  label: {
    color: (prop: { isLocked: boolean }) =>
      prop.isLocked ? theme.palette.grey[400] : 'inherit'
  }
}));

export const MarginFooter: React.FC<CellProps<BillingByRoleData>> = ({
  column: { id }
}: CellProps<BillingByRoleData>) => {
  const { lockedColumn } = useLockedColumnContext();
  const { canEdit } = usePlanEditContext();
  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const isLocked = Boolean(lockedColumn === id);
  const classes = useStyles({ isLocked: isLocked && canEdit && !cpoNewUx });

  const { margin } = usePlanTotalContext();

  return <span className={classes.label}>{margin.toFixed(2)}&nbsp;%</span>;
};
