import React from 'react';
import PageHeader from 'common/components/PageHeader';
import StickyHeader from 'common/components/StickyHeader';
import { PlanStatus } from 'generated-types';
import { useHasCpoEditPermission } from 'common/hooks';
import { usePlanContext } from './context';
import PlanPageTitleBar from './PlanPageTitleBar';
import PlanTitleBarActions from './PlanTitleBarActions';
import PlanPageStatusBar from './PlanPageStatusBar';
import PlanMoreActions from './PlanMoreActions';
import { PlanActions } from './components';
import PlanUpdateCostsAction from './PlanUpdateCostsAction';

const PlanPageHeader: React.FC = () => {
  const { plan, loading } = usePlanContext();
  const hasCpoEditPermission = useHasCpoEditPermission();
  return (
    <>
      <PageHeader bottomBorder={false}>
        <PlanPageTitleBar
          name={plan?.name ?? undefined}
          startDate={plan?.effectiveDate ?? undefined}
          endDate={plan?.endDate ?? undefined}
          loading={loading}>
          {!loading && hasCpoEditPermission && (
            <PlanTitleBarActions>
              <PlanActions />
              <PlanMoreActions planId={plan?.id} planStatus={plan?.status} />
            </PlanTitleBarActions>
          )}
        </PlanPageTitleBar>
      </PageHeader>
      <StickyHeader level={1}>
        <PlanPageStatusBar
          status={plan?.status ?? PlanStatus.Draft}
          loading={loading}
        />
        <PlanUpdateCostsAction />
      </StickyHeader>
    </>
  );
};

export default PlanPageHeader;
