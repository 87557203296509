import React, { useMemo } from 'react';
import {
  InMemoryCache,
  NormalizedCacheObject,
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import withCustomScalar from './scalar';
import {
  errorLoggingMiddleware,
  unauthorizedHandlerMiddleware
} from './errorHandling';

const useApolloClientForUrl = (
  apiUrl: string
): ApolloClient<NormalizedCacheObject> => {
  const client = useMemo(
    () =>
      new ApolloClient({
        name: 'cpo-frontend',
        link: ApolloLink.from([
          // eslint-disable-next-line no-console
          onError(errorLoggingMiddleware(console.error)),
          // eslint-disable-next-line no-console
          onError(unauthorizedHandlerMiddleware(console.error)),
          withCustomScalar(),
          new HttpLink({
            uri: apiUrl,
            credentials: 'include'
          })
        ]),
        cache: new InMemoryCache()
      }),
    [apiUrl]
  );

  return client;
};

type GqlClientProviderProps = {
  children: React.ReactNode;
  apiUrl: string;
};

export const GqlClientProvider: React.FC<GqlClientProviderProps> = ({
  children,
  apiUrl
}: GqlClientProviderProps) => {
  const client = useApolloClientForUrl(apiUrl);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
