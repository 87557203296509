import React from 'react';
import BigNumber from 'bignumber.js';
import { CellProps } from 'react-table';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TooltipMoney } from 'common/components';
import { BillingByRoleData } from 'common/types';
import { isMoney } from 'util/money';

export const BillableCell: React.FC<CellProps<BillingByRoleData>> = ({
  value
}: CellProps<BillingByRoleData>) => {
  if (BigNumber.isBigNumber(value)) {
    return <>{value.toString()}</>;
  } else if (!value) {
    return (
      <Tooltip title={<FormattedMessage id="noDataAvailable" />}>
        <span>&mdash;</span>
      </Tooltip>
    );
  } else if (isMoney(value)) {
    return (
      <TooltipMoney
        currencySymbol={value?.currency?.symbol ?? ''}
        value={value?.amount?.toNumber() ?? 0}
        placement="right"
      />
    );
  } else {
    return <>{value}</>;
  }
};
