import { useCallback, useEffect, useState } from 'react';
import { useSaveTableSettings, useTableSettings } from 'common/hooks';
import { PlanListSortableFields, SortDirection } from 'generated-types';

const PLANS_TABLE_KEY = 'cpo-plan-table';

export const usePlanTableSort = (): {
  loadingTableSetting: boolean;
  periodSortDirection: SortDirection;
  togglePeriodSort: () => void;
} => {
  const { loading: loadingTableSetting, tableSettings } = useTableSettings({
    tableKey: PLANS_TABLE_KEY,
    defaultColumns: []
  });
  const { saveTableSettings } = useSaveTableSettings();

  const [periodSortDirection, setDateSortDirection] = useState<
    SortDirection.Asc | SortDirection.Desc
  >(SortDirection.Desc);
  const [
    tableSortSettingInitialized,
    setTableSortSettingInitialized
  ] = useState<boolean>(false);

  const togglePeriodSort = useCallback(() => {
    const direction =
      periodSortDirection === SortDirection.Asc
        ? SortDirection.Desc
        : SortDirection.Asc;
    setDateSortDirection(direction);
    saveTableSettings(PLANS_TABLE_KEY, [], {
      field: PlanListSortableFields.Period,
      direction: direction
    });
  }, [periodSortDirection, saveTableSettings]);

  useEffect(() => {
    if (!loadingTableSetting && !tableSortSettingInitialized && tableSettings) {
      if (tableSettings.sort?.direction) {
        setDateSortDirection(tableSettings.sort.direction);
      }
      setTableSortSettingInitialized(true);
    }
  }, [
    loadingTableSetting,
    periodSortDirection,
    saveTableSettings,
    tableSortSettingInitialized,
    tableSettings
  ]);

  return {
    loadingTableSetting: loadingTableSetting || !tableSortSettingInitialized,
    periodSortDirection,
    togglePeriodSort
  };
};
