import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  makeStyles
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Skeleton } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from 'App/Me';
import { Label } from 'common/components';
import { usePlanWarningContext } from 'plans/plan/context';

const useExpansionPanelStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      marginTop: 0,
      marginBottom: theme.spacing(2)
    }
  },
  expanded: {}
}));

const useExpansionPanelSummaryStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.warning.light,
    minHeight: theme.spacing(5),
    '&$expanded': {
      minHeight: theme.spacing(5)
    },
    padding: theme.spacing(0, 2, 0, 4)
  },
  content: {
    '&$expanded': {
      margin: theme.spacing(0.5, 0)
    }
  },
  expanded: {}
}));

const useExpansionPanelDetailsStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.warning.light,
    padding: theme.spacing(0, 2, 2, 4)
  }
}));

const useStyles = makeStyles(() => ({
  skeleton: {
    width: '100%'
  }
}));

export const NoBusinessCostWarning: React.FC = () => {
  const classes = useStyles();
  const expansionPanelClasses = useExpansionPanelStyles();
  const expansionPanelSummaryClasses = useExpansionPanelSummaryStyles();
  const expansionPanelDetailsClasses = useExpansionPanelDetailsStyles();
  const { plan, loading } = usePlanWarningContext();
  const { links } = useMeContext();
  const href = useMemo(() => {
    return `${links?.uiRootUrl}admin/business-costs`;
  }, [links]);

  if (loading || !plan) {
    return <Skeleton className={classes.skeleton} height={60} />;
  }
  return (
    <Accordion classes={expansionPanelClasses}>
      <AccordionSummary
        classes={expansionPanelSummaryClasses}
        expandIcon={<ArrowDropDownIcon />}>
        <Label variant="body2">
          <FormattedMessage id="noBusinessCostWarning.warningText" />
        </Label>
      </AccordionSummary>
      <AccordionDetails classes={expansionPanelDetailsClasses}>
        <Link href={href} target="_blank">
          <Label variant="body2">
            <FormattedMessage id="noBusinessCostWarning.gotoBusinessCosts" />
          </Label>
        </Link>
      </AccordionDetails>
    </Accordion>
  );
};
