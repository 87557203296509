import { useCallback, ChangeEvent } from 'react';
import BigNumber from 'bignumber.js';
import { isValidNumber } from 'util/number';
import { calculateGlobalMargin } from 'rateCard/helpers';
import { OnChange } from 'common/types';

const getValue = (value: string): BigNumber => {
  if (isValidNumber(value) && new BigNumber(value).isGreaterThan(0)) {
    return new BigNumber(value);
  }
  return new BigNumber(1);
};

const isValueChanged = (original: BigNumber, current: BigNumber): boolean =>
  !original.decimalPlaces(2).isEqualTo(current.decimalPlaces(2));

export const useTotalRevenueChange = (
  handleTotalRevenueChange: (totalRevenue: BigNumber) => void,
  handleMarginChange: (value: string, updateRefs?: boolean) => void,
  totalHours: BigNumber,
  loadedCost: BigNumber,
  totalRevenue: BigNumber
): OnChange =>
  useCallback(
    (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
    ): void => {
      const value = typeof event === 'string' ? event : event.target.value;
      const enteredRevenue = getValue(value);
      if (!isValueChanged(totalRevenue, enteredRevenue)) {
        return;
      }
      const margin = calculateGlobalMargin(
        new BigNumber(enteredRevenue),
        loadedCost,
        totalHours
      );
      handleMarginChange(margin.toString());
      handleTotalRevenueChange(enteredRevenue);
    },
    [
      handleMarginChange,
      handleTotalRevenueChange,
      loadedCost,
      totalHours,
      totalRevenue
    ]
  );
