import { useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';
import { DateTime } from 'luxon';
import {
  ActiveCostOptimizationResultDocument,
  RoleCommitInput,
  UpcomingCostOptimizationResultDocument,
  useCommitCostOptimizationResultMutation
} from 'generated-types';
import { useWorkDataPolling } from './useWorkDataPolling';

export type CommitCostOptimizationResultOptions = {
  startDate: DateTime;
  endDate: DateTime;
  roleData: RoleCommitInput[];
};
export const useCommitCostOptimizationResult = ({
  id,
  utilization,
  margin,
  onHandleSuccess,
  onCommitComplete
}: {
  id: string;
  utilization: BigNumber;
  margin: BigNumber;
  onHandleSuccess?: () => void;
  onCommitComplete?: () => void;
}): {
  commitSuccess: boolean;
  commitFailure: boolean;
  committing: boolean;
  commitCostOptimizationResult: (
    options: CommitCostOptimizationResultOptions
  ) => Promise<void>;
  handleSuccess: () => void;
  handleFailure: () => void;
  progressPercentage?: BigNumber | null;
} => {
  const [committing, setCommitting] = useState(false);
  const [commitFailure, setCommitFailure] = useState(false);
  const [commitSuccess, setCommitSuccess] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState<
    BigNumber | null | undefined
  >(undefined);

  const [
    commitCostOptimizationMutation
  ] = useCommitCostOptimizationResultMutation();
  const onCommitStart = useCallback(() => {
    setCommitting(true);
  }, []);
  const onCommitSuccess = useCallback(() => {
    setCommitting(false);
    setCommitSuccess(true);
    onCommitComplete?.();
  }, [onCommitComplete]);

  const onCommitFailure = useCallback(() => {
    setCommitting(false);
    setCommitFailure(true);
    onCommitComplete?.();
  }, [onCommitComplete]);

  const onUpdateProgress = useCallback(
    (progressPercentage?: BigNumber | null) => {
      setProgressPercentage(progressPercentage);
    },
    []
  );
  const startPoolingWork = useWorkDataPolling(
    onCommitSuccess,
    onCommitFailure,
    true,
    onUpdateProgress
  );

  const commitCostOptimizationResult = useCallback(
    async ({
      startDate,
      roleData,
      endDate
    }: CommitCostOptimizationResultOptions) => {
      onCommitStart();
      const today = DateTime.local();
      try {
        const { data, errors } = await commitCostOptimizationMutation({
          refetchQueries: [
            {
              query: ActiveCostOptimizationResultDocument,
              variables: {
                date: {
                  year: today.year,
                  month: today.month,
                  day: today.day
                }
              }
            },
            {
              query: UpcomingCostOptimizationResultDocument,
              variables: {
                date: {
                  year: today.year,
                  month: today.month,
                  day: today.day
                }
              }
            }
          ],
          variables: {
            input: {
              costOptimizationResultId: id,
              utilization: utilization,
              margin: margin,
              effectiveDate: {
                day: startDate.day,
                month: startDate.month,
                year: startDate.year
              },
              endDate: endDate && {
                day: endDate.day,
                month: endDate.month,
                year: endDate.year
              },
              roleData
            }
          }
        });
        if (errors) {
          throw new Error();
        }
        if (data?.commitCostOptimizationResult?.backgroundWorkId) {
          startPoolingWork(data.commitCostOptimizationResult.backgroundWorkId);
        } else {
          setCommitting(false);
        }
      } catch (e) {
        onCommitFailure();
        return;
      }
    },
    [
      commitCostOptimizationMutation,
      id,
      margin,
      onCommitFailure,
      onCommitStart,
      startPoolingWork,
      utilization
    ]
  );

  const handleSuccess = useCallback(() => {
    setCommitSuccess(false);
    onHandleSuccess?.();
  }, [onHandleSuccess]);

  const handleFailure = useCallback(() => {
    setCommitFailure(false);
  }, []);

  return {
    commitSuccess,
    commitFailure,
    committing,
    progressPercentage,
    commitCostOptimizationResult,
    handleSuccess,
    handleFailure
  };
};
