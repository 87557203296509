import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(() => ({
  root: {}
}));

type DrawerBodyProps = { classes?: ClassNameMap; children?: React.ReactNode };

export const DrawerBody: React.FC<DrawerBodyProps> = ({
  classes,
  children
}: DrawerBodyProps) => {
  const cls = useStyles({ classes });
  return <div className={cls.root}>{children}</div>;
};
