/* eslint-disable react/no-set-state */

/**
 * Wrap components that may fail in this component to prevent them
 * from crashing the entire application when an error occurs.
 *
 * Roughly based on https://github.com/bvaughn/react-error-boundary
 *
 * onDidCatch method in @hocs/with-lifecycle doesn't provide access
 * to the component props which means it's almost entirely useless.
 * We need to use a class here to be able to implement componentDidCatch.
 */
import React from 'react';

import ErrorBoundaryFallbackComponent from './ErrorBoundaryFallbackComponent';

type ErrorBoundaryProps = {
  onError?: (err: Error) => void;
  FallbackComponent?: React.ElementType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fallbackComponentProps?: any;
};

type ErrorBoundaryState = {
  info: React.ErrorInfo | null;
  error: Error | null;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      info: null,
      error: null
    };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const { onError } = this.props;

    if (typeof onError === 'function') {
      try {
        onError(error);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    this.setState({
      error,
      info
    });
  }

  render(): React.ReactNode {
    const { error, info } = this.state;
    const {
      children,
      FallbackComponent = ErrorBoundaryFallbackComponent,
      fallbackComponentProps = {}
    } = this.props;

    if (error !== null) {
      return (
        <FallbackComponent
          error={error}
          componentStack={info ? info.componentStack : ''}
          {...fallbackComponentProps}
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
