import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Alert, Skeleton } from '@material-ui/lab';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import classnames from 'classnames';
import { Label } from 'common/components';
import { usePlanWarningContext } from 'plans/plan/context';
import { useHasBlockingErrors } from 'plans/hooks';

const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(2, 3, 1) },
  title: {
    fontWeight: theme.typography.fontWeightMedium
  },
  warning: {
    width: '100%',
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.warning.light,
    marginTop: theme.spacing(1)
  },
  error: {
    backgroundColor: 'rgb(253, 236, 234)'
  },
  skeletonRoot: {
    width: '100%',
    height: theme.spacing(11)
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

type PlanDrawerTitleProps = { classes?: ClassNameMap };

export const PlanDrawerTitle: React.FC<PlanDrawerTitleProps> = ({
  classes
}: PlanDrawerTitleProps) => {
  const cls = useStyles({ classes });
  const { loading, plan } = usePlanWarningContext();

  const hasBlockingErrors = useHasBlockingErrors(
    plan?.overlappingPlansWarnings
  );
  const totalWarningsCount =
    (plan?.warningsCount?.totalWarningsCount?.toNumber() ?? 0) +
    (plan?.operationOverhead?.length ? 0 : 1);

  const countValue = useMemo(
    () => ({
      count: (
        <span className={cls.bold}>
          &nbsp;{totalWarningsCount}
          &nbsp;
        </span>
      )
    }),
    [cls.bold, totalWarningsCount]
  );

  if (loading || !plan) {
    return (
      <div className={classnames(cls.root, cls.skeletonRoot)}>
        <Skeleton width={100} />
        <Skeleton width={200} height={45} />
      </div>
    );
  }
  return (
    <div className={cls.root}>
      <Label
        className={cls.title}
        variant="body1"
        labelId="planWarningDrawer.title"
      />
      <Alert
        severity={hasBlockingErrors ? 'error' : 'warning'}
        className={classnames(cls.warning, {
          [cls.error]: hasBlockingErrors
        })}>
        <FormattedPlural
          one={<FormattedMessage id="planPage.warning.text.one" />}
          other={
            <FormattedMessage
              id="planPage.warning.text.other"
              values={countValue}
            />
          }
          value={totalWarningsCount}
        />
      </Alert>
    </div>
  );
};
