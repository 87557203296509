import { useMemo } from 'react';
import { Me } from 'generated-types';
import { useMeContext } from 'App/Me';
import {
  DateTimeFormatProviderData,
  defaultDateFormat,
  defaultDateTimeFormat
} from './DateTimeFormatProvider';

const getLuxonJsDateFormatStringFromDotNetPattern = (
  dotNetPattern: string
): string => {
  let luxonFormatString = dotNetPattern;

  luxonFormatString = luxonFormatString.replace(/dddd/g, 'cccc');
  luxonFormatString = luxonFormatString.replace(/ddd/g, 'ccc');
  luxonFormatString = luxonFormatString.replace(/tt/g, 'a');

  return luxonFormatString;
};

const getLuxonJsDateFormatFromMe = (me: Me): string => {
  const dotNetPattern =
    me.locale?.dateTimeSettings?.shortDateFormat?.dotNetPattern;

  return getLuxonJsDateFormatStringFromDotNetPattern(
    dotNetPattern ?? defaultDateFormat
  );
};

const getLuxonJsDateTimeFormatFromMe = (me: Me): string => {
  const dotNetPattern =
    me.locale?.dateTimeSettings?.shortDateFormat?.dotNetPattern;
  const dotNetTimePattern =
    me.locale?.dateTimeSettings?.shortTimeFormat?.dotNetPattern;

  const combinedPattern = `${dotNetPattern} ${dotNetTimePattern}`;

  return dotNetPattern && dotNetTimePattern
    ? getLuxonJsDateFormatStringFromDotNetPattern(combinedPattern)
    : defaultDateTimeFormat;
};

export const useDateTimeFormat = (): DateTimeFormatProviderData => {
  const me = useMeContext();
  return useMemo(
    () => ({
      dateFormat: getLuxonJsDateFormatFromMe(me),
      dateTimeFormat: getLuxonJsDateTimeFormatFromMe(me)
    }),
    [me]
  );
};
