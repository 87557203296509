import React, {
  createContext,
  useContext,
  MutableRefObject,
  useMemo
} from 'react';
import { UnregisterCallback } from 'history';

type NavigationBlockContextProps = {
  unblockRef: MutableRefObject<UnregisterCallback | undefined>;
  unblockNavigation: () => void;
};

const NavigationBlockContext = createContext<
  NavigationBlockContextProps | undefined
>(undefined);

export const NavigationBlockContextProvider: React.FC<NavigationBlockContextProps> = ({
  unblockRef,
  unblockNavigation,
  children
}: React.PropsWithChildren<NavigationBlockContextProps>) => {
  const value = useMemo(() => ({ unblockRef, unblockNavigation }), [
    unblockNavigation,
    unblockRef
  ]);

  return (
    <NavigationBlockContext.Provider value={value}>
      {children}
    </NavigationBlockContext.Provider>
  );
};

export const useNavigationBlockContext = ():
  | NavigationBlockContextProps
  | undefined => {
  return useContext(NavigationBlockContext);
};
