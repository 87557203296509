import React, { useContext, useMemo, useState } from 'react';
import { Maybe } from 'generated-types';

export type CalculateAttribute = 'utilization' | 'margin' | 'billingRate';
export type CalculateForContextValue = {
  calculateAttribute: CalculateAttribute;
  setCalculateAttribute: (value: CalculateAttribute) => void;
  lockedColumns: CalculateAttribute[];
  setLockedColumns: (value: CalculateAttribute[]) => void;
};

const CalculateAttributeContext = React.createContext<
  Maybe<CalculateForContextValue>
>(null);

export const useCalculateAttributeContext = (): CalculateForContextValue => {
  const val = useContext(CalculateAttributeContext);
  if (!val) {
    throw new Error(
      'useCalculateAttributeContext must be called within a CalculateAttributeContext'
    );
  }

  return val;
};

type CalculateAttributeContextProviderProps = {
  children: React.ReactNode;
};

export const CalculateAttributeContextProvider: React.FC<CalculateAttributeContextProviderProps> = ({
  children
}: CalculateAttributeContextProviderProps) => {
  const [calculateAttribute, setCalculateAttribute] = useState<
    CalculateAttribute
  >('billingRate');

  const [lockedColumns, setLockedColumns] = useState<CalculateAttribute[]>([]);
  return (
    <CalculateAttributeContext.Provider
      value={useMemo(
        () => ({
          calculateAttribute,
          setCalculateAttribute,
          lockedColumns,
          setLockedColumns
        }),
        [calculateAttribute, lockedColumns]
      )}>
      {children}
    </CalculateAttributeContext.Provider>
  );
};

export const CalculateAttributeContextConsumer =
  CalculateAttributeContext.Consumer;
