import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CpoRouteProps } from 'common/routes/Routes';
import OverviewRouteProxy from './OverviewRouteProxy';

export const overviewPageRoute = (prefix = ''): CpoRouteProps => ({
  path: `${prefix}/overview`,
  component: OverviewRouteProxy,
  title: <FormattedMessage id="tabs.overview" />
});
