import { fade, lighten, makeStyles } from '@material-ui/core/styles';

export const useTableStyles = makeStyles(theme => ({
  table: {
    alignSelf: 'flex-start',
    width: 'unset',
    minWidth: '100%',
    borderCollapse: 'separate',
    margin: 0,
    padding: 0,
    border: 'none',
    borderBottom: `1px solid ${lighten(fade(theme.palette.divider, 1), 0.88)}`
  },
  secondColumnStickyRow: {
    '& > td:nth-child(2)': {
      position: 'sticky',
      left: theme.spacing(0),
      zIndex: 2
    },
    '& > th:nth-child(2)': {
      backgroundColor: '#ffffff',
      position: 'sticky',
      zIndex: 4
    },
    '& > td:nth-child(2), th:nth-child(2)': {
      left: theme.spacing(20),
      maxWidth: theme.spacing(15),
      minWidth: theme.spacing(15)
    }
  },
  tableRow: {
    '& > td:first-child': {
      position: 'sticky',
      left: theme.spacing(2),
      zIndex: 2
    },
    '& > th:first-child': {
      backgroundColor: '#ffffff',
      position: 'sticky',
      left: theme.spacing(2),
      zIndex: 4
    },
    '& > td:first-child, th:first-child': {
      width: theme.spacing(18),
      maxWidth: theme.spacing(18),
      minWidth: theme.spacing(18)
    },
    '& > th, td': {
      minWidth: theme.spacing(15),
      maxWidth: theme.spacing(20),
      overflowX: 'hidden',
      whiteSpace: 'normal'
    },
    '& > th': {
      padding: theme.spacing(0.375, 2.5),
      color: theme.palette.grey[600],
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: '#ffffff'
    }
  },
  spanHeaderRow: {
    backgroundColor: '#ffffff',
    '& > th:first-child, th:nth-child(2), th:nth-child(3)': {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: '#ffffff'
    },
    '& > th:first-child': {
      left: theme.spacing(2),
      zIndex: 5
    },
    '& > th': {
      zIndex: 1
    }
  },
  headerRow: {
    '& > th': {
      zIndex: 3,
      top: theme.spacing(3.625),
      backgroundColor: '#ffffff'
    }
  },
  borderCell: {
    borderRight: `1px solid ${theme.palette.grey[300]}`
  },
  header1: {
    '& > th': {
      top: theme.spacing(25.5)
    }
  },
  header2: {
    '& > th': {
      top: theme.spacing(29.125)
    }
  }
}));
