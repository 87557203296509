import React from 'react';
import { TableHead, TableRow, TableCell, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import BigNumber from 'bignumber.js';
import { RoleBreakdown } from 'generated-types';
import { NumberValue, MoneyValue } from 'common/components';

const useStyles = makeStyles(theme => ({
  tableCell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1)
    }
  },
  tableRow: {
    background: theme.palette.grey[100]
  }
}));

const useCellStyle = makeStyles(theme => ({
  root: {
    '&:last-child': {
      paddingRight: theme.spacing(3)
    }
  }
}));

type RoleSummaryTableTotalsHeaderProps = {
  role: Maybe<RoleBreakdown>;
  cellClasses?: ClassNameMap;
  margin: BigNumber;
  utilization: BigNumber;
};
export const RoleSummaryTableTotalsHeader: React.FC<RoleSummaryTableTotalsHeaderProps> = ({
  role,
  cellClasses,
  margin,
  utilization
}: RoleSummaryTableTotalsHeaderProps) => {
  const classes = useStyles();
  const cellStyle = useCellStyle({ classes: cellClasses });
  const numberColumnDirection = 'right';

  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        <TableCell classes={cellStyle} className={classes.tableCell}>
          <FormattedMessage
            id="roleUserBreakdownPage.roleSummary"
            values={{ roleName: role?.role?.displayText }}
          />
        </TableCell>
        <TableCell align={numberColumnDirection} className={classes.tableCell}>
          <NumberValue value={role?.totalHours?.toNumber() ?? 0} />
        </TableCell>
        <TableCell align={numberColumnDirection} className={classes.tableCell}>
          <MoneyValue
            currencySymbol={role?.totalCost?.currency?.symbol ?? ''}
            value={role?.totalCost?.amount?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell align={numberColumnDirection} className={classes.tableCell}>
          <MoneyValue
            currencySymbol={role?.employeeCost?.currency?.symbol ?? ''}
            value={role?.employeeCost?.amount?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell align={numberColumnDirection} className={classes.tableCell}>
          <MoneyValue
            currencySymbol={role?.nonBillableOverhead?.currency?.symbol ?? ''}
            value={role?.nonBillableOverhead?.amount?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell align={numberColumnDirection} className={classes.tableCell}>
          <MoneyValue
            currencySymbol={role?.businessOverhead?.currency?.symbol ?? ''}
            value={role?.businessOverhead?.amount?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell align={numberColumnDirection} className={classes.tableCell}>
          <MoneyValue
            currencySymbol={role?.loadedCost?.currency?.symbol ?? ''}
            value={role?.loadedCost?.amount?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell align={numberColumnDirection} className={classes.tableCell}>
          <NumberValue
            value={utilization.toNumber() ?? 0}
            suffix=" %"
            decimalScale={2}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell align={numberColumnDirection} className={classes.tableCell}>
          <NumberValue
            value={margin.toNumber() ?? 0}
            suffix=" %"
            decimalScale={2}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell align={numberColumnDirection} className={classes.tableCell}>
          <MoneyValue
            currencySymbol={role?.billingCost?.currency?.symbol ?? ''}
            value={role?.billingCost?.amount?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell
          align={numberColumnDirection}
          classes={cellStyle}
          className={classes.tableCell}>
          <MoneyValue
            currencySymbol={role?.billingCost?.currency?.symbol ?? ''}
            value={role?.revenue?.amount?.toNumber() ?? 0}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default RoleSummaryTableTotalsHeader;
