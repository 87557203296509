import { DateTime, DateObject } from 'luxon';
import { ApolloError } from '@apollo/client';
import {
  Maybe,
  useGetOverlappingPlansQuery,
  GetOverlappingPlansQuery
} from 'generated-types';

type UseOverlappingPlansResult = {
  loading: boolean;
  data: GetOverlappingPlansQuery | undefined;
  error: ApolloError | undefined;
};

const dateObj = (d: DateObject) => ({
  year: d.year,
  month: d.month,
  day: d.day
});

export const useOverlappingPlans = (
  startDate?: Maybe<DateTime>,
  endDate?: Maybe<DateTime>
): UseOverlappingPlansResult => {
  const { loading, data, error } = useGetOverlappingPlansQuery({
    skip: !startDate || !endDate,
    variables: {
      startDate: startDate && dateObj(startDate?.toObject()),
      endDate: endDate && dateObj(endDate?.toObject())
    },
    fetchPolicy: 'network-only'
  });

  return { loading, data, error };
};
