import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, Tooltip } from '@material-ui/core';
import { useHoverDirty } from 'react-use';
import { Maybe } from 'generated-types';
import { Label } from 'common/components';
import { useRedirect } from 'common/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    width: ({ percent }: { percent: number }) => `${percent}%`,
    padding: theme.spacing(0, 0.25)
  },
  range: {
    padding: theme.spacing(0, 0, 3, 0),
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  horizontalLine: {
    width: '100%',
    height: theme.spacing(0.125),
    borderTop: `1px solid ${theme.palette.grey[500]}`,
    margin: theme.spacing(2, 0, 0, -0.125)
  },
  verticalLine: {
    width: theme.spacing(0.125),
    height: theme.spacing(5),
    borderLeft: `1px solid ${theme.palette.grey[500]}`,
    margin: theme.spacing(0),
    color: theme.palette.grey[700]
  },
  label: {
    ...theme.typography.body2,
    marginTop: theme.spacing(-5.25),
    textAlign: 'center',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 1)
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    display: 'initial'
  }
}));

export type PlanViewProps = {
  hasPlan: boolean;
  percent: number;
  hasStartTick: boolean;
  hasEndTick: boolean;
  planId?: Maybe<string>;
  planName?: Maybe<string>;
};

export const PlanView: React.FC<PlanViewProps> = ({
  hasPlan,
  percent,
  planName,
  hasStartTick,
  planId,
  hasEndTick
}: PlanViewProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const classes = useStyles({ percent });
  const planNameRef = useRef<HTMLButtonElement>(null);
  const isHovering = useHoverDirty(planNameRef);

  const checkTextSize = useCallback(() => {
    const scrollWidth = planNameRef.current?.scrollWidth ?? 0;
    const clientWidth = planNameRef.current?.clientWidth ?? 0;
    const tooltipStatus = scrollWidth > clientWidth;
    setShowTooltip(tooltipStatus);
  }, []);

  useEffect(() => {
    if (!isHovering) {
      setShowTooltip(false);
    }
  }, [isHovering]);

  const onClick = useRedirect(`/plans/${planId}`);
  return (
    <div className={classes.root}>
      {hasPlan && (
        <>
          <div className={classes.range}>
            {hasStartTick && <hr className={classes.verticalLine} />}
            <hr className={classes.horizontalLine} />
            {hasEndTick && <hr className={classes.verticalLine} />}
          </div>
          <Label onClick={onClick} className={classes.label}>
            <Tooltip
              title={planName ?? ''}
              open={showTooltip}
              onOpen={checkTextSize}>
              <ButtonBase className={classes.text} ref={planNameRef}>
                {planName}
              </ButtonBase>
            </Tooltip>
          </Label>
        </>
      )}
    </div>
  );
};
