import React from 'react';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import { usePlanEditContext } from 'plans/plan/context';
import { Button } from 'common/components';

const startIcon = <CreateIcon />;

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2)
  }
}));

export const PlanEditButton: React.FC = () => {
  const { enablePlanEdit } = usePlanEditContext();
  const classes = useStyles();
  return (
    <Button
      classes={classes}
      startIcon={startIcon}
      onClick={enablePlanEdit}
      labelId="planPage.edit"
      variant="contained"
    />
  );
};
