import React, { useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { DatePicker } from '@material-ui/pickers';
import { FormattedMessage } from 'react-intl';
import { Button, Label } from 'common/components';
import { useMeDateTimeFormats } from 'App/DateTimeFormatProvider';
import { useModalStyles } from './useModalStyles';
import { useSetRatesConfirmation } from './useSetRatesConfirmation';

const useDialogStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(0.5, 0.5, 2)
  }
}));
type SetRatesConfirmationDialogProps = {
  open: boolean;
  effectiveDate: string;
  endDate?: string | null;
  closeDialog: () => void;
  commitRateCard: (endDate: DateTime) => void;
};

const startDateLabel = <FormattedMessage id="effectiveDateDialog.startDate" />;
const endDateLabel = <FormattedMessage id="effectiveDateDialog.endDate" />;

export const SetRatesConfirmationDialog: React.FC<SetRatesConfirmationDialogProps> = ({
  open,
  closeDialog,
  commitRateCard,
  effectiveDate,
  endDate: initialEndDate
}: SetRatesConfirmationDialogProps) => {
  const classes = useModalStyles();
  const { dateFormat } = useMeDateTimeFormats();
  const startDate = useMemo<string | undefined>(() => {
    if (effectiveDate) {
      return DateTime.fromISO(effectiveDate).toFormat(dateFormat);
    }
  }, [dateFormat, effectiveDate]);
  const { endDate, onEndDateChange, setRates } = useSetRatesConfirmation({
    closeDialog,
    commitRates: commitRateCard,
    endDate: initialEndDate
  });
  return (
    <Dialog open={open} onClose={closeDialog} classes={useDialogStyles()}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Label variant="h6" labelId="activePage.setRatesDialog.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Label
          variant="body1"
          className={classes.info}
          labelId="activePage.setRatesDialog.content"
        />
        <TextField
          className={classes.dateDivider}
          disabled
          variant="filled"
          label={startDateLabel}
          value={startDate}
        />
        <DatePicker
          autoOk
          inputVariant="filled"
          label={endDateLabel}
          disablePast
          value={endDate}
          onChange={onEndDateChange}
        />
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          onClick={closeDialog}
          className={classes.cancel}
          color="default"
          labelId="activePage.setRatesDialog.cancel"
        />
        <Button
          color="primary"
          disabled={!Boolean(endDate)}
          onClick={setRates}
          labelId="activePage.setRatesDialog.setRates"
        />
      </DialogActions>
    </Dialog>
  );
};

export default SetRatesConfirmationDialog;
