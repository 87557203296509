import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SummaryCard } from 'common/components';

const useStyles = makeStyles(theme => ({
  root: {
    height: 120
  },
  container: {
    maxWidth: '25%',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '18%'
    }
  }
}));
export const RoleRateSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid item xs={4} className={classes.container}>
      <SummaryCard className={classes.root}>
        <Skeleton />
        <Grid container>
          <Grid item xs={3}>
            {[...Array(2)].map((__: number, rowIndex: number) => (
              <Skeleton key={`${rowIndex}`} />
            ))}
          </Grid>
          <Grid item xs={4}>
            {[...Array(2)].map((__: number, rowIndex: number) => (
              <Skeleton key={`${rowIndex}`} />
            ))}
          </Grid>
          <Grid item xs={4}>
            <Skeleton />
          </Grid>
          <Grid item xs={12}>
            <Skeleton />
          </Grid>
        </Grid>
      </SummaryCard>
    </Grid>
  );
};

export default RoleRateSkeleton;
