import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { SmartBudgetResult } from 'generated-types';
import { PlanView } from './PlanView';
import { usePlanRange } from './usePlanRange';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '90%',
    margin: theme.spacing(-3, 6, 4, 8),
    [theme.breakpoints.up(1000)]: {
      margin: theme.spacing(-3, 6, 4, 8.25)
    },
    [theme.breakpoints.up(1100)]: {
      margin: theme.spacing(-3, 6, 4, 9.125)
    },
    [theme.breakpoints.up(1250)]: {
      margin: theme.spacing(-3, 6, 4, 10)
    },
    [theme.breakpoints.up(1300)]: {
      margin: theme.spacing(-3, 6, 4, 10.5)
    },
    [theme.breakpoints.up(1400)]: {
      margin: theme.spacing(-3, 6, 4, 11.5)
    },
    [theme.breakpoints.up(1550)]: {
      margin: theme.spacing(-3, 6, 4, 12.5)
    },
    [theme.breakpoints.up(1650)]: {
      margin: theme.spacing(-3, 6, 4, 13.5)
    },
    [theme.breakpoints.up(1800)]: {
      margin: theme.spacing(-3, 6, 4, 15)
    },
    [theme.breakpoints.up(2000)]: {
      margin: theme.spacing(-3, 12, 4, 18)
    },
    [theme.breakpoints.up(2300)]: {
      margin: theme.spacing(-3, 12, 4, 20)
    },
    [theme.breakpoints.up(2800)]: {
      margin: theme.spacing(-3, 12, 4, 24)
    }
  }
}));

export type PlanRangeProps = {
  plans: SmartBudgetResult[];
  startDate: DateTime;
  endDate: DateTime;
};

export const PlanRange: React.FC<PlanRangeProps> = ({
  plans,
  startDate,
  endDate
}: PlanRangeProps) => {
  const classes = useStyles();
  const planRanges = usePlanRange({ startDate, endDate, plans });
  return (
    <div className={classes.root}>
      {planRanges.map((plan, index) => (
        <PlanView
          hasPlan={Boolean(plan.name && plan.id)}
          key={`plan_${index}`}
          percent={plan.weight}
          planName={plan.name}
          planId={plan.id}
          hasStartTick={plan.isStartBeforeRange !== true}
          hasEndTick={plan.isEndAfterRange !== true}
        />
      ))}
    </div>
  );
};
