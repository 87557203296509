import React, { useCallback, useMemo } from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme
} from 'victory';
import { DateTime } from 'luxon';
import { useIntl, useMessage, useMessages } from 'App/MessageProvider';
import { useOverviewChartData } from 'overview/hooks';
import { useOverviewDateRangeContext } from 'overview/context/OverviewDateRangeContext';
import { Legends } from '../Legends';
import { PlanRange } from '../PlanRange';
import { VictoryLabelAndTooltip } from './VictoryLabelAndTooltip';
import { numberAbbreviatedTickFormat } from './util';
import { useChartStyles } from './useChartStyles';
import { OverviewChartSkeleton } from './OverviewChartSkeleton';
import { useXAxisTick } from './hooks';

enum ChartAxisKeys {
  MONTH = 'month',
  BUDGETED = 'budgeted',
  ESTIMATED = 'estimated',
  ACTUAL = 'actual'
}
export type OverviewBarType = 'estimated' | 'budgeted' | 'actual';

export const OverviewChart: React.FC = () => {
  const intl = useIntl();
  const { startDate, endDate } = useOverviewDateRangeContext();
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);
  const {
    loading,
    overviewGraphData,
    legends,
    baseCurrency,
    plans
  } = useOverviewChartData(
    { year: start.year, month: start.month, day: start.day },
    { year: end.year, month: end.month, day: end.day }
  );
  const chartConfig = useChartStyles({ startDate: start, endDate: end });
  const getYTickFormat = useCallback(
    tick => numberAbbreviatedTickFormat({ intl, tick: Math.abs(tick) }),
    [intl]
  );

  const getTooltipComponent = useCallback(
    (type: OverviewBarType) => (
      <VictoryLabelAndTooltip
        pointerLength={chartConfig.tooltip.pointerLength}
        pointerWidth={chartConfig.tooltip.pointerWidth}
        flyoutWidth={chartConfig.tooltip.flyoutWidth}
        flyoutHeight={chartConfig.tooltip.flyoutHeight}
        cornerRadius={chartConfig.tooltip.cornerRadius}
        labelComponent={
          <VictoryLabel
            lineHeight={1.3}
            style={chartConfig.tooltip.labelStyle}
          />
        }
        flyoutStyle={chartConfig.tooltip.flyoutStyle}
        style={chartConfig.bar.label.style}
        type={type}
        dy={-2}
      />
    ),
    [
      chartConfig.bar.label.style,
      chartConfig.tooltip.cornerRadius,
      chartConfig.tooltip.flyoutHeight,
      chartConfig.tooltip.flyoutStyle,
      chartConfig.tooltip.flyoutWidth,
      chartConfig.tooltip.labelStyle,
      chartConfig.tooltip.pointerLength,
      chartConfig.tooltip.pointerWidth
    ]
  );

  const getTickLabel = useCallback(
    ({ dx, dy }: { dx?: number; dy?: number }) => (
      <VictoryLabel style={chartConfig.axis.labelStyles} dx={dx} dy={dy} />
    ),
    [chartConfig.axis.labelStyles]
  );

  const toolTipMessages = useMessages([
    'overviewPage.chart.tooltip.budget',
    'overviewPage.chart.tooltip.estimated',
    'overviewPage.chart.tooltip.actual'
  ]);
  const getTooltipLabels = useCallback(
    ({ datum }) =>
      [
        datum.budgeted &&
          `${
            toolTipMessages['overviewPage.chart.tooltip.budget']
          } ${numberAbbreviatedTickFormat({
            intl,
            tick: Math.abs(datum.budgeted.toFixed())
          })}`,
        datum.estimated &&
          `${
            toolTipMessages['overviewPage.chart.tooltip.estimated']
          } ${numberAbbreviatedTickFormat({
            intl,
            tick: Math.abs(datum.estimated.toFixed())
          })}`,
        datum.actual &&
          `${
            toolTipMessages['overviewPage.chart.tooltip.actual']
          } ${numberAbbreviatedTickFormat({
            intl,
            tick: Math.abs(datum.actual.toFixed())
          })}`
      ].filter(value => value) as string[],
    [intl, toolTipMessages]
  );
  const axisLabelComponent = useMemo(
    () => (
      <VictoryLabel
        verticalAnchor="middle"
        x={3}
        style={chartConfig.axis.y.axisLabel}
      />
    ),
    [chartConfig.axis.y.axisLabel]
  );
  const xAxisTick = useXAxisTick({
    startDate: start,
    endDate: end,
    overviewGraphData
  });
  const yAxisLabel = useMessage('overviewPage.chart.yAxisLabel', {
    baseCurrency: baseCurrency ?? '$'
  });

  const chartDomain = useMemo(() => {
    const hasData = overviewGraphData?.find(
      item => item.budgeted || item.actual || item.estimated
    );
    return hasData ? undefined : { y: 10 };
  }, [overviewGraphData]);
  return (
    <>
      {loading ? (
        <OverviewChartSkeleton />
      ) : (
        <>
          <Legends legends={legends} />
          <VictoryChart
            padding={chartConfig.chart.padding}
            height={chartConfig.chart.height}
            theme={VictoryTheme.material}
            domainPadding={chartConfig.chart.domainPadding}
            maxDomain={chartDomain}>
            <VictoryAxis
              theme={VictoryTheme.material}
              style={chartConfig.axis.x.style}
              tickLabelComponent={getTickLabel({ dy: -9 })}
              tickFormat={xAxisTick}
            />
            <VictoryAxis
              dependentAxis
              crossAxis={false}
              label={yAxisLabel}
              axisLabelComponent={axisLabelComponent}
              theme={VictoryTheme.material}
              style={chartConfig.axis.y.style}
              tickLabelComponent={getTickLabel({ dx: 8 })}
              tickFormat={getYTickFormat}
              tickCount={4}
            />
            <VictoryBar
              key={ChartAxisKeys.BUDGETED}
              theme={VictoryTheme.material}
              barWidth={chartConfig.bar.budget.barWidth}
              style={chartConfig.bar.budget.style}
              data={overviewGraphData ?? []}
              x={ChartAxisKeys.MONTH}
              y={ChartAxisKeys.BUDGETED}
              labels={getTooltipLabels}
              labelComponent={getTooltipComponent('budgeted')}
            />
            <VictoryBar
              key={ChartAxisKeys.ESTIMATED}
              theme={VictoryTheme.material}
              barWidth={chartConfig.bar.estimated.barWidth}
              style={chartConfig.bar.estimated.style}
              data={overviewGraphData ?? []}
              x={ChartAxisKeys.MONTH}
              y={ChartAxisKeys.ESTIMATED}
              labels={getTooltipLabels}
              labelComponent={getTooltipComponent('estimated')}
            />
            <VictoryBar
              key={ChartAxisKeys.ACTUAL}
              theme={VictoryTheme.material}
              barWidth={chartConfig.bar.actual.barWidth}
              style={chartConfig.bar.actual.style}
              data={overviewGraphData ?? []}
              x={ChartAxisKeys.MONTH}
              y={ChartAxisKeys.ACTUAL}
              labels={getTooltipLabels}
              labelComponent={getTooltipComponent('actual')}
            />
          </VictoryChart>
          {plans && <PlanRange plans={plans} startDate={start} endDate={end} />}
        </>
      )}
    </>
  );
};
