/* eslint-disable */
import BigNumber from 'bignumber.js';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** BigNumber represent number in number or string format */
  BigNumber: BigNumber;
  /** Object representing a date in {day, month, year} format */
  DateObject: any;
  SearchCriterionSettings: any;
  WorkResult: any;
};





export type BusinessCostInput = {
  operationName: Scalars['String'];
  operationCost: Scalars['BigNumber'];
  isNew?: Maybe<Scalars['Boolean']>;
};

export type Calendar = {
  __typename?: 'Calendar';
  displayText?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type CloneCostOptimizationResultInput = {
  costOptimizationResultId: Scalars['String'];
};

export type CommitCostOptimizationResult = {
  __typename?: 'CommitCostOptimizationResult';
  backgroundWorkId?: Maybe<Scalars['String']>;
};

export type CommitCostOptimizationResultInput = {
  costOptimizationResultId: Scalars['String'];
  utilization: Scalars['BigNumber'];
  margin: Scalars['BigNumber'];
  effectiveDate: Scalars['DateObject'];
  endDate: Scalars['DateObject'];
  roleData?: Maybe<Array<Maybe<RoleCommitInput>>>;
};

export type CommitSmartBudgetInput = {
  smartBudgetId: Scalars['String'];
};

export type CommitSmartBudgetResult = {
  __typename?: 'CommitSmartBudgetResult';
  backgroundWorkId?: Maybe<Scalars['String']>;
};

export type CostOptimizationResult = {
  __typename?: 'CostOptimizationResult';
  id: Scalars['String'];
  /** @deprecated use 'name' node */
  fileName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<PlanStatus>;
  lastModified?: Maybe<Scalars['String']>;
  lastSynchronizedAt?: Maybe<Scalars['String']>;
  lastCommittedAt?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  /** @deprecated imports are not downloadable */
  downloadUrl?: Maybe<Scalars['String']>;
  usersCount?: Maybe<Scalars['Int']>;
  billableUserCount?: Maybe<Scalars['Int']>;
  nonBillableUserCount?: Maybe<Scalars['Int']>;
  utilization?: Maybe<Scalars['BigNumber']>;
  margin?: Maybe<Scalars['BigNumber']>;
  nearestX?: Maybe<Scalars['BigNumber']>;
  totalCurrentUtilization?: Maybe<Scalars['BigNumber']>;
  roleCostBreakdown?: Maybe<Array<RoleBreakdown>>;
  nonBillableRoles?: Maybe<Array<NonBillableRoleBreakdown>>;
  operationOverhead?: Maybe<Array<OperationOverhead>>;
  currencyTotals?: Maybe<Array<Maybe<Money>>>;
  billableCost?: Maybe<Money>;
  nonBillableCost?: Maybe<Money>;
  overheadCost?: Maybe<Money>;
  standardRate?: Maybe<Money>;
  laborRate?: Maybe<Money>;
  businessOverheadRate?: Maybe<Money>;
  nonBillableOverheadRate?: Maybe<Money>;
  billingRate?: Maybe<Money>;
  totalCost?: Maybe<Money>;
  totalNonBillableCost?: Maybe<Money>;
  totalOverheadCost?: Maybe<Money>;
  totalLoadedCost?: Maybe<Money>;
  totalRevenue?: Maybe<Money>;
  totalProfit?: Maybe<Money>;
  totalBillableHours?: Maybe<Scalars['BigNumber']>;
  totalNonBillableHours?: Maybe<Scalars['BigNumber']>;
  totalHours?: Maybe<Scalars['BigNumber']>;
  totalResourceCost?: Maybe<Money>;
  warningsCount?: Maybe<PlanWarningCount>;
  userDirectCostWarnings?: Maybe<Array<Maybe<User>>>;
  overlappingPlansWarnings?: Maybe<Array<Maybe<OverlappingPlansWarning>>>;
  uploadedBy?: Maybe<User>;
};


export type CostOptimizationResultRoleCostBreakdownArgs = {
  roleUri?: Maybe<Scalars['String']>;
};

export type CostOptimizationResultConnection = {
  __typename?: 'CostOptimizationResultConnection';
  edges: Array<CostOptimizationResultEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CostOptimizationResultEdge = {
  __typename?: 'CostOptimizationResultEdge';
  cursor: Scalars['String'];
  node: CostOptimizationResult;
};

export type CreatePlanInput = {
  name: Scalars['String'];
  dateRange: DateRangeInput;
  utilization: Scalars['BigNumber'];
  margin: Scalars['BigNumber'];
  ignoreWarning: Scalars['Boolean'];
  costOptimizationResultId?: Maybe<Scalars['String']>;
  smartBudgetId?: Maybe<Scalars['String']>;
  copyRates?: Maybe<Scalars['Boolean']>;
  copyUtilization?: Maybe<Scalars['Boolean']>;
};

export type CreatePlanResult = {
  __typename?: 'CreatePlanResult';
  backgroundWorkId?: Maybe<Scalars['String']>;
};

export type CreateSmartBudgetInput = {
  name: Scalars['String'];
  dateRange: DateRangeInput;
  utilization: Scalars['BigNumber'];
  margin: Scalars['BigNumber'];
  ignoreWarning: Scalars['Boolean'];
  smartBudgetId?: Maybe<Scalars['String']>;
  copyRates?: Maybe<Scalars['Boolean']>;
  copyUtilization?: Maybe<Scalars['Boolean']>;
};

export type CreateSmartBudgetResult = {
  __typename?: 'CreateSmartBudgetResult';
  backgroundWorkId?: Maybe<Scalars['String']>;
};

export type CsvValidationError = {
  __typename?: 'CSVValidationError';
  type: CsvValidationErrorType;
  line?: Maybe<Scalars['Int']>;
  column?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  cellValue?: Maybe<Scalars['String']>;
};

export enum CsvValidationErrorType {
  ErrNegativeValue = 'ERR_NEGATIVE_VALUE',
  ErrInvalidUserRecord = 'ERR_INVALID_USER_RECORD',
  ErrInvalidCostRecord = 'ERR_INVALID_COST_RECORD',
  ErrMixedDatatypes = 'ERR_MIXED_DATATYPES',
  ErrUserNotExist = 'ERR_USER_NOT_EXIST',
  ErrRoleNotExist = 'ERR_ROLE_NOT_EXIST',
  ErrRoleDisabled = 'ERR_ROLE_DISABLED',
  ErrCurrencyNotExist = 'ERR_CURRENCY_NOT_EXIST',
  ErrCurrencyDisabled = 'ERR_CURRENCY_DISABLED',
  ErrInvalidEncoding = 'ERR_INVALID_ENCODING',
  ErrDuplicateUser = 'ERR_DUPLICATE_USER'
}

export type Currency = {
  __typename?: 'Currency';
  id?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  displayText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

export type CustomTerm = {
  __typename?: 'CustomTerm';
  originalTerm?: Maybe<Scalars['String']>;
  customTerm?: Maybe<Scalars['String']>;
};


export type DateRange = {
  __typename?: 'DateRange';
  startDate?: Maybe<Scalars['DateObject']>;
  endDate?: Maybe<Scalars['DateObject']>;
};

export type DateRangeInput = {
  startDate: Scalars['DateObject'];
  endDate: Scalars['DateObject'];
};

export type DateTimeFormat = {
  __typename?: 'DateTimeFormat';
  amDesignator?: Maybe<Scalars['String']>;
  dateSeparator?: Maybe<Scalars['String']>;
  dotNetPattern?: Maybe<Scalars['String']>;
  pmDesignator?: Maybe<Scalars['String']>;
  timeSeparator?: Maybe<Scalars['String']>;
};

export type DateTimeSetting = {
  __typename?: 'DateTimeSetting';
  daysOfWeek?: Maybe<Array<Maybe<DayOfWeek>>>;
  fullDateTimeFormat?: Maybe<DateTimeFormat>;
  longDateFormat?: Maybe<DateTimeFormat>;
  longTimeFormat?: Maybe<DateTimeFormat>;
  shortDateFormat?: Maybe<DateTimeFormat>;
  shortTimeFormat?: Maybe<DateTimeFormat>;
  months?: Maybe<Array<Maybe<Month>>>;
};

export type DayOfWeek = {
  __typename?: 'DayOfWeek';
  abbreviatedName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type DeleteCostOptimizationResultInput = {
  costOptimizationResultId: Scalars['String'];
};

export type DeleteSmartBudgetInput = {
  smartBudgetId: Scalars['String'];
};

export type DiscardCostOptimizationResultInput = {
  costOptimizationResultId: Scalars['String'];
};

export type DiscardSmartBudgetInput = {
  smartBudgetId: Scalars['String'];
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  exchangeRate?: Maybe<Scalars['Float']>;
  value?: Maybe<Money>;
};

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  cpoTargetBillability?: Maybe<Scalars['Boolean']>;
  cpoNewUx?: Maybe<Scalars['Boolean']>;
  cpoDashboard?: Maybe<Scalars['Boolean']>;
};

export enum GraphGranularity {
  Month = 'MONTH'
}

export type GraphMetricSummaryMoney = {
  __typename?: 'GraphMetricSummaryMoney';
  dateRange: DateRange;
  actual?: Maybe<Money>;
  budgeted?: Maybe<Money>;
  estimated?: Maybe<Money>;
};

export type Language = {
  __typename?: 'Language';
  uri?: Maybe<Scalars['String']>;
  displayText?: Maybe<Scalars['String']>;
  cultureCode?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
};

export type Locale = {
  __typename?: 'Locale';
  dateTimeSettings?: Maybe<DateTimeSetting>;
  language?: Maybe<Language>;
  numberSettings?: Maybe<NumberSettings>;
  customTerms?: Maybe<Array<Maybe<CustomTerm>>>;
};

export type Me = {
  __typename?: 'Me';
  id: Scalars['String'];
  displayText: Scalars['String'];
  locale?: Maybe<Locale>;
  links?: Maybe<MeLinks>;
  baseCurrency?: Maybe<Currency>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  featureFlags?: Maybe<FeatureFlags>;
};

export type MeLinks = {
  __typename?: 'MeLinks';
  logoutUrl: Scalars['String'];
  redirection403Url: Scalars['String'];
  sessionTimeoutRedirectUrl: Scalars['String'];
  uiRootUrl: Scalars['String'];
  serviceUrl: Scalars['String'];
  psaUrl: Scalars['String'];
};

export type MetricSummaryMoney = {
  __typename?: 'MetricSummaryMoney';
  actual?: Maybe<Money>;
  budgeted?: Maybe<Money>;
  deltaPercent?: Maybe<Scalars['BigNumber']>;
  deltaMoney?: Maybe<Money>;
};

export type MetricSummaryPercent = {
  __typename?: 'MetricSummaryPercent';
  actual?: Maybe<Scalars['BigNumber']>;
  budgeted?: Maybe<Scalars['BigNumber']>;
  delta?: Maybe<Scalars['BigNumber']>;
};

export type Money = {
  __typename?: 'Money';
  uri?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['BigNumber']>;
  currency?: Maybe<Currency>;
  toBaseCurrency?: Maybe<ExchangeRate>;
};


export type MoneyToBaseCurrencyArgs = {
  effectiveDate?: Maybe<Scalars['DateObject']>;
};

export type Month = {
  __typename?: 'Month';
  abbreviatedName?: Maybe<Scalars['String']>;
  calendar?: Maybe<Calendar>;
  displayText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createSmartBudget: CreateSmartBudgetResult;
  /** @deprecated use createSmartBudget instead */
  createPlan: CreatePlanResult;
  deleteSmartBudget: Scalars['Boolean'];
  /** @deprecated use deleteSmartBudget instead */
  deletePlan: Scalars['Boolean'];
  updateSmartBudgetCosts: UpdateSmartBudgetCostsResult;
  /** @deprecated use updateSmartBudgetCosts instead */
  updatePlanCosts: UpdatePlanCostsResult;
  updateSmartBudgetName: UpdateSmartBudgetNameResult;
  /** @deprecated use updateSmartBudgetName */
  updatePlanName: UpdatePlanNameResult;
  putBusinessCosts: PutBusinessCostsResult;
  /** @deprecated no longer used in new flow */
  putCostFile: Scalars['String'];
  /** @deprecated no longer used in new flow */
  validateCostFile: ValidateCostFileResult;
  putUserBreakdownParameters: PutUserBreakdownParametersResult;
  putRoleBreakdownParameters: PutRoleBreakdownParametersResult;
  /** @deprecated no longer used in new flow */
  putCostOptimizationResultParameters: PutCostOptimizationResultParametersResult;
  /** @deprecated use commitSmartBudget */
  commitCostOptimizationResult: CommitCostOptimizationResult;
  commitSmartBudget: CommitSmartBudgetResult;
  /** @deprecated use discardSmartBudget */
  discardCostOptimizationResult: Scalars['Boolean'];
  discardSmartBudget: Scalars['Boolean'];
  /** @deprecated no longer used in new flow */
  deleteCostOptimizationResult: Scalars['Boolean'];
  /** @deprecated no longer used in new flow */
  cloneCostOptimizationResult: CostOptimizationResult;
  putSmartBudgetRounding: PutSmartBudgetRoundingResult;
  /** @deprecated use putSmartBudgetRounding instead */
  putPlanRounding: PutPlanRoundingResult;
  putTableSetting?: Maybe<PutTableSettingResult>;
  putSearchSettings?: Maybe<PutSearchSettingsResult>;
};


export type MutationCreateSmartBudgetArgs = {
  input: CreateSmartBudgetInput;
};


export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};


export type MutationDeleteSmartBudgetArgs = {
  input: DeleteSmartBudgetInput;
};


export type MutationDeletePlanArgs = {
  input: DeleteSmartBudgetInput;
};


export type MutationUpdateSmartBudgetCostsArgs = {
  input: UpdateSmartBudgetCostsInput;
};


export type MutationUpdatePlanCostsArgs = {
  input: UpdatePlanCostsInput;
};


export type MutationUpdateSmartBudgetNameArgs = {
  input: UpdateSmartBudgetNameInput;
};


export type MutationUpdatePlanNameArgs = {
  input: UpdatePlanNameInput;
};


export type MutationPutBusinessCostsArgs = {
  input: PutBusinessCostsInput;
};


export type MutationPutCostFileArgs = {
  input: PutCostFileInput;
};


export type MutationValidateCostFileArgs = {
  input: ValidateCostFileInput;
};


export type MutationPutUserBreakdownParametersArgs = {
  input: PutUserBreakdownParametersInput;
};


export type MutationPutRoleBreakdownParametersArgs = {
  input: PutRoleBreakdownParametersInput;
};


export type MutationPutCostOptimizationResultParametersArgs = {
  input: PutCostOptimizationResultParametersInput;
};


export type MutationCommitCostOptimizationResultArgs = {
  input: CommitCostOptimizationResultInput;
};


export type MutationCommitSmartBudgetArgs = {
  input: CommitSmartBudgetInput;
};


export type MutationDiscardCostOptimizationResultArgs = {
  input: DiscardCostOptimizationResultInput;
};


export type MutationDiscardSmartBudgetArgs = {
  input: DiscardSmartBudgetInput;
};


export type MutationDeleteCostOptimizationResultArgs = {
  input: DeleteCostOptimizationResultInput;
};


export type MutationCloneCostOptimizationResultArgs = {
  input: CloneCostOptimizationResultInput;
};


export type MutationPutSmartBudgetRoundingArgs = {
  input: PutSmartBudgetRoundingInput;
};


export type MutationPutPlanRoundingArgs = {
  input: PutPlanRoundingInput;
};


export type MutationPutTableSettingArgs = {
  input?: Maybe<PutTableSettingInput>;
};


export type MutationPutSearchSettingsArgs = {
  input: PutSearchSettingsInput;
};

export type NonBillableRoleBreakdown = {
  __typename?: 'NonBillableRoleBreakdown';
  id: Scalars['String'];
  userCount?: Maybe<Scalars['BigNumber']>;
  hours?: Maybe<Scalars['BigNumber']>;
  cost?: Maybe<Scalars['BigNumber']>;
  role?: Maybe<Role>;
};

export type NumberFormat = {
  __typename?: 'NumberFormat';
  decimalDigits?: Maybe<Scalars['Int']>;
  decimalSeparator?: Maybe<Scalars['String']>;
  groupSeparator?: Maybe<Scalars['String']>;
  groupSizes?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type NumberSettings = {
  __typename?: 'NumberSettings';
  number?: Maybe<NumberFormat>;
  currency?: Maybe<NumberFormat>;
};

export type OperationOverhead = {
  __typename?: 'OperationOverhead';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  cost?: Maybe<Money>;
  isNew?: Maybe<Scalars['Boolean']>;
};

export type OverlappingPlansWarning = {
  __typename?: 'OverlappingPlansWarning';
  planId: Scalars['String'];
  planName?: Maybe<Scalars['String']>;
  planStartDate?: Maybe<Scalars['String']>;
  planEndDate?: Maybe<Scalars['String']>;
  planStatus?: Maybe<PlanStatus>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  count: Scalars['Int'];
  nextPage?: Maybe<Scalars['String']>;
  previousPage?: Maybe<Scalars['String']>;
};

export type Permission = {
  __typename?: 'Permission';
  dataAccessLevelUris?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissionActionUri?: Maybe<Scalars['String']>;
};

export type PlanListSort = {
  field: PlanListSortableFields;
  direction: SortDirection;
};

export enum PlanListSortableFields {
  Period = 'PERIOD'
}

export enum PlanStatus {
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE'
}

export type PlanWarningCount = {
  __typename?: 'PlanWarningCount';
  totalWarningsCount?: Maybe<Scalars['BigNumber']>;
  userDirectCostWarningCount?: Maybe<Scalars['BigNumber']>;
};

export type PutBusinessCostsInput = {
  id: Scalars['String'];
  businessCosts: Array<BusinessCostInput>;
};

export type PutBusinessCostsResult = {
  __typename?: 'PutBusinessCostsResult';
  /** @deprecated use smartBudgetId */
  costOptimizationResultId: Scalars['String'];
  smartBudgetId: Scalars['String'];
};

export type PutCostFileInput = {
  binaryObjectUri: Scalars['String'];
  effectiveDate: Scalars['DateObject'];
};

export type PutCostOptimizationResultParametersInput = {
  costOptimizationResultId: Scalars['String'];
  margin?: Maybe<Scalars['BigNumber']>;
  utilization?: Maybe<Scalars['BigNumber']>;
};

export type PutCostOptimizationResultParametersResult = {
  __typename?: 'PutCostOptimizationResultParametersResult';
  costOptimizationResult: CostOptimizationResult;
};

export type PutPlanRoundingInput = {
  smartBudgetId: Scalars['String'];
  nearestX: Scalars['BigNumber'];
};

export type PutPlanRoundingResult = {
  __typename?: 'PutPlanRoundingResult';
  smartBudgetResult?: Maybe<SmartBudgetResult>;
};

export type PutRoleBreakdownParametersInput = {
  smartBudgetId: Scalars['String'];
  parameters: Array<RoleBreakdownParameters>;
};

export type PutRoleBreakdownParametersResult = {
  __typename?: 'PutRoleBreakdownParametersResult';
  smartBudgetResult: SmartBudgetResult;
};

export type PutSearchSettingsInput = {
  key: Scalars['String'];
  settings?: Maybe<SearchSettingsInput>;
};

export type PutSearchSettingsResult = {
  __typename?: 'PutSearchSettingsResult';
  searchSettings?: Maybe<SearchSettings>;
};

export type PutSmartBudgetRoundingInput = {
  smartBudgetId: Scalars['String'];
  nearestX: Scalars['BigNumber'];
};

export type PutSmartBudgetRoundingResult = {
  __typename?: 'PutSmartBudgetRoundingResult';
  smartBudgetResult?: Maybe<SmartBudgetResult>;
};

export type PutTableSettingInput = {
  tableKey: Scalars['String'];
  columns?: Maybe<Array<Scalars['String']>>;
  sort?: Maybe<SortSettingInput>;
};

export type PutTableSettingResult = {
  __typename?: 'PutTableSettingResult';
  tableSetting?: Maybe<TableSetting>;
};

export type PutUserBreakdownParametersInput = {
  smartBudgetId: Scalars['String'];
  parameters: Array<UserBreakdownParameters>;
};

export type PutUserBreakdownParametersResult = {
  __typename?: 'PutUserBreakdownParametersResult';
  smartBudgetResult: SmartBudgetResult;
  userBreakdowns: Array<Maybe<UserBreakdown>>;
};

export type Query = {
  __typename?: 'Query';
  me: Me;
  /** @deprecated use smartBudget */
  costOptimizationResult?: Maybe<CostOptimizationResult>;
  smartBudget?: Maybe<SmartBudgetResult>;
  /** @deprecated use smartBudgets */
  costOptimizationResults: CostOptimizationResultConnection;
  bulkSmartBudgetResult: Array<Maybe<SmartBudgetResult>>;
  smartBudgets: SmartBudgetResultConnection;
  /** @deprecated no longer used in new flow */
  activeCostOptimizationResult?: Maybe<CostOptimizationResult>;
  /** @deprecated no longer used in new flow */
  upcomingCostOptimizationResult?: Maybe<Array<Maybe<CostOptimizationResult>>>;
  smartBudgetOverviewSummary: SmartBudgetOverviewSummaryResult;
  smartBudgetOverviewGraph: SmartBudgetOverviewGraphResult;
  cpoBackgroundWork?: Maybe<WorkData>;
  tableSetting?: Maybe<TableSetting>;
  searchSettings?: Maybe<SearchSettings>;
};


export type QueryCostOptimizationResultArgs = {
  id: Scalars['String'];
  today?: Maybe<Scalars['DateObject']>;
};


export type QuerySmartBudgetArgs = {
  id: Scalars['String'];
  today?: Maybe<Scalars['DateObject']>;
};


export type QueryCostOptimizationResultsArgs = {
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  overlapsDateRange?: Maybe<DateRangeInput>;
  searchText?: Maybe<Scalars['String']>;
  sort?: Maybe<PlanListSort>;
};


export type QueryBulkSmartBudgetResultArgs = {
  ids: Array<Scalars['String']>;
};


export type QuerySmartBudgetsArgs = {
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  overlapsDateRange?: Maybe<DateRangeInput>;
  searchText?: Maybe<Scalars['String']>;
  sort?: Maybe<PlanListSort>;
};


export type QueryActiveCostOptimizationResultArgs = {
  date: Scalars['DateObject'];
};


export type QueryUpcomingCostOptimizationResultArgs = {
  date: Scalars['DateObject'];
};


export type QuerySmartBudgetOverviewSummaryArgs = {
  dateRange: DateRangeInput;
};


export type QuerySmartBudgetOverviewGraphArgs = {
  dateRange: DateRangeInput;
  granularity: GraphGranularity;
};


export type QueryCpoBackgroundWorkArgs = {
  workId: Scalars['String'];
};


export type QueryTableSettingArgs = {
  tableKey: Scalars['String'];
};


export type QuerySearchSettingsArgs = {
  key: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  id?: Maybe<Scalars['String']>;
  displayText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RoleBreakdown = {
  __typename?: 'RoleBreakdown';
  id: Scalars['String'];
  userCount?: Maybe<Scalars['BigNumber']>;
  totalHours?: Maybe<Scalars['BigNumber']>;
  margin?: Maybe<Scalars['BigNumber']>;
  utilization?: Maybe<Scalars['BigNumber']>;
  currentUtilization?: Maybe<Scalars['BigNumber']>;
  employeeCost?: Maybe<Money>;
  nonBillableOverhead?: Maybe<Money>;
  nonBillableOverheadTotal?: Maybe<Money>;
  businessOverhead?: Maybe<Money>;
  businessOverheadTotal?: Maybe<Money>;
  loadedCost?: Maybe<Money>;
  loadedCostTotal?: Maybe<Money>;
  billingCost?: Maybe<Money>;
  totalCost?: Maybe<Money>;
  revenue?: Maybe<Money>;
  profit?: Maybe<Money>;
  currentBillingRate?: Maybe<Money>;
  userBreakdown?: Maybe<UserBreakdownConnection>;
  role?: Maybe<Role>;
};


export type RoleBreakdownUserBreakdownArgs = {
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
};

export type RoleBreakdownParameters = {
  roleUri: Scalars['String'];
  utilization?: Maybe<Scalars['BigNumber']>;
  margin?: Maybe<Scalars['BigNumber']>;
};

export type RoleCommitInput = {
  id: Scalars['String'];
  margin?: Maybe<Scalars['BigNumber']>;
  utilization?: Maybe<Scalars['BigNumber']>;
};


export type SearchSettings = {
  __typename?: 'SearchSettings';
  id: Scalars['String'];
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  criterions?: Maybe<Scalars['SearchCriterionSettings']>;
};

export type SearchSettingsInput = {
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  criterions?: Maybe<Scalars['SearchCriterionSettings']>;
};

export type SmartBudgetOverviewGraphResult = {
  __typename?: 'SmartBudgetOverviewGraphResult';
  dateRange: DateRange;
  granularity: GraphGranularity;
  revenue?: Maybe<Array<GraphMetricSummaryMoney>>;
  smartBudgets: Array<SmartBudgetResult>;
};

export type SmartBudgetOverviewSummaryResult = {
  __typename?: 'SmartBudgetOverviewSummaryResult';
  dateRange: DateRange;
  utilization?: Maybe<MetricSummaryPercent>;
  margin?: Maybe<MetricSummaryPercent>;
  billingRate?: Maybe<MetricSummaryMoney>;
  revenue?: Maybe<MetricSummaryMoney>;
  totalCost?: Maybe<MetricSummaryMoney>;
  profit?: Maybe<MetricSummaryMoney>;
};

export type SmartBudgetResult = {
  __typename?: 'SmartBudgetResult';
  id: Scalars['String'];
  /** @deprecated use 'name' node */
  fileName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<PlanStatus>;
  lastModified?: Maybe<Scalars['String']>;
  lastSynchronizedAt?: Maybe<Scalars['String']>;
  lastCommittedAt?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  /** @deprecated imports are not downloadable */
  downloadUrl?: Maybe<Scalars['String']>;
  usersCount?: Maybe<Scalars['Int']>;
  billableUserCount?: Maybe<Scalars['Int']>;
  nonBillableUserCount?: Maybe<Scalars['Int']>;
  utilization?: Maybe<Scalars['BigNumber']>;
  margin?: Maybe<Scalars['BigNumber']>;
  nearestX?: Maybe<Scalars['BigNumber']>;
  totalCurrentUtilization?: Maybe<Scalars['BigNumber']>;
  roleCostBreakdown?: Maybe<Array<RoleBreakdown>>;
  nonBillableRoles?: Maybe<Array<NonBillableRoleBreakdown>>;
  operationOverhead?: Maybe<Array<OperationOverhead>>;
  currencyTotals?: Maybe<Array<Maybe<Money>>>;
  billableCost?: Maybe<Money>;
  nonBillableCost?: Maybe<Money>;
  overheadCost?: Maybe<Money>;
  standardRate?: Maybe<Money>;
  laborRate?: Maybe<Money>;
  businessOverheadRate?: Maybe<Money>;
  nonBillableOverheadRate?: Maybe<Money>;
  billingRate?: Maybe<Money>;
  totalCost?: Maybe<Money>;
  totalNonBillableCost?: Maybe<Money>;
  totalOverheadCost?: Maybe<Money>;
  totalLoadedCost?: Maybe<Money>;
  totalRevenue?: Maybe<Money>;
  totalProfit?: Maybe<Money>;
  totalBillableHours?: Maybe<Scalars['BigNumber']>;
  totalNonBillableHours?: Maybe<Scalars['BigNumber']>;
  totalHours?: Maybe<Scalars['BigNumber']>;
  totalResourceCost?: Maybe<Money>;
  warningsCount?: Maybe<PlanWarningCount>;
  userDirectCostWarnings?: Maybe<Array<Maybe<User>>>;
  overlappingPlansWarnings?: Maybe<Array<Maybe<OverlappingPlansWarning>>>;
  uploadedBy?: Maybe<User>;
};


export type SmartBudgetResultRoleCostBreakdownArgs = {
  roleUri?: Maybe<Scalars['String']>;
};

export type SmartBudgetResultConnection = {
  __typename?: 'SmartBudgetResultConnection';
  edges: Array<SmartBudgetResultEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SmartBudgetResultEdge = {
  __typename?: 'SmartBudgetResultEdge';
  cursor: Scalars['String'];
  node: SmartBudgetResult;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortSetting = {
  __typename?: 'SortSetting';
  field: Scalars['String'];
  direction: SortDirection;
  customFieldUri?: Maybe<Scalars['String']>;
};

export type SortSettingInput = {
  field: Scalars['String'];
  direction: SortDirection;
  customFieldUri?: Maybe<Scalars['String']>;
};

export type TableSetting = {
  __typename?: 'TableSetting';
  id: Scalars['String'];
  columns: Array<Scalars['String']>;
  sort?: Maybe<SortSetting>;
};

export type UpdatePlanCostsInput = {
  smartBudgetId: Scalars['String'];
};

export type UpdatePlanCostsResult = {
  __typename?: 'UpdatePlanCostsResult';
  backgroundWorkId: Scalars['String'];
};

export type UpdatePlanNameInput = {
  smartBudgetId: Scalars['String'];
  name: Scalars['String'];
};

export type UpdatePlanNameResult = {
  __typename?: 'UpdatePlanNameResult';
  smartBudgetResult: SmartBudgetResult;
};

export type UpdateSmartBudgetCostsInput = {
  smartBudgetId: Scalars['String'];
};

export type UpdateSmartBudgetCostsResult = {
  __typename?: 'UpdateSmartBudgetCostsResult';
  backgroundWorkId: Scalars['String'];
};

export type UpdateSmartBudgetNameInput = {
  smartBudgetId: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateSmartBudgetNameResult = {
  __typename?: 'UpdateSmartBudgetNameResult';
  smartBudgetResult: SmartBudgetResult;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['String']>;
  displayText?: Maybe<Scalars['String']>;
  loginName?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type UserBreakdown = {
  __typename?: 'UserBreakdown';
  id: Scalars['String'];
  hours?: Maybe<Scalars['BigNumber']>;
  totalCost?: Maybe<Money>;
  billable?: Maybe<Scalars['Boolean']>;
  nonBillableOverhead?: Maybe<Money>;
  businessOverhead?: Maybe<Money>;
  utilization?: Maybe<Scalars['BigNumber']>;
  margin?: Maybe<Scalars['BigNumber']>;
  standardCostRate?: Maybe<Money>;
  loadedCostRate?: Maybe<Money>;
  billingRate?: Maybe<Money>;
  revenue?: Maybe<Money>;
  user?: Maybe<User>;
  role?: Maybe<Role>;
};

export type UserBreakdownConnection = {
  __typename?: 'UserBreakdownConnection';
  edges: Array<UserBreakdownEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserBreakdownEdge = {
  __typename?: 'UserBreakdownEdge';
  cursor: Scalars['String'];
  node: UserBreakdown;
};

export type UserBreakdownParameters = {
  userUri: Scalars['String'];
  roleUri: Scalars['String'];
  utilization?: Maybe<Scalars['BigNumber']>;
  margin?: Maybe<Scalars['BigNumber']>;
};

export type ValidateCostFileInput = {
  binaryObjectUri: Scalars['String'];
};

export type ValidateCostFileResult = {
  __typename?: 'ValidateCostFileResult';
  validationErrors?: Maybe<Array<CsvValidationError>>;
};

export type WorkData = {
  __typename?: 'WorkData';
  id: Scalars['String'];
  workStatus?: Maybe<WorkStatus>;
  message?: Maybe<Scalars['String']>;
  workResult?: Maybe<Scalars['WorkResult']>;
  progressPercentage?: Maybe<Scalars['BigNumber']>;
};


export enum WorkStatus {
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Me' }
    & Pick<Me, 'id'>
    & { links?: Maybe<(
      { __typename?: 'MeLinks' }
      & Pick<MeLinks, 'serviceUrl' | 'psaUrl' | 'uiRootUrl'>
    )>, locale?: Maybe<(
      { __typename?: 'Locale' }
      & { language?: Maybe<(
        { __typename?: 'Language' }
        & Pick<Language, 'cultureCode' | 'displayText' | 'uri' | 'languageCode'>
      )>, dateTimeSettings?: Maybe<(
        { __typename?: 'DateTimeSetting' }
        & { longDateFormat?: Maybe<(
          { __typename?: 'DateTimeFormat' }
          & Pick<DateTimeFormat, 'dotNetPattern'>
        )>, shortDateFormat?: Maybe<(
          { __typename?: 'DateTimeFormat' }
          & Pick<DateTimeFormat, 'dotNetPattern'>
        )>, shortTimeFormat?: Maybe<(
          { __typename?: 'DateTimeFormat' }
          & Pick<DateTimeFormat, 'dotNetPattern'>
        )> }
      )>, numberSettings?: Maybe<(
        { __typename?: 'NumberSettings' }
        & { number?: Maybe<(
          { __typename?: 'NumberFormat' }
          & Pick<NumberFormat, 'decimalDigits' | 'decimalSeparator' | 'groupSeparator' | 'groupSizes'>
        )>, currency?: Maybe<(
          { __typename?: 'NumberFormat' }
          & Pick<NumberFormat, 'decimalDigits' | 'decimalSeparator' | 'groupSeparator' | 'groupSizes'>
        )> }
      )>, customTerms?: Maybe<Array<Maybe<(
        { __typename?: 'CustomTerm' }
        & Pick<CustomTerm, 'originalTerm' | 'customTerm'>
      )>>> }
    )>, baseCurrency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'symbol'>
    )>, permissions?: Maybe<Array<Maybe<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'permissionActionUri' | 'dataAccessLevelUris'>
    )>>>, featureFlags?: Maybe<(
      { __typename?: 'FeatureFlags' }
      & Pick<FeatureFlags, 'cpoTargetBillability' | 'cpoNewUx' | 'cpoDashboard'>
    )> }
  ) }
);

export type UpcomingCostOptimizationResultQueryVariables = Exact<{
  date: Scalars['DateObject'];
}>;


export type UpcomingCostOptimizationResultQuery = (
  { __typename?: 'Query' }
  & { upcomingCostOptimizationResult?: Maybe<Array<Maybe<(
    { __typename?: 'CostOptimizationResult' }
    & PartialCostOptimizationResultFragmentFragment
  )>>> }
);

export type ActiveCostOptimizationResultQueryVariables = Exact<{
  date: Scalars['DateObject'];
}>;


export type ActiveCostOptimizationResultQuery = (
  { __typename?: 'Query' }
  & { activeCostOptimizationResult?: Maybe<(
    { __typename?: 'CostOptimizationResult' }
    & FullCostOptimizationResultFragmentFragment
  )> }
);

export type CloneCostOptimizationResultMutationVariables = Exact<{
  input: CloneCostOptimizationResultInput;
}>;


export type CloneCostOptimizationResultMutation = (
  { __typename?: 'Mutation' }
  & { cloneCostOptimizationResult: (
    { __typename?: 'CostOptimizationResult' }
    & FullCostOptimizationResultFragmentFragment
  ) }
);

export type CommitCostOptimizationResultMutationVariables = Exact<{
  input: CommitCostOptimizationResultInput;
}>;


export type CommitCostOptimizationResultMutation = (
  { __typename?: 'Mutation' }
  & { commitCostOptimizationResult: (
    { __typename?: 'CommitCostOptimizationResult' }
    & Pick<CommitCostOptimizationResult, 'backgroundWorkId'>
  ) }
);

export type CommitSmartBudgetMutationVariables = Exact<{
  input: CommitSmartBudgetInput;
}>;


export type CommitSmartBudgetMutation = (
  { __typename?: 'Mutation' }
  & { commitSmartBudget: (
    { __typename?: 'CommitSmartBudgetResult' }
    & Pick<CommitSmartBudgetResult, 'backgroundWorkId'>
  ) }
);

export type CpoBackgroundWorkQueryVariables = Exact<{
  workId: Scalars['String'];
}>;


export type CpoBackgroundWorkQuery = (
  { __typename?: 'Query' }
  & { cpoBackgroundWork?: Maybe<(
    { __typename?: 'WorkData' }
    & Pick<WorkData, 'id' | 'workStatus' | 'message' | 'workResult' | 'progressPercentage'>
  )> }
);

export type SaveRateCardMutationVariables = Exact<{
  smartBudgetId: Scalars['String'];
  rateCardParameters: Array<RoleBreakdownParameters>;
}>;


export type SaveRateCardMutation = (
  { __typename?: 'Mutation' }
  & { putRoleBreakdownParameters: (
    { __typename?: 'PutRoleBreakdownParametersResult' }
    & { smartBudgetResult: (
      { __typename?: 'SmartBudgetResult' }
      & Pick<SmartBudgetResult, 'id' | 'lastModified' | 'utilization' | 'margin'>
      & { roleCostBreakdown?: Maybe<Array<(
        { __typename?: 'RoleBreakdown' }
        & FullRoleBreakdownFragmentFragment
      )>> }
      & PartialSmartBudgetResultFragmentFragment
    ) }
  ) }
);

export type CostOptimizationResultCurrencyTotalsQueryVariables = Exact<{
  id: Scalars['String'];
  today: Scalars['DateObject'];
}>;


export type CostOptimizationResultCurrencyTotalsQuery = (
  { __typename?: 'Query' }
  & { costOptimizationResult?: Maybe<(
    { __typename?: 'CostOptimizationResult' }
    & Pick<CostOptimizationResult, 'id'>
    & { billableCost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragmentFragment
    )>, nonBillableCost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragmentFragment
    )>, overheadCost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragmentFragment
    )>, totalCost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragmentFragment
    )>, currencyTotals?: Maybe<Array<Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'uri' | 'amount'>
      & { currency?: Maybe<(
        { __typename?: 'Currency' }
        & Pick<Currency, 'symbol'>
      )>, toBaseCurrency?: Maybe<(
        { __typename?: 'ExchangeRate' }
        & Pick<ExchangeRate, 'exchangeRate'>
        & { value?: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount'>
          & { currency?: Maybe<(
            { __typename?: 'Currency' }
            & Pick<Currency, 'symbol'>
          )> }
        )> }
      )> }
    )>>> }
  )> }
);

export type CostOptimizationResultRoleBreakdownsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CostOptimizationResultRoleBreakdownsQuery = (
  { __typename?: 'Query' }
  & { costOptimizationResult?: Maybe<(
    { __typename?: 'CostOptimizationResult' }
    & Pick<CostOptimizationResult, 'id' | 'usersCount' | 'totalHours' | 'totalNonBillableHours' | 'totalBillableHours' | 'billableUserCount' | 'nonBillableUserCount'>
    & { billableCost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragmentFragment
    )>, nonBillableCost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragmentFragment
    )>, totalCost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragmentFragment
    )>, nonBillableRoles?: Maybe<Array<(
      { __typename?: 'NonBillableRoleBreakdown' }
      & Pick<NonBillableRoleBreakdown, 'id' | 'userCount' | 'hours' | 'cost'>
      & { role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'name' | 'id' | 'displayText'>
      )> }
    )>>, roleCostBreakdown?: Maybe<Array<(
      { __typename?: 'RoleBreakdown' }
      & Pick<RoleBreakdown, 'id' | 'userCount' | 'totalHours'>
      & { totalCost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      )>, billingCost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'displayText' | 'name'>
      )> }
    )>> }
  )> }
);

export type DeleteCostOptimizationResultMutationVariables = Exact<{
  input: DeleteCostOptimizationResultInput;
}>;


export type DeleteCostOptimizationResultMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostOptimizationResult'>
);

export type DiscardSmartBudgetMutationVariables = Exact<{
  input: DiscardSmartBudgetInput;
}>;


export type DiscardSmartBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'discardSmartBudget'>
);

export type FullCostOptimizationResultFragmentFragment = (
  { __typename?: 'CostOptimizationResult' }
  & Pick<CostOptimizationResult, 'id' | 'fileName' | 'name' | 'lastModified' | 'lastSynchronizedAt' | 'lastCommittedAt' | 'status' | 'effectiveDate' | 'endDate' | 'utilization' | 'totalCurrentUtilization' | 'margin' | 'totalBillableHours' | 'downloadUrl' | 'nonBillableUserCount' | 'totalNonBillableHours' | 'nearestX'>
  & { billableCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, standardRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, laborRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, businessOverheadRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, nonBillableOverheadRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, billingRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalRevenue?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalProfit?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalLoadedCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalOverheadCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalNonBillableCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, uploadedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayText'>
  )>, roleCostBreakdown?: Maybe<Array<(
    { __typename?: 'RoleBreakdown' }
    & FullRoleBreakdownFragmentFragment
  )>>, nonBillableRoles?: Maybe<Array<(
    { __typename?: 'NonBillableRoleBreakdown' }
    & Pick<NonBillableRoleBreakdown, 'id' | 'userCount' | 'hours' | 'cost'>
    & { role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'displayText'>
    )> }
  )>>, nonBillableCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, operationOverhead?: Maybe<Array<(
    { __typename?: 'OperationOverhead' }
    & Pick<OperationOverhead, 'id' | 'name' | 'isNew'>
    & { cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragmentFragment
    )> }
  )>>, totalResourceCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, currency?: Maybe<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'symbol' | 'displayText'>
  )>, warningsCount?: Maybe<(
    { __typename?: 'PlanWarningCount' }
    & Pick<PlanWarningCount, 'totalWarningsCount' | 'userDirectCostWarningCount'>
  )>, overlappingPlansWarnings?: Maybe<Array<Maybe<(
    { __typename?: 'OverlappingPlansWarning' }
    & Pick<OverlappingPlansWarning, 'planId' | 'planName' | 'planStartDate' | 'planEndDate' | 'planStatus'>
  )>>> }
);

export type FullRoleBreakdownFragmentFragment = (
  { __typename?: 'RoleBreakdown' }
  & Pick<RoleBreakdown, 'id' | 'utilization' | 'currentUtilization' | 'margin' | 'userCount' | 'totalHours'>
  & { totalCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, employeeCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, nonBillableOverhead?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, businessOverhead?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, loadedCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, billingCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, revenue?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, profit?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, loadedCostTotal?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, businessOverheadTotal?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, nonBillableOverheadTotal?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'displayText' | 'name'>
  )>, currentBillingRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )> }
);

export type MoneyFragmentFragment = (
  { __typename?: 'Money' }
  & Pick<Money, 'amount'>
  & { currency?: Maybe<(
    { __typename?: 'Currency' }
    & CurrencyFragmentFragment
  )> }
);

export type CurrencyFragmentFragment = (
  { __typename?: 'Currency' }
  & Pick<Currency, 'id' | 'symbol'>
);

export type FullSmartBudgetResultFragmentFragment = (
  { __typename?: 'SmartBudgetResult' }
  & Pick<SmartBudgetResult, 'id' | 'fileName' | 'name' | 'lastModified' | 'lastSynchronizedAt' | 'lastCommittedAt' | 'status' | 'effectiveDate' | 'endDate' | 'utilization' | 'totalCurrentUtilization' | 'margin' | 'totalBillableHours' | 'downloadUrl' | 'nonBillableUserCount' | 'totalNonBillableHours' | 'nearestX'>
  & { billableCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, standardRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, laborRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, businessOverheadRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, nonBillableOverheadRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, billingRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalRevenue?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalProfit?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalLoadedCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalOverheadCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalNonBillableCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, uploadedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayText'>
  )>, roleCostBreakdown?: Maybe<Array<(
    { __typename?: 'RoleBreakdown' }
    & FullRoleBreakdownFragmentFragment
  )>>, nonBillableRoles?: Maybe<Array<(
    { __typename?: 'NonBillableRoleBreakdown' }
    & Pick<NonBillableRoleBreakdown, 'id' | 'userCount' | 'hours' | 'cost'>
    & { role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'displayText'>
    )> }
  )>>, nonBillableCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, operationOverhead?: Maybe<Array<(
    { __typename?: 'OperationOverhead' }
    & Pick<OperationOverhead, 'id' | 'name' | 'isNew'>
    & { cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragmentFragment
    )> }
  )>>, totalResourceCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, currency?: Maybe<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'symbol' | 'displayText'>
  )>, warningsCount?: Maybe<(
    { __typename?: 'PlanWarningCount' }
    & Pick<PlanWarningCount, 'totalWarningsCount' | 'userDirectCostWarningCount'>
  )>, overlappingPlansWarnings?: Maybe<Array<Maybe<(
    { __typename?: 'OverlappingPlansWarning' }
    & Pick<OverlappingPlansWarning, 'planId' | 'planName' | 'planStartDate' | 'planEndDate' | 'planStatus'>
  )>>> }
);

export type TableSettingsQueryVariables = Exact<{
  tableKey: Scalars['String'];
}>;


export type TableSettingsQuery = (
  { __typename?: 'Query' }
  & { tableSetting?: Maybe<(
    { __typename?: 'TableSetting' }
    & Pick<TableSetting, 'id' | 'columns'>
    & { sort?: Maybe<(
      { __typename?: 'SortSetting' }
      & Pick<SortSetting, 'field' | 'direction' | 'customFieldUri'>
    )> }
  )> }
);

export type PartialCostOptimizationResultFragmentFragment = (
  { __typename?: 'CostOptimizationResult' }
  & Pick<CostOptimizationResult, 'id' | 'fileName' | 'lastModified' | 'status' | 'effectiveDate' | 'endDate' | 'utilization' | 'margin' | 'usersCount' | 'totalBillableHours' | 'downloadUrl'>
  & { billableCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, standardRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, laborRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, businessOverheadRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, nonBillableOverheadRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, billingRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalRevenue?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, uploadedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayText'>
  )> }
);

export type PartialSmartBudgetResultFragmentFragment = (
  { __typename?: 'SmartBudgetResult' }
  & Pick<SmartBudgetResult, 'id' | 'fileName' | 'lastModified' | 'status' | 'effectiveDate' | 'endDate' | 'utilization' | 'margin' | 'usersCount' | 'totalBillableHours' | 'downloadUrl'>
  & { billableCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, standardRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, laborRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, businessOverheadRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, nonBillableOverheadRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, billingRate?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalRevenue?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, totalCost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, uploadedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayText'>
  )> }
);

export type PutSearchSettingsMutationVariables = Exact<{
  input: PutSearchSettingsInput;
}>;


export type PutSearchSettingsMutation = (
  { __typename?: 'Mutation' }
  & { putSearchSettings?: Maybe<(
    { __typename?: 'PutSearchSettingsResult' }
    & { searchSettings?: Maybe<(
      { __typename?: 'SearchSettings' }
      & Pick<SearchSettings, 'id' | 'criterions' | 'keywords'>
    )> }
  )> }
);

export type SearchSettingsQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type SearchSettingsQuery = (
  { __typename?: 'Query' }
  & { searchSettings?: Maybe<(
    { __typename?: 'SearchSettings' }
    & Pick<SearchSettings, 'id' | 'keywords' | 'criterions'>
  )> }
);

export type SmartBudgetQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SmartBudgetQuery = (
  { __typename?: 'Query' }
  & { smartBudget?: Maybe<(
    { __typename?: 'SmartBudgetResult' }
    & FullSmartBudgetResultFragmentFragment
  )> }
);

export type PutTableSettingMutationVariables = Exact<{
  input: PutTableSettingInput;
}>;


export type PutTableSettingMutation = (
  { __typename?: 'Mutation' }
  & { putTableSetting?: Maybe<(
    { __typename?: 'PutTableSettingResult' }
    & { tableSetting?: Maybe<(
      { __typename?: 'TableSetting' }
      & Pick<TableSetting, 'id' | 'columns'>
      & { sort?: Maybe<(
        { __typename?: 'SortSetting' }
        & Pick<SortSetting, 'field' | 'direction' | 'customFieldUri'>
      )> }
    )> }
  )> }
);

export type PutCostFileMutationVariables = Exact<{
  input: PutCostFileInput;
}>;


export type PutCostFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'putCostFile'>
);

export type ValidateCostFileMutationVariables = Exact<{
  input: ValidateCostFileInput;
}>;


export type ValidateCostFileMutation = (
  { __typename?: 'Mutation' }
  & { validateCostFile: (
    { __typename?: 'ValidateCostFileResult' }
    & { validationErrors?: Maybe<Array<(
      { __typename?: 'CSVValidationError' }
      & Pick<CsvValidationError, 'type' | 'line' | 'column' | 'message' | 'cellValue'>
    )>> }
  ) }
);

export type SmartBudgetsQueryVariables = Exact<{
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
}>;


export type SmartBudgetsQuery = (
  { __typename?: 'Query' }
  & { smartBudgets: (
    { __typename?: 'SmartBudgetResultConnection' }
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'count' | 'hasNextPage'>
    )>, edges: Array<(
      { __typename?: 'SmartBudgetResultEdge' }
      & Pick<SmartBudgetResultEdge, 'cursor'>
      & { node: (
        { __typename?: 'SmartBudgetResult' }
        & PartialSmartBudgetResultFragmentFragment
      ) }
    )> }
  ) }
);

export type CreateNewSmartBudgetMutationVariables = Exact<{
  name: Scalars['String'];
  startDate: Scalars['DateObject'];
  endDate: Scalars['DateObject'];
}>;


export type CreateNewSmartBudgetMutation = (
  { __typename?: 'Mutation' }
  & { createSmartBudget: (
    { __typename?: 'CreateSmartBudgetResult' }
    & Pick<CreateSmartBudgetResult, 'backgroundWorkId'>
  ) }
);

export type SmartBudgetOverviewSummaryQueryVariables = Exact<{
  startDate: Scalars['DateObject'];
  endDate: Scalars['DateObject'];
}>;


export type SmartBudgetOverviewSummaryQuery = (
  { __typename?: 'Query' }
  & { smartBudgetOverviewSummary: (
    { __typename?: 'SmartBudgetOverviewSummaryResult' }
    & { dateRange: (
      { __typename?: 'DateRange' }
      & Pick<DateRange, 'startDate' | 'endDate'>
    ), utilization?: Maybe<(
      { __typename?: 'MetricSummaryPercent' }
      & Pick<MetricSummaryPercent, 'actual' | 'budgeted' | 'delta'>
    )>, margin?: Maybe<(
      { __typename?: 'MetricSummaryPercent' }
      & Pick<MetricSummaryPercent, 'actual' | 'budgeted' | 'delta'>
    )>, billingRate?: Maybe<(
      { __typename?: 'MetricSummaryMoney' }
      & Pick<MetricSummaryMoney, 'deltaPercent'>
      & { actual?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )>, budgeted?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )>, deltaMoney?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )> }
    )>, revenue?: Maybe<(
      { __typename?: 'MetricSummaryMoney' }
      & Pick<MetricSummaryMoney, 'deltaPercent'>
      & { actual?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )>, budgeted?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )>, deltaMoney?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )> }
    )>, totalCost?: Maybe<(
      { __typename?: 'MetricSummaryMoney' }
      & Pick<MetricSummaryMoney, 'deltaPercent'>
      & { actual?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )>, budgeted?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )>, deltaMoney?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )> }
    )>, profit?: Maybe<(
      { __typename?: 'MetricSummaryMoney' }
      & Pick<MetricSummaryMoney, 'deltaPercent'>
      & { actual?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )>, budgeted?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )>, deltaMoney?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )> }
      )> }
    )> }
  ) }
);

export type SmartBudgetOverviewGraphQueryVariables = Exact<{
  dateRange: DateRangeInput;
  granularity: GraphGranularity;
}>;


export type SmartBudgetOverviewGraphQuery = (
  { __typename?: 'Query' }
  & { smartBudgetOverviewGraph: (
    { __typename?: 'SmartBudgetOverviewGraphResult' }
    & Pick<SmartBudgetOverviewGraphResult, 'granularity'>
    & { dateRange: (
      { __typename?: 'DateRange' }
      & Pick<DateRange, 'startDate' | 'endDate'>
    ), revenue?: Maybe<Array<(
      { __typename?: 'GraphMetricSummaryMoney' }
      & { dateRange: (
        { __typename?: 'DateRange' }
        & Pick<DateRange, 'startDate' | 'endDate'>
      ), budgeted?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )>, toBaseCurrency?: Maybe<(
          { __typename?: 'ExchangeRate' }
          & Pick<ExchangeRate, 'exchangeRate'>
          & { value?: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount'>
            & { currency?: Maybe<(
              { __typename?: 'Currency' }
              & Pick<Currency, 'symbol'>
            )> }
          )> }
        )> }
      )>, estimated?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )>, toBaseCurrency?: Maybe<(
          { __typename?: 'ExchangeRate' }
          & Pick<ExchangeRate, 'exchangeRate'>
          & { value?: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount'>
            & { currency?: Maybe<(
              { __typename?: 'Currency' }
              & Pick<Currency, 'symbol'>
            )> }
          )> }
        )> }
      )>, actual?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        )>, toBaseCurrency?: Maybe<(
          { __typename?: 'ExchangeRate' }
          & Pick<ExchangeRate, 'exchangeRate'>
          & { value?: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount'>
            & { currency?: Maybe<(
              { __typename?: 'Currency' }
              & Pick<Currency, 'symbol'>
            )> }
          )> }
        )> }
      )> }
    )>>, smartBudgets: Array<(
      { __typename?: 'SmartBudgetResult' }
      & Pick<SmartBudgetResult, 'id' | 'name' | 'effectiveDate' | 'endDate'>
    )> }
  ) }
);

export type CreateSmartBudgetMutationVariables = Exact<{
  input: CreateSmartBudgetInput;
}>;


export type CreateSmartBudgetMutation = (
  { __typename?: 'Mutation' }
  & { createSmartBudget: (
    { __typename?: 'CreateSmartBudgetResult' }
    & Pick<CreateSmartBudgetResult, 'backgroundWorkId'>
  ) }
);

export type GetOverlappingPlansQueryVariables = Exact<{
  startDate: Scalars['DateObject'];
  endDate: Scalars['DateObject'];
}>;


export type GetOverlappingPlansQuery = (
  { __typename?: 'Query' }
  & { smartBudgets: (
    { __typename?: 'SmartBudgetResultConnection' }
    & { edges: Array<(
      { __typename?: 'SmartBudgetResultEdge' }
      & { node: (
        { __typename?: 'SmartBudgetResult' }
        & Pick<SmartBudgetResult, 'id' | 'name' | 'effectiveDate' | 'endDate'>
      ) }
    )> }
  ) }
);

export type SearchPlansQueryVariables = Exact<{
  text?: Maybe<Scalars['String']>;
}>;


export type SearchPlansQuery = (
  { __typename?: 'Query' }
  & { smartBudgets: (
    { __typename?: 'SmartBudgetResultConnection' }
    & { edges: Array<(
      { __typename?: 'SmartBudgetResultEdge' }
      & { node: (
        { __typename?: 'SmartBudgetResult' }
        & Pick<SmartBudgetResult, 'id' | 'name' | 'margin' | 'utilization'>
      ) }
    )> }
  ) }
);

export type PlanWarningQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PlanWarningQuery = (
  { __typename?: 'Query' }
  & { costOptimizationResult?: Maybe<(
    { __typename?: 'CostOptimizationResult' }
    & Pick<CostOptimizationResult, 'id' | 'lastSynchronizedAt'>
    & { operationOverhead?: Maybe<Array<(
      { __typename?: 'OperationOverhead' }
      & Pick<OperationOverhead, 'id'>
    )>>, warningsCount?: Maybe<(
      { __typename?: 'PlanWarningCount' }
      & Pick<PlanWarningCount, 'totalWarningsCount' | 'userDirectCostWarningCount'>
    )>, userDirectCostWarnings?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayText' | 'loginName' | 'slug'>
    )>>>, overlappingPlansWarnings?: Maybe<Array<Maybe<(
      { __typename?: 'OverlappingPlansWarning' }
      & Pick<OverlappingPlansWarning, 'planId' | 'planName' | 'planStartDate' | 'planEndDate' | 'planStatus'>
    )>>> }
  )> }
);

export type PlansQueryVariables = Exact<{
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  sort?: Maybe<PlanListSort>;
  searchText?: Maybe<Scalars['String']>;
}>;


export type PlansQuery = (
  { __typename?: 'Query' }
  & { smartBudgets: (
    { __typename?: 'SmartBudgetResultConnection' }
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'count' | 'hasNextPage'>
    )>, edges: Array<(
      { __typename?: 'SmartBudgetResultEdge' }
      & Pick<SmartBudgetResultEdge, 'cursor'>
      & { node: (
        { __typename?: 'SmartBudgetResult' }
        & Pick<SmartBudgetResult, 'name'>
        & PartialSmartBudgetResultFragmentFragment
      ) }
    )> }
  ) }
);

export type DeleteSmartBudgetMutationVariables = Exact<{
  input: DeleteSmartBudgetInput;
}>;


export type DeleteSmartBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSmartBudget'>
);

export type PutBusinessCostsMutationVariables = Exact<{
  input: PutBusinessCostsInput;
}>;


export type PutBusinessCostsMutation = (
  { __typename?: 'Mutation' }
  & { putBusinessCosts: (
    { __typename?: 'PutBusinessCostsResult' }
    & Pick<PutBusinessCostsResult, 'smartBudgetId'>
  ) }
);

export type PutSmartBudgetRoundingMutationVariables = Exact<{
  input: PutSmartBudgetRoundingInput;
}>;


export type PutSmartBudgetRoundingMutation = (
  { __typename?: 'Mutation' }
  & { putSmartBudgetRounding: (
    { __typename?: 'PutSmartBudgetRoundingResult' }
    & { smartBudgetResult?: Maybe<(
      { __typename?: 'SmartBudgetResult' }
      & Pick<SmartBudgetResult, 'id' | 'nearestX'>
    )> }
  ) }
);

export type UpdateCostsMutationVariables = Exact<{
  smartBudgetId: Scalars['String'];
}>;


export type UpdateCostsMutation = (
  { __typename?: 'Mutation' }
  & { updateSmartBudgetCosts: (
    { __typename?: 'UpdateSmartBudgetCostsResult' }
    & Pick<UpdateSmartBudgetCostsResult, 'backgroundWorkId'>
  ) }
);

export type UpdateSmartBudgetNameMutationVariables = Exact<{
  input: UpdateSmartBudgetNameInput;
}>;


export type UpdateSmartBudgetNameMutation = (
  { __typename?: 'Mutation' }
  & { updateSmartBudgetName: (
    { __typename?: 'UpdateSmartBudgetNameResult' }
    & { smartBudgetResult: (
      { __typename?: 'SmartBudgetResult' }
      & Pick<SmartBudgetResult, 'id' | 'name'>
    ) }
  ) }
);

export type PutUserBreakdownParametersMutationVariables = Exact<{
  input: PutUserBreakdownParametersInput;
}>;


export type PutUserBreakdownParametersMutation = (
  { __typename?: 'Mutation' }
  & { putUserBreakdownParameters: (
    { __typename?: 'PutUserBreakdownParametersResult' }
    & { smartBudgetResult: (
      { __typename?: 'SmartBudgetResult' }
      & Pick<SmartBudgetResult, 'utilization' | 'margin'>
      & { roleCostBreakdown?: Maybe<Array<(
        { __typename?: 'RoleBreakdown' }
        & Pick<RoleBreakdown, 'id' | 'margin' | 'utilization'>
        & { role?: Maybe<(
          { __typename?: 'Role' }
          & Pick<Role, 'id'>
        )>, revenue?: Maybe<(
          { __typename?: 'Money' }
          & MoneyFragmentFragment
        )>, billingCost?: Maybe<(
          { __typename?: 'Money' }
          & MoneyFragmentFragment
        )> }
      )>> }
      & PartialSmartBudgetResultFragmentFragment
    ), userBreakdowns: Array<Maybe<(
      { __typename?: 'UserBreakdown' }
      & Pick<UserBreakdown, 'id' | 'hours' | 'billable' | 'utilization' | 'margin'>
      & { totalCost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      )>, billingRate?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      )>, revenue?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayText'>
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name' | 'displayText'>
      )> }
    )>> }
  ) }
);

export type RoleUserBreakdownsQueryVariables = Exact<{
  id: Scalars['String'];
  roleUri: Scalars['String'];
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
}>;


export type RoleUserBreakdownsQuery = (
  { __typename?: 'Query' }
  & { costOptimizationResult?: Maybe<(
    { __typename?: 'CostOptimizationResult' }
    & Pick<CostOptimizationResult, 'id'>
    & { roleCostBreakdown?: Maybe<Array<(
      { __typename?: 'RoleBreakdown' }
      & Pick<RoleBreakdown, 'id'>
      & { userBreakdown?: Maybe<(
        { __typename?: 'UserBreakdownConnection' }
        & { pageInfo?: Maybe<(
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'count' | 'nextPage' | 'previousPage'>
        )>, edges: Array<(
          { __typename?: 'UserBreakdownEdge' }
          & Pick<UserBreakdownEdge, 'cursor'>
          & { node: (
            { __typename?: 'UserBreakdown' }
            & Pick<UserBreakdown, 'id' | 'billable' | 'hours' | 'utilization' | 'margin'>
            & { nonBillableOverhead?: Maybe<(
              { __typename?: 'Money' }
              & MoneyFragmentFragment
            )>, businessOverhead?: Maybe<(
              { __typename?: 'Money' }
              & MoneyFragmentFragment
            )>, standardCostRate?: Maybe<(
              { __typename?: 'Money' }
              & MoneyFragmentFragment
            )>, loadedCostRate?: Maybe<(
              { __typename?: 'Money' }
              & MoneyFragmentFragment
            )>, billingRate?: Maybe<(
              { __typename?: 'Money' }
              & MoneyFragmentFragment
            )>, revenue?: Maybe<(
              { __typename?: 'Money' }
              & MoneyFragmentFragment
            )>, totalCost?: Maybe<(
              { __typename?: 'Money' }
              & MoneyFragmentFragment
            )>, user?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'displayText' | 'id'>
            )>, role?: Maybe<(
              { __typename?: 'Role' }
              & Pick<Role, 'displayText' | 'id'>
            )> }
          ) }
        )> }
      )> }
    )>> }
  )> }
);

export const CurrencyFragmentFragmentDoc = gql`
    fragment CurrencyFragment on Currency {
  id
  symbol
}
    `;
export const MoneyFragmentFragmentDoc = gql`
    fragment MoneyFragment on Money {
  amount
  currency {
    ...CurrencyFragment
  }
}
    ${CurrencyFragmentFragmentDoc}`;
export const FullRoleBreakdownFragmentFragmentDoc = gql`
    fragment FullRoleBreakdownFragment on RoleBreakdown {
  id
  utilization
  currentUtilization
  margin
  userCount
  totalCost {
    ...MoneyFragment
  }
  employeeCost {
    ...MoneyFragment
  }
  nonBillableOverhead {
    ...MoneyFragment
  }
  businessOverhead {
    ...MoneyFragment
  }
  loadedCost {
    ...MoneyFragment
  }
  billingCost {
    ...MoneyFragment
  }
  revenue {
    ...MoneyFragment
  }
  profit {
    ...MoneyFragment
  }
  loadedCostTotal {
    ...MoneyFragment
  }
  businessOverheadTotal {
    ...MoneyFragment
  }
  nonBillableOverheadTotal {
    ...MoneyFragment
  }
  role {
    id
    displayText
    name
  }
  totalHours
  currentBillingRate {
    ...MoneyFragment
  }
}
    ${MoneyFragmentFragmentDoc}`;
export const FullCostOptimizationResultFragmentFragmentDoc = gql`
    fragment FullCostOptimizationResultFragment on CostOptimizationResult {
  id
  fileName
  name
  lastModified
  lastSynchronizedAt
  lastCommittedAt
  status
  effectiveDate
  endDate
  utilization
  totalCurrentUtilization
  margin
  billableCost {
    ...MoneyFragment
  }
  standardRate {
    ...MoneyFragment
  }
  laborRate {
    ...MoneyFragment
  }
  businessOverheadRate {
    ...MoneyFragment
  }
  nonBillableOverheadRate {
    ...MoneyFragment
  }
  billingRate {
    ...MoneyFragment
  }
  totalRevenue {
    ...MoneyFragment
  }
  totalProfit {
    ...MoneyFragment
  }
  totalLoadedCost {
    ...MoneyFragment
  }
  totalOverheadCost {
    ...MoneyFragment
  }
  totalNonBillableCost {
    ...MoneyFragment
  }
  totalBillableHours
  downloadUrl
  totalCost {
    ...MoneyFragment
  }
  uploadedBy {
    id
    displayText
  }
  roleCostBreakdown {
    ...FullRoleBreakdownFragment
  }
  nonBillableRoles {
    id
    userCount
    hours
    cost
    role {
      displayText
    }
  }
  nonBillableCost {
    ...MoneyFragment
  }
  operationOverhead {
    id
    name
    cost {
      ...MoneyFragment
    }
    isNew
  }
  totalResourceCost {
    ...MoneyFragment
  }
  nonBillableUserCount
  totalNonBillableHours
  currency {
    id
    symbol
    displayText
  }
  nearestX
  warningsCount {
    totalWarningsCount
    userDirectCostWarningCount
  }
  overlappingPlansWarnings {
    planId
    planName
    planStartDate
    planEndDate
    planStatus
  }
}
    ${MoneyFragmentFragmentDoc}
${FullRoleBreakdownFragmentFragmentDoc}`;
export const FullSmartBudgetResultFragmentFragmentDoc = gql`
    fragment FullSmartBudgetResultFragment on SmartBudgetResult {
  id
  fileName
  name
  lastModified
  lastSynchronizedAt
  lastCommittedAt
  status
  effectiveDate
  endDate
  utilization
  totalCurrentUtilization
  margin
  billableCost {
    ...MoneyFragment
  }
  standardRate {
    ...MoneyFragment
  }
  laborRate {
    ...MoneyFragment
  }
  businessOverheadRate {
    ...MoneyFragment
  }
  nonBillableOverheadRate {
    ...MoneyFragment
  }
  billingRate {
    ...MoneyFragment
  }
  totalRevenue {
    ...MoneyFragment
  }
  totalProfit {
    ...MoneyFragment
  }
  totalLoadedCost {
    ...MoneyFragment
  }
  totalOverheadCost {
    ...MoneyFragment
  }
  totalNonBillableCost {
    ...MoneyFragment
  }
  totalBillableHours
  downloadUrl
  totalCost {
    ...MoneyFragment
  }
  uploadedBy {
    id
    displayText
  }
  roleCostBreakdown {
    ...FullRoleBreakdownFragment
  }
  nonBillableRoles {
    id
    userCount
    hours
    cost
    role {
      displayText
    }
  }
  nonBillableCost {
    ...MoneyFragment
  }
  operationOverhead {
    id
    name
    cost {
      ...MoneyFragment
    }
    isNew
  }
  totalResourceCost {
    ...MoneyFragment
  }
  nonBillableUserCount
  totalNonBillableHours
  currency {
    id
    symbol
    displayText
  }
  nearestX
  warningsCount {
    totalWarningsCount
    userDirectCostWarningCount
  }
  overlappingPlansWarnings {
    planId
    planName
    planStartDate
    planEndDate
    planStatus
  }
}
    ${MoneyFragmentFragmentDoc}
${FullRoleBreakdownFragmentFragmentDoc}`;
export const PartialCostOptimizationResultFragmentFragmentDoc = gql`
    fragment PartialCostOptimizationResultFragment on CostOptimizationResult {
  id
  fileName
  lastModified
  status
  effectiveDate
  endDate
  utilization
  margin
  usersCount
  billableCost {
    ...MoneyFragment
  }
  standardRate {
    ...MoneyFragment
  }
  laborRate {
    ...MoneyFragment
  }
  businessOverheadRate {
    ...MoneyFragment
  }
  nonBillableOverheadRate {
    ...MoneyFragment
  }
  billingRate {
    ...MoneyFragment
  }
  totalRevenue {
    ...MoneyFragment
  }
  totalBillableHours
  totalCost {
    ...MoneyFragment
  }
  downloadUrl
  uploadedBy {
    id
    displayText
  }
}
    ${MoneyFragmentFragmentDoc}`;
export const PartialSmartBudgetResultFragmentFragmentDoc = gql`
    fragment PartialSmartBudgetResultFragment on SmartBudgetResult {
  id
  fileName
  lastModified
  status
  effectiveDate
  endDate
  utilization
  margin
  usersCount
  billableCost {
    ...MoneyFragment
  }
  standardRate {
    ...MoneyFragment
  }
  laborRate {
    ...MoneyFragment
  }
  businessOverheadRate {
    ...MoneyFragment
  }
  nonBillableOverheadRate {
    ...MoneyFragment
  }
  billingRate {
    ...MoneyFragment
  }
  totalRevenue {
    ...MoneyFragment
  }
  totalBillableHours
  totalCost {
    ...MoneyFragment
  }
  downloadUrl
  uploadedBy {
    id
    displayText
  }
}
    ${MoneyFragmentFragmentDoc}`;
export const GetMeDocument = gql`
    query getMe {
  me {
    id
    links {
      serviceUrl
      psaUrl
      uiRootUrl
    }
    locale {
      language {
        cultureCode
        displayText
        uri
        languageCode
      }
      dateTimeSettings {
        longDateFormat {
          dotNetPattern
        }
        shortDateFormat {
          dotNetPattern
        }
        shortTimeFormat {
          dotNetPattern
        }
      }
      numberSettings {
        number {
          decimalDigits
          decimalSeparator
          groupSeparator
          groupSizes
        }
        currency {
          decimalDigits
          decimalSeparator
          groupSeparator
          groupSizes
        }
      }
      customTerms {
        originalTerm
        customTerm
      }
    }
    baseCurrency {
      id
      symbol
    }
    permissions {
      permissionActionUri
      dataAccessLevelUris
    }
    featureFlags {
      cpoTargetBillability
      cpoNewUx
      cpoDashboard
    }
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const UpcomingCostOptimizationResultDocument = gql`
    query upcomingCostOptimizationResult($date: DateObject!) {
  upcomingCostOptimizationResult(date: $date) {
    ...PartialCostOptimizationResultFragment
  }
}
    ${PartialCostOptimizationResultFragmentFragmentDoc}`;

/**
 * __useUpcomingCostOptimizationResultQuery__
 *
 * To run a query within a React component, call `useUpcomingCostOptimizationResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingCostOptimizationResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingCostOptimizationResultQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpcomingCostOptimizationResultQuery(baseOptions?: Apollo.QueryHookOptions<UpcomingCostOptimizationResultQuery, UpcomingCostOptimizationResultQueryVariables>) {
        return Apollo.useQuery<UpcomingCostOptimizationResultQuery, UpcomingCostOptimizationResultQueryVariables>(UpcomingCostOptimizationResultDocument, baseOptions);
      }
export function useUpcomingCostOptimizationResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingCostOptimizationResultQuery, UpcomingCostOptimizationResultQueryVariables>) {
          return Apollo.useLazyQuery<UpcomingCostOptimizationResultQuery, UpcomingCostOptimizationResultQueryVariables>(UpcomingCostOptimizationResultDocument, baseOptions);
        }
export type UpcomingCostOptimizationResultQueryHookResult = ReturnType<typeof useUpcomingCostOptimizationResultQuery>;
export type UpcomingCostOptimizationResultLazyQueryHookResult = ReturnType<typeof useUpcomingCostOptimizationResultLazyQuery>;
export type UpcomingCostOptimizationResultQueryResult = Apollo.QueryResult<UpcomingCostOptimizationResultQuery, UpcomingCostOptimizationResultQueryVariables>;
export const ActiveCostOptimizationResultDocument = gql`
    query activeCostOptimizationResult($date: DateObject!) {
  activeCostOptimizationResult(date: $date) {
    ...FullCostOptimizationResultFragment
  }
}
    ${FullCostOptimizationResultFragmentFragmentDoc}`;

/**
 * __useActiveCostOptimizationResultQuery__
 *
 * To run a query within a React component, call `useActiveCostOptimizationResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveCostOptimizationResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveCostOptimizationResultQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useActiveCostOptimizationResultQuery(baseOptions?: Apollo.QueryHookOptions<ActiveCostOptimizationResultQuery, ActiveCostOptimizationResultQueryVariables>) {
        return Apollo.useQuery<ActiveCostOptimizationResultQuery, ActiveCostOptimizationResultQueryVariables>(ActiveCostOptimizationResultDocument, baseOptions);
      }
export function useActiveCostOptimizationResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveCostOptimizationResultQuery, ActiveCostOptimizationResultQueryVariables>) {
          return Apollo.useLazyQuery<ActiveCostOptimizationResultQuery, ActiveCostOptimizationResultQueryVariables>(ActiveCostOptimizationResultDocument, baseOptions);
        }
export type ActiveCostOptimizationResultQueryHookResult = ReturnType<typeof useActiveCostOptimizationResultQuery>;
export type ActiveCostOptimizationResultLazyQueryHookResult = ReturnType<typeof useActiveCostOptimizationResultLazyQuery>;
export type ActiveCostOptimizationResultQueryResult = Apollo.QueryResult<ActiveCostOptimizationResultQuery, ActiveCostOptimizationResultQueryVariables>;
export const CloneCostOptimizationResultDocument = gql`
    mutation cloneCostOptimizationResult($input: CloneCostOptimizationResultInput!) {
  cloneCostOptimizationResult(input: $input) {
    ...FullCostOptimizationResultFragment
  }
}
    ${FullCostOptimizationResultFragmentFragmentDoc}`;
export type CloneCostOptimizationResultMutationFn = Apollo.MutationFunction<CloneCostOptimizationResultMutation, CloneCostOptimizationResultMutationVariables>;

/**
 * __useCloneCostOptimizationResultMutation__
 *
 * To run a mutation, you first call `useCloneCostOptimizationResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneCostOptimizationResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneCostOptimizationResultMutation, { data, loading, error }] = useCloneCostOptimizationResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneCostOptimizationResultMutation(baseOptions?: Apollo.MutationHookOptions<CloneCostOptimizationResultMutation, CloneCostOptimizationResultMutationVariables>) {
        return Apollo.useMutation<CloneCostOptimizationResultMutation, CloneCostOptimizationResultMutationVariables>(CloneCostOptimizationResultDocument, baseOptions);
      }
export type CloneCostOptimizationResultMutationHookResult = ReturnType<typeof useCloneCostOptimizationResultMutation>;
export type CloneCostOptimizationResultMutationResult = Apollo.MutationResult<CloneCostOptimizationResultMutation>;
export type CloneCostOptimizationResultMutationOptions = Apollo.BaseMutationOptions<CloneCostOptimizationResultMutation, CloneCostOptimizationResultMutationVariables>;
export const CommitCostOptimizationResultDocument = gql`
    mutation commitCostOptimizationResult($input: CommitCostOptimizationResultInput!) {
  commitCostOptimizationResult(input: $input) {
    backgroundWorkId
  }
}
    `;
export type CommitCostOptimizationResultMutationFn = Apollo.MutationFunction<CommitCostOptimizationResultMutation, CommitCostOptimizationResultMutationVariables>;

/**
 * __useCommitCostOptimizationResultMutation__
 *
 * To run a mutation, you first call `useCommitCostOptimizationResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommitCostOptimizationResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commitCostOptimizationResultMutation, { data, loading, error }] = useCommitCostOptimizationResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommitCostOptimizationResultMutation(baseOptions?: Apollo.MutationHookOptions<CommitCostOptimizationResultMutation, CommitCostOptimizationResultMutationVariables>) {
        return Apollo.useMutation<CommitCostOptimizationResultMutation, CommitCostOptimizationResultMutationVariables>(CommitCostOptimizationResultDocument, baseOptions);
      }
export type CommitCostOptimizationResultMutationHookResult = ReturnType<typeof useCommitCostOptimizationResultMutation>;
export type CommitCostOptimizationResultMutationResult = Apollo.MutationResult<CommitCostOptimizationResultMutation>;
export type CommitCostOptimizationResultMutationOptions = Apollo.BaseMutationOptions<CommitCostOptimizationResultMutation, CommitCostOptimizationResultMutationVariables>;
export const CommitSmartBudgetDocument = gql`
    mutation commitSmartBudget($input: CommitSmartBudgetInput!) {
  commitSmartBudget(input: $input) {
    backgroundWorkId
  }
}
    `;
export type CommitSmartBudgetMutationFn = Apollo.MutationFunction<CommitSmartBudgetMutation, CommitSmartBudgetMutationVariables>;

/**
 * __useCommitSmartBudgetMutation__
 *
 * To run a mutation, you first call `useCommitSmartBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommitSmartBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commitSmartBudgetMutation, { data, loading, error }] = useCommitSmartBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommitSmartBudgetMutation(baseOptions?: Apollo.MutationHookOptions<CommitSmartBudgetMutation, CommitSmartBudgetMutationVariables>) {
        return Apollo.useMutation<CommitSmartBudgetMutation, CommitSmartBudgetMutationVariables>(CommitSmartBudgetDocument, baseOptions);
      }
export type CommitSmartBudgetMutationHookResult = ReturnType<typeof useCommitSmartBudgetMutation>;
export type CommitSmartBudgetMutationResult = Apollo.MutationResult<CommitSmartBudgetMutation>;
export type CommitSmartBudgetMutationOptions = Apollo.BaseMutationOptions<CommitSmartBudgetMutation, CommitSmartBudgetMutationVariables>;
export const CpoBackgroundWorkDocument = gql`
    query cpoBackgroundWork($workId: String!) {
  cpoBackgroundWork(workId: $workId) {
    id
    workStatus
    message
    workResult
    progressPercentage
  }
}
    `;

/**
 * __useCpoBackgroundWorkQuery__
 *
 * To run a query within a React component, call `useCpoBackgroundWorkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpoBackgroundWorkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpoBackgroundWorkQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useCpoBackgroundWorkQuery(baseOptions?: Apollo.QueryHookOptions<CpoBackgroundWorkQuery, CpoBackgroundWorkQueryVariables>) {
        return Apollo.useQuery<CpoBackgroundWorkQuery, CpoBackgroundWorkQueryVariables>(CpoBackgroundWorkDocument, baseOptions);
      }
export function useCpoBackgroundWorkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpoBackgroundWorkQuery, CpoBackgroundWorkQueryVariables>) {
          return Apollo.useLazyQuery<CpoBackgroundWorkQuery, CpoBackgroundWorkQueryVariables>(CpoBackgroundWorkDocument, baseOptions);
        }
export type CpoBackgroundWorkQueryHookResult = ReturnType<typeof useCpoBackgroundWorkQuery>;
export type CpoBackgroundWorkLazyQueryHookResult = ReturnType<typeof useCpoBackgroundWorkLazyQuery>;
export type CpoBackgroundWorkQueryResult = Apollo.QueryResult<CpoBackgroundWorkQuery, CpoBackgroundWorkQueryVariables>;
export const SaveRateCardDocument = gql`
    mutation saveRateCard($smartBudgetId: String!, $rateCardParameters: [RoleBreakdownParameters!]!) {
  putRoleBreakdownParameters(input: {smartBudgetId: $smartBudgetId, parameters: $rateCardParameters}) {
    smartBudgetResult {
      id
      lastModified
      utilization
      margin
      ...PartialSmartBudgetResultFragment
      roleCostBreakdown {
        ...FullRoleBreakdownFragment
      }
    }
  }
}
    ${PartialSmartBudgetResultFragmentFragmentDoc}
${FullRoleBreakdownFragmentFragmentDoc}`;
export type SaveRateCardMutationFn = Apollo.MutationFunction<SaveRateCardMutation, SaveRateCardMutationVariables>;

/**
 * __useSaveRateCardMutation__
 *
 * To run a mutation, you first call `useSaveRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRateCardMutation, { data, loading, error }] = useSaveRateCardMutation({
 *   variables: {
 *      smartBudgetId: // value for 'smartBudgetId'
 *      rateCardParameters: // value for 'rateCardParameters'
 *   },
 * });
 */
export function useSaveRateCardMutation(baseOptions?: Apollo.MutationHookOptions<SaveRateCardMutation, SaveRateCardMutationVariables>) {
        return Apollo.useMutation<SaveRateCardMutation, SaveRateCardMutationVariables>(SaveRateCardDocument, baseOptions);
      }
export type SaveRateCardMutationHookResult = ReturnType<typeof useSaveRateCardMutation>;
export type SaveRateCardMutationResult = Apollo.MutationResult<SaveRateCardMutation>;
export type SaveRateCardMutationOptions = Apollo.BaseMutationOptions<SaveRateCardMutation, SaveRateCardMutationVariables>;
export const CostOptimizationResultCurrencyTotalsDocument = gql`
    query costOptimizationResultCurrencyTotals($id: String!, $today: DateObject!) {
  costOptimizationResult(id: $id) {
    id
    billableCost {
      ...MoneyFragment
    }
    nonBillableCost {
      ...MoneyFragment
    }
    overheadCost {
      ...MoneyFragment
    }
    totalCost {
      ...MoneyFragment
    }
    currencyTotals {
      uri
      amount
      currency {
        symbol
      }
      toBaseCurrency(effectiveDate: $today) {
        exchangeRate
        value {
          amount
          currency {
            symbol
          }
        }
      }
    }
  }
}
    ${MoneyFragmentFragmentDoc}`;

/**
 * __useCostOptimizationResultCurrencyTotalsQuery__
 *
 * To run a query within a React component, call `useCostOptimizationResultCurrencyTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostOptimizationResultCurrencyTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostOptimizationResultCurrencyTotalsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      today: // value for 'today'
 *   },
 * });
 */
export function useCostOptimizationResultCurrencyTotalsQuery(baseOptions?: Apollo.QueryHookOptions<CostOptimizationResultCurrencyTotalsQuery, CostOptimizationResultCurrencyTotalsQueryVariables>) {
        return Apollo.useQuery<CostOptimizationResultCurrencyTotalsQuery, CostOptimizationResultCurrencyTotalsQueryVariables>(CostOptimizationResultCurrencyTotalsDocument, baseOptions);
      }
export function useCostOptimizationResultCurrencyTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostOptimizationResultCurrencyTotalsQuery, CostOptimizationResultCurrencyTotalsQueryVariables>) {
          return Apollo.useLazyQuery<CostOptimizationResultCurrencyTotalsQuery, CostOptimizationResultCurrencyTotalsQueryVariables>(CostOptimizationResultCurrencyTotalsDocument, baseOptions);
        }
export type CostOptimizationResultCurrencyTotalsQueryHookResult = ReturnType<typeof useCostOptimizationResultCurrencyTotalsQuery>;
export type CostOptimizationResultCurrencyTotalsLazyQueryHookResult = ReturnType<typeof useCostOptimizationResultCurrencyTotalsLazyQuery>;
export type CostOptimizationResultCurrencyTotalsQueryResult = Apollo.QueryResult<CostOptimizationResultCurrencyTotalsQuery, CostOptimizationResultCurrencyTotalsQueryVariables>;
export const CostOptimizationResultRoleBreakdownsDocument = gql`
    query costOptimizationResultRoleBreakdowns($id: String!) {
  costOptimizationResult(id: $id) {
    id
    usersCount
    totalHours
    totalNonBillableHours
    billableCost {
      ...MoneyFragment
    }
    nonBillableCost {
      ...MoneyFragment
    }
    totalBillableHours
    totalCost {
      ...MoneyFragment
    }
    billableUserCount
    nonBillableUserCount
    nonBillableRoles {
      id
      userCount
      hours
      cost
      role {
        name
        id
        displayText
      }
    }
    roleCostBreakdown {
      id
      userCount
      totalHours
      totalCost {
        ...MoneyFragment
      }
      billingCost {
        ...MoneyFragment
      }
      role {
        id
        displayText
        name
      }
    }
  }
}
    ${MoneyFragmentFragmentDoc}`;

/**
 * __useCostOptimizationResultRoleBreakdownsQuery__
 *
 * To run a query within a React component, call `useCostOptimizationResultRoleBreakdownsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostOptimizationResultRoleBreakdownsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostOptimizationResultRoleBreakdownsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCostOptimizationResultRoleBreakdownsQuery(baseOptions?: Apollo.QueryHookOptions<CostOptimizationResultRoleBreakdownsQuery, CostOptimizationResultRoleBreakdownsQueryVariables>) {
        return Apollo.useQuery<CostOptimizationResultRoleBreakdownsQuery, CostOptimizationResultRoleBreakdownsQueryVariables>(CostOptimizationResultRoleBreakdownsDocument, baseOptions);
      }
export function useCostOptimizationResultRoleBreakdownsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostOptimizationResultRoleBreakdownsQuery, CostOptimizationResultRoleBreakdownsQueryVariables>) {
          return Apollo.useLazyQuery<CostOptimizationResultRoleBreakdownsQuery, CostOptimizationResultRoleBreakdownsQueryVariables>(CostOptimizationResultRoleBreakdownsDocument, baseOptions);
        }
export type CostOptimizationResultRoleBreakdownsQueryHookResult = ReturnType<typeof useCostOptimizationResultRoleBreakdownsQuery>;
export type CostOptimizationResultRoleBreakdownsLazyQueryHookResult = ReturnType<typeof useCostOptimizationResultRoleBreakdownsLazyQuery>;
export type CostOptimizationResultRoleBreakdownsQueryResult = Apollo.QueryResult<CostOptimizationResultRoleBreakdownsQuery, CostOptimizationResultRoleBreakdownsQueryVariables>;
export const DeleteCostOptimizationResultDocument = gql`
    mutation deleteCostOptimizationResult($input: DeleteCostOptimizationResultInput!) {
  deleteCostOptimizationResult(input: $input)
}
    `;
export type DeleteCostOptimizationResultMutationFn = Apollo.MutationFunction<DeleteCostOptimizationResultMutation, DeleteCostOptimizationResultMutationVariables>;

/**
 * __useDeleteCostOptimizationResultMutation__
 *
 * To run a mutation, you first call `useDeleteCostOptimizationResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostOptimizationResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostOptimizationResultMutation, { data, loading, error }] = useDeleteCostOptimizationResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCostOptimizationResultMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCostOptimizationResultMutation, DeleteCostOptimizationResultMutationVariables>) {
        return Apollo.useMutation<DeleteCostOptimizationResultMutation, DeleteCostOptimizationResultMutationVariables>(DeleteCostOptimizationResultDocument, baseOptions);
      }
export type DeleteCostOptimizationResultMutationHookResult = ReturnType<typeof useDeleteCostOptimizationResultMutation>;
export type DeleteCostOptimizationResultMutationResult = Apollo.MutationResult<DeleteCostOptimizationResultMutation>;
export type DeleteCostOptimizationResultMutationOptions = Apollo.BaseMutationOptions<DeleteCostOptimizationResultMutation, DeleteCostOptimizationResultMutationVariables>;
export const DiscardSmartBudgetDocument = gql`
    mutation discardSmartBudget($input: DiscardSmartBudgetInput!) {
  discardSmartBudget(input: $input)
}
    `;
export type DiscardSmartBudgetMutationFn = Apollo.MutationFunction<DiscardSmartBudgetMutation, DiscardSmartBudgetMutationVariables>;

/**
 * __useDiscardSmartBudgetMutation__
 *
 * To run a mutation, you first call `useDiscardSmartBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardSmartBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardSmartBudgetMutation, { data, loading, error }] = useDiscardSmartBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscardSmartBudgetMutation(baseOptions?: Apollo.MutationHookOptions<DiscardSmartBudgetMutation, DiscardSmartBudgetMutationVariables>) {
        return Apollo.useMutation<DiscardSmartBudgetMutation, DiscardSmartBudgetMutationVariables>(DiscardSmartBudgetDocument, baseOptions);
      }
export type DiscardSmartBudgetMutationHookResult = ReturnType<typeof useDiscardSmartBudgetMutation>;
export type DiscardSmartBudgetMutationResult = Apollo.MutationResult<DiscardSmartBudgetMutation>;
export type DiscardSmartBudgetMutationOptions = Apollo.BaseMutationOptions<DiscardSmartBudgetMutation, DiscardSmartBudgetMutationVariables>;
export const TableSettingsDocument = gql`
    query tableSettings($tableKey: String!) {
  tableSetting(tableKey: $tableKey) {
    id
    columns
    sort {
      field
      direction
      customFieldUri
    }
  }
}
    `;

/**
 * __useTableSettingsQuery__
 *
 * To run a query within a React component, call `useTableSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableSettingsQuery({
 *   variables: {
 *      tableKey: // value for 'tableKey'
 *   },
 * });
 */
export function useTableSettingsQuery(baseOptions?: Apollo.QueryHookOptions<TableSettingsQuery, TableSettingsQueryVariables>) {
        return Apollo.useQuery<TableSettingsQuery, TableSettingsQueryVariables>(TableSettingsDocument, baseOptions);
      }
export function useTableSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableSettingsQuery, TableSettingsQueryVariables>) {
          return Apollo.useLazyQuery<TableSettingsQuery, TableSettingsQueryVariables>(TableSettingsDocument, baseOptions);
        }
export type TableSettingsQueryHookResult = ReturnType<typeof useTableSettingsQuery>;
export type TableSettingsLazyQueryHookResult = ReturnType<typeof useTableSettingsLazyQuery>;
export type TableSettingsQueryResult = Apollo.QueryResult<TableSettingsQuery, TableSettingsQueryVariables>;
export const PutSearchSettingsDocument = gql`
    mutation putSearchSettings($input: PutSearchSettingsInput!) {
  putSearchSettings(input: $input) {
    searchSettings {
      id
      criterions
      keywords
    }
  }
}
    `;
export type PutSearchSettingsMutationFn = Apollo.MutationFunction<PutSearchSettingsMutation, PutSearchSettingsMutationVariables>;

/**
 * __usePutSearchSettingsMutation__
 *
 * To run a mutation, you first call `usePutSearchSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutSearchSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putSearchSettingsMutation, { data, loading, error }] = usePutSearchSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePutSearchSettingsMutation(baseOptions?: Apollo.MutationHookOptions<PutSearchSettingsMutation, PutSearchSettingsMutationVariables>) {
        return Apollo.useMutation<PutSearchSettingsMutation, PutSearchSettingsMutationVariables>(PutSearchSettingsDocument, baseOptions);
      }
export type PutSearchSettingsMutationHookResult = ReturnType<typeof usePutSearchSettingsMutation>;
export type PutSearchSettingsMutationResult = Apollo.MutationResult<PutSearchSettingsMutation>;
export type PutSearchSettingsMutationOptions = Apollo.BaseMutationOptions<PutSearchSettingsMutation, PutSearchSettingsMutationVariables>;
export const SearchSettingsDocument = gql`
    query searchSettings($key: String!) {
  searchSettings(key: $key) {
    id
    keywords
    criterions
  }
}
    `;

/**
 * __useSearchSettingsQuery__
 *
 * To run a query within a React component, call `useSearchSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSettingsQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useSearchSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SearchSettingsQuery, SearchSettingsQueryVariables>) {
        return Apollo.useQuery<SearchSettingsQuery, SearchSettingsQueryVariables>(SearchSettingsDocument, baseOptions);
      }
export function useSearchSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSettingsQuery, SearchSettingsQueryVariables>) {
          return Apollo.useLazyQuery<SearchSettingsQuery, SearchSettingsQueryVariables>(SearchSettingsDocument, baseOptions);
        }
export type SearchSettingsQueryHookResult = ReturnType<typeof useSearchSettingsQuery>;
export type SearchSettingsLazyQueryHookResult = ReturnType<typeof useSearchSettingsLazyQuery>;
export type SearchSettingsQueryResult = Apollo.QueryResult<SearchSettingsQuery, SearchSettingsQueryVariables>;
export const SmartBudgetDocument = gql`
    query smartBudget($id: String!) {
  smartBudget(id: $id) {
    ...FullSmartBudgetResultFragment
  }
}
    ${FullSmartBudgetResultFragmentFragmentDoc}`;

/**
 * __useSmartBudgetQuery__
 *
 * To run a query within a React component, call `useSmartBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartBudgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSmartBudgetQuery(baseOptions?: Apollo.QueryHookOptions<SmartBudgetQuery, SmartBudgetQueryVariables>) {
        return Apollo.useQuery<SmartBudgetQuery, SmartBudgetQueryVariables>(SmartBudgetDocument, baseOptions);
      }
export function useSmartBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmartBudgetQuery, SmartBudgetQueryVariables>) {
          return Apollo.useLazyQuery<SmartBudgetQuery, SmartBudgetQueryVariables>(SmartBudgetDocument, baseOptions);
        }
export type SmartBudgetQueryHookResult = ReturnType<typeof useSmartBudgetQuery>;
export type SmartBudgetLazyQueryHookResult = ReturnType<typeof useSmartBudgetLazyQuery>;
export type SmartBudgetQueryResult = Apollo.QueryResult<SmartBudgetQuery, SmartBudgetQueryVariables>;
export const PutTableSettingDocument = gql`
    mutation putTableSetting($input: PutTableSettingInput!) {
  putTableSetting(input: $input) {
    tableSetting {
      id
      columns
      sort {
        field
        direction
        customFieldUri
      }
    }
  }
}
    `;
export type PutTableSettingMutationFn = Apollo.MutationFunction<PutTableSettingMutation, PutTableSettingMutationVariables>;

/**
 * __usePutTableSettingMutation__
 *
 * To run a mutation, you first call `usePutTableSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutTableSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putTableSettingMutation, { data, loading, error }] = usePutTableSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePutTableSettingMutation(baseOptions?: Apollo.MutationHookOptions<PutTableSettingMutation, PutTableSettingMutationVariables>) {
        return Apollo.useMutation<PutTableSettingMutation, PutTableSettingMutationVariables>(PutTableSettingDocument, baseOptions);
      }
export type PutTableSettingMutationHookResult = ReturnType<typeof usePutTableSettingMutation>;
export type PutTableSettingMutationResult = Apollo.MutationResult<PutTableSettingMutation>;
export type PutTableSettingMutationOptions = Apollo.BaseMutationOptions<PutTableSettingMutation, PutTableSettingMutationVariables>;
export const PutCostFileDocument = gql`
    mutation putCostFile($input: PutCostFileInput!) {
  putCostFile(input: $input)
}
    `;
export type PutCostFileMutationFn = Apollo.MutationFunction<PutCostFileMutation, PutCostFileMutationVariables>;

/**
 * __usePutCostFileMutation__
 *
 * To run a mutation, you first call `usePutCostFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutCostFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putCostFileMutation, { data, loading, error }] = usePutCostFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePutCostFileMutation(baseOptions?: Apollo.MutationHookOptions<PutCostFileMutation, PutCostFileMutationVariables>) {
        return Apollo.useMutation<PutCostFileMutation, PutCostFileMutationVariables>(PutCostFileDocument, baseOptions);
      }
export type PutCostFileMutationHookResult = ReturnType<typeof usePutCostFileMutation>;
export type PutCostFileMutationResult = Apollo.MutationResult<PutCostFileMutation>;
export type PutCostFileMutationOptions = Apollo.BaseMutationOptions<PutCostFileMutation, PutCostFileMutationVariables>;
export const ValidateCostFileDocument = gql`
    mutation validateCostFile($input: ValidateCostFileInput!) {
  validateCostFile(input: $input) {
    validationErrors {
      type
      line
      column
      message
      cellValue
    }
  }
}
    `;
export type ValidateCostFileMutationFn = Apollo.MutationFunction<ValidateCostFileMutation, ValidateCostFileMutationVariables>;

/**
 * __useValidateCostFileMutation__
 *
 * To run a mutation, you first call `useValidateCostFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCostFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCostFileMutation, { data, loading, error }] = useValidateCostFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateCostFileMutation(baseOptions?: Apollo.MutationHookOptions<ValidateCostFileMutation, ValidateCostFileMutationVariables>) {
        return Apollo.useMutation<ValidateCostFileMutation, ValidateCostFileMutationVariables>(ValidateCostFileDocument, baseOptions);
      }
export type ValidateCostFileMutationHookResult = ReturnType<typeof useValidateCostFileMutation>;
export type ValidateCostFileMutationResult = Apollo.MutationResult<ValidateCostFileMutation>;
export type ValidateCostFileMutationOptions = Apollo.BaseMutationOptions<ValidateCostFileMutation, ValidateCostFileMutationVariables>;
export const SmartBudgetsDocument = gql`
    query smartBudgets($count: Int!, $cursor: String) {
  smartBudgets(count: $count, cursor: $cursor) {
    pageInfo {
      count
      hasNextPage
    }
    edges {
      cursor
      node {
        ...PartialSmartBudgetResultFragment
      }
    }
  }
}
    ${PartialSmartBudgetResultFragmentFragmentDoc}`;

/**
 * __useSmartBudgetsQuery__
 *
 * To run a query within a React component, call `useSmartBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartBudgetsQuery({
 *   variables: {
 *      count: // value for 'count'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSmartBudgetsQuery(baseOptions?: Apollo.QueryHookOptions<SmartBudgetsQuery, SmartBudgetsQueryVariables>) {
        return Apollo.useQuery<SmartBudgetsQuery, SmartBudgetsQueryVariables>(SmartBudgetsDocument, baseOptions);
      }
export function useSmartBudgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmartBudgetsQuery, SmartBudgetsQueryVariables>) {
          return Apollo.useLazyQuery<SmartBudgetsQuery, SmartBudgetsQueryVariables>(SmartBudgetsDocument, baseOptions);
        }
export type SmartBudgetsQueryHookResult = ReturnType<typeof useSmartBudgetsQuery>;
export type SmartBudgetsLazyQueryHookResult = ReturnType<typeof useSmartBudgetsLazyQuery>;
export type SmartBudgetsQueryResult = Apollo.QueryResult<SmartBudgetsQuery, SmartBudgetsQueryVariables>;
export const CreateNewSmartBudgetDocument = gql`
    mutation CreateNewSmartBudget($name: String!, $startDate: DateObject!, $endDate: DateObject!) {
  createSmartBudget(input: {name: $name, dateRange: {startDate: $startDate, endDate: $endDate}, utilization: "80", margin: "35", ignoreWarning: false}) {
    backgroundWorkId
  }
}
    `;
export type CreateNewSmartBudgetMutationFn = Apollo.MutationFunction<CreateNewSmartBudgetMutation, CreateNewSmartBudgetMutationVariables>;

/**
 * __useCreateNewSmartBudgetMutation__
 *
 * To run a mutation, you first call `useCreateNewSmartBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewSmartBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewSmartBudgetMutation, { data, loading, error }] = useCreateNewSmartBudgetMutation({
 *   variables: {
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreateNewSmartBudgetMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewSmartBudgetMutation, CreateNewSmartBudgetMutationVariables>) {
        return Apollo.useMutation<CreateNewSmartBudgetMutation, CreateNewSmartBudgetMutationVariables>(CreateNewSmartBudgetDocument, baseOptions);
      }
export type CreateNewSmartBudgetMutationHookResult = ReturnType<typeof useCreateNewSmartBudgetMutation>;
export type CreateNewSmartBudgetMutationResult = Apollo.MutationResult<CreateNewSmartBudgetMutation>;
export type CreateNewSmartBudgetMutationOptions = Apollo.BaseMutationOptions<CreateNewSmartBudgetMutation, CreateNewSmartBudgetMutationVariables>;
export const SmartBudgetOverviewSummaryDocument = gql`
    query smartBudgetOverviewSummary($startDate: DateObject!, $endDate: DateObject!) {
  smartBudgetOverviewSummary(dateRange: {startDate: $startDate, endDate: $endDate}) {
    dateRange {
      startDate
      endDate
    }
    utilization {
      actual
      budgeted
      delta
    }
    margin {
      actual
      budgeted
      delta
    }
    billingRate {
      actual {
        amount
        currency {
          symbol
        }
      }
      budgeted {
        amount
        currency {
          symbol
        }
      }
      deltaPercent
      deltaMoney {
        amount
        currency {
          symbol
        }
      }
    }
    revenue {
      actual {
        amount
        currency {
          symbol
        }
      }
      budgeted {
        amount
        currency {
          symbol
        }
      }
      deltaPercent
      deltaMoney {
        amount
        currency {
          symbol
        }
      }
    }
    totalCost {
      actual {
        amount
        currency {
          symbol
        }
      }
      budgeted {
        amount
        currency {
          symbol
        }
      }
      deltaPercent
      deltaMoney {
        amount
        currency {
          symbol
        }
      }
    }
    profit {
      actual {
        amount
        currency {
          symbol
        }
      }
      budgeted {
        amount
        currency {
          symbol
        }
      }
      deltaPercent
      deltaMoney {
        amount
        currency {
          symbol
        }
      }
    }
  }
}
    `;

/**
 * __useSmartBudgetOverviewSummaryQuery__
 *
 * To run a query within a React component, call `useSmartBudgetOverviewSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartBudgetOverviewSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartBudgetOverviewSummaryQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useSmartBudgetOverviewSummaryQuery(baseOptions?: Apollo.QueryHookOptions<SmartBudgetOverviewSummaryQuery, SmartBudgetOverviewSummaryQueryVariables>) {
        return Apollo.useQuery<SmartBudgetOverviewSummaryQuery, SmartBudgetOverviewSummaryQueryVariables>(SmartBudgetOverviewSummaryDocument, baseOptions);
      }
export function useSmartBudgetOverviewSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmartBudgetOverviewSummaryQuery, SmartBudgetOverviewSummaryQueryVariables>) {
          return Apollo.useLazyQuery<SmartBudgetOverviewSummaryQuery, SmartBudgetOverviewSummaryQueryVariables>(SmartBudgetOverviewSummaryDocument, baseOptions);
        }
export type SmartBudgetOverviewSummaryQueryHookResult = ReturnType<typeof useSmartBudgetOverviewSummaryQuery>;
export type SmartBudgetOverviewSummaryLazyQueryHookResult = ReturnType<typeof useSmartBudgetOverviewSummaryLazyQuery>;
export type SmartBudgetOverviewSummaryQueryResult = Apollo.QueryResult<SmartBudgetOverviewSummaryQuery, SmartBudgetOverviewSummaryQueryVariables>;
export const SmartBudgetOverviewGraphDocument = gql`
    query smartBudgetOverviewGraph($dateRange: DateRangeInput!, $granularity: GraphGranularity!) {
  smartBudgetOverviewGraph(dateRange: $dateRange, granularity: $granularity) {
    dateRange {
      startDate
      endDate
    }
    granularity
    revenue {
      dateRange {
        startDate
        endDate
      }
      budgeted {
        amount
        currency {
          symbol
        }
        toBaseCurrency {
          exchangeRate
          value {
            amount
            currency {
              symbol
            }
          }
        }
      }
      estimated {
        amount
        currency {
          symbol
        }
        toBaseCurrency {
          exchangeRate
          value {
            amount
            currency {
              symbol
            }
          }
        }
      }
      actual {
        amount
        currency {
          symbol
        }
        toBaseCurrency {
          exchangeRate
          value {
            amount
            currency {
              symbol
            }
          }
        }
      }
    }
    smartBudgets {
      id
      name
      effectiveDate
      endDate
    }
  }
}
    `;

/**
 * __useSmartBudgetOverviewGraphQuery__
 *
 * To run a query within a React component, call `useSmartBudgetOverviewGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartBudgetOverviewGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartBudgetOverviewGraphQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      granularity: // value for 'granularity'
 *   },
 * });
 */
export function useSmartBudgetOverviewGraphQuery(baseOptions?: Apollo.QueryHookOptions<SmartBudgetOverviewGraphQuery, SmartBudgetOverviewGraphQueryVariables>) {
        return Apollo.useQuery<SmartBudgetOverviewGraphQuery, SmartBudgetOverviewGraphQueryVariables>(SmartBudgetOverviewGraphDocument, baseOptions);
      }
export function useSmartBudgetOverviewGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmartBudgetOverviewGraphQuery, SmartBudgetOverviewGraphQueryVariables>) {
          return Apollo.useLazyQuery<SmartBudgetOverviewGraphQuery, SmartBudgetOverviewGraphQueryVariables>(SmartBudgetOverviewGraphDocument, baseOptions);
        }
export type SmartBudgetOverviewGraphQueryHookResult = ReturnType<typeof useSmartBudgetOverviewGraphQuery>;
export type SmartBudgetOverviewGraphLazyQueryHookResult = ReturnType<typeof useSmartBudgetOverviewGraphLazyQuery>;
export type SmartBudgetOverviewGraphQueryResult = Apollo.QueryResult<SmartBudgetOverviewGraphQuery, SmartBudgetOverviewGraphQueryVariables>;
export const CreateSmartBudgetDocument = gql`
    mutation createSmartBudget($input: CreateSmartBudgetInput!) {
  createSmartBudget(input: $input) {
    backgroundWorkId
  }
}
    `;
export type CreateSmartBudgetMutationFn = Apollo.MutationFunction<CreateSmartBudgetMutation, CreateSmartBudgetMutationVariables>;

/**
 * __useCreateSmartBudgetMutation__
 *
 * To run a mutation, you first call `useCreateSmartBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmartBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmartBudgetMutation, { data, loading, error }] = useCreateSmartBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSmartBudgetMutation(baseOptions?: Apollo.MutationHookOptions<CreateSmartBudgetMutation, CreateSmartBudgetMutationVariables>) {
        return Apollo.useMutation<CreateSmartBudgetMutation, CreateSmartBudgetMutationVariables>(CreateSmartBudgetDocument, baseOptions);
      }
export type CreateSmartBudgetMutationHookResult = ReturnType<typeof useCreateSmartBudgetMutation>;
export type CreateSmartBudgetMutationResult = Apollo.MutationResult<CreateSmartBudgetMutation>;
export type CreateSmartBudgetMutationOptions = Apollo.BaseMutationOptions<CreateSmartBudgetMutation, CreateSmartBudgetMutationVariables>;
export const GetOverlappingPlansDocument = gql`
    query GetOverlappingPlans($startDate: DateObject!, $endDate: DateObject!) {
  smartBudgets(count: 1000, overlapsDateRange: {startDate: $startDate, endDate: $endDate}) {
    edges {
      node {
        id
        name
        effectiveDate
        endDate
      }
    }
  }
}
    `;

/**
 * __useGetOverlappingPlansQuery__
 *
 * To run a query within a React component, call `useGetOverlappingPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverlappingPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverlappingPlansQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetOverlappingPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetOverlappingPlansQuery, GetOverlappingPlansQueryVariables>) {
        return Apollo.useQuery<GetOverlappingPlansQuery, GetOverlappingPlansQueryVariables>(GetOverlappingPlansDocument, baseOptions);
      }
export function useGetOverlappingPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverlappingPlansQuery, GetOverlappingPlansQueryVariables>) {
          return Apollo.useLazyQuery<GetOverlappingPlansQuery, GetOverlappingPlansQueryVariables>(GetOverlappingPlansDocument, baseOptions);
        }
export type GetOverlappingPlansQueryHookResult = ReturnType<typeof useGetOverlappingPlansQuery>;
export type GetOverlappingPlansLazyQueryHookResult = ReturnType<typeof useGetOverlappingPlansLazyQuery>;
export type GetOverlappingPlansQueryResult = Apollo.QueryResult<GetOverlappingPlansQuery, GetOverlappingPlansQueryVariables>;
export const SearchPlansDocument = gql`
    query SearchPlans($text: String) {
  smartBudgets(count: 100, searchText: $text) {
    edges {
      node {
        id
        name
        margin
        utilization
      }
    }
  }
}
    `;

/**
 * __useSearchPlansQuery__
 *
 * To run a query within a React component, call `useSearchPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlansQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSearchPlansQuery(baseOptions?: Apollo.QueryHookOptions<SearchPlansQuery, SearchPlansQueryVariables>) {
        return Apollo.useQuery<SearchPlansQuery, SearchPlansQueryVariables>(SearchPlansDocument, baseOptions);
      }
export function useSearchPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPlansQuery, SearchPlansQueryVariables>) {
          return Apollo.useLazyQuery<SearchPlansQuery, SearchPlansQueryVariables>(SearchPlansDocument, baseOptions);
        }
export type SearchPlansQueryHookResult = ReturnType<typeof useSearchPlansQuery>;
export type SearchPlansLazyQueryHookResult = ReturnType<typeof useSearchPlansLazyQuery>;
export type SearchPlansQueryResult = Apollo.QueryResult<SearchPlansQuery, SearchPlansQueryVariables>;
export const PlanWarningDocument = gql`
    query planWarning($id: String!) {
  costOptimizationResult(id: $id) {
    id
    lastSynchronizedAt
    operationOverhead {
      id
    }
    warningsCount {
      totalWarningsCount
      userDirectCostWarningCount
    }
    userDirectCostWarnings {
      id
      displayText
      loginName
      slug
    }
    overlappingPlansWarnings {
      planId
      planName
      planStartDate
      planEndDate
      planStatus
    }
  }
}
    `;

/**
 * __usePlanWarningQuery__
 *
 * To run a query within a React component, call `usePlanWarningQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanWarningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanWarningQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanWarningQuery(baseOptions?: Apollo.QueryHookOptions<PlanWarningQuery, PlanWarningQueryVariables>) {
        return Apollo.useQuery<PlanWarningQuery, PlanWarningQueryVariables>(PlanWarningDocument, baseOptions);
      }
export function usePlanWarningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanWarningQuery, PlanWarningQueryVariables>) {
          return Apollo.useLazyQuery<PlanWarningQuery, PlanWarningQueryVariables>(PlanWarningDocument, baseOptions);
        }
export type PlanWarningQueryHookResult = ReturnType<typeof usePlanWarningQuery>;
export type PlanWarningLazyQueryHookResult = ReturnType<typeof usePlanWarningLazyQuery>;
export type PlanWarningQueryResult = Apollo.QueryResult<PlanWarningQuery, PlanWarningQueryVariables>;
export const PlansDocument = gql`
    query plans($count: Int!, $cursor: String, $sort: PlanListSort, $searchText: String) {
  smartBudgets(count: $count, cursor: $cursor, sort: $sort, searchText: $searchText) {
    pageInfo {
      count
      hasNextPage
    }
    edges {
      cursor
      node {
        name
        ...PartialSmartBudgetResultFragment
      }
    }
  }
}
    ${PartialSmartBudgetResultFragmentFragmentDoc}`;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      count: // value for 'count'
 *      cursor: // value for 'cursor'
 *      sort: // value for 'sort'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, baseOptions);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, baseOptions);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const DeleteSmartBudgetDocument = gql`
    mutation deleteSmartBudget($input: DeleteSmartBudgetInput!) {
  deleteSmartBudget(input: $input)
}
    `;
export type DeleteSmartBudgetMutationFn = Apollo.MutationFunction<DeleteSmartBudgetMutation, DeleteSmartBudgetMutationVariables>;

/**
 * __useDeleteSmartBudgetMutation__
 *
 * To run a mutation, you first call `useDeleteSmartBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmartBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmartBudgetMutation, { data, loading, error }] = useDeleteSmartBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSmartBudgetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSmartBudgetMutation, DeleteSmartBudgetMutationVariables>) {
        return Apollo.useMutation<DeleteSmartBudgetMutation, DeleteSmartBudgetMutationVariables>(DeleteSmartBudgetDocument, baseOptions);
      }
export type DeleteSmartBudgetMutationHookResult = ReturnType<typeof useDeleteSmartBudgetMutation>;
export type DeleteSmartBudgetMutationResult = Apollo.MutationResult<DeleteSmartBudgetMutation>;
export type DeleteSmartBudgetMutationOptions = Apollo.BaseMutationOptions<DeleteSmartBudgetMutation, DeleteSmartBudgetMutationVariables>;
export const PutBusinessCostsDocument = gql`
    mutation putBusinessCosts($input: PutBusinessCostsInput!) {
  putBusinessCosts(input: $input) {
    smartBudgetId
  }
}
    `;
export type PutBusinessCostsMutationFn = Apollo.MutationFunction<PutBusinessCostsMutation, PutBusinessCostsMutationVariables>;

/**
 * __usePutBusinessCostsMutation__
 *
 * To run a mutation, you first call `usePutBusinessCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutBusinessCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putBusinessCostsMutation, { data, loading, error }] = usePutBusinessCostsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePutBusinessCostsMutation(baseOptions?: Apollo.MutationHookOptions<PutBusinessCostsMutation, PutBusinessCostsMutationVariables>) {
        return Apollo.useMutation<PutBusinessCostsMutation, PutBusinessCostsMutationVariables>(PutBusinessCostsDocument, baseOptions);
      }
export type PutBusinessCostsMutationHookResult = ReturnType<typeof usePutBusinessCostsMutation>;
export type PutBusinessCostsMutationResult = Apollo.MutationResult<PutBusinessCostsMutation>;
export type PutBusinessCostsMutationOptions = Apollo.BaseMutationOptions<PutBusinessCostsMutation, PutBusinessCostsMutationVariables>;
export const PutSmartBudgetRoundingDocument = gql`
    mutation putSmartBudgetRounding($input: PutSmartBudgetRoundingInput!) {
  putSmartBudgetRounding(input: $input) {
    smartBudgetResult {
      id
      nearestX
    }
  }
}
    `;
export type PutSmartBudgetRoundingMutationFn = Apollo.MutationFunction<PutSmartBudgetRoundingMutation, PutSmartBudgetRoundingMutationVariables>;

/**
 * __usePutSmartBudgetRoundingMutation__
 *
 * To run a mutation, you first call `usePutSmartBudgetRoundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutSmartBudgetRoundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putSmartBudgetRoundingMutation, { data, loading, error }] = usePutSmartBudgetRoundingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePutSmartBudgetRoundingMutation(baseOptions?: Apollo.MutationHookOptions<PutSmartBudgetRoundingMutation, PutSmartBudgetRoundingMutationVariables>) {
        return Apollo.useMutation<PutSmartBudgetRoundingMutation, PutSmartBudgetRoundingMutationVariables>(PutSmartBudgetRoundingDocument, baseOptions);
      }
export type PutSmartBudgetRoundingMutationHookResult = ReturnType<typeof usePutSmartBudgetRoundingMutation>;
export type PutSmartBudgetRoundingMutationResult = Apollo.MutationResult<PutSmartBudgetRoundingMutation>;
export type PutSmartBudgetRoundingMutationOptions = Apollo.BaseMutationOptions<PutSmartBudgetRoundingMutation, PutSmartBudgetRoundingMutationVariables>;
export const UpdateCostsDocument = gql`
    mutation UpdateCosts($smartBudgetId: String!) {
  updateSmartBudgetCosts(input: {smartBudgetId: $smartBudgetId}) {
    backgroundWorkId
  }
}
    `;
export type UpdateCostsMutationFn = Apollo.MutationFunction<UpdateCostsMutation, UpdateCostsMutationVariables>;

/**
 * __useUpdateCostsMutation__
 *
 * To run a mutation, you first call `useUpdateCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostsMutation, { data, loading, error }] = useUpdateCostsMutation({
 *   variables: {
 *      smartBudgetId: // value for 'smartBudgetId'
 *   },
 * });
 */
export function useUpdateCostsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCostsMutation, UpdateCostsMutationVariables>) {
        return Apollo.useMutation<UpdateCostsMutation, UpdateCostsMutationVariables>(UpdateCostsDocument, baseOptions);
      }
export type UpdateCostsMutationHookResult = ReturnType<typeof useUpdateCostsMutation>;
export type UpdateCostsMutationResult = Apollo.MutationResult<UpdateCostsMutation>;
export type UpdateCostsMutationOptions = Apollo.BaseMutationOptions<UpdateCostsMutation, UpdateCostsMutationVariables>;
export const UpdateSmartBudgetNameDocument = gql`
    mutation updateSmartBudgetName($input: UpdateSmartBudgetNameInput!) {
  updateSmartBudgetName(input: $input) {
    smartBudgetResult {
      id
      name
    }
  }
}
    `;
export type UpdateSmartBudgetNameMutationFn = Apollo.MutationFunction<UpdateSmartBudgetNameMutation, UpdateSmartBudgetNameMutationVariables>;

/**
 * __useUpdateSmartBudgetNameMutation__
 *
 * To run a mutation, you first call `useUpdateSmartBudgetNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmartBudgetNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmartBudgetNameMutation, { data, loading, error }] = useUpdateSmartBudgetNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSmartBudgetNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSmartBudgetNameMutation, UpdateSmartBudgetNameMutationVariables>) {
        return Apollo.useMutation<UpdateSmartBudgetNameMutation, UpdateSmartBudgetNameMutationVariables>(UpdateSmartBudgetNameDocument, baseOptions);
      }
export type UpdateSmartBudgetNameMutationHookResult = ReturnType<typeof useUpdateSmartBudgetNameMutation>;
export type UpdateSmartBudgetNameMutationResult = Apollo.MutationResult<UpdateSmartBudgetNameMutation>;
export type UpdateSmartBudgetNameMutationOptions = Apollo.BaseMutationOptions<UpdateSmartBudgetNameMutation, UpdateSmartBudgetNameMutationVariables>;
export const PutUserBreakdownParametersDocument = gql`
    mutation putUserBreakdownParameters($input: PutUserBreakdownParametersInput!) {
  putUserBreakdownParameters(input: $input) {
    smartBudgetResult {
      ...PartialSmartBudgetResultFragment
      utilization
      margin
      roleCostBreakdown {
        id
        role {
          id
        }
        margin
        utilization
        revenue {
          ...MoneyFragment
        }
        billingCost {
          ...MoneyFragment
        }
      }
    }
    userBreakdowns {
      id
      hours
      totalCost {
        ...MoneyFragment
      }
      billable
      utilization
      margin
      billingRate {
        ...MoneyFragment
      }
      revenue {
        ...MoneyFragment
      }
      user {
        id
        displayText
      }
      role {
        id
        name
        displayText
      }
    }
  }
}
    ${PartialSmartBudgetResultFragmentFragmentDoc}
${MoneyFragmentFragmentDoc}`;
export type PutUserBreakdownParametersMutationFn = Apollo.MutationFunction<PutUserBreakdownParametersMutation, PutUserBreakdownParametersMutationVariables>;

/**
 * __usePutUserBreakdownParametersMutation__
 *
 * To run a mutation, you first call `usePutUserBreakdownParametersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutUserBreakdownParametersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putUserBreakdownParametersMutation, { data, loading, error }] = usePutUserBreakdownParametersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePutUserBreakdownParametersMutation(baseOptions?: Apollo.MutationHookOptions<PutUserBreakdownParametersMutation, PutUserBreakdownParametersMutationVariables>) {
        return Apollo.useMutation<PutUserBreakdownParametersMutation, PutUserBreakdownParametersMutationVariables>(PutUserBreakdownParametersDocument, baseOptions);
      }
export type PutUserBreakdownParametersMutationHookResult = ReturnType<typeof usePutUserBreakdownParametersMutation>;
export type PutUserBreakdownParametersMutationResult = Apollo.MutationResult<PutUserBreakdownParametersMutation>;
export type PutUserBreakdownParametersMutationOptions = Apollo.BaseMutationOptions<PutUserBreakdownParametersMutation, PutUserBreakdownParametersMutationVariables>;
export const RoleUserBreakdownsDocument = gql`
    query roleUserBreakdowns($id: String!, $roleUri: String!, $count: Int!, $cursor: String) {
  costOptimizationResult(id: $id) {
    id
    roleCostBreakdown(roleUri: $roleUri) {
      id
      userBreakdown(count: $count, cursor: $cursor) {
        pageInfo {
          hasNextPage
          count
          nextPage
          previousPage
        }
        edges {
          cursor
          node {
            id
            billable
            hours
            nonBillableOverhead {
              ...MoneyFragment
            }
            businessOverhead {
              ...MoneyFragment
            }
            utilization
            margin
            standardCostRate {
              ...MoneyFragment
            }
            loadedCostRate {
              ...MoneyFragment
            }
            billingRate {
              ...MoneyFragment
            }
            revenue {
              ...MoneyFragment
            }
            totalCost {
              ...MoneyFragment
            }
            user {
              displayText
              id
            }
            role {
              displayText
              id
            }
          }
        }
      }
    }
  }
}
    ${MoneyFragmentFragmentDoc}`;

/**
 * __useRoleUserBreakdownsQuery__
 *
 * To run a query within a React component, call `useRoleUserBreakdownsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleUserBreakdownsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleUserBreakdownsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      roleUri: // value for 'roleUri'
 *      count: // value for 'count'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRoleUserBreakdownsQuery(baseOptions?: Apollo.QueryHookOptions<RoleUserBreakdownsQuery, RoleUserBreakdownsQueryVariables>) {
        return Apollo.useQuery<RoleUserBreakdownsQuery, RoleUserBreakdownsQueryVariables>(RoleUserBreakdownsDocument, baseOptions);
      }
export function useRoleUserBreakdownsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleUserBreakdownsQuery, RoleUserBreakdownsQueryVariables>) {
          return Apollo.useLazyQuery<RoleUserBreakdownsQuery, RoleUserBreakdownsQueryVariables>(RoleUserBreakdownsDocument, baseOptions);
        }
export type RoleUserBreakdownsQueryHookResult = ReturnType<typeof useRoleUserBreakdownsQuery>;
export type RoleUserBreakdownsLazyQueryHookResult = ReturnType<typeof useRoleUserBreakdownsLazyQuery>;
export type RoleUserBreakdownsQueryResult = Apollo.QueryResult<RoleUserBreakdownsQuery, RoleUserBreakdownsQueryVariables>;