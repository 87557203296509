import React from 'react';
import { Paper, PaperProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export type SummaryProps = PaperProps;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    }
  }
}));

const SummaryCard: React.FC<SummaryProps> = ({
  classes,
  children,
  ...rest
}: SummaryProps) => {
  const cls = useStyles({ classes });
  return (
    <Paper classes={cls} elevation={2} {...rest}>
      {children}
    </Paper>
  );
};

export default SummaryCard;
