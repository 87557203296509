/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'App';

(window as any).renderCostPriceOptimizer = (
  containerId: string,
  props: any = {}
): void => {
  const targetNode = document.getElementById(containerId);
  if (!targetNode) {
    throw new Error(
      'Unable to mount SmartBudget - Target node does not exist in document.'
    );
  }

  ReactDOM.render(<App {...props} />, targetNode);
};

(window as any).unmountCostPriceOptimizer = (containerId: string): void => {
  const targetNode = document.getElementById(containerId);
  if (!targetNode) {
    // Node was already removed from the page, so we don't have to do anything.
    return;
  }

  ReactDOM.unmountComponentAtNode(targetNode);
};

export { App };
