import { useCallback, useState } from 'react';
import { DateTime } from 'luxon';

type OnDateChange = (date: DateTime | null) => void;
export type UseEffectiveDateOptions = {
  startDate: DateTime | null;
  endDate: DateTime | null;
  onStartDateChange: OnDateChange;
  onEndDateChange: OnDateChange;
  setRates: () => void;
};

export const useEffectiveDate = (
  closeDialog: () => void,
  commitRates: (startDate: DateTime, endDate: DateTime) => void
): UseEffectiveDateOptions => {
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [endDate, setEndDate] = useState<DateTime | null>(null);
  const onStartDateChange = useCallback(
    (date: DateTime | null) => {
      setStartDate(date);
      if (endDate && endDate < (date as DateTime)) {
        setEndDate(date);
      }
    },
    [endDate]
  );
  const onEndDateChange = useCallback(
    (date: DateTime | null) => {
      setEndDate(date);
      if (startDate && (date as DateTime) < startDate) {
        setStartDate(date);
      }
    },
    [startDate]
  );

  const setRates = useCallback(() => {
    closeDialog();
    commitRates(startDate ?? DateTime.local(), endDate as DateTime);
  }, [closeDialog, commitRates, endDate, startDate]);

  return {
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    setRates
  };
};
