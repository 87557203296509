import React, { useMemo } from 'react';
import { CssBaseline } from '@material-ui/core';
import rtl from 'jss-rtl';
import { create } from 'jss';
import {
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
  Theme,
  jssPreset,
  Direction
} from '@material-ui/core/styles';
import { useDirection } from './useDirection';

export const repliconBlue = {
  50: '#e1f5ff',
  100: '#b3e6ff',
  200: '#80d6ff',
  300: '#4ac5ff',
  400: '#1db8ff',
  500: '#00abff',
  600: '#009df2',
  700: '#008add',
  800: '#007ac9',
  900: '#0658a7',
  A100: '#',
  A200: '#',
  A400: '#',
  A700: '#'
};

const theme = (direction: Direction): Theme =>
  createMuiTheme({
    direction: direction,
    palette: {
      contrastThreshold: 4.5, // WCAG 2 level AA requires a contrast ratio of at least 4.5:1 for normal text and 3:1 for large text.
      primary: {
        light: '#d7deff',
        main: '#3758fb',
        dark: '#1736a9'
      },
      secondary: {
        light: '#fdc5d7',
        main: '#e9055b',
        dark: '#91054d'
      },
      error: {
        light: '#fcd4d0',
        main: '#d9240f',
        dark: '#a71809'
      },
      warning: {
        light: '#fffad7',
        main: '#fbe637',
        dark: '#917205'
      },
      info: {
        light: '#c6ecfc',
        main: '#37bbfb',
        dark: '#005ba4'
      },
      success: {
        light: '#d2f9ea',
        main: '#26d293',
        dark: '#008454'
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)'
      }
    },
    typography: {
      fontFamily: 'Roboto, Helvetica, Segoe UI, Arial, sans-serif',
      fontSize: 13,
      h1: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      h2: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      h3: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      h4: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      h5: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      h6: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      subtitle1: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      subtitle2: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      button: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      }
    },
    props: {
      MuiListItem: {
        dense: true
      },
      MuiTextField: {
        size: 'small',
        margin: 'none',
        variant: 'filled'
      },
      MuiFormControl: {
        size: 'small',
        margin: 'none',
        variant: 'filled'
      }
    }
  });

const overrideParentTheme = (parentTheme: Theme): Theme =>
  createMuiTheme({
    ...parentTheme,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: '#ffffff'
          }
        }
      }
    }
  });

type MuiThemeProviderProps = {
  parentTheme?: Theme;
  children: React.ReactNode;
};

const classNameGenerator = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'cpojss'
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const MuiThemeProvider: React.FC<MuiThemeProviderProps> = ({
  parentTheme,
  children
}: MuiThemeProviderProps) => {
  const direction = useDirection();
  document.dir = direction;

  const appTheme = useMemo(
    () => (parentTheme ? overrideParentTheme(parentTheme) : theme(direction)),
    [direction, parentTheme]
  );
  return (
    <StylesProvider jss={jss} generateClassName={classNameGenerator}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline key="appBaseline" />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};
