import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';
import { Maybe, Money } from 'generated-types';
import { BillingByRoleData } from 'common/types';
import { PlanTotals } from '../components/BillableRoleTable/hooks';

export type PlanTotalContextValue = {
  planTotals?: Maybe<PlanTotals>;
  utilization: BigNumber;
  margin: BigNumber;
  revenue: Money;
  profit: Money;
  roleData: BillingByRoleData[];
};

const PlanTotalContext = React.createContext<Maybe<PlanTotalContextValue>>(
  null
);

const usePlanTotalContext = (): PlanTotalContextValue => {
  const val = useContext(PlanTotalContext);
  if (!val) {
    throw new Error(
      'usePlanTotalContext must be called within a PlanTotalContextProvider with a resolved PlanTotals.'
    );
  }

  return val;
};

const PlanTotalContextProvider = PlanTotalContext.Provider;
const PlanTotalContextConsumer = PlanTotalContext.Consumer;

export {
  PlanTotalContext,
  usePlanTotalContext,
  PlanTotalContextProvider,
  PlanTotalContextConsumer
};
