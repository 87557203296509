import React from 'react';
import { CellProps } from 'react-table';
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from 'App/MessageProvider';
import { BillingByRoleData } from 'common/types';
import {
  usePlanEditContext,
  useRoleTableFormContext
} from 'plans/plan/context';
import { TableFormNumberEditField } from 'rateCard';
import { NumberValue } from 'common/components';
import { useIsCalculatingAttribute } from 'plans/plan/hooks';

const useStyles = makeStyles(theme => ({
  percentage: {
    paddingLeft: 1,
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '80%'
    }
  }
}));

export const UtilizationCell: React.FC<CellProps<BillingByRoleData>> = ({
  row
}: CellProps<BillingByRoleData>) => {
  const { getValues, onUtilizationChangeHandler } = useRoleTableFormContext();
  const classes = useStyles();
  const label = useMessage(
    'planPage.billableResourcesCard.utilizationFieldLabel',
    {
      roleName: row.original.roleName
    }
  );
  const { canEdit } = usePlanEditContext();
  const isCalculatingColumn = useIsCalculatingAttribute('utilization');
  if (!canEdit || isCalculatingColumn) {
    return (
      <NumberValue
        value={Number(getValues?.()[row.original.id].utilization)}
        suffix=" %"
        fixedDecimalScale
      />
    );
  }

  return (
    <TableFormNumberEditField
      className={classes.percentage}
      id={row.original.id}
      attribute="utilization"
      fullWidth
      value={getValues?.()[row.original.id].utilization}
      onChanged={onUtilizationChangeHandler}
      suffix="%"
      ariaLabel={label}
    />
  );
};
