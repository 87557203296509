import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AttachmentSharpIcon from '@material-ui/icons/AttachmentSharp';
import { Button, Label } from 'common/components';
import { useDialogState } from 'common/hooks';
import { CsvValidationError } from 'generated-types';
import {
  DownloadTemplateButton,
  FileUploadDialog,
  UploadError,
  ValidationErrors
} from './components';
import { useUploadResultHandler } from './hooks';

const useStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.h5,
    padding: theme.spacing(8, 0),
    color: theme.palette.grey[800]
  },
  steps: {
    ...theme.typography.h6,
    fontSize: theme.spacing(1.815),
    fontWeight: 600,
    color: theme.palette.grey[700],
    padding: theme.spacing(2, 0)
  },
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '50%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    margin: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(11)
  },
  uploadBtn: {
    textTransform: (prop: {
      error: string | null;
      validationErrors: CsvValidationError[] | [];
    }): 'uppercase' | 'none' =>
      prop?.error || prop?.validationErrors.length ? 'uppercase' : 'none'
  },
  error: {
    background: '#fff5f4',
    width: '90%',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1.5, 1)
  }
}));

const attachmentIcon = <AttachmentSharpIcon />;

const FileUploadPage: React.FC = () => {
  const { open, openDialog, closeDialog } = useDialogState();
  const {
    error,
    validationErrors,
    onUploadFailure,
    onUploadSuccess
  } = useUploadResultHandler(closeDialog);
  const classes = useStyles({ error, validationErrors });
  const uploadFileClickHandler = useCallback(() => {
    openDialog();
  }, [openDialog]);

  return (
    <div>
      <div className={classes.root}>
        <Label className={classes.title} labelId="fileUploadPage.title" />
        <Label className={classes.steps} labelId="fileUploadPage.step1Title" />
        <DownloadTemplateButton />
        <Label className={classes.steps} labelId="fileUploadPage.step2Title" />
        {Boolean(error) && <UploadError />}
        {validationErrors.length ? (
          <ValidationErrors validationErrors={validationErrors} />
        ) : null}
        <Button
          className={classes.uploadBtn}
          variant="contained"
          color="primary"
          startIcon={attachmentIcon}
          onClick={uploadFileClickHandler}
          labelId={
            error || validationErrors.length
              ? 'fileUploadPage.reupload'
              : 'fileUploadPage.uploadCsv'
          }
        />
        {open && (
          <FileUploadDialog
            open={open}
            closeDialog={closeDialog}
            onUploadSuccess={onUploadSuccess}
            onUploadFailure={onUploadFailure}
          />
        )}
      </div>
    </div>
  );
};

export default FileUploadPage;
