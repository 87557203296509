import {
  useTableSettingsQuery,
  TableSetting,
  SortSetting
} from 'generated-types';

export const useTableSettings = ({
  tableKey,
  defaultColumns = [],
  defaultSort = null
}: {
  tableKey: string;
  defaultColumns: string[];
  defaultSort?: SortSetting | null;
}): {
  loading: boolean;
  tableSettings?: TableSetting | null;
} => {
  const { loading, data, error } = useTableSettingsQuery({
    variables: { tableKey }
  });

  if (
    !loading &&
    (error || !data?.tableSetting || data.tableSetting.columns.length === 0)
  ) {
    const tableSettings: TableSetting = {
      ...(data?.tableSetting ?? { id: tableKey })
    } as TableSetting;
    if (!data?.tableSetting?.columns.length) {
      tableSettings.columns = defaultColumns;
    }
    if (!data?.tableSetting?.sort) {
      tableSettings.sort = defaultSort;
    }
    return {
      loading,
      tableSettings: tableSettings
    };
  }

  return { loading, tableSettings: data?.tableSetting };
};
