import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { useDebounce } from 'react-use';
import { ClickAwayListener } from '@material-ui/core';
import { BillingSlider } from 'rateCard/components/UtilizationMarginCard/BillingSlider';
import {
  usePlanTotalContext,
  useRoleTableFormContext
} from 'plans/plan/context';
import { useTemporaryState } from 'common/hooks';

const UPDATE_DEBOUNCE_TIME = 25;

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%'
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#6A6A6A'
  }
}));

const useBillingSliderStyles = makeStyles(() => ({
  root: {
    padding: 0
  }
}));

const useSliderStyles = makeStyles(theme => ({
  root: {
    color: '#25D293',
    padding: 0
  },
  track: {
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5)
  },
  rail: {
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5)
  },
  thumb: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginTop: theme.spacing(-0.75),
    '&:hover': {
      boxShadow: 'none'
    }
  }
}));

export const GlobalUtilization: React.FC = () => {
  const classes = useStyles();
  const { utilization } = usePlanTotalContext();
  const { onGlobalUtilizationChangeHandler } = useRoleTableFormContext();

  const [
    localUtilization,
    setLocalUtilization,
    discardLocalUtilization
  ] = useTemporaryState(utilization);

  useDebounce(
    () => {
      if (localUtilization && !utilization.isEqualTo(localUtilization)) {
        onGlobalUtilizationChangeHandler(localUtilization.toString());
        discardLocalUtilization();
      }
    },
    UPDATE_DEBOUNCE_TIME,
    [localUtilization]
  );

  const [showSlider, setShowSlider] = useState(false);
  const [enableSlider, setEnableSlider] = useState(false);

  const onMouseEnter = useCallback(() => {
    setShowSlider(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    if (!enableSlider) {
      setShowSlider(false);
    }
  }, [enableSlider]);

  const onClick = useCallback(() => {
    setEnableSlider(true);
  }, []);

  const handleClickAway = useCallback(() => {
    setShowSlider(false);
    setEnableSlider(false);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={classes.paper}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}>
        <BillingSlider
          classes={useBillingSliderStyles()}
          sliderClasses={useSliderStyles()}
          value={utilization}
          labelId="planPage.planSummary.slider.utilization"
          onChange={useCallback(v => setLocalUtilization(new BigNumber(v)), [
            setLocalUtilization
          ])}
          max={99}
          disableSlider={!enableSlider}
          showSlider={showSlider}
        />
      </div>
    </ClickAwayListener>
  );
};
