import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { Label } from 'common/components';

const useStyles = makeStyles(theme => ({
  cancel: {
    color: theme.palette.grey[600]
  },
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: '0 0 20px 0'
  }
}));

export type DeleteConfirmationContentProps = {
  upcomingEffectiveDate?: string | null;
};

export const DeleteConfirmationContent: React.FC<DeleteConfirmationContentProps> = ({
  upcomingEffectiveDate
}: DeleteConfirmationContentProps) => {
  const classes = useStyles();
  const effectiveDate = useMemo(() => {
    if (upcomingEffectiveDate) {
      return DateTime.fromISO(upcomingEffectiveDate).toFormat('LLLL dd, yyyy');
    }
  }, [upcomingEffectiveDate]);
  return (
    <>
      <Label
        variant="body1"
        className={classes.info}
        labelId={
          upcomingEffectiveDate
            ? 'upcoming.deleteConfirmation.hasFutureCard.info1'
            : 'upcoming.deleteConfirmation.noFutureCard.info1'
        }
      />
      <Label
        variant="body1"
        className={classes.info}
        labelId={
          upcomingEffectiveDate
            ? 'upcoming.deleteConfirmation.hasFutureCard.info2'
            : 'upcoming.deleteConfirmation.noFutureCard.info2'
        }
      />
      {upcomingEffectiveDate && (
        <Label
          variant="body1"
          className={classes.info}
          labelId="upcoming.deleteConfirmation.hasFutureCard.info3">
          <b>{effectiveDate}</b>
        </Label>
      )}
    </>
  );
};
