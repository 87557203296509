import React, { useEffect, useState } from 'react';
import { useEmbeddedAppContext } from 'App/EmbeddedAppContext';
import StickyHeader, { StickyHeaderProps } from '../StickyHeader';

type PageHeaderProps = StickyHeaderProps;

/**
 * PageHeader should be included on every page to provide context to the user
 * so that they're able to determine where in the application they are. It contains
 * logic to ensure that the "default" app bar which is provided by PSA is hidden
 * and replaced with the CPO controlled app bar.
 */
const PageHeader: React.FC<PageHeaderProps> = props => {
  const { setAppHeaderVisible } = useEmbeddedAppContext();
  // Hide the PSA-provided header when we render, then show our own.
  const [showCPOHeader, setShowCPOHeader] = useState(false);
  useEffect(() => {
    if (showCPOHeader === false) {
      setAppHeaderVisible?.(false);
      setShowCPOHeader(true);
    }
  }, [setAppHeaderVisible, showCPOHeader]);

  return (
    <>
      <StickyHeader {...props} />
    </>
  );
};

export default PageHeader;
