import { useState, useCallback } from 'react';

/**
 * Similar to `useState`, but provides a third return value which resets
 * @param initialValue
 */
const useTemporaryState = <T>(
  initialValue: T
): [
  T | undefined,
  React.Dispatch<React.SetStateAction<T | undefined>>,
  () => void
] => {
  const [state, setState] = useState<T | undefined>(initialValue);
  const discardState = useCallback(() => setState(undefined), []);

  return [state, setState, discardState];
};

export default useTemporaryState;
