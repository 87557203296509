import React, { useCallback, useContext, useMemo, useState } from 'react';
import uuid from 'uuid';
import { Maybe } from 'generated-types';
import { useQueryArgs } from 'common/hooks';

export type PlanEditContextValue = {
  canEdit: boolean;
  enablePlanEdit: () => void;
  disablePlanEdit: () => void;
  resetPlan: () => void;
};

const PlanEditContext = React.createContext<Maybe<PlanEditContextValue>>(null);

export const usePlanEditContext = (): PlanEditContextValue => {
  const val = useContext(PlanEditContext);
  if (!val) {
    throw new Error(
      'usePlanEditContext must be called within a PlanEditContextProvider with a resolved PlanEdit'
    );
  }

  return val;
};

type PlanTotalContextProviderProps = {
  children: React.ReactNode;
};

export const PlanEditContextProvider: React.FC<PlanTotalContextProviderProps> = ({
  children
}: PlanTotalContextProviderProps) => {
  const searchParams = useQueryArgs();
  const [canEdit, setCanEdit] = useState<boolean>(
    searchParams.get('newPlan') === 'true'
  );
  const enablePlanEdit = useCallback(() => setCanEdit(true), [setCanEdit]);
  const disablePlanEdit = useCallback(() => {
    setCanEdit(false);
  }, [setCanEdit]);

  const [key, setKey] = useState(uuid.v4());
  const resetPlan = useCallback(() => setKey(uuid.v4()), [setKey]);
  return (
    <PlanEditContext.Provider
      key={key}
      value={useMemo(
        () => ({
          canEdit,
          disablePlanEdit,
          enablePlanEdit,
          resetPlan
        }),
        [canEdit, disablePlanEdit, enablePlanEdit, resetPlan]
      )}>
      {children}
    </PlanEditContext.Provider>
  );
};

export const PlanEditContextConsumer = PlanEditContext.Consumer;
