import React, { useCallback } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Label } from 'common/components';
import { useOverviewDateRangeContext } from 'overview/context/OverviewDateRangeContext';

const useStyles = makeStyles(theme => ({
  dateRange: {
    display: 'flex',
    height: 10
  },
  hyphen: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0.5, 0.5, 0, 0)
  },
  inputDate: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    width: 125
  }
}));
export const DatePickerAction: React.FC = () => {
  const classes = useStyles();
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate
  } = useOverviewDateRangeContext();
  const onStartDateChange = useCallback(
    (startDate: DateTime | null) => {
      startDate && setStartDate(startDate.toISO());
      startDate &&
        startDate > DateTime.fromISO(endDate) &&
        setEndDate(startDate?.toISO());
    },
    [endDate, setEndDate, setStartDate]
  );

  const onEndDateChange = useCallback(
    (endDate: DateTime | null) => {
      endDate && setEndDate(endDate.toISO());
    },
    [setEndDate]
  );
  return (
    <div className={classes.dateRange}>
      <DatePicker
        autoOk
        id="startDate"
        orientation="portrait"
        variant="inline"
        inputVariant="standard"
        value={startDate}
        onChange={date => onStartDateChange(date)}
        InputProps={{
          className: classes.inputDate
        }}
      />
      <Label className={classes.hyphen}>&mdash;</Label>
      <DatePicker
        autoOk
        id="endDate"
        orientation="portrait"
        variant="inline"
        inputVariant="standard"
        minDate={startDate}
        value={endDate}
        onChange={date => onEndDateChange(date)}
        InputProps={{
          className: classes.inputDate
        }}
      />
    </div>
  );
};
