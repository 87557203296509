import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import { useIntl } from 'App/MessageProvider';
import NotSupportedSVG from './mobileIcons.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: { lineHeight: 1 },
  notSupported: {},
  container: {
    margin: theme.spacing(4, 2),
    textAlign: 'center'
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    opacity: 0.5
  }
}));

const ScreenSizeNotSupportedPage: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="h6" className={classes.title}>
          <FormattedMessage id="notSupportedPage.title" />
        </Typography>
        <Typography component="p" variant="body1" className={classes.message}>
          <FormattedMessage id="notSupportedPage.description" />
        </Typography>
        <img
          src={NotSupportedSVG}
          className={classes.notSupported}
          alt={intl.formatMessage({ id: 'notSupportedPage.imageAlt' })}
        />
      </div>
    </div>
  );
};

export default ScreenSizeNotSupportedPage;
