import { useSearchSettingsQuery, SearchSettings } from 'generated-types';

export const useSearchSettings = ({
  key
}: {
  key: string;
}): {
  loading: boolean;
  searchSettings?: SearchSettings | null;
} => {
  const { loading, data, error } = useSearchSettingsQuery({
    variables: { key }
  });

  if (
    !loading &&
    (error ||
      !data?.searchSettings ||
      data.searchSettings.keywords?.length === 0)
  ) {
    const searchSettings: SearchSettings = {
      ...(data?.searchSettings ?? { id: key })
    } as SearchSettings;
    return {
      loading,
      searchSettings: searchSettings
    };
  }

  return { loading, searchSettings: data?.searchSettings };
};
