import React, { useContext, useMemo, useCallback, useRef } from 'react';
import BigNumber from 'bignumber.js';
import { OperationOverhead } from 'generated-types';
import { RoleData } from 'common/types';
import { CalculateAttribute } from './CalculateAttributeContext';

export type PlanStateItem = {
  billableState?: RoleData;
  businessCostState?: OperationOverhead[];
  calculateForItem?: CalculateAttribute;
  billingRatePercent?: BigNumber;
  popAgain?: boolean;
};

type PlanUndoContextValue = {
  history: PlanStateItem[];
  push: (item: PlanStateItem) => void;
  pop: () => PlanStateItem | undefined;
  reset: () => void;
};

export const PlanUndoContext = React.createContext<
  PlanUndoContextValue | undefined
>(undefined);

export const usePlanUndoContext = (): PlanUndoContextValue => {
  const value = useContext(PlanUndoContext);
  if (!value) {
    throw new Error('Must be called within a PlanUndoContext provider');
  }

  return value;
};

const useHistoryActions = (
  history: PlanStateItem[]
): {
  push: (item: PlanStateItem) => void;
  pop: () => PlanStateItem | undefined;
} => {
  const push = useCallback(
    (item: PlanStateItem) => {
      if (!item.popAgain) {
        history.push(item);
      } else {
        const topItem = history.pop();
        history.push({ ...topItem, ...item });
      }
    },
    [history]
  );

  const pop = useCallback(() => history.pop(), [history]);
  return { push, pop };
};

export const PlanUndoContextProvider: React.FC = props => {
  const { children } = props;
  const history = useRef<PlanStateItem[]>([]);

  const reset = useCallback(() => {
    history.current = [];
  }, []);

  const historyActions = useHistoryActions(history.current);

  const contextValue = useMemo<PlanUndoContextValue>(
    () => ({
      history: history.current,
      ...historyActions,
      reset
    }),
    [historyActions, reset]
  );

  return (
    <PlanUndoContext.Provider value={contextValue}>
      {children}
    </PlanUndoContext.Provider>
  );
};
