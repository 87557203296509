import { useCallback } from 'react';
import { TableColumnInstance } from 'common/types';
import { useSaveTableSettings } from 'common/hooks';

export const useColumnSelectionActions = ({
  tableKey,
  columns,
  defaultColumns,
  enabledFields,
  setEnabledFields,
  closeDialog
}: {
  tableKey: string;
  columns: TableColumnInstance[];
  defaultColumns: string[];
  enabledFields: Set<string>;
  setEnabledFields: React.Dispatch<React.SetStateAction<Set<string>>>;
  closeDialog: () => void;
}): {
  saving: boolean;
  pushTableSettings: () => Promise<void>;
  resetToDefault: () => Promise<void>;
  onChange: (id: string, checked: boolean) => void;
} => {
  const { saveTableSettings, saving } = useSaveTableSettings();
  const pushTableSettings = useCallback(async () => {
    await saveTableSettings(tableKey, Array.from(enabledFields));
    columns.forEach(cols =>
      cols.columns.forEach(column =>
        column.toggleHidden(!enabledFields.has(column.id))
      )
    );
    closeDialog();
  }, [closeDialog, columns, enabledFields, saveTableSettings, tableKey]);

  const resetToDefault = useCallback(async () => {
    setEnabledFields(new Set(defaultColumns));
  }, [defaultColumns, setEnabledFields]);

  const onChange = useCallback(
    (id: string, checked: boolean) => {
      if (checked) {
        setEnabledFields(new Set([...Array.from(enabledFields), id]));
      } else {
        setEnabledFields(
          new Set(Array.from(enabledFields).filter(field => field !== id))
        );
      }
    },
    [enabledFields, setEnabledFields]
  );
  return { saving, pushTableSettings, resetToDefault, onChange };
};
