import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label } from 'common/components';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
    background: '#fff5f4',
    width: '90%',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1.5, 1)
  }
}));

export interface UploadErrorProps {
  classes?: { [key: string]: string };
}

const UploadError: React.FC<UploadErrorProps> = ({
  classes
}: UploadErrorProps) => {
  const styles = useStyles({ classes });

  return (
    <div>
      <Label
        className={styles.error}
        labelId="fileUploadPage.fileUploadError"
      />
    </div>
  );
};

export default UploadError;
