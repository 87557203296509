import React from 'react';
import { CellProps } from 'react-table';
import { TooltipMoney } from 'common/components';
import { BillingByRoleData } from 'common/types';
import { usePlanTotalContext } from 'plans/plan/context';

export const CalculatedProfitFooter: React.FC<CellProps<
  BillingByRoleData
>> = () => {
  const { profit } = usePlanTotalContext();

  return (
    <TooltipMoney
      currencySymbol={profit?.currency?.symbol ?? ''}
      value={profit?.amount?.toNumber() ?? 0}
      placement="right"
    />
  );
};
