import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(4),
    justifyContent: 'space-evenly'
  },
  legends: {
    display: 'flex',
    paddingRight: theme.spacing(3),
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 4)
  }
}));

export const OverviewChartSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1" className={classes.legends}>
        <Skeleton width="20%" />
      </Typography>
      <div className={classes.root}>
        <Skeleton width="3%" height={250} variant="rect" />
        <Skeleton width="3%" height={20} variant="rect" />
        <Skeleton width="3%" height={150} variant="rect" />
        <Skeleton width="3%" height={300} variant="rect" />
        <Skeleton width="3%" height={60} variant="rect" />
        <Skeleton width="3%" height={40} variant="rect" />
        <Skeleton width="3%" height={160} variant="rect" />
        <Skeleton width="3%" height={120} variant="rect" />
        <Skeleton width="3%" height={100} variant="rect" />
        <Skeleton width="3%" height={10} variant="rect" />
        <Skeleton width="3%" height={80} variant="rect" />
        <Skeleton width="3%" height={200} variant="rect" />
      </div>
    </>
  );
};
