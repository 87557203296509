import React, { useCallback, useState, MutableRefObject, useMemo } from 'react';
import { makeStyles, Direction } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { FormattedMessage } from 'react-intl';
import { UnregisterCallback } from 'history';
import { DateTime } from 'luxon';
import { useActivePath } from 'active';
import {
  DeleteDataDialog,
  ProgressButton,
  UploadDiscardDialog,
  Label,
  Button
} from 'common/components';
import {
  useDialogState,
  useDiscardCostOptimizationResult,
  useTotalCostBreakdown,
  useRoleBreakdown
} from 'common/hooks';
import { useUploadPath } from 'fileUpload/useUploadPath';
import {
  UtilizationMarginCard,
  RateCardButtonRow,
  CommittingDialog
} from 'rateCard/components';
import { BillingByRoleData, FormData as RateCardFormData } from 'common/types';
import { RoleDataTable } from 'rateCard/components';
import {
  useCommitCostOptimizationResult,
  useSaveRateCard
} from 'billing/hooks';
import {
  calculateTotalRevenue,
  useBillingEditHandlers
} from 'rateCard/hooks/useBillingEditHandlers';
import { SmartBudgetResult } from 'generated-types';
import {
  resetFormDirtyState,
  useUtilizationMargin,
  useActiveForm
} from 'active/hooks';
import { DEFAULT_MARGIN, DEFAULT_UTILIZATION } from 'rateCard/helpers';
import { LockedColumnContextProvider } from 'common/context';
import { useDirection } from 'App/MuiThemeProvider/useDirection';
import { EffectiveDateDialog } from './components';

const useStyles = makeStyles(theme => ({
  summary: {
    margin: theme.spacing(2, 0, 0, 3),
    paddingLeft: theme.spacing(1.5),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(1)
    },
    paddingRight: (props: { direction: Direction }): number =>
      props.direction === 'rtl' ? theme.spacing(1.5) : theme.spacing(0)
  },
  footer: {
    marginTop: theme.spacing(2),
    backgroundColor: 'white',
    display: 'flex',
    position: 'sticky',
    bottom: 0,
    justifyContent: 'flex-end',
    zIndex: 5
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    margin: theme.spacing(0, 3, 0, 0),
    '& > div': {
      margin: theme.spacing(0, 1, 0, 1)
    }
  },
  summaryInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3)
  },
  buttonRow: {
    paddingLeft: theme.spacing(2)
  },
  fileName: {
    ...theme.typography.body2,
    fontWeight: 'bold',
    fontSize: 16,
    color: 'black',
    padding: theme.spacing(3.5, 0, 1.5, 3)
  }
}));

export interface UploadDataContentProps {
  costOptimizationResult: SmartBudgetResult;
  unblockRef: MutableRefObject<UnregisterCallback | undefined>;
  unblockNavigation: () => void;
}

export const UploadDataContent: React.FC<UploadDataContentProps> = ({
  costOptimizationResult,
  unblockRef,
  unblockNavigation
}: UploadDataContentProps) => {
  const {
    utilization,
    margin,
    handleUtilizationChange,
    handleMarginChange,
    handleTotalRevenueChange,
    reset,
    updateRefs
  } = useUtilizationMargin(
    costOptimizationResult.margin ?? DEFAULT_MARGIN,
    costOptimizationResult.utilization ?? DEFAULT_UTILIZATION,
    costOptimizationResult.roleCostBreakdown
  );

  const roleCostBreakdown = useRoleBreakdown(costOptimizationResult);
  const totalCostBreakdown = useTotalCostBreakdown(costOptimizationResult);
  const history = useHistory();
  const uploadRootPath = useUploadPath();
  const handleClick = useCallback(() => {
    unblockNavigation();
    history.replace(uploadRootPath);
  }, [history, unblockNavigation, uploadRootPath]);

  const activePath = useActivePath();
  const onSetRatesComplete = useCallback(() => {
    unblockNavigation();
    history.replace(activePath);
  }, [activePath, history, unblockNavigation]);

  const {
    commitSuccess,
    commitFailure,
    committing,
    commitCostOptimizationResult,
    handleSuccess,
    handleFailure
  } = useCommitCostOptimizationResult({
    id: costOptimizationResult.id ?? '',
    utilization,
    margin,
    onHandleSuccess: onSetRatesComplete
  });

  const [billingData, setBillingData] = useState<BillingByRoleData[]>([]);
  const [lockedColumn, setLockedColumn] = useState<keyof RateCardFormData>();

  const {
    setValue,
    setRoleData,
    getAccurateValues,
    setRates,
    getValues,
    formState,
    control
  } = useActiveForm(
    roleCostBreakdown ?? [],
    utilization,
    margin,
    lockedColumn,
    updateRefs,
    setBillingData,
    commitCostOptimizationResult
  );

  const { dirty, dirtyFields } = formState;

  const onSaveComplete = useCallback(() => resetFormDirtyState(control), [
    control
  ]);
  const [
    saveRateCard,
    { loading: putRoleBreakdownParametersLoading }
  ] = useSaveRateCard({
    costOptimizationResult: costOptimizationResult,
    dirtyFields: dirtyFields,
    onSaveComplete: onSaveComplete,
    getFormValues: getValues
  });

  const {
    open: discardOpen,
    openDialog: discardOpenDialog,
    closeDialog: discardCloseDialog
  } = useDialogState();

  const {
    open: effectiveDateOpen,
    openDialog: effectiveDateOpenDialog,
    closeDialog: effectiveDateCloseDialog
  } = useDialogState();

  const {
    open: committingDialogOpen,
    openDialog: committingDialogOpenDialog,
    closeDialog: committingDialogCloseDialog
  } = useDialogState();

  const commitRates = useCallback(
    (startDate: DateTime, endDate: DateTime) => {
      committingDialogOpenDialog();
      setRates(startDate, endDate);
    },
    [committingDialogOpenDialog, setRates]
  );
  const {
    discarding,
    discardCostOptimizationResult
  } = useDiscardCostOptimizationResult(
    costOptimizationResult.id ?? '',
    handleClick
  );

  const totalRevenue = useMemo(
    () => calculateTotalRevenue(billingData, getValues()),
    [billingData, getValues]
  );

  const {
    onUtilizationChangeHandler,
    onMarginChangeHandler,
    onBillingRateChangeHandler,
    onRevenueChangeHandler
  } = useBillingEditHandlers(
    billingData,
    setValue,
    getAccurateValues,
    costOptimizationResult.totalCost?.amount ?? new BigNumber(0),
    handleMarginChange,
    handleUtilizationChange,
    setRoleData,
    handleTotalRevenueChange,
    lockedColumn
  );
  const direction = useDirection();
  const classes = useStyles({ direction });

  return (
    <LockedColumnContextProvider
      canLockColumns={true}
      setLockedColumn={setLockedColumn}
      lockedColumn={lockedColumn}>
      <div className={classes.summary}>
        <Label className={classes.fileName}>
          {costOptimizationResult.fileName ?? ''}
        </Label>
        <RateCardButtonRow
          costOptimizationResultId={costOptimizationResult.id}
          className={classes.buttonRow}
        />
        <div className={classes.summaryInfo}>
          <UtilizationMarginCard
            utilization={utilization}
            handleUtilizationChange={handleUtilizationChange}
            margin={margin}
            handleMarginChange={handleMarginChange}
            revert={reset}
            totalCost={costOptimizationResult.totalCost}
            totalRevenue={totalRevenue}
            handleTotalRevenueChange={handleTotalRevenueChange}
            loadedCost={
              totalCostBreakdown?.laborRate?.amount ?? new BigNumber(0)
            }
            totalHours={
              totalCostBreakdown?.totalBillableHours ?? new BigNumber(0)
            }
          />
          <div className={classes.buttonContainer}>
            <ProgressButton
              variant="contained"
              color="default"
              loading={discarding}
              disable={committing || commitSuccess}
              onClick={discardOpenDialog}>
              <FormattedMessage id="costPage.discard" />
            </ProgressButton>
            <ProgressButton
              variant="contained"
              color="default"
              loading={putRoleBreakdownParametersLoading}
              disabled={
                !dirty ||
                putRoleBreakdownParametersLoading ||
                committing ||
                commitSuccess
              }
              onClick={saveRateCard as () => Promise<void>}
              labelId="activePage.saveRates"
              successSnackbarLabelId="activePage.saveRatesSuccess"
              failureSnackbarLabelId="activePage.saveRatesFailure"
            />
            <Button
              variant="contained"
              color="primary"
              disabled={discarding || commitSuccess}
              onClick={effectiveDateOpenDialog}
              labelId="billingPage.commit"
            />
          </div>
        </div>
      </div>
      <RoleDataTable
        editing={true}
        canNavigateToRolePage={true}
        dirty={dirty}
        billingData={billingData}
        totalCostBreakdown={totalCostBreakdown}
        getValues={getValues}
        totalRevenue={totalRevenue}
        margin={margin}
        utilization={utilization}
        saveRates={saveRateCard as () => Promise<void>}
        onUtilizationChangeHandler={onUtilizationChangeHandler}
        onMarginChangeHandler={onMarginChangeHandler}
        onBillingRateChangeHandler={onBillingRateChangeHandler}
        onRevenueChangeHandler={onRevenueChangeHandler}
      />
      <DeleteDataDialog
        open={discardOpen}
        closeDialog={discardCloseDialog}
        onDiscardClick={discardCostOptimizationResult}
      />
      <UploadDiscardDialog
        id={costOptimizationResult.id ?? ''}
        unblockRef={unblockRef}
      />
      {effectiveDateOpen && (
        <EffectiveDateDialog
          open={effectiveDateOpen}
          closeDialog={effectiveDateCloseDialog}
          commitRates={commitRates}
        />
      )}
      {committingDialogOpen && (
        <CommittingDialog
          open={committingDialogOpen}
          closeDialog={committingDialogCloseDialog}
          committing={committing}
          commitSuccess={commitSuccess}
          commitFailure={commitFailure}
          handleSuccess={handleSuccess}
          handleFailure={handleFailure}
        />
      )}
    </LockedColumnContextProvider>
  );
};
