import React from 'react';
import { TableRow, makeStyles, TableHead } from '@material-ui/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import BigNumber from 'bignumber.js';
import { TotalCostBreakdown } from 'common/types';
import { RoleBreakdown } from 'generated-types';
import {
  RoleSummaryTableGlobalHeader,
  RoleSummaryTableTotalsHeader
} from './components';

const useStyles = makeStyles(theme => ({
  spacer: {
    height: theme.spacing(2.5),
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

type RoleSummaryTableHeaderProps = {
  totalCostBreakdown: Maybe<TotalCostBreakdown>;
  role: Maybe<RoleBreakdown>;
  cellClasses?: ClassNameMap;
  utilization: BigNumber;
  margin: BigNumber;
};
export const RoleSummaryTableHeader: React.FC<RoleSummaryTableHeaderProps> = ({
  role,
  totalCostBreakdown,
  cellClasses,
  margin,
  utilization
}: RoleSummaryTableHeaderProps) => {
  const classes = useStyles();
  return (
    <>
      <RoleSummaryTableGlobalHeader
        totalCostBreakdown={totalCostBreakdown}
        cellClasses={cellClasses}
      />
      <TableHead>
        <TableRow className={classes.spacer} />
      </TableHead>
      <RoleSummaryTableTotalsHeader
        role={role}
        cellClasses={cellClasses}
        margin={role?.margin ?? margin}
        utilization={role?.utilization ?? utilization}
      />
    </>
  );
};

export default RoleSummaryTableHeader;
