import { useMeContext } from 'App/Me';
import { FeatureFlags } from 'generated-types';

export const useFeatureFlag = (
  key: keyof Omit<FeatureFlags, '__typename'>,
  defaultValue = false
): boolean => {
  const me = useMeContext();
  return me.featureFlags?.[key] ?? defaultValue;
};
