import React, { useContext, useMemo } from 'react';
import { TableSetting, Maybe } from 'generated-types';

export const TableSettingsContext = React.createContext<Set<string>>(new Set());

export const useEnableColumnsContext = (): Set<string> => {
  const val = useContext(TableSettingsContext);
  if (!val) {
    throw new Error(
      'useBillableResourceTableSettingsContext must be called within a BillableResourceTableSettingsContextProvider with a resolved TableSettings.'
    );
  }

  return val;
};

type TableSettingsProviderProps = {
  tableSettings?: Maybe<TableSetting>;
};
export const TableSettingsContextProvider: React.FC<TableSettingsProviderProps> = ({
  children,
  tableSettings
}: React.PropsWithChildren<TableSettingsProviderProps>) => {
  const value = useMemo(() => new Set(tableSettings?.columns ?? []), [
    tableSettings
  ]);
  return (
    <TableSettingsContext.Provider value={value}>
      {children}
    </TableSettingsContext.Provider>
  );
};

export const TableSettingsContextConsumer = TableSettingsContext.Consumer;
