import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { ProgressButton, EditConfirmationDialog } from 'common/components';
import { useDialogState } from 'common/hooks';
import { CostOptimizationResult } from 'generated-types';
import { useRouteBase } from 'App/EmbeddedAppContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 4, 5, 4),
    '& > *': {
      margin: theme.spacing(0, 2, 0, 2)
    }
  }
}));

export type ActiveActionsProps = {
  cloning: boolean;
  cloneRateCard: () => Promise<CostOptimizationResult | undefined>;
  editBasePath?: string;
  editSubpath?: string;
};

export const ActiveActions: React.FC<ActiveActionsProps> = ({
  cloning,
  cloneRateCard,
  editBasePath = '/active',
  editSubpath = ''
}: ActiveActionsProps) => {
  const classes = useStyles();
  const {
    open: editConfirmationOpen,
    openDialog: editConfirmationOpenDialog,
    closeDialog: editConfirmationCloseDialog
  } = useDialogState();
  const routeBase = useRouteBase();
  const history = useHistory();
  const onEditClick = useCallback(async () => {
    editConfirmationCloseDialog();
    const clonedRateCard = await cloneRateCard();
    if (clonedRateCard) {
      history.push(
        `${routeBase}${editBasePath}/edit/${clonedRateCard.id}${editSubpath}`
      );
    }
  }, [
    cloneRateCard,
    editBasePath,
    editConfirmationCloseDialog,
    editSubpath,
    history,
    routeBase
  ]);

  return (
    <div className={classes.root}>
      <EditConfirmationDialog
        open={editConfirmationOpen}
        closeDialog={editConfirmationCloseDialog}
        onEditClick={onEditClick}
      />
      <ProgressButton
        color="primary"
        variant="contained"
        labelId="activePage.editRates"
        onClick={editConfirmationOpenDialog}
        loading={cloning}
      />
    </div>
  );
};
