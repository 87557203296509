import { useCallback } from 'react';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { useIntl } from 'App/MessageProvider';

export const usePaginationLabelFn = (): ((
  options: LabelDisplayedRowsArgs
) => string) => {
  const intl = useIntl();

  return useCallback(
    ({ from, to, count }: LabelDisplayedRowsArgs) => {
      return intl.formatMessage(
        { id: 'table.paginationMsg' },
        { from, to, count }
      );
    },
    [intl]
  );
};
