import React, { useCallback, useState, MutableRefObject } from 'react';
import { makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import BigNumber from 'bignumber.js';
import { UnregisterCallback } from 'history';
import { SmartBudgetResult } from 'generated-types';
import {
  useRoleBreakdown,
  useTotalCostBreakdown,
  useDiscardCostOptimizationResult,
  useDialogState
} from 'common/hooks';
import { useBillingEditHandlers } from 'rateCard/hooks';
import { calculateTotalRevenue } from 'rateCard/hooks/useBillingEditHandlers';
import {
  useUtilizationMargin,
  useActiveForm,
  resetFormDirtyState
} from 'active/hooks';
import { DEFAULT_MARGIN, DEFAULT_UTILIZATION } from 'rateCard/helpers';
import {
  useCommitCostOptimizationResult,
  useSaveRateCard
} from 'billing/hooks';
import { BillingByRoleData, FormData as RateCardFormData } from 'common/types';
import { UtilizationMarginCard } from 'rateCard/components';
import {
  FileUploadedDate,
  UploadDiscardDialog,
  DeleteDataDialog
} from 'common/components';
import { RateCardButtonRow, CommittingDialog } from 'rateCard/components';
import { RoleDataTable } from 'rateCard/components';
import { LockedColumnContextProvider } from 'common/context';
import { EditRateCardActions } from './components';

type RateCardEditCardProps = {
  costOptimizationResult: SmartBudgetResult;
  navigateToActivePath: () => void;
  unblockRef: MutableRefObject<UnregisterCallback | undefined>;
};

const useStyles = makeStyles(theme => ({
  buttonRow: {
    paddingLeft: theme.spacing(5.5)
  }
}));

const useMarginUtilizationStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1.5, 0, 3, 6.5),
    width: '35%'
  }
}));

export const RateCardEditCard: React.FC<RateCardEditCardProps> = ({
  unblockRef,
  costOptimizationResult,
  navigateToActivePath
}: RateCardEditCardProps) => {
  const classes = useStyles();
  const marginUtilizationClasses = useMarginUtilizationStyles();
  const roleCostBreakdown = useRoleBreakdown(costOptimizationResult);
  const totalCostBreakdown = useTotalCostBreakdown(costOptimizationResult);

  const {
    utilization,
    margin,
    handleUtilizationChange,
    handleMarginChange,
    handleTotalRevenueChange,
    reset,
    updateRefs
  } = useUtilizationMargin(
    costOptimizationResult.margin ?? DEFAULT_MARGIN,
    costOptimizationResult.utilization ?? DEFAULT_UTILIZATION,
    costOptimizationResult.roleCostBreakdown
  );

  const {
    discarding,
    discardCostOptimizationResult
  } = useDiscardCostOptimizationResult(
    costOptimizationResult.id ?? '',
    navigateToActivePath
  );

  const {
    handleFailure,
    handleSuccess,
    commitCostOptimizationResult,
    committing,
    commitFailure,
    commitSuccess
  } = useCommitCostOptimizationResult({
    id: costOptimizationResult.id,
    utilization,
    margin,
    onHandleSuccess: navigateToActivePath
  });

  const [billingData, setBillingData] = useState<BillingByRoleData[]>([]);
  const [lockedColumn, setLockedColumn] = useState<keyof RateCardFormData>();

  const {
    getAccurateValues,
    setValue,
    setRoleData,
    setRates,
    getValues,
    formState,
    control
  } = useActiveForm(
    roleCostBreakdown ?? [],
    utilization,
    margin,
    lockedColumn,
    updateRefs,
    setBillingData,
    commitCostOptimizationResult
  );

  const { dirty, dirtyFields } = formState;

  const onSaveComplete = useCallback(() => resetFormDirtyState(control), [
    control
  ]);

  const [
    saveRateCard,
    { loading: putRoleBreakdownParametersLoading }
  ] = useSaveRateCard({
    costOptimizationResult: costOptimizationResult,
    dirtyFields: dirtyFields,
    onSaveComplete: onSaveComplete,
    getFormValues: getAccurateValues
  });

  const {
    open: discardOpen,
    openDialog: discardOpenDialog,
    closeDialog: discardCloseDialog
  } = useDialogState();

  const {
    open: committingDialogOpen,
    openDialog: committingDialogOpenDialog,
    closeDialog: committingDialogCloseDialog
  } = useDialogState();

  const commitRateCard = useCallback(
    async (endDate: DateTime) => {
      committingDialogOpenDialog();
      await setRates(
        DateTime.fromISO(costOptimizationResult.effectiveDate as string),
        endDate
      );
    },
    [committingDialogOpenDialog, costOptimizationResult.effectiveDate, setRates]
  );

  const totalRevenue = calculateTotalRevenue(billingData, getValues());

  const {
    onUtilizationChangeHandler,
    onMarginChangeHandler,
    onBillingRateChangeHandler,
    onRevenueChangeHandler
  } = useBillingEditHandlers(
    billingData,
    setValue,
    getAccurateValues,
    costOptimizationResult.totalCost?.amount ?? new BigNumber(0),
    handleMarginChange,
    handleUtilizationChange,
    setRoleData,
    handleTotalRevenueChange,
    lockedColumn
  );

  return (
    <LockedColumnContextProvider
      canLockColumns={true}
      setLockedColumn={setLockedColumn}
      lockedColumn={lockedColumn}>
      <FileUploadedDate
        filename={costOptimizationResult.fileName ?? ''}
        uploadDate={costOptimizationResult.lastModified ?? ''}
        effectiveDate={costOptimizationResult.effectiveDate ?? ''}
        endDate={costOptimizationResult.endDate ?? ''}
      />
      <RateCardButtonRow
        costOptimizationResultId={costOptimizationResult.id}
        className={classes.buttonRow}
      />
      <UtilizationMarginCard
        classes={marginUtilizationClasses}
        utilization={utilization}
        handleUtilizationChange={handleUtilizationChange}
        margin={margin}
        handleMarginChange={handleMarginChange}
        revert={reset}
        totalCost={costOptimizationResult.totalCost}
        totalRevenue={totalRevenue}
        handleTotalRevenueChange={handleTotalRevenueChange}
        loadedCost={totalCostBreakdown?.laborRate?.amount ?? new BigNumber(0)}
        totalHours={totalCostBreakdown?.totalBillableHours ?? new BigNumber(0)}
      />

      {billingData && (
        <>
          <RoleDataTable
            editing={true}
            canNavigateToRolePage={true}
            dirty={dirty}
            billingData={billingData}
            totalCostBreakdown={totalCostBreakdown}
            getValues={getValues}
            totalRevenue={totalRevenue}
            utilization={utilization}
            margin={margin}
            saveRates={saveRateCard as () => Promise<void>}
            onUtilizationChangeHandler={onUtilizationChangeHandler}
            onMarginChangeHandler={onMarginChangeHandler}
            onBillingRateChangeHandler={onBillingRateChangeHandler}
            onRevenueChangeHandler={onRevenueChangeHandler}
          />
          <EditRateCardActions
            discarding={discarding}
            committing={committing}
            saving={putRoleBreakdownParametersLoading}
            canSaveRateCard={dirty}
            effectiveDate={costOptimizationResult.effectiveDate as string}
            endDate={costOptimizationResult.endDate}
            cancelEditing={discardOpenDialog}
            commitRateCard={commitRateCard}
            saveRateCard={saveRateCard}
          />
          <DeleteDataDialog
            open={discardOpen}
            closeDialog={discardCloseDialog}
            onDiscardClick={discardCostOptimizationResult}
            variant={'edit'}
          />
          <UploadDiscardDialog
            id={costOptimizationResult.id ?? ''}
            unblockRef={unblockRef}
          />
          {committingDialogOpen && (
            <CommittingDialog
              open={committingDialogOpen}
              closeDialog={committingDialogCloseDialog}
              committing={committing}
              commitSuccess={commitSuccess}
              commitFailure={commitFailure}
              handleSuccess={handleSuccess}
              handleFailure={handleFailure}
            />
          )}
        </>
      )}
    </LockedColumnContextProvider>
  );
};

export default RateCardEditCard;
