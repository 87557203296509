import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Button, Label, ProgressButton } from 'common/components';
import { useIsBreakpointDown } from 'common/hooks';
import { PlanStatus, Maybe } from 'generated-types';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: '0 0 20px 0'
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1.5)
  }
}));
type RemovePlanConfirmationDialogProps = {
  open: boolean;
  discarding: boolean;
  closeDialog: () => void;
  onDeleteClick: () => void;
  planStatus?: Maybe<PlanStatus>;
};

export const RemovePlanConfirmationDialog: React.FC<RemovePlanConfirmationDialogProps> = ({
  open,
  discarding,
  closeDialog,
  onDeleteClick,
  planStatus
}: RemovePlanConfirmationDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}
      classes={useDialogStyles()}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Label variant="h6" labelId="planMoreActions.removePlan" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {planStatus === PlanStatus.Active && (
          <Label
            variant="body1"
            className={classes.info}
            labelId={'planMoreActions.deletePlanConfirmation'}
          />
        )}
        <Label
          variant="body1"
          className={classes.info}
          labelId={'planMoreActions.removePlanConfirmation'}
        />
      </DialogContent>
      <DialogActions className={classes.dialogContent}>
        <Button
          onClick={closeDialog}
          labelId="planMoreActions.cancel"
          disabled={discarding}
        />
        <ProgressButton
          autoFocus
          variant="text"
          color="secondary"
          loading={discarding}
          disable={discarding}
          onClick={onDeleteClick}>
          <FormattedMessage id="planMoreActions.removePlan" />
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};
