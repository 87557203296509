import React, { useState, useMemo } from 'react';
import {
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Direction
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { RoleBreakdown, SmartBudgetResult } from 'generated-types';
import {
  useRoleBreakdown,
  useTotalCostBreakdown,
  usePaginationLabelFn
} from 'common/hooks';
import { BillingByRoleData } from 'common/types';
import { calculateRevenueForRole } from 'rateCard/helpers';
import {
  RoleDataTableHeader,
  RoleDataTableTotals,
  RoleDataTableRow,
  RoleTableColumns
} from 'rateCard/components';
import { useDirection } from 'App/MuiThemeProvider/useDirection';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: theme.spacing(170),
    tableLayout: 'fixed'
  }
}));
const useCellStyle = makeStyles(theme => ({
  root: {
    '&:last-child': {
      paddingRight: theme.spacing(3)
    },
    '&:first-child': {
      paddingLeft: theme.spacing(4.5)
    },
    '&:nth-child(2)': {
      [theme.breakpoints.down('lg')]: {
        paddingRight: (props: { direction: Direction }): number =>
          props.direction === 'ltr' ? theme.spacing(4) : theme.spacing(1)
      },
      paddingRight: (props: { direction: Direction }): number =>
        props.direction === 'ltr' ? theme.spacing(3.5) : theme.spacing(1)
    },
    '&:nth-child(3)': {
      [theme.breakpoints.down('lg')]: {
        paddingRight: (props: { direction: Direction }): number =>
          props.direction === 'rtl' ? theme.spacing(4) : theme.spacing(1)
      },
      paddingRight: (props: { direction: Direction }): number =>
        props.direction === 'rtl' ? theme.spacing(3.5) : theme.spacing(1)
    },
    '&:nth-child(7)': {
      paddingRight: (props: { direction: Direction }): number =>
        props.direction === 'ltr' ? theme.spacing(3.5) : theme.spacing(1)
    },
    '&:nth-child(8)': {
      paddingRight: (props: { direction: Direction }): number =>
        props.direction === 'rtl' ? theme.spacing(3.5) : theme.spacing(1)
    }
  }
}));

const getRoleBreakdown = (
  roleBreakdown: RoleBreakdown[]
): BillingByRoleData[] =>
  roleBreakdown &&
  roleBreakdown.map(role => {
    return {
      id: role.id,
      roleName: role?.role?.displayText ?? '',
      roleId: role?.role?.id ?? '',
      totalHours: new BigNumber(role?.totalHours ?? 0),
      standardRate:
        role && role.employeeCost
          ? role.employeeCost
          : { amount: new BigNumber(0) },
      nonBillableOverhead:
        role && role.nonBillableOverhead
          ? role.nonBillableOverhead
          : { amount: new BigNumber(0) },
      businessOverhead:
        role && role.businessOverhead
          ? role.businessOverhead
          : { amount: new BigNumber(0) },
      loadedCost:
        role && role.loadedCost
          ? role.loadedCost
          : { amount: new BigNumber(0) },
      utilization: role.utilization ?? new BigNumber(0),
      margin: role.margin ?? new BigNumber(0),
      billingRate:
        role && role.billingCost
          ? role.billingCost
          : { amount: new BigNumber(0) },
      totalCost: role.totalCost ?? { amount: new BigNumber(0) },
      revenue: {
        amount: calculateRevenueForRole(
          role?.billingCost?.amount ?? new BigNumber(0),
          role.utilization ?? new BigNumber(0),
          role.totalHours ?? new BigNumber(0)
        ),
        currency: role?.loadedCost?.currency
      }
    };
  });

type HistoryExpansionDetailsSummaryTableProps = {
  costOptimizationResult: SmartBudgetResult;
  onNavigationHandler?: (roleUri: string) => void;
};
export const HistoryExpansionDetailsSummaryTable: React.FC<HistoryExpansionDetailsSummaryTableProps> = ({
  costOptimizationResult,
  onNavigationHandler
}: HistoryExpansionDetailsSummaryTableProps) => {
  const direction = useDirection();
  const classes = useStyles();
  const cellClasses = useCellStyle({ direction });
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const roleCostBreakdown = useRoleBreakdown(costOptimizationResult);
  const roleBreakdowns = useMemo(() => {
    if (roleCostBreakdown) {
      return getRoleBreakdown(roleCostBreakdown);
    }
  }, [roleCostBreakdown]);
  const totalCostBreakdown = useTotalCostBreakdown(costOptimizationResult);

  return (
    <>
      <TableContainer>
        <Table className={classes.root}>
          <RoleTableColumns />
          <RoleDataTableHeader cellClasses={cellClasses} />
          <RoleDataTableTotals
            cellClasses={cellClasses}
            totalCostBreakdown={totalCostBreakdown}
            utilization={costOptimizationResult.utilization ?? new BigNumber(0)}
            margin={costOptimizationResult.margin ?? new BigNumber(0)}
            totalRevenue={
              costOptimizationResult.totalRevenue?.amount ?? new BigNumber(0)
            }
          />
          <TableBody>
            {roleBreakdowns
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(roleBreakdown => {
                return (
                  <RoleDataTableRow
                    cellClasses={cellClasses}
                    key={roleBreakdown?.id}
                    roleBreakdown={roleBreakdown}
                    onNavigationHandler={onNavigationHandler}
                    canNavigateToRolePage={true}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        labelDisplayedRows={usePaginationLabelFn()}
        count={costOptimizationResult.roleCostBreakdown?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
      />
    </>
  );
};

export default HistoryExpansionDetailsSummaryTable;
