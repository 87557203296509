import React, { createContext, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

type EmbeddedAppContextData = {
  baseRoute: string;
  isEmbedded?: boolean;
  setAppHeaderVisible?: (visible: boolean) => void;
};

export const EmbeddedAppContext = createContext<EmbeddedAppContextData>({
  baseRoute: '',
  isEmbedded: false,
  setAppHeaderVisible: (): null => null
});

type EmbeddedAppProviderProps = EmbeddedAppContextData & {
  children: React.ReactNode;
};

export const EmbeddedAppProvider: React.FC<EmbeddedAppProviderProps> = ({
  children,
  baseRoute,
  setAppHeaderVisible
}) => {
  const contextValue: EmbeddedAppContextData = useMemo(
    () => ({
      setAppHeaderVisible: setAppHeaderVisible,
      isEmbedded: Boolean(setAppHeaderVisible),
      baseRoute: baseRoute
    }),
    [baseRoute, setAppHeaderVisible]
  );

  return (
    <EmbeddedAppContext.Provider value={contextValue}>
      {children}
    </EmbeddedAppContext.Provider>
  );
};

export const useEmbeddedAppContext = (): EmbeddedAppContextData => {
  const contextValue = useContext(EmbeddedAppContext);

  return contextValue;
};

EmbeddedAppProvider.propTypes = {
  baseRoute: PropTypes.string.isRequired,
  setAppHeaderVisible: PropTypes.func
};
