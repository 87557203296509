import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { useDebounce } from 'react-use';
import { BillingSlider } from 'rateCard/components/UtilizationMarginCard/BillingSlider';
import { useLockedColumnContext } from 'common/context';
import { useMessage } from 'App/MessageProvider';
import { useTemporaryState } from 'common/hooks';

const UPDATE_DEBOUNCE_TIME = 25;

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%'
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#6A6A6A'
  }
}));

const useBillingSliderStyles = makeStyles(() => ({
  root: {
    padding: 0
  }
}));

const useSliderStyles = makeStyles(theme => ({
  root: {
    color: '#25D293',
    padding: 0
  },
  track: {
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5)
  },
  rail: {
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5)
  },
  thumb: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginTop: theme.spacing(-0.75),
    '&:hover': {
      boxShadow: 'none'
    }
  }
}));

type GlobalSliderProps = {
  labelId: string;
  value: BigNumber;
  onChangeHandler: (value: string) => void;
};

export const GlobalSlider: React.FC<GlobalSliderProps> = ({
  labelId,
  value,
  onChangeHandler
}: GlobalSliderProps) => {
  const classes = useStyles();
  const [localValue, setLocalValue, discardLocalValue] = useTemporaryState(
    value
  );
  const { lockedColumn } = useLockedColumnContext();
  const label = useMessage(labelId);
  const isLocked = useMemo(() => lockedColumn === label, [label, lockedColumn]);

  useDebounce(
    () => {
      if (localValue && !value.isEqualTo(localValue)) {
        onChangeHandler(localValue.toString());
        discardLocalValue();
      }
    },
    UPDATE_DEBOUNCE_TIME,
    [localValue]
  );

  return (
    <div className={classes.paper}>
      <BillingSlider
        classes={useBillingSliderStyles()}
        sliderClasses={useSliderStyles()}
        value={value}
        labelId={labelId}
        onChange={useCallback(v => setLocalValue(new BigNumber(v)), [
          setLocalValue
        ])}
        max={99}
        disableSlider={isLocked}
        showSlider={true}
        disabled={isLocked}
      />
    </div>
  );
};
