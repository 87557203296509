import React, { useCallback, useEffect, useMemo } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  CalculateAttribute,
  useCalculateAttributeContext,
  usePlanUndoContext
} from 'plans/plan/context';
import { Label } from 'common/components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingRight: theme.spacing(2)
  },
  label: {
    fontSize: theme.spacing(2.25),
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: theme.spacing(2),
    alignSelf: 'center'
  },
  button: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 2),
    textTransform: 'none',
    '&$btnSelected': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main
    }
  },
  btnSelected: {}
}));

export const CALCULATE_ATTRIBUTES: CalculateAttribute[] = [
  'utilization',
  'billingRate',
  'margin'
];

export const CalculateAttributeButton: React.FC = () => {
  const classes = useStyles();
  const buttonStyles = useMemo(
    () => ({ root: classes.button, selected: classes.btnSelected }),
    [classes.btnSelected, classes.button]
  );

  const {
    calculateAttribute,
    setCalculateAttribute,
    setLockedColumns
  } = useCalculateAttributeContext();
  const { push } = usePlanUndoContext();
  const handleClick = useCallback(
    (
      _: React.MouseEvent<HTMLElement>,
      newAttribute: CalculateAttribute | null
    ) => {
      if (newAttribute) {
        push({ calculateForItem: calculateAttribute });
        setCalculateAttribute(newAttribute);
        setLockedColumns(
          CALCULATE_ATTRIBUTES.filter(item => newAttribute !== item)
        );
      }
    },
    [calculateAttribute, push, setCalculateAttribute, setLockedColumns]
  );

  useEffect(() => {
    setLockedColumns(
      CALCULATE_ATTRIBUTES.filter(item => calculateAttribute !== item)
    );
  }, [calculateAttribute, setLockedColumns]);
  return (
    <div className={classes.root}>
      <Label
        className={classes.label}
        variant="body1"
        labelId="planPage.calculateFor"
      />
      <ToggleButtonGroup
        value={calculateAttribute}
        onChange={handleClick}
        exclusive
        size="small"
        color="primary">
        {CALCULATE_ATTRIBUTES.map(attribute => (
          <ToggleButton
            key={attribute}
            value={attribute}
            color="primary"
            classes={buttonStyles}>
            <FormattedMessage id={`planPage.planningForBtn.${attribute}`} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};
