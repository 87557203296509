import { DateTime } from 'luxon';

export const getFormattedDate = (
  format: string,
  locale?: string | null,
  date?: string | null
): string | undefined => {
  if (date) {
    return DateTime.fromISO(date)
      .setLocale(locale ?? 'en-us')
      .toFormat(format);
  }
};
