import React, { useCallback } from 'react';
import {
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'App/MessageProvider';
import { useDialogState, useMenuState, usePath } from 'common/hooks';
import { PlanStatus, Maybe } from 'generated-types';
import { RemovePlanConfirmationDialog } from './components/RemovePlanConfirmationDialog';
import { useRemovePlan } from './hooks';

const useStyles = makeStyles(theme => ({
  moreActionsButton: {
    padding: theme.spacing(0.75),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

type PlanMoreActionsProps = {
  planId?: string;
  planStatus?: Maybe<PlanStatus>;
};

const PlanMoreActions: React.FC<PlanMoreActionsProps> = ({
  planId,
  planStatus
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const isOpen = Boolean(anchorEl);
  const { closeDialog, open: isDialogOpen, openDialog } = useDialogState();
  const history = useHistory();
  const path = usePath('plans');
  const onDeleteSuccess = useCallback(() => {
    closeDialog();
    history.replace(path);
  }, [closeDialog, history, path]);

  const onRemovePlanClick = useCallback(() => {
    onMenuClose();
    openDialog();
  }, [onMenuClose, openDialog]);

  const { removing, removePlan } = useRemovePlan(
    onDeleteSuccess,
    planId as string,
    planStatus
  );

  return (
    <>
      <IconButton
        className={classes.moreActionsButton}
        onClick={onMenuClick}
        aria-haspopup="true"
        data-qe-id="PlanMoreActionsButton"
        aria-label={intl.formatMessage({
          id: 'planMoreActions.moreActions'
        })}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isOpen}
        onClose={onMenuClose}>
        <MenuItem onClick={onRemovePlanClick} data-qe-id="RemovePlanButton">
          <Typography color="secondary" variant="body2">
            <FormattedMessage id="planMoreActions.removePlan" />
          </Typography>
        </MenuItem>
      </Menu>
      {isDialogOpen && (
        <RemovePlanConfirmationDialog
          closeDialog={closeDialog}
          open={isDialogOpen}
          discarding={removing}
          onDeleteClick={removePlan}
          planStatus={planStatus}
        />
      )}
    </>
  );
};

export default PlanMoreActions;
