import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Label } from 'common/components';
import { useModalStyles } from './useModalStyles';

const useDialogStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(0.5, 0.5, 2)
  }
}));
type EditConfirmationDialogProps = {
  open: boolean;
  closeDialog: () => void;
  onEditClick: () => void;
};

export const EditConfirmationDialog: React.FC<EditConfirmationDialogProps> = ({
  open,
  closeDialog,
  onEditClick
}: EditConfirmationDialogProps) => {
  const classes = useModalStyles();

  return (
    <Dialog open={open} onClose={closeDialog} classes={useDialogStyles()}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Label variant="h6" labelId="activePage.editDialog.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Label
          variant="body1"
          className={classes.info}
          labelId="activePage.editDialog.info1"
        />
        <Label
          variant="body1"
          className={classes.info}
          labelId="activePage.editDialog.info2"
        />
      </DialogContent>
      <DialogActions className={classes.dialogContent}>
        <Button
          onClick={closeDialog}
          className={classes.cancel}
          color="default"
          labelId="activePage.editDialog.cancel"
        />
        <Button
          color="primary"
          onClick={onEditClick}
          labelId="activePage.editDialog.edit"
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditConfirmationDialog;
