import { useSmartBudgetQuery, SmartBudgetResult } from 'generated-types';

export const useCostPrice = (
  id: string
): {
  loading: boolean;
  costOptimizationResult: SmartBudgetResult | undefined | null;
} => {
  const { loading, data } = useSmartBudgetQuery({
    variables: { id }
  });

  return {
    loading,
    costOptimizationResult: data?.smartBudget
  };
};
