import React from 'react';
import { Alert } from '@material-ui/lab';
import { makeStyles, Typography } from '@material-ui/core';

import { GetOverlappingPlansQuery } from 'generated-types';
import { Label, DateRangeBase } from 'common/components';

const useStyles = makeStyles({
  plan: {
    fontWeight: 500
  }
});

const useAlertClasses = makeStyles({
  root: {
    backgroundColor: '#FFF4E5'
  },
  icon: {
    '& > svg': {
      color: '#FFA117'
    }
  }
});

type OverlappingPlanWarningProps = {
  overlappingPlans: GetOverlappingPlansQuery['smartBudgets']['edges'];
};

const OverlappingPlanWarning: React.FC<OverlappingPlanWarningProps> = props => {
  const classes = useStyles();
  const alertClasses = useAlertClasses();
  return (
    <Alert severity="warning" classes={alertClasses}>
      <Label labelId="addPlanDialog.overlappingPlanWarning" />
      {props.overlappingPlans.map(plan => (
        <Typography key={plan.node.id} className={classes.plan}>
          {plan.node.name}&nbsp;-&nbsp;
          <DateRangeBase
            startDate={plan.node.effectiveDate}
            endDate={plan.node.endDate}
          />
        </Typography>
      ))}
    </Alert>
  );
};

export default OverlappingPlanWarning;
