import React, { createContext } from 'react';
import { LinearProgress } from '@material-ui/core';
import { useGetMeQuery, Maybe, GetMeQuery } from 'generated-types';
import { DateTimeFormatProvider } from 'App/DateTimeFormatProvider';
import { NumberFormatProvider } from 'App/NumberFormatProvider';

export const MeContext = createContext<Maybe<GetMeQuery['me']>>(null);

export const MeProvider: React.FC = ({ children }) => {
  const { loading, data } = useGetMeQuery();

  return loading || !Boolean(data) ? (
    <LinearProgress variant="query" />
  ) : (
    <MeContext.Provider value={data?.me ?? null}>
      <DateTimeFormatProvider>
        <NumberFormatProvider>{children}</NumberFormatProvider>
      </DateTimeFormatProvider>
    </MeContext.Provider>
  );
};

export default MeProvider;
