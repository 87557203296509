import { useFeatureFlag } from 'common/hooks';
import {
  CalculateAttribute,
  useCalculateAttributeContext
} from 'plans/plan/context';

export const useIsCalculatingAttribute = (
  attribute: CalculateAttribute
): boolean => {
  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const { calculateAttribute } = useCalculateAttributeContext();

  return cpoNewUx && calculateAttribute === attribute;
};
