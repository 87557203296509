import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import { Label } from '../Label';
import { Button } from '../Button';

export type SimpleDialogProps = {
  titleMessageId: string;
  message1Id: string;
  message2Id?: string;
  confirmAction: () => void;
  open: boolean;
  closeDialog: () => void;
};

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: '0 0 20px 0'
  }
}));

export const SimpleDialog: React.FC<SimpleDialogProps> = ({
  titleMessageId,
  message1Id,
  message2Id,
  confirmAction,
  open,
  closeDialog
}: SimpleDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Label variant="h6" labelId={titleMessageId} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Label variant="body1" labelId={message1Id} className={classes.info} />
        {message2Id && (
          <Label
            variant="body1"
            labelId={message2Id}
            className={classes.info}
          />
        )}
        <DialogActions>
          <Button
            onClick={closeDialog}
            labelId="button.cancel"
            color="default"
          />
          <Button onClick={confirmAction} labelId="button.ok" color="primary" />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
