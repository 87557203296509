import React, { useMemo } from 'react';
import { Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { useMessage } from 'App/MessageProvider';
import { Label, MoneyValue, NumberValue } from 'common/components';
import { SmartBudgetResult } from 'generated-types';
import { useFeatureFlag } from 'common/hooks';
import {
  usePlanContext,
  usePlanTotalContext,
  usePlanEditContext,
  useRoleTableFormContext,
  useSaveDraftContext
} from '../context';
import { DataCardHeader } from '../DataCard';
import {
  CalculateAttributeButton,
  GlobalPopoverSlider,
  GlobalSlider
} from '../components';
import { useIsCalculatingAttribute } from '../hooks';
import { GlobalBillingRateSlider } from '../components/GlobalBillingRateSlider';
import { useBusinessCost } from './hooks';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    margin: theme.spacing(3, 0, 3, 0),
    padding: theme.spacing(0, 3, 0, 3),
    justifyContent: 'space-between'
  },
  billingRateGoal: {
    [theme.breakpoints.up(1600)]: {
      display: 'flex'
    }
  },
  profit: {
    paddingRight: 0
  },
  value: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold
  }
}));

interface PlanSummaryProps {
  plan: SmartBudgetResult;
}
export const PlanSummaryCard: React.FC<PlanSummaryProps> = ({
  plan
}: PlanSummaryProps) => {
  const cpoNewUx = useFeatureFlag('cpoNewUx');
  const classes = useStyles();
  const title = useMessage('planPage.planSummary.title');
  const { billingRateValue, setBillingRateValue } = useSaveDraftContext();
  const { canEdit } = usePlanEditContext();
  const { totalCost } = useBusinessCost(plan);
  const resultsAlignment = 'left';
  const {
    planTotals,
    margin,
    revenue,
    profit,
    utilization
  } = usePlanTotalContext();
  const {
    onGlobalUtilizationChangeHandler,
    onGlobalMarginChangeHandler,
    onGlobalBillingRateChangeHandler
  } = useRoleTableFormContext();

  const actions = useMemo(() => {
    return <>{canEdit && cpoNewUx && <CalculateAttributeButton />}</>;
  }, [canEdit, cpoNewUx]);
  const isCalculatingUtilization = useIsCalculatingAttribute('utilization');
  const isCalculatingMargin = useIsCalculatingAttribute('margin');

  return (
    <Card>
      <DataCardHeader
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
        action={actions}
      />
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={7} sm={5} md={2}>
          <PlanSummaryItem labelId="planPage.planSummary.utilizationGoal">
            {!cpoNewUx ? (
              <>
                {canEdit && !isCalculatingUtilization ? (
                  <>
                    <Label className={classes.value} />
                    <GlobalSlider
                      key="planPage.planSummary.utilizationGoal"
                      labelId="planPage.planSummary.slider.utilization"
                      value={utilization}
                      onChangeHandler={onGlobalUtilizationChangeHandler}
                    />
                  </>
                ) : (
                  <Label className={classes.value}>
                    <NumberValue
                      value={utilization?.toNumber() ?? 0}
                      fixedDecimalScale
                    />
                    &nbsp;%
                  </Label>
                )}
              </>
            ) : (
              <GlobalPopoverSlider
                key="planPage.planSummary.utilizationGoal"
                labelId="planPage.planSummary.slider.utilization"
                readOnly={!canEdit || isCalculatingUtilization}
                value={utilization}
                onChangeHandler={onGlobalUtilizationChangeHandler}
              />
            )}
          </PlanSummaryItem>
        </Grid>
        <Grid item xs={7} sm={5} md={2}>
          <PlanSummaryItem labelId="planPage.planSummary.marginGoal">
            {!cpoNewUx ? (
              <>
                {canEdit && !isCalculatingMargin ? (
                  <>
                    <Label className={classes.value} />
                    <GlobalSlider
                      key="planPage.planSummary.marginGoal"
                      labelId="planPage.planSummary.slider.margin"
                      value={margin}
                      onChangeHandler={onGlobalMarginChangeHandler}
                    />
                  </>
                ) : (
                  <Label className={classes.value}>
                    <NumberValue
                      value={margin?.toNumber() ?? 0}
                      fixedDecimalScale
                    />
                    &nbsp;%
                  </Label>
                )}
              </>
            ) : (
              <GlobalPopoverSlider
                key="planPage.planSummary.marginGoal"
                labelId="planPage.planSummary.slider.margin"
                value={margin}
                onChangeHandler={onGlobalMarginChangeHandler}
                readOnly={!canEdit || isCalculatingMargin}
              />
            )}
          </PlanSummaryItem>
        </Grid>
        <Grid item xs={7} sm={6} md={2}>
          <PlanSummaryItem labelId="planPage.planSummary.avgBillingRateGoal">
            <GlobalBillingRateSlider
              key="planPage.planSummary.avgBillingRateGoal"
              labelId="planPage.planSummary.slider.avgBillingRateGoal"
              billingRate={planTotals?.billingRate}
              billingRateValue={billingRateValue}
              readOnly={!(canEdit && cpoNewUx)}
              setBillingRateValue={setBillingRateValue}
              onChangeHandler={onGlobalBillingRateChangeHandler}
            />
          </PlanSummaryItem>
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          <PlanSummaryItem
            labelId="planPage.planSummary.revenueGoal"
            align={resultsAlignment}>
            <MoneyValue
              className={classes.value}
              value={revenue?.amount?.toNumber() ?? 0}
              currencySymbol={revenue?.currency?.symbol ?? ''}
            />
          </PlanSummaryItem>
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          <PlanSummaryItem
            labelId="planPage.planSummary.totalCost"
            align={resultsAlignment}>
            <MoneyValue
              className={classes.value}
              value={totalCost?.amount?.toNumber() ?? 0}
              currencySymbol={planTotals?.totalCost?.currency?.symbol ?? ''}
            />
          </PlanSummaryItem>
        </Grid>
        <Grid item xs={4} sm={3} md={2} className={classes.profit}>
          <PlanSummaryItem
            labelId="planPage.planSummary.profitGoal"
            align={resultsAlignment}>
            <MoneyValue
              className={classes.value}
              value={profit?.amount?.toNumber() ?? 0}
              currencySymbol={profit?.currency?.symbol ?? ''}
            />
          </PlanSummaryItem>
        </Grid>
      </Grid>
    </Card>
  );
};

export type PlanSummaryItemProps = {
  labelId: string;
  children: React.ReactNode;
  align?: 'left' | 'right';
};

const useItemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      textAlign: (props: { align: 'left' | 'right' }) => props.align
    }
  },
  title: {
    ...theme.typography.body2,
    color: theme.palette.grey[700]
  },
  value: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold
  },
  skeleton: {
    alignSelf: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignSelf: (props: { align: 'left' | 'right' }) =>
        props.align === 'left' ? 'flex-start' : 'flex-end'
    }
  }
}));

export const PlanSummaryItem: React.FC<PlanSummaryItemProps> = ({
  align = 'left',
  labelId,
  children
}: PlanSummaryItemProps) => {
  const classes = useItemStyles({ align: align });
  const { loading } = usePlanContext();

  if (loading) {
    return (
      <div className={classes.root}>
        <Label className={classes.title} labelId={labelId} />
        <Skeleton className={classes.skeleton} width={100} />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Label className={classes.title} labelId={labelId} />
      {children}
    </div>
  );
};
