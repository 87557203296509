import React, { useMemo, useCallback, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { useUpdateCostsMutation } from 'generated-types';
import { DateTimeBase, ProgressButton } from 'common/components';
import { useWorkDataPolling } from 'billing/hooks/useWorkDataPolling';
import {
  usePlanContext,
  usePlanEditContext,
  usePlanUndoContext
} from './context';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  alert: {
    padding: theme.spacing(0.5, 1, 0.5, 1),
    height: 32,
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& > div:nth-child(0)': {
      padding: 0
    },
    '& > div:nth-child(1)': {
      padding: 0
    }
  },
  alertButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  alertUpdateButton: {
    marginLeft: theme.spacing(2),
    lineHeight: 'initial'
  },
  date: {
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: '0.2rem'
  }
}));

const useSmallUpdateButtonStyles = makeStyles({
  buttonProgress: {
    top: 'calc(50% - 8px)',
    left: '50%'
  }
});

const PlanUpdateCostsAction: React.FC = () => {
  const classes = useStyles();
  const smallUpdateButtonClasses = useSmallUpdateButtonStyles();
  const { plan, refetch } = usePlanContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSmartBudget, { loading }] = useUpdateCostsMutation();
  const { canEdit } = usePlanEditContext();
  const { reset: resetHistory } = usePlanUndoContext();

  const onWorkFinish = useCallback(() => {
    setIsUpdating(false);
    resetHistory();
    refetch?.();
  }, [refetch, resetHistory]);
  const startPolling = useWorkDataPolling(onWorkFinish, onWorkFinish);

  const messageValues = useMemo(
    () => ({
      lastUpdate: (
        <span className={classes.date}>
          <DateTimeBase dateTime={plan?.lastSynchronizedAt} />
        </span>
      )
    }),
    [classes.date, plan]
  );

  const onClick = useCallback(async () => {
    if (!plan?.id) {
      return;
    }

    setIsUpdating(true);
    const { data } = await updateSmartBudget({
      variables: {
        smartBudgetId: plan.id
      }
    });

    if (data?.updateSmartBudgetCosts?.backgroundWorkId) {
      startPolling(data.updateSmartBudgetCosts.backgroundWorkId);
    }
  }, [plan, startPolling, updateSmartBudget]);

  const buttonLoading = loading || isUpdating;
  const buttonDisabled = buttonLoading || !plan;

  return (
    <div className={classes.root}>
      {Boolean(plan?.lastSynchronizedAt?.length) && (
        <Alert className={classes.alert} severity="info">
          <div className={classes.alertButtonContainer}>
            <FormattedMessage
              id="planUpdateCostsAction.lastUpdate"
              values={messageValues}
            />
            {canEdit && (
              <ProgressButton
                variant="outlined"
                labelId="planUpdateCostsAction.label"
                disabled={buttonDisabled}
                loading={buttonLoading}
                onClick={onClick}
                className={classes.alertUpdateButton}
                size="small"
                classes={smallUpdateButtonClasses}
                circularProgressSize={16}
              />
            )}
          </div>
        </Alert>
      )}
    </div>
  );
};

export default PlanUpdateCostsAction;
