import { useMemo } from 'react';
import { Direction } from '@material-ui/core/styles';
import { getLangDir } from 'rtl-detect';
import { useMeContext } from 'App/Me';

export const useDirection = (): Direction => {
  const me = useMeContext();
  const languageCode = useMemo(() => me.locale?.language?.languageCode, [
    me.locale
  ]);
  return languageCode ? getLangDir(languageCode) : 'ltr';
};
