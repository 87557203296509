import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import BigNumber from 'bignumber.js';
import { calculateRevenueForRole } from 'rateCard/helpers';
import { UserBreakdown } from 'generated-types';

const userCalculator = new RoleBillingRateCalculator();

export const handleUserBillingRateChange = (
  userBreakdown: UserBreakdown,
  putUserBreakdowns: (
    attribute: 'margin' | 'utilization'
  ) => (value: BigNumber) => Promise<void>
) => (billingRate: number): void => {
  const userRevenue = calculateRevenueForRole(
    new BigNumber(billingRate),
    userBreakdown.utilization ?? new BigNumber(0),
    userBreakdown.hours ?? new BigNumber(0)
  );
  const margin = userCalculator
    .calculateMargin(
      userRevenue,
      userBreakdown.loadedCostRate?.amount ?? new BigNumber(0),
      userBreakdown.hours ?? new BigNumber(0)
    )
    .multipliedBy(100);

  putUserBreakdowns('margin')(margin);
};

export const handleUserRevenueChange = (
  userBreakdown: UserBreakdown,
  putUserBreakdowns: (
    attribute: 'margin' | 'utilization'
  ) => (value: BigNumber) => Promise<void>
) => (revenue: number): void => {
  const margin = userCalculator
    .calculateMargin(
      new BigNumber(revenue),
      userBreakdown.loadedCostRate?.amount ?? new BigNumber(0),
      userBreakdown.hours ?? new BigNumber(0)
    )
    .multipliedBy(100);

  putUserBreakdowns('margin')(margin);
};
