import React, { useMemo } from 'react';
import { DrawerBody, DrawerHeader, SideDrawer } from 'common/components';
import { usePlanWarnings } from 'plans/hooks';
import { PlanWarningContextProvider } from 'plans/plan/context';
import { PlanDrawerContent } from './PlanDrawerContent';
import { PlanDrawerTitle } from './PlanDrawerTitle';

type DrawerHeaderProps = {
  planId: string;
  open: boolean;
  closeDrawer: () => void;
};

export const PlanWarningDrawer: React.FC<DrawerHeaderProps> = ({
  planId,
  open,
  closeDrawer
}: DrawerHeaderProps) => {
  const { loading, plan, error } = usePlanWarnings(planId);
  const planWarningContextValue = useMemo(() => ({ loading, plan, error }), [
    error,
    loading,
    plan
  ]);
  return (
    <PlanWarningContextProvider value={planWarningContextValue}>
      <SideDrawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <PlanDrawerTitle />
        </DrawerHeader>
        <DrawerBody>
          <PlanDrawerContent />
        </DrawerBody>
      </SideDrawer>
    </PlanWarningContextProvider>
  );
};
