import { DateObject, DateTime } from 'luxon';
import { useMessages } from 'App/MessageProvider';
import { useMeContext } from 'App/Me';
import {
  GraphGranularity,
  SmartBudgetResult,
  useSmartBudgetOverviewGraphQuery
} from 'generated-types';

export type OverviewChartData = {
  month: string;
  budgeted: number;
  actual: number;
  estimated: number;
};

export type OverviewLegend = { color: string; label: string };

export type OverviewFormattedGraphData = {
  month: string;
  budgeted: number;
  estimated: number;
  actual: number;
  dateRange: { startDate?: DateObject; endDate?: DateObject };
}[];

export const useOverviewChartData = (
  startDate: DateObject,
  endDate: DateObject
): {
  loading: boolean;
  overviewGraphData?: OverviewFormattedGraphData;
  legends: OverviewLegend[];
  baseCurrency?: string | null;
  plans?: SmartBudgetResult[];
} => {
  const legendMessages = useMessages([
    'overviewPage.chart.legend.actual',
    'overviewPage.chart.legend.budgeted',
    'overviewPage.chart.legend.estimated'
  ]);

  const legends = [
    {
      color: '#00CD8C',
      label: legendMessages['overviewPage.chart.legend.actual'] as string
    },
    {
      color: '#CBF9E8',
      label: legendMessages['overviewPage.chart.legend.budgeted'] as string
    },
    {
      color: '#C3B9EC',
      label: legendMessages['overviewPage.chart.legend.estimated'] as string
    }
  ];

  const { loading, data } = useSmartBudgetOverviewGraphQuery({
    variables: {
      dateRange: { startDate, endDate },
      granularity: GraphGranularity.Month
    },
    fetchPolicy: 'no-cache'
  });
  const me = useMeContext();
  const baseCurrency = me.baseCurrency?.symbol;
  const overviewFormattedGraphData = data?.smartBudgetOverviewGraph.revenue?.map(
    m => {
      return {
        month: DateTime.fromObject(m.dateRange.startDate).toISODate(),
        budgeted: m.budgeted?.toBaseCurrency?.value?.amount?.toNumber() ?? 0,
        estimated: m.estimated?.toBaseCurrency?.value?.amount?.toNumber() ?? 0,
        actual: m.actual?.toBaseCurrency?.value?.amount?.toNumber() ?? 0,
        dateRange: m.dateRange
      };
    }
  );
  return {
    loading,
    overviewGraphData: overviewFormattedGraphData,
    legends: legends,
    baseCurrency: baseCurrency,
    plans: data?.smartBudgetOverviewGraph.smartBudgets
  };
};
