import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { DateTime } from 'luxon';
import { Maybe, SmartBudgetResult } from 'generated-types';

type PlanInRange = {
  startDate: Maybe<string>;
  endDate: Maybe<string>;
  id?: Maybe<string>;
  name?: Maybe<string>;
  isStartBeforeRange?: boolean;
  isEndAfterRange?: boolean;
  weight: number;
};

export type UsePlanRangeArgs = {
  startDate: DateTime;
  endDate: DateTime;
  plans: SmartBudgetResult[];
};

const getMinDate = (date1: DateTime, date2: DateTime) =>
  date1 < date2 ? date1 : date2;

const getMaxDate = (date1: DateTime, date2: DateTime) =>
  date1 > date2 ? date1 : date2;

const getWeightCorrection = (rangeLength: number) => {
  if (rangeLength >= 21) {
    return 0.975;
  }
  if (rangeLength >= 18) {
    return 0.985;
  }
  if (rangeLength >= 15) {
    return 0.992;
  }
  return 1;
};

const getPlanInRange = ({
  startDate,
  endDate,
  rangeLength,
  planName,
  planId,
  isStartBeforeRange,
  isEndAfterRange
}: {
  startDate: DateTime;
  endDate: DateTime;
  rangeLength: number;
  planId?: Maybe<string>;
  planName?: Maybe<string>;
  isStartBeforeRange?: boolean;
  isEndAfterRange?: boolean;
}) => {
  const month = Math.ceil(
    endDate.diff(startDate, ['month']).toObject().months ?? 0
  );
  return {
    startDate: startDate.toISODate(),
    endDate: endDate.toISODate(),
    name: planName,
    id: planId,
    weight: month
      ? new BigNumber(month)
          .dividedBy(rangeLength)
          .times(100)
          .times(getWeightCorrection(rangeLength))
          .toNumber()
      : 0,
    isStartBeforeRange,
    isEndAfterRange
  };
};

export const usePlanRange = ({
  startDate,
  endDate,
  plans
}: UsePlanRangeArgs): PlanInRange[] => {
  const rangeLength = useMemo(
    () => Math.ceil(endDate.diff(startDate, ['month']).toObject().months ?? 0),
    [endDate, startDate]
  );

  return useMemo(() => {
    const plansByRange: PlanInRange[] = [];

    let movingStartDate = DateTime.fromObject(startDate.toObject());
    plans.forEach(plan => {
      if (!plan.effectiveDate || !plan.endDate) {
        return;
      }
      if (
        movingStartDate.toFormat('yyyy-MM') <
        DateTime.fromISO(plan.effectiveDate).toFormat('yyyy-MM')
      ) {
        const rangeEndDate = DateTime.fromISO(plan.effectiveDate)
          .minus({ month: 1 })
          .endOf('month');
        plansByRange.push(
          getPlanInRange({
            startDate: movingStartDate,
            endDate: rangeEndDate,
            rangeLength
          })
        );

        const planEndDate = getMinDate(endDate, DateTime.fromISO(plan.endDate));
        plansByRange.push(
          getPlanInRange({
            startDate: DateTime.fromISO(plan.effectiveDate),
            endDate: planEndDate,
            planName: plan.name,
            planId: plan.id,
            rangeLength,
            isEndAfterRange: planEndDate === endDate
          })
        );
      } else {
        const planStartDate = getMaxDate(
          startDate,
          DateTime.fromISO(plan.effectiveDate)
        );
        const planEndDate = getMinDate(endDate, DateTime.fromISO(plan.endDate));
        plansByRange.push(
          getPlanInRange({
            startDate: planStartDate,
            endDate: planEndDate,
            planName: plan.name,
            planId: plan.id,
            rangeLength,
            isStartBeforeRange: planStartDate === startDate,
            isEndAfterRange: planEndDate === endDate
          })
        );
      }
      movingStartDate = DateTime.fromISO(plan.endDate)
        .plus({ month: 1 })
        .startOf('month');
    });

    if (movingStartDate < endDate) {
      plansByRange.push(
        getPlanInRange({
          startDate: movingStartDate,
          endDate: endDate,
          rangeLength
        })
      );
    }
    return plansByRange;
  }, [endDate, plans, rangeLength, startDate]);
};
