import { ApolloError } from '@apollo/client';
import {
  CostOptimizationResult,
  Maybe,
  usePlanWarningQuery
} from 'generated-types';

export const usePlanWarnings = (
  id: string
): {
  plan?: Maybe<CostOptimizationResult>;
  loading: boolean;
  error?: Maybe<ApolloError>;
} => {
  const { loading, data, error } = usePlanWarningQuery({
    variables: { id },
    fetchPolicy: 'network-only'
  });

  return { loading, plan: data?.costOptimizationResult, error: error };
};
