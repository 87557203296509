import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import BigNumber from 'bignumber.js';
import { RoleBillingRateCalculator } from '@replicon/cost-price-optimizer-models';
import { TooltipMoney } from 'common/components';
import { BillingByRoleData } from 'common/types';
import { useRoleTableFormContext } from 'plans/plan/context';

const billingCalculator = new RoleBillingRateCalculator();

export const CalculatedProfitCell: React.FC<CellProps<BillingByRoleData>> = ({
  row
}: CellProps<BillingByRoleData>) => {
  const totalHours = row.values.totalHours;

  if (!BigNumber.isBigNumber(totalHours)) {
    throw new Error('Must have totalHours value to calculate.');
  }

  const loadedCost = row.values.loadedCost;
  const { getValues } = useRoleTableFormContext();
  const revenue = getValues?.()[row.original.id].revenue;

  const calculatedValue = useMemo(() => {
    return billingCalculator.calculateProfit(
      new BigNumber(revenue),
      loadedCost.amount,
      totalHours
    );
  }, [loadedCost.amount, revenue, totalHours]);

  return (
    <TooltipMoney
      value={calculatedValue.toNumber()}
      currencySymbol={loadedCost.currency?.symbol}
      placement="right"
    />
  );
};
