import React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory, History as THistory } from 'history';
import { Router } from 'react-router-dom';
import { Theme } from '@material-ui/core';
import { RootTabs } from 'common/routes';
import { ErrorBoundary } from 'common/components';
import { TabNavigationContextProvider } from 'common/context';
import BasePage from './BasePage';
import { EmbeddedAppProvider } from './EmbeddedAppContext';
import GqlClientProvider from './GqlClient';
import { MeProvider } from './Me';
import { MessageProvider } from './MessageProvider';
import { MuiThemeProvider } from './MuiThemeProvider';
import { CpoMuiPickersUtilProvider } from './CpoMuiPickersUtilsProvider';

type AppProps = {
  apiUrl?: string;
  routeBasename?: string;
  history?: THistory;
  parentTheme?: Theme;
  setShowHeader?: (v: boolean) => void;
  className?: string;
};

const defaultHistory = createBrowserHistory();

const App: React.FC<AppProps> = ({
  apiUrl = '/graphql',
  routeBasename = '',
  history,
  parentTheme,
  setShowHeader
}: AppProps) => {
  return (
    <ErrorBoundary>
      <Router history={history ?? defaultHistory}>
        <EmbeddedAppProvider
          baseRoute={routeBasename}
          setAppHeaderVisible={setShowHeader}>
          <GqlClientProvider apiUrl={apiUrl}>
            <MeProvider>
              <MuiThemeProvider parentTheme={parentTheme}>
                <MessageProvider>
                  <CpoMuiPickersUtilProvider>
                    <TabNavigationContextProvider>
                      <BasePage>
                        <RootTabs embedded={setShowHeader !== undefined} />
                      </BasePage>
                    </TabNavigationContextProvider>
                  </CpoMuiPickersUtilProvider>
                </MessageProvider>
              </MuiThemeProvider>
            </MeProvider>
          </GqlClientProvider>
        </EmbeddedAppProvider>
      </Router>
    </ErrorBoundary>
  );
};

App.propTypes = {
  apiUrl: PropTypes.string
};

export default App;
