import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { useDebounce } from 'react-use';
import { Popover, Slider } from '@material-ui/core';
import { useLockedColumnContext } from 'common/context';
import { useIntl } from 'App/MessageProvider';
import {
  MIN_BILLING_RATE_CHANGE_PERCENT,
  MAX_BILLING_RATE_CHANGE_PERCENT,
  UPDATE_DEBOUNCE_TIME
} from 'plans/constants';
import { MoneyValue, NumberEditField } from 'common/components';
import { useSliderHandlers } from 'rateCard/components/UtilizationMarginCard/useSliderHandlers';
import { useIsCalculatingAttribute } from 'plans/plan/hooks';
import { useTemporaryState } from 'common/hooks';
import { usePlanUndoContext } from 'plans/plan/context';
import { Maybe, Money } from 'generated-types';

const useBaseStyles = makeStyles(theme => ({
  paper: {
    width: '100%'
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#6A6A6A'
  },
  value: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold
  }
}));

const useBillingSliderStyles = makeStyles(() => ({
  root: {
    padding: 0
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    flexDirection: 'row-reverse'
  },
  fadeIn: {
    opacity: 1,
    transition: 'opacity 300ms ease-in-out'
  },
  popper: {
    zIndex: 100,
    width: theme.spacing(100)
  },
  popperContent: {
    display: 'flex',
    padding: theme.spacing(2.5, 2.5, 2),
    width: theme.spacing(35),
    height: theme.spacing(6)
  }
}));

const useSliderStyles = makeStyles(theme => ({
  root: {
    color: '#25D293',
    padding: 0,
    marginLeft: 15
  },
  track: {
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5)
  },
  rail: {
    width: '100%',
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5)
  },
  thumb: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginTop: theme.spacing(-0.75),
    '&:hover': {
      boxShadow: 'none'
    }
  }
}));

const useInputStyles = makeStyles(theme => ({
  root: {
    width: '40%'
  },
  input: {
    width: theme.spacing(5.75),
    padding: theme.spacing(0, 0, 0.5)
  }
}));

const useMoneyInputStyle = makeStyles(theme => ({
  root: {
    width: theme.spacing(10),
    padding: theme.spacing(0),
    fontSize: theme.typography.body2.fontSize
  },
  underline: {
    '&$disabled:before': {
      borderBottomStyle: 'solid'
    }
  },
  disabled: {
    color: theme.palette.grey[900]
  },
  input: {
    width: theme.spacing(5.75),
    padding: theme.spacing(0.25, 0)
  }
}));

export type GlobalBillingRateSliderProps = {
  labelId: string;
  billingRateValue: BigNumber;
  billingRate?: Maybe<Money>;
  readOnly: boolean;
  setBillingRateValue: (value: BigNumber) => void;
  onChangeHandler: (value: string) => void;
};

const GlobalBillingRateSlider: React.FC<GlobalBillingRateSliderProps> = ({
  labelId,
  billingRateValue,
  billingRate,
  readOnly,
  setBillingRateValue,
  onChangeHandler
}: GlobalBillingRateSliderProps) => {
  const classes = useBaseStyles();
  const { lockedColumn } = useLockedColumnContext();

  const isLocked = useMemo(() => lockedColumn === 'billingRate', [
    lockedColumn
  ]);

  const valueToPercent = (value: number, min: number, max: number): number => {
    return ((value - min) * 100) / (max - min);
  };

  const centeredSliderContainer = (
    value: number,
    min: number,
    max: number,
    center: number
  ) => {
    const CenteredSlider = React.forwardRef<HTMLSpanElement, any>(
      (props, ref) => {
        const { children, ...rest } = props;
        CenteredSlider.displayName = 'CenteredSlider';
        const centerPct = valueToPercent(center, min, max);
        const left =
          value < center ? valueToPercent(value, min, max) : centerPct;
        const width =
          value < center
            ? Math.abs(centerPct - left)
            : valueToPercent(value, min, max) - centerPct;

        return (
          <span ref={ref} {...rest}>
            <span
              style={{
                left: `${left}%`,
                width: `${width}%`,
                color: '#25D293',
                display: 'block',
                position: 'absolute',
                height: 4,
                borderRadius: 1,
                backgroundColor: '#25D293'
              }}
            />
            {children}
          </span>
        );
      }
    );
    return CenteredSlider;
  };

  const [
    localBillingRateValue,
    setLocalBillingRateValue,
    discardLocalBillingRateValue
  ] = useTemporaryState(billingRateValue);
  const isCalculatingBillingRate = useIsCalculatingAttribute('billingRate');
  const { push } = usePlanUndoContext();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleOpenPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );
  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const { open, id } = useMemo(() => {
    const open = Boolean(anchorEl);
    return {
      open,
      id: open ? labelId : undefined
    };
  }, [anchorEl, labelId]);

  useDebounce(
    () => {
      if (
        localBillingRateValue &&
        !billingRateValue.isEqualTo(localBillingRateValue)
      ) {
        push({ billingRatePercent: billingRateValue });
        setBillingRateValue(localBillingRateValue);
        onChangeHandler(localBillingRateValue.toString());
        discardLocalBillingRateValue();
      }
    },
    UPDATE_DEBOUNCE_TIME,
    [localBillingRateValue]
  );

  const intl = useIntl();
  const { onInputChange, onSliderChange } = useSliderHandlers(
    useCallback((v: string) => setLocalBillingRateValue(new BigNumber(v)), [
      setLocalBillingRateValue
    ]),
    MIN_BILLING_RATE_CHANGE_PERCENT,
    MAX_BILLING_RATE_CHANGE_PERCENT
  );
  const sliderCls = useSliderStyles({ classes: useSliderStyles() });
  const billingRateStyle = useMoneyInputStyle();

  const cls = useStyles({ classes: useBillingSliderStyles() });
  const inputCls = useInputStyles();
  const Container = centeredSliderContainer(
    billingRateValue.toNumber(),
    MIN_BILLING_RATE_CHANGE_PERCENT,
    MAX_BILLING_RATE_CHANGE_PERCENT,
    0
  );
  return (
    <div className={classes.paper}>
      <div aria-describedby={id} onMouseEnter={handleOpenPopover}>
        {readOnly || isCalculatingBillingRate ? (
          <MoneyValue
            className={classes.value}
            value={billingRate?.amount?.toNumber() ?? 0}
            currencySymbol={billingRate?.currency?.symbol ?? ''}
          />
        ) : (
          <NumberEditField
            classes={billingRateStyle}
            value={billingRate?.amount?.toString() ?? '0'}
            prefix={billingRate?.currency?.symbol ?? ''}
            disabled
            onClick={handleOpenPopover}
          />
        )}
      </div>
      {!isCalculatingBillingRate && !readOnly && (
        <Popover
          className={cls.popper}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 25,
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}>
          <div className={cls.popperContent}>
            <NumberEditField
              id={labelId}
              ariaLabel={intl.formatMessage({
                id: 'planPage.planSummary.avgBillingRateGoal'
              })}
              classes={inputCls}
              value={billingRateValue.toString()}
              onChange={onInputChange}
              suffix="%"
              disabled={isLocked}
            />
            <Slider
              classes={sliderCls}
              aria-labelledby={labelId}
              onChange={onSliderChange}
              value={billingRateValue.toNumber()}
              min={MIN_BILLING_RATE_CHANGE_PERCENT}
              max={MAX_BILLING_RATE_CHANGE_PERCENT}
              track={false}
              defaultValue={0}
              disabled={isLocked}
              component={Container}
            />
          </div>
        </Popover>
      )}
    </div>
  );
};

export default GlobalBillingRateSlider;
