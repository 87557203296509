import React, { useContext, useMemo } from 'react';
import { useSessionStorage } from 'react-use';
import { DateTime } from 'luxon';
import { Maybe } from 'generated-types';

export type OverviewDateRangeContextValue = {
  startDate: string;
  setStartDate: (value: string) => void;
  endDate: string;
  setEndDate: (value: string) => void;
};

const OverviewDateRangeContext = React.createContext<
  Maybe<OverviewDateRangeContextValue>
>(null);

export const useOverviewDateRangeContext = (): OverviewDateRangeContextValue => {
  const val = useContext(OverviewDateRangeContext);
  if (!val) {
    throw new Error(
      'useOverviewDateRangeContext must be called within a OverviewDateRangeContext'
    );
  }

  return val;
};

type OverviewDateRangeContextProviderProps = {
  children: React.ReactNode;
};

export const OverviewDateRangeContextProvider: React.FC<OverviewDateRangeContextProviderProps> = ({
  children
}: OverviewDateRangeContextProviderProps) => {
  const [startDate, setStartDate] = useSessionStorage<string>(
    'startDate',
    DateTime.local()
      .startOf('year')
      .toISO()
  );

  const [endDate, setEndDate] = useSessionStorage<string>(
    'endDate',
    DateTime.local()
      .endOf('year')
      .toISO()
  );
  return (
    <OverviewDateRangeContext.Provider
      value={useMemo(
        () => ({
          startDate,
          setStartDate,
          endDate,
          setEndDate
        }),
        [startDate, setStartDate, endDate, setEndDate]
      )}>
      {children}
    </OverviewDateRangeContext.Provider>
  );
};

export const OverviewDateRangeContextConsumer =
  OverviewDateRangeContext.Consumer;
