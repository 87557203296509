import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Maybe } from 'graphql/jsutils/Maybe';
import BigNumber from 'bignumber.js';
import classnames from 'classnames';
import { MoneyValue, NumberValue } from 'common/components';
import { TotalCostBreakdown } from 'common/types';

const useStyles = makeStyles(theme => ({
  tableCell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1)
    }
  },
  tableRow: {
    background: theme.palette.grey[100]
  },
  title: {
    paddingLeft: theme.spacing(4.5)
  }
}));

const useCellStyle = makeStyles(theme => ({
  root: {
    '&:last-child': {
      paddingRight: theme.spacing(3)
    }
  }
}));

export interface RoleDataTableTotalsProps {
  classes?: ClassNameMap;
  cellClasses?: ClassNameMap;
  totalCostBreakdown: Maybe<TotalCostBreakdown>;
  utilization?: BigNumber;
  margin?: BigNumber;
  totalRevenue: BigNumber;
}
export const RoleDataTableTotals: React.FC<RoleDataTableTotalsProps> = ({
  classes: overriddenClasses,
  cellClasses: overriddenCellClasses,
  totalCostBreakdown,
  utilization,
  margin,
  totalRevenue
}: RoleDataTableTotalsProps): React.ReactElement => {
  const classes = useStyles({ classes: overriddenClasses });
  const cellStyle = useCellStyle({ classes: overriddenCellClasses });
  const numberColumnDirection = 'right';
  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        <TableCell className={classnames(classes.tableCell, classes.title)}>
          <FormattedMessage id="expansionPanelDetailsTable.businessSummary" />
        </TableCell>
        <TableCell
          classes={cellStyle}
          className={classes.tableCell}
          align={numberColumnDirection}>
          <NumberValue
            value={totalCostBreakdown?.totalBillableHours?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell
          classes={cellStyle}
          className={classes.tableCell}
          align={numberColumnDirection}>
          <MoneyValue
            currencySymbol={totalCostBreakdown?.billableCost?.currency?.symbol}
            value={totalCostBreakdown?.billableCost?.amount?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell className={classes.tableCell} align={numberColumnDirection}>
          <MoneyValue
            currencySymbol={totalCostBreakdown?.standardRate?.currency?.symbol}
            value={totalCostBreakdown?.standardRate?.amount?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell className={classes.tableCell} align={numberColumnDirection}>
          <MoneyValue
            currencySymbol={
              totalCostBreakdown?.nonBillableOverheadRate?.currency?.symbol
            }
            value={
              totalCostBreakdown?.nonBillableOverheadRate?.amount?.toNumber() ??
              0
            }
          />
        </TableCell>
        <TableCell className={classes.tableCell} align={numberColumnDirection}>
          <MoneyValue
            currencySymbol={
              totalCostBreakdown?.businessOverheadRate?.currency?.symbol
            }
            value={
              totalCostBreakdown?.businessOverheadRate?.amount?.toNumber() ?? 0
            }
          />
        </TableCell>
        <TableCell
          classes={cellStyle}
          className={classes.tableCell}
          align={numberColumnDirection}>
          <MoneyValue
            currencySymbol={totalCostBreakdown?.laborRate?.currency?.symbol}
            value={totalCostBreakdown?.laborRate?.amount?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell
          classes={cellStyle}
          className={classes.tableCell}
          align={numberColumnDirection}>
          <NumberValue
            value={
              (utilization ?? totalCostBreakdown?.utilization)?.toNumber() ?? 0
            }
            suffix=" %"
            decimalScale={2}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell
          classes={cellStyle}
          className={classes.tableCell}
          align={numberColumnDirection}>
          <NumberValue
            value={(margin ?? totalCostBreakdown?.margin)?.toNumber() ?? 0}
            suffix=" %"
            decimalScale={2}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell className={classes.tableCell} align={numberColumnDirection}>
          <MoneyValue
            currencySymbol={totalCostBreakdown?.billingRate?.currency?.symbol}
            value={totalCostBreakdown?.billingRate?.amount?.toNumber() ?? 0}
          />
        </TableCell>
        <TableCell
          classes={cellStyle}
          className={classes.tableCell}
          align={numberColumnDirection}>
          <MoneyValue
            currencySymbol={totalCostBreakdown?.totalRevenue?.currency?.symbol}
            value={totalRevenue.toNumber() ?? 0}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default RoleDataTableTotals;
