import React from 'react';
import { PageTitleContextProvider } from 'common/context';
import { useIntl } from 'App/MessageProvider';
import AppRoot from './AppRoot';

export type RootTabsProps = {
  embedded?: boolean;
};

export const RootTabs: React.FC<RootTabsProps> = () => {
  const intl = useIntl();

  return (
    <PageTitleContextProvider intl={intl}>
      <AppRoot />
    </PageTitleContextProvider>
  );
};
