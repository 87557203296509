import React from 'react';
import { CellProps } from 'react-table';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { BillingByRoleData } from 'common/types';
import { RTLOverride } from '../RTLOverride';

const useStyles = makeStyles(theme => ({
  root: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  headCount: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontSize: 10
  }
}));
export const RoleNameCell: React.FC<CellProps<BillingByRoleData>> = ({
  value,
  row
}: CellProps<BillingByRoleData>) => {
  const classes = useStyles();
  return (
    <Tooltip title={value} key={value}>
      <div className={classes.root}>
        {value}
        <div className={classes.headCount}>
          <RTLOverride>
            <FormattedMessage id="planPage.billableResourcesCard.roleNameCell.headCount" />
            {': '}
            {row.original.userCount?.toNumber()}
          </RTLOverride>
        </div>
      </div>
    </Tooltip>
  );
};
