import React from 'react';
import { HeaderProps } from 'react-table';
import BigNumber from 'bignumber.js';
import { usePlanTotalContext } from 'plans/plan/context';
import { BillingByRoleData } from 'common/types';
import { PlanTotals } from './hooks';

export const CurrentUtilizationFooter: React.FC<HeaderProps<
  BillingByRoleData
>> = ({ column: { id: key } }: HeaderProps<BillingByRoleData>) => {
  const { planTotals } = usePlanTotalContext() ?? {};
  const value = planTotals?.[key as keyof PlanTotals] as BigNumber;
  return <>{value ? <>{value.toFixed(2)}&nbsp;%</> : <>&mdash;</>}</>;
};
