import { DateTime } from 'luxon';
import {
  useActiveCostOptimizationResultQuery,
  CostOptimizationResult
} from 'generated-types';

export const useActiveCostOptimizationResult = (
  effectiveDate = DateTime.local().toUTC()
): {
  loading: boolean;
  costOptimizationResult: CostOptimizationResult | undefined | null;
} => {
  const date = effectiveDate.toObject();

  if (!date.year || !date.month || !date.day) {
    throw new Error('Unable to load active result, invalid date provided.');
  }

  const { loading, data } = useActiveCostOptimizationResultQuery({
    variables: {
      date: {
        year: date.year,
        month: date.month,
        day: date.day
      }
    }
  });

  return {
    loading,
    costOptimizationResult: data?.activeCostOptimizationResult
  };
};
