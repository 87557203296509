import React from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { usePageTitleContext } from 'common/context';
import PageHeader from 'common/components/PageHeader';
import { useIntl } from 'App/MessageProvider';
import { PlansTabs } from 'plans';
import { OverviewSummaryCard } from './components/OverviewSummaryCard';
import { OverviewChartCard } from './components/OverviewChartCard';
import { OverviewDateRangeContextProvider } from './context/OverviewDateRangeContext';

const useStyles = makeStyles(theme => ({
  summaryContainer: {
    padding: theme.spacing(2, 2, 0),
    position: 'sticky',
    left: theme.spacing(0),
    top: theme.spacing(2.25),
    zIndex: 200,
    background: '#fafafa'
  },
  safariSticky: {
    position: '-webkit-sticky'
  },
  graphCard: {
    padding: theme.spacing(2),
    background: '#fafafa'
  }
}));
export const OverviewPage: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  usePageTitleContext([{ messageId: 'overviewPage.overview' }]);
  return (
    <OverviewDateRangeContextProvider>
      <PageHeader title={intl.formatMessage({ id: 'appName' })} />
      <PlansTabs />
      <div
        className={classnames(classes.summaryContainer, classes.safariSticky)}>
        <OverviewSummaryCard />
      </div>
      <div className={classes.graphCard}>
        <OverviewChartCard />
      </div>
    </OverviewDateRangeContextProvider>
  );
};
