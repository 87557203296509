import React, { useContext } from 'react';
import { ApolloError } from '@apollo/client';

import { SmartBudgetResult, Maybe } from 'generated-types';

export type PlanContextValue = {
  plan?: Maybe<SmartBudgetResult>;
  loading: boolean;
  error?: Maybe<ApolloError>;
  refetch?: () => void;
};

const PlanContext = React.createContext<Maybe<PlanContextValue>>(null);
const usePlanContext = (): PlanContextValue => {
  const val = useContext(PlanContext);
  if (!val) {
    throw new Error(
      'usePlanContext must be called within a PlanContextProvider with a resolved CostOptimizationResult.'
    );
  }

  return val;
};

const PlanContextProvider = PlanContext.Provider;
const PlanContextConsumer = PlanContext.Consumer;

export {
  PlanContext,
  usePlanContext,
  PlanContextProvider,
  PlanContextConsumer
};
