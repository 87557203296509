/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import SearchDropdown, { SearchDropdownProps } from './SearchDropdown';

const useSelectOverrideClasses = makeStyles(theme => ({
  root: {},
  input: {
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(1.5)
  },
  valueContainer: {
    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingLeft: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    }
  },
  clearIndicatorOverride: {
    alignSelf: 'center'
  },
  singleValue: {},
  error: {}
}));

const useIndicatorsContainerStyles = makeStyles(theme => ({
  indicatorsContainer: {
    position: 'absolute',
    top: 'calc(50% - 12px)',
    right: theme.spacing(1),
    display: 'flex'
  }
}));

export const IndicatorSeparator = (): React.ReactElement => <div />;

type IndicatorsContainerProps = {
  children: any;
};
export const IndicatorsContainer: React.FC<IndicatorsContainerProps> = ({
  children
}: IndicatorsContainerProps) => {
  const classes = useIndicatorsContainerStyles();

  return <div className={classes.indicatorsContainer}>{children}</div>;
};

IndicatorsContainer.propTypes = {
  children: PropTypes.any
};
const FilledSearchDropdown: React.FC<Partial<SearchDropdownProps>> = props => {
  const {
    labelClassName,
    errorClassName,
    TextFieldProps: OverrideTextFieldProps,
    label,
    components,
    ...rest
  } = props as any;

  const selectClasses = useSelectOverrideClasses(props);

  const FormHelperTextProps = useMemo(
    () => ({
      classes: {
        error: selectClasses.error
      }
    }),
    [selectClasses.error]
  );

  const TextFieldProps = useMemo(() => {
    const p = {
      variant: 'filled',
      label,
      InputLabelProps: {
        classes: {
          root: labelClassName,
          error: classNames(selectClasses.error, errorClassName)
        }
      },
      FormHelperTextProps,
      ...OverrideTextFieldProps
    };

    return p;
  }, [
    FormHelperTextProps,
    OverrideTextFieldProps,
    errorClassName,
    label,
    labelClassName,
    selectClasses.error
  ]);

  const overrideComponents = useMemo(
    () => ({
      IndicatorsContainer,
      IndicatorSeparator,
      ...components
    }),
    [components]
  );

  return (
    <SearchDropdown
      {...rest}
      classes={selectClasses}
      components={overrideComponents}
      TextFieldProps={TextFieldProps}
    />
  );
};

export default FilledSearchDropdown;
