import { DateTime } from 'luxon';
import {
  CostOptimizationResult,
  useUpcomingCostOptimizationResultQuery
} from 'generated-types';

export const useUpcomingCostOptimizationResult = (): {
  loading: boolean;
  costOptimizationResults: CostOptimizationResult[] | undefined | null;
} => {
  const date = DateTime.local();

  const { loading, data } = useUpcomingCostOptimizationResultQuery({
    variables: {
      date: {
        year: date.year,
        month: date.month,
        day: date.day
      }
    }
  });

  return {
    loading,
    costOptimizationResults: data?.upcomingCostOptimizationResult
      ?.filter(result => result)
      .reverse()
      .map(costCard => ({ ...costCard } as CostOptimizationResult))
  };
};
