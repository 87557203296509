import { SmartBudgetResult } from 'generated-types';

export enum ErrorCodes {
  NO_BILLABLE_ROLES = 'NO_BILLABLE_ROLES',
  NO_USERS_WITH_BILLABLE_ROLES = 'NO_USERS_WITH_BILLABLE_ROLES',
  USERS_WITH_DISABLED_ROLE = 'USERS_WITH_DISABLED_ROLE',
  ATLEAST_ONE_USER_WITH_DIRECT_COST = 'ATLEAST_ONE_USER_WITH_DIRECT_COST'
}

export enum WarningCodes {
  NO_COST_FOR_USERS = 'NO_COST_FOR_USERS'
}

export type Error = {
  code: ErrorCodes;
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: any;
};

export type CreatePlanWorkData = {
  planId: string;
  errors?: Error[];
  warnings?: Warning[];
};

export type Warning = {
  code: WarningCodes;
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: any;
};

export type UserCostWarning = {
  userCount: number;
  users: { displayText: string; uri: string }[];
};

export type RoleCostError = {
  roleCount: number;
  roles: { displayText: string; uri: string }[];
};

export type PlanSearchPartial = Pick<
  SmartBudgetResult,
  'id' | 'name' | 'margin' | 'utilization'
>;
