import React from 'react';
import { DateTime } from 'luxon';
import {
  SmartBudgetResultConnection,
  SmartBudgetResultEdge
} from 'generated-types';
import HistoryExpansionPanelContent from './HistoryExpansionPanelContent';

export interface HistoryExpansionPanelProps {
  data: SmartBudgetResultConnection;
}

const today = DateTime.local().toISODate();
export const HistoryExpansionPanel: React.FC<HistoryExpansionPanelProps> = ({
  data
}: HistoryExpansionPanelProps) => {
  return (
    <>
      {data.edges
        .filter(
          role => role.node.effectiveDate && role.node.effectiveDate > today
        )
        .map((file: SmartBudgetResultEdge) => (
          <HistoryExpansionPanelContent key={file.node.id} file={file} />
        ))}
    </>
  );
};

export default HistoryExpansionPanel;
