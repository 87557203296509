import {
  SmartBudgetResultConnection,
  PlanListSort,
  usePlansQuery
} from 'generated-types';

export const usePlans = ({
  id = null,
  count,
  fetchPolicy = 'network-only',
  sort,
  skip = false,
  searchText
}: {
  id?: string | null;
  count: number;
  // HACK: For unit testing. Queries with fragments & network-only requests
  // break MockedProvider for some reason.
  fetchPolicy?: 'network-only' | 'no-cache';
  sort?: PlanListSort;
  skip?: boolean;
  searchText?: string;
}): {
  loading: boolean;
  costOptimizationResults: SmartBudgetResultConnection | undefined;
} => {
  const { loading, data } = usePlansQuery({
    variables: { count, cursor: id, sort, searchText },
    skip,
    fetchPolicy
  });

  return {
    loading,
    costOptimizationResults: data?.smartBudgets
  };
};
