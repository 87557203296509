import React, { useMemo } from 'react';
import { Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SpeedIcon from '@material-ui/icons/Speed';
import { DateTime } from 'luxon';
import { useOverviewSummary } from 'overview/hooks';
import { DataCardHeader } from 'plans/plan/DataCard';
import { useMessage } from 'App/MessageProvider';
import { useOverviewDateRangeContext } from 'overview/context/OverviewDateRangeContext';
import { OverviewSummaryItem } from './OverviewSummaryItem';
import { DatePickerAction } from './components/DatePickerAction';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    margin: theme.spacing(3, 0, 3, 0),
    padding: theme.spacing(0, 3, 0, 3),
    justifyContent: 'space-between'
  },
  billingRateGoal: {
    [theme.breakpoints.up(1600)]: {
      display: 'flex'
    }
  },
  profit: {
    paddingRight: 0
  },
  value: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold
  },
  icon: { marginTop: theme.spacing(2), marginLeft: theme.spacing(2) },
  hyphen: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0, 0.5, 0, 0)
  },
  inputDate: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    width: 116
  },
  root: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  content: {
    flex: '0 1 auto'
  },
  action: {
    marginTop: 1,
    marginLeft: 20
  }
}));

export const OverviewSummaryCard: React.FC = () => {
  const classes = useStyles();
  const title = useMessage('overviewPage.overviewSummary.title');
  const avatar = <SpeedIcon />;
  const resultsAlignment = 'left';
  const { startDate, endDate } = useOverviewDateRangeContext();

  const { loading, overviewSummary } = useOverviewSummary(
    DateTime.fromISO(startDate).toObject(),
    DateTime.fromISO(endDate).toObject()
  );

  return (
    <Card>
      <DataCardHeader
        avatar={avatar}
        classes={useMemo(
          () => ({
            root: classes.root,
            content: classes.content,
            action: classes.action
          }),
          [classes.action, classes.content, classes.root]
        )}
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
        action={<DatePickerAction />}
      />
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={7} sm={5} md={4} lg={2}>
          <OverviewSummaryItem
            loading={loading}
            labelId="overviewPage.overviewSummary.utilization"
            actual={overviewSummary?.utilization?.actual?.toNumber() ?? 0}
            type="percent"
            goal={overviewSummary?.utilization?.budgeted?.toNumber() ?? 0}
            delta={overviewSummary?.utilization?.delta?.toNumber() ?? 0}
          />
        </Grid>
        <Grid item xs={7} sm={5} md={4} lg={2}>
          <OverviewSummaryItem
            loading={loading}
            type="percent"
            labelId="overviewPage.overviewSummary.margin"
            actual={overviewSummary?.margin?.actual?.toNumber() ?? 0}
            goal={overviewSummary?.margin?.budgeted?.toNumber() ?? 0}
            delta={overviewSummary?.margin?.delta?.toNumber() ?? 0}
          />
        </Grid>
        <Grid item xs={7} sm={6} md={4} lg={2}>
          <OverviewSummaryItem
            loading={loading}
            type="money"
            labelId="overviewPage.overviewSummary.billingRate"
            actual={
              overviewSummary?.billingRate?.actual?.amount?.toNumber() ?? 0
            }
            goal={
              overviewSummary?.billingRate?.budgeted?.amount?.toNumber() ?? 0
            }
            delta={overviewSummary?.billingRate?.deltaPercent?.toNumber() ?? 0}
            currencySymbol={
              overviewSummary?.billingRate?.budgeted?.currency?.symbol
            }
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={2}>
          <OverviewSummaryItem
            loading={loading}
            type="money"
            labelId="overviewPage.overviewSummary.revenue"
            align={resultsAlignment}
            actual={overviewSummary?.revenue?.actual?.amount?.toNumber() ?? 0}
            goal={overviewSummary?.revenue?.budgeted?.amount?.toNumber() ?? 0}
            delta={overviewSummary?.revenue?.deltaPercent?.toNumber() ?? 0}
            currencySymbol={
              overviewSummary?.revenue?.budgeted?.currency?.symbol
            }
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={2}>
          <OverviewSummaryItem
            loading={loading}
            type="money"
            labelId="overviewPage.overviewSummary.totalCost"
            align={resultsAlignment}
            actual={overviewSummary?.totalCost?.actual?.amount?.toNumber() ?? 0}
            goal={overviewSummary?.totalCost?.budgeted?.amount?.toNumber() ?? 0}
            delta={overviewSummary?.totalCost?.deltaPercent?.toNumber() ?? 0}
            currencySymbol={
              overviewSummary?.totalCost?.budgeted?.currency?.symbol
            }
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={2} className={classes.profit}>
          <OverviewSummaryItem
            loading={loading}
            type="money"
            labelId="overviewPage.overviewSummary.profit"
            align={resultsAlignment}
            actual={overviewSummary?.profit?.actual?.amount?.toNumber() ?? 0}
            goal={overviewSummary?.profit?.budgeted?.amount?.toNumber() ?? 0}
            delta={overviewSummary?.profit?.deltaPercent?.toNumber() ?? 0}
            currencySymbol={overviewSummary?.profit?.budgeted?.currency?.symbol}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
