import React, { useCallback, useContext, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Maybe, Money } from 'generated-types';

export type PlanSummaryValues = {
  totalCost?: Maybe<Money>;
  utilization?: BigNumber;
  billingRate?: Maybe<Money>;
  margin?: BigNumber;
  revenue?: Maybe<Money>;
  profit?: Maybe<Money>;
};

export type PlanSummaryContextValue = PlanSummaryValues & {
  updateSummary: (planSummaryValue: PlanSummaryValues) => void;
};

const PlanSummaryContext = React.createContext<Maybe<PlanSummaryContextValue>>(
  null
);

export const usePlanSummaryContext = (): PlanSummaryContextValue => {
  const val = useContext(PlanSummaryContext);
  if (!val) {
    throw new Error(
      'usePlanSummaryContext must be called within a PlanSummaryContextProvider with a resolved PlanSummary'
    );
  }

  return val;
};

type PlanTotalContextProviderProps = {
  children: React.ReactNode;
};

export const PlanSummaryContextProvider: React.FC<PlanTotalContextProviderProps> = ({
  children
}: PlanTotalContextProviderProps) => {
  const [planSummaryValue, setPlanSummaryValue] = useState<PlanSummaryValues>(
    {}
  );
  const updateSummary = useCallback((value: PlanSummaryValues) => {
    setPlanSummaryValue(value);
  }, []);
  return (
    <PlanSummaryContext.Provider
      value={useMemo(() => ({ ...planSummaryValue, updateSummary }), [
        planSummaryValue,
        updateSummary
      ])}>
      {children}
    </PlanSummaryContext.Provider>
  );
};
export const PlanSummaryContextConsumer = PlanSummaryContext.Consumer;
