import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import {
  ProgressButton,
  SetRatesConfirmationDialog,
  Button
} from 'common/components';
import { useDialogState } from 'common/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 4, 5, 4),
    '& > *': {
      margin: theme.spacing(0, 1, 0, 1)
    }
  }
}));

export type EditRateCardActionsProps = {
  discarding: boolean;
  committing: boolean;
  saving: boolean;
  effectiveDate: string;
  endDate?: string | null;
  canSaveRateCard: boolean;
  cancelEditing: () => void;
  commitRateCard: (endDate: DateTime) => void;
  saveRateCard: () => void;
};

export const EditRateCardActions: React.FC<EditRateCardActionsProps> = ({
  discarding,
  committing,
  saving,
  canSaveRateCard,
  effectiveDate,
  endDate,
  cancelEditing,
  commitRateCard,
  saveRateCard
}: EditRateCardActionsProps) => {
  const classes = useStyles();

  const {
    open: setRatesConfirmationOpen,
    openDialog: setRatesConfirmationOpenDialog,
    closeDialog: setRatesConfirmationCloseDialog
  } = useDialogState();

  return (
    <div className={classes.root}>
      {setRatesConfirmationOpen && (
        <SetRatesConfirmationDialog
          open={setRatesConfirmationOpen}
          closeDialog={setRatesConfirmationCloseDialog}
          commitRateCard={commitRateCard}
          effectiveDate={effectiveDate}
          endDate={endDate}
        />
      )}
      <Button
        color="primary"
        variant="contained"
        labelId="activePage.setRates"
        disabled={committing}
        onClick={setRatesConfirmationOpenDialog}
      />
      <ProgressButton
        variant="contained"
        color="default"
        labelId="activePage.saveRates"
        loading={saving}
        disabled={!canSaveRateCard || saving || discarding}
        successSnackbarLabelId="activePage.saveRatesSuccess"
        failureSnackbarLabelId="activePage.saveRatesFailure"
        onClick={saveRateCard}
      />
      <ProgressButton
        color="primary"
        variant="text"
        labelId="activePage.cancel"
        onClick={cancelEditing}
        loading={discarding}
      />
    </div>
  );
};

export default EditRateCardActions;
