import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { plansPageRoute } from 'plans';
import { importPageRoute } from 'import';
import { useRouteBase } from 'App/EmbeddedAppContext';
import { overviewPageRoute } from 'overview/overviewRoute';
import { useTabNavigationContext } from 'common/context';
import { useFeatureFlag } from 'common/hooks';
import { CpoRouteProps } from './Routes';

export const newRootRoutes = (
  prefix: string,
  dashboardEnabled = false
): CpoRouteProps[] =>
  [
    dashboardEnabled && overviewPageRoute(prefix),
    plansPageRoute(prefix),
    importPageRoute(prefix)
  ].filter((route): route is CpoRouteProps => Boolean(route));

const AppRoot: React.FC = () => {
  const dashBoard = useFeatureFlag('cpoDashboard');
  const baseRoute = useRouteBase();
  const routes = newRootRoutes(baseRoute, dashBoard);

  const { tabValue } = useTabNavigationContext();

  return (
    <Switch>
      {routes.map(r => {
        const path = Array.isArray(r.path) ? r.path[0] : r.path;
        return <Route key={path} {...r} />;
      })}
      <Route path={baseRoute}>
        <Redirect from={baseRoute} to={`${baseRoute}/${tabValue}`} />
      </Route>
      <Route exact path="/">
        <Redirect from="/" to={`${baseRoute}/${tabValue}`} />
      </Route>
    </Switch>
  );
};

export default AppRoot;
