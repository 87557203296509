import { useEffect, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import {
  PlanTotalContextValue,
  useLocalBusinessCostContext,
  usePlanSummaryContext
} from 'plans/plan/context';
import { SmartBudgetResult } from 'generated-types';

export const usePropagatePlanSummaryChanges = ({
  planTotalContextValue,
  plan
}: {
  planTotalContextValue: PlanTotalContextValue;
  plan: SmartBudgetResult;
}): void => {
  const { updateSummary } = usePlanSummaryContext();
  const { businessCostsTotal } = useLocalBusinessCostContext();
  const totalCost = useMemo(
    () => ({
      amount: (plan.totalResourceCost?.amount ?? new BigNumber(0)).plus(
        businessCostsTotal.amount ?? new BigNumber(0)
      ),
      currency: plan.currency
    }),
    [businessCostsTotal, plan.currency, plan.totalResourceCost]
  );
  useEffect(() => {
    updateSummary({
      totalCost: totalCost,
      utilization: planTotalContextValue.utilization,
      billingRate: planTotalContextValue.planTotals?.billingRate,
      margin: planTotalContextValue.margin,
      revenue: planTotalContextValue.revenue,
      profit: planTotalContextValue.profit
    });
  }, [
    plan.currency,
    planTotalContextValue.margin,
    planTotalContextValue.planTotals,
    planTotalContextValue.profit,
    planTotalContextValue.revenue,
    planTotalContextValue.utilization,
    totalCost,
    updateSummary
  ]);
};
