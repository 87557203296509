import React, { MutableRefObject, useCallback, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Action, History, Location, UnregisterCallback } from 'history';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  useDialogState,
  useDiscardCostOptimizationResult,
  useIsBreakpointDown,
  usePlanBlock
} from 'common/hooks';
import { Button } from '../Button';
import { Label } from '../Label';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: theme.spacing(1.5, 2)
  },
  dialogContent: {
    padding: theme.spacing(0, 2)
  },
  info: {
    textAlign: 'left',
    color: theme.palette.grey[600],
    padding: '0 0 20px 0'
  }
}));

export interface UploadDiscardDialogProps {
  id: string;
  unblockRef: MutableRefObject<UnregisterCallback | undefined>;
  block?: boolean;
}

export type BlockAction = {
  location: Location;
  action: Action;
};

export const discardHandler = (
  blockedAction: BlockAction | null,
  closeDialog: () => void,
  history: History,
  unblockNavigation: () => void
): void => {
  closeDialog();
  if (blockedAction?.action === 'POP') {
    history.goBack();
    return;
  }

  unblockNavigation();
  if (blockedAction) {
    history.push(blockedAction?.location);
  }
};

export const UploadDiscardDialog: React.FC<UploadDiscardDialogProps> = ({
  id,
  unblockRef,
  block = true
}: UploadDiscardDialogProps) => {
  const classes = useStyles();
  const { open, openDialog, closeDialog } = useDialogState();
  const history = useHistory();
  const [blockedAction, setBlockedAction] = useState<BlockAction | null>(null);
  const { unblockNavigation } = usePlanBlock(
    block,
    unblockRef,
    openDialog,
    setBlockedAction
  );

  const discardDataHandler = useCallback(
    () =>
      discardHandler(blockedAction, closeDialog, history, unblockNavigation),
    [blockedAction, closeDialog, history, unblockNavigation]
  );
  const { discardCostOptimizationResult } = useDiscardCostOptimizationResult(
    id ?? '',
    discardDataHandler
  );

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      fullScreen={useIsBreakpointDown('sm')}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Label variant="h6" labelId="uploadDiscard.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Label
          variant="body1"
          className={classes.info}
          labelId="uploadDiscard.info"
        />
        <Label
          variant="body1"
          className={classes.info}
          labelId="uploadDiscard.info2"
        />
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            <FormattedMessage id="uploadDiscard.cancel" />
          </Button>
          <Button onClick={discardCostOptimizationResult} color="secondary">
            <FormattedMessage id="uploadDiscard.delete" />
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
