import React, { Suspense } from 'react';
import { LinearProgress, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Link,
  match,
  Route,
  RouteProps,
  Switch,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import classnames from 'classnames';
import StickyHeader from 'common/components/StickyHeader';
import { CpoRouteProps } from '../../Routes';

const useStyles = makeStyles({
  tabs: {
    flexGrow: 1,
    flexShrink: 1
  }
});

type RouteTabsProps = {
  routes: CpoRouteProps[];
  className?: string;
  children?: React.ReactNode;
};

export const buildLinkPath = (
  pathMatch: match,
  dynamicPath?: string
): string | undefined => {
  const pathEndsWithSlash = pathMatch.path.endsWith('/');
  const urlEndsWithSlash = pathMatch.url.endsWith('/');

  return dynamicPath?.replace(
    pathMatch.path,
    `${pathMatch.url}${pathEndsWithSlash && !urlEndsWithSlash ? '/' : ''}`
  );
};

export const findTabValue = (
  tabRoutes: CpoRouteProps[],
  currentPath: string,
  match: match
): number => {
  const tabIndex = tabRoutes.findIndex(route => {
    const linkPath = buildLinkPath(
      match,
      Array.isArray(route.path) ? route.path[0] : route.path
    );

    return linkPath && route.exact === false
      ? currentPath.includes(linkPath)
      : linkPath === currentPath;
  });

  return tabIndex === -1 ? 0 : tabIndex;
};

export const RouteTabs: React.FC<RouteTabsProps> = ({
  routes,
  className,
  children
}: RouteTabsProps) => {
  const location = useLocation();
  const match = useRouteMatch();
  const classes = useStyles();
  return (
    <>
      <StickyHeader>
        <Tabs
          value={findTabValue(routes, location.pathname, match)}
          indicatorColor="primary"
          className={classnames(classes.tabs, className)}>
          {routes.map((route: CpoRouteProps) => {
            const path = Array.isArray(route.path) ? route.path[0] : route.path;
            return (
              <Tab
                key={path}
                label={route.title}
                component={Link}
                to={route.link ? route.link : buildLinkPath(match, path) ?? '/'}
              />
            );
          })}
        </Tabs>
      </StickyHeader>

      <Suspense fallback={<LinearProgress />}>
        <Switch>
          {routes.map((route: RouteProps) => {
            return (
              <Route
                key={Array.isArray(route.path) ? route.path[0] : route.path}
                {...route}
              />
            );
          })}
          {children}
        </Switch>
      </Suspense>
    </>
  );
};

export default RouteTabs;
