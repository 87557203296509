import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ScreenSizeNotSupportedPage } from 'common/components';
import { PlansListPage } from './list';
import { PlanPage } from './plan';

/**
 * The following routes exist here:
 *  Plan list page:     https://path.to.psa.replicon.com/cost-price-optimizer/plans
 *  View and edit plan: https://path.to.psa.replicon.com/cost-price-optimizer/plans/:planid
 */
const PlansRouteProxy: React.FC = () => {
  const { url } = useRouteMatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return <ScreenSizeNotSupportedPage />;
  }

  return (
    <Switch>
      <Route path={`${url}/`} exact component={PlansListPage} />
      <Route path={`${url}/:planId`} exact component={PlanPage} />
    </Switch>
  );
};

export default PlansRouteProxy;
