import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import BigNumber from 'bignumber.js';
import { SummaryCard, NumberValue, MoneyValue } from 'common/components';
import { Maybe, Money } from 'generated-types';
import { useDialogState } from 'common/hooks';
import RoleCostItem from './RoleCostItem';
import { ExchangeRateDialog } from './components/ExchangeRateDialog';
import { RoleCostSkeleton } from './components/RoleCostSkeleton';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  costContainer: {
    display: 'flex',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '40%'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1.5),
      paddingLeft: 0
    },
    paddingLeft: theme.spacing(1.5),
    height: 136
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
export interface RoleCostSummaryProps {
  costDataSummary: {
    userCount: number | null | undefined;
    billableUserCount: number | null | undefined;
    nonBillableUserCount: number | null | undefined;
  };
  currencies?: {
    id: string;
    currencyTotals: Maybe<Maybe<Money | undefined>[]>;
    totalCost?: Maybe<Money>;
    billableCost?: Maybe<BigNumber>;
    nonBillableCost?: Maybe<BigNumber>;
    overheadCost?: Maybe<BigNumber>;
  } | null;
  loading: boolean;
}

export const RoleCostSummary: React.FC<RoleCostSummaryProps> = ({
  costDataSummary,
  currencies,
  loading
}: RoleCostSummaryProps) => {
  const classes = useStyles();

  const { open, openDialog, closeDialog } = useDialogState();
  return (
    <>
      {loading && <RoleCostSkeleton />}
      {costDataSummary && currencies && (
        <>
          <div className={classes.costContainer}>
            <SummaryCard>
              <div className={classes.title}>
                <FormattedMessage id="costDataSummary.title" />
                &nbsp; ({currencies.totalCost?.currency?.symbol?.toString()})
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <RoleCostItem messageId="costDataSummary.currencyCount">
                    <NumberValue
                      value={currencies?.currencyTotals?.length ?? 0}
                    />
                  </RoleCostItem>
                  <RoleCostItem messageId="costDataSummary.overhead">
                    <MoneyValue
                      value={currencies.overheadCost?.toNumber() ?? 0}
                      currencySymbol={currencies?.totalCost?.currency?.symbol}
                    />
                  </RoleCostItem>
                </Grid>
                <Grid item xs={4}>
                  <RoleCostItem messageId="costDataSummary.billable">
                    <MoneyValue
                      value={currencies.billableCost?.toNumber() ?? 0}
                      currencySymbol={currencies.totalCost?.currency?.symbol}
                    />
                  </RoleCostItem>
                  <RoleCostItem messageId="costDataSummary.totalCost">
                    <MoneyValue
                      value={currencies.totalCost?.amount?.toNumber() ?? 0}
                      currencySymbol={currencies.totalCost?.currency?.symbol}
                    />
                  </RoleCostItem>
                </Grid>
                <Grid item xs={4}>
                  <RoleCostItem messageId="costDataSummary.nonBillable">
                    <MoneyValue
                      value={currencies.nonBillableCost?.toNumber() ?? 0}
                      currencySymbol={currencies.totalCost?.currency?.symbol}
                    />
                  </RoleCostItem>
                </Grid>
                <Grid item xs={12} className={classes.button}>
                  <Button
                    component="button"
                    color="primary"
                    onClick={openDialog}>
                    <FormattedMessage id="costDataSummary.costInfoButton" />
                  </Button>
                </Grid>
              </Grid>
            </SummaryCard>
          </div>
          <ExchangeRateDialog
            open={open}
            closeDialog={closeDialog}
            currencies={currencies?.currencyTotals}
            baseCurrency={currencies.totalCost?.currency?.symbol?.toString()}
            totalCost={currencies.totalCost}
          />
        </>
      )}
    </>
  );
};

export default RoleCostSummary;
