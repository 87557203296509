import React, { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Snackbar,
  SnackbarProps,
  SnackbarOrigin,
  SnackbarContent
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import green from '@material-ui/core/colors/green';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
};

const anchorOrigin: SnackbarOrigin = { horizontal: 'left', vertical: 'bottom' };
type Variant = 'success' | 'error';

export interface AlertSnackbarProps extends SnackbarProps {
  labelId?: string;
  variant: Variant;
  onClose?: () => void;
}

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.light
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export const AlertSnackbar: React.FC<AlertSnackbarProps> = ({
  labelId,
  classes,
  variant,
  className,
  open,
  onClose,
  ...rest
}: AlertSnackbarProps) => {
  const cls = useStyles({ classes });
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const Icon = useMemo(() => variantIcon[variant], [variant]);
  const message = useMemo(
    () => (
      <span className={cls.message}>
        <Icon className={classnames(cls.icon, cls.iconVariant)} />
        {labelId && <FormattedMessage id={labelId} />}
      </span>
    ),
    [cls.icon, cls.iconVariant, cls.message, labelId]
  );
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      {...rest}
      onClose={handleClose}
      open={open}>
      <SnackbarContent
        className={classnames(cls[variant], className)}
        message={message}
      />
    </Snackbar>
  );
};
