import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  quotient: {
    display: 'flex',
    alignItems: 'center'
  },
  numerator: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grey[600]}`
  },
  denominator: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export interface CalculationInfoDivisionProps {
  numerator: string;
  denominator: string;
  quotient: string;
}

export const CalculationInfoDivision: React.FC<CalculationInfoDivisionProps> = ({
  numerator,
  denominator,
  quotient
}: CalculationInfoDivisionProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.quotient}>
        <FormattedMessage id={quotient} />
        &nbsp;=&nbsp;
      </div>
      <div>
        <div className={classes.numerator}>
          <FormattedMessage id={numerator} />
        </div>
        <div className={classes.denominator}>
          <FormattedMessage id={denominator} />
        </div>
      </div>
    </div>
  );
};

export default CalculationInfoDivision;
